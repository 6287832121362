import { format } from 'date-fns';
import React, { useState } from 'react';
import { Divider, Grid, Image, Label, Segment } from 'semantic-ui-react';
import { collectSubProducts, getCurrencyPrefix } from 'shared/utils';
import styled from 'styled-components';
import CaretDown from '../../assets/icons/caretDown.png';
import CaretUp from '../../assets/icons/caretUp.png';
import chatIcon from '../../assets/icons/chatIcon.png';
import { ILineItem, OrderType } from '../../shared/models/Cart';
import { Order, OrderStatus } from '../../shared/models/Order';
import { Step, Stepper } from '../Stepper';
import { OpenOrderContainer, PastOrderContainer, PastOrderSummary } from './styled';

const OrderDateTime = styled.div`
  margin-bottom: 10px;
`;
interface OrderStatusProps {
  onChatIconClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  order: Order;
}

interface OpenOrderStatusProps extends OrderStatusProps {
  hideChatIcon?: boolean;
  scheduledTime: Date;
}

interface OrderItemsProps {
  summary: Order;
}

enum OrderStepStatus {
  NEW = 'NEW',
  IN_PROCESS = 'IN_PROCESS',
  FINALIZING = 'FINALIZING',
  READY = 'READY',
}

export const getOrderStatus = (order: Order) => {
  switch (order.status) {
    case OrderStatus.IN_PROCESS:
    case OrderStatus.READY_FOR_RUNNER:
      return OrderStepStatus.IN_PROCESS;
    case OrderStatus.READY_FOR_PICKUP:
    case OrderStatus.OUT_FOR_DELIVERY:
      return OrderStepStatus.READY;
    case OrderStatus.FULFILLED:
    case OrderStatus.DONE:
      return OrderStepStatus.FINALIZING;
    default:
      return OrderStepStatus.NEW;
  }
};

const OrderItems = ({ summary }: OrderItemsProps) => {
  const currencyPrefix = getCurrencyPrefix(summary.currency);
  const renderItems = () => {
    return summary.lineItems.map((entry: ILineItem) => {
      // eslint-disable-next-line prefer-const
      let subProducts: any[] = [];
      collectSubProducts(entry.subSelections || [], subProducts);

      return (
        <React.Fragment key={entry.id}>
          <Grid.Row columns={2}>
            <Grid.Column textAlign="left" width={10}>
              {`${entry.name}${entry.quantity > 1 ? ` x${entry.quantity}` : ''}`}
            </Grid.Column>
            <Grid.Column textAlign="right" width={6}>
              {currencyPrefix}
              {Number(entry.productPrice * entry.quantity).toFixed(2)}
            </Grid.Column>
          </Grid.Row>
          {subProducts.map((sp: any, ii: number) => (
            <Grid.Row columns={2} key={ii}>
              <Grid.Column textAlign="left" width={10}>
                {`${sp.name}${entry.quantity > 1 ? ` x${entry.quantity}` : ''}`}
              </Grid.Column>
              <Grid.Column textAlign="right" width={6}>
                {currencyPrefix}
                {Number(sp.price * entry.quantity).toFixed(2)}
              </Grid.Column>
            </Grid.Row>
          ))}
          {entry.quantityRefunded > 0 && (
            <Grid.Row columns={2} className="refunded-column">
              <Grid.Column textAlign="left" width={10}>
                {`Quantity Refunded: x${entry.quantityRefunded}`}
              </Grid.Column>
              <Grid.Column textAlign="right" width={6}>
                {currencyPrefix}
                {Number(entry.total * entry.quantityRefunded).toFixed(2)}
              </Grid.Column>
            </Grid.Row>
          )}
        </React.Fragment>
      );
    });
  };
  return (
    <>
      <Grid container>{renderItems()}</Grid>
      <Divider />
      <Grid container className="totals">
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">Subtotal</Grid.Column>
          <Grid.Column textAlign="right">
            {currencyPrefix}
            {Number(summary.subTotal).toFixed(2)}
          </Grid.Column>
        </Grid.Row>
        {summary.discountAmt > 0 && (
          <Grid.Row columns={2}>
            <Grid.Column textAlign="left">Discount</Grid.Column>
            <Grid.Column textAlign="right">
              {currencyPrefix}
              {Number(summary.discountAmt).toFixed(2)}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">Service Charge</Grid.Column>
          <Grid.Column textAlign="right">
            {currencyPrefix}
            {Number(summary.convenienceFee).toFixed(2)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">Tip</Grid.Column>
          <Grid.Column textAlign="right">
            {currencyPrefix}
            {Number(summary.tipAmt).toFixed(2)}
          </Grid.Column>
        </Grid.Row>
        {summary.taxAmt > 0 && (
          <Grid.Row columns={2}>
            <Grid.Column textAlign="left">Tax</Grid.Column>
            <Grid.Column textAlign="right">
              {currencyPrefix}
              {Number(summary.taxAmt).toFixed(2)}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2} className="bold">
          <Grid.Column textAlign="left">Total</Grid.Column>
          <Grid.Column textAlign="right">
            {currencyPrefix}
            {Number(summary.total).toFixed(2)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export function OpenOrderStatus({
  onChatIconClick,
  scheduledTime,
  hideChatIcon = false,
  order,
}: OpenOrderStatusProps) {
  const [displaySummary, setDisplaySummary] = useState(false);
  const pickup = order.type === OrderType.PICKUP;

  const currentStep = getOrderStatus(order);

  const formatDateTimeString = () => {
    const dateTime = new Date(scheduledTime);
    const formattedDateTime = format(dateTime, 'M/d @ h:mm a');
    if (pickup) {
      return `Est. Pick Up ${formattedDateTime}`;
    }
    return `Est. Delivery ${formattedDateTime}`;
  };

  return (
    <OpenOrderContainer showSummary={displaySummary}>
      <Segment style={{ borderRadius: '10px' }}>
        {!hideChatIcon && (
          <Image
            onClick={onChatIconClick}
            className="chat-icon"
            src={chatIcon}
            wrapped
            ui={false}
          />
        )}
        <div className="title">Order #{order.orderNo}</div>
        {scheduledTime && <OrderDateTime>{`${formatDateTimeString()}`}</OrderDateTime>}
        {/* Intentionally repetitive; prevents error of undefined JSX elements */}
        {pickup ? (
          <Stepper direction="horizontal" activeKey={currentStep}>
            <Step key="NEW">Kitchen Alerted</Step>
            <Step key="IN_PROCESS">Being Prepared</Step>
            <Step key="READY">Ready for Pick Up</Step>
          </Stepper>
        ) : (
          <Stepper direction="horizontal" activeKey={currentStep}>
            <Step key="NEW">Kitchen Alerted</Step>
            <Step key="IN_PROCESS">Being Prepared</Step>
            <Step key="READY">Delivering</Step>
            <Step key="FINALIZING">Delivered</Step>
          </Stepper>
        )}
        <div className="toggle_details" onClick={() => setDisplaySummary(!displaySummary)}>
          <Image
            className={`caret-dropdown ${displaySummary ? 'active' : ''}`}
            src={displaySummary ? CaretUp : CaretDown}
          />
        </div>
        {displaySummary && (
          <Segment className="order_details">
            <OrderItems summary={order} />
          </Segment>
        )}
      </Segment>
    </OpenOrderContainer>
  );
}

export function PastOrderStatus({ order, onChatIconClick }: OrderStatusProps) {
  const [displaySummary, setDisplaySummary] = useState(false);
  const orderDate = new Date(order.createdTime);

  return (
    <PastOrderContainer>
      <Segment style={{ borderRadius: '10px' }}>
        <Image onClick={onChatIconClick} className="chat-icon" src={chatIcon} wrapped ui={false} />
        <Grid container>
          <div className="title" onClick={() => setDisplaySummary(!displaySummary)}>
            {order && <span className="store">{order.store.name}</span>}
            Order #{order.orderNo}
            <Image src={displaySummary ? CaretUp : CaretDown} />
          </div>
          <div className="date">{format(orderDate!, 'MM/dd/yy')}</div>
        </Grid>
        {displaySummary ? (
          <PastOrderSummary>
            {(order.refundStatus === 'PARTIAL' || order.refundStatus === 'NOT_APPLICABLE') && (
              <Label>Done</Label>
            )}
            {order.refundStatus === 'FULL' && <Label>Refunded</Label>}
            <OrderItems summary={order} />
          </PastOrderSummary>
        ) : null}
      </Segment>
    </PastOrderContainer>
  );
}
