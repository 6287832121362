import { PaymentGateway } from '../shared/models/Venue';
import { APIService } from './APIService';

const baseAuthPath = '/auth';
const baseUserPath = '/users';

export type OTPType = {
  phoneNumber: string;
  countryCode: string;
};

export type VerifyOTPType = {
  phoneNumber: string;
  countryCode: string;
  otpCode: string;
  createUser: boolean;
};

export type UserType = {
  email: string;
  firstName: string;
  lastName: string;
};

export type NotificationType = {
  sendSMSNotifications?: boolean;
  sendPushNotifications?: boolean;
};

export type RegisterDevice = {
  deviceId: string;
  deviceOS: string;
};

export interface AddCardDto {
  paymentMethodId: string;
  paymentGateway: PaymentGateway;
}

export class UserService {
  static async GetCurrentUser(): Promise<any> {
    return await APIService.getData(`${baseUserPath}/current`);
  }

  static async UpdateUser(id: string, user: UserType): Promise<any> {
    return await APIService.updateData(`${baseUserPath}/${id}`, user);
  }

  static async UpdateNotifications(id: string, payload: NotificationType): Promise<any> {
    return await APIService.updateData(`${baseUserPath}/${id}`, payload);
  }

  static async SaveCard(id: string, addCardDto: AddCardDto): Promise<any> {
    return await APIService.postData(`${baseUserPath}/${id}/card`, addCardDto);
  }

  static async GetStoredCards(
    id: string,
    gameDayOrder?: boolean,
    { paymentGateways }: { paymentGateways?: PaymentGateway[] } = {},
  ): Promise<any> {
    return await APIService.getData(`${baseUserPath}/${id}/cards`, {
      ...(paymentGateways && { paymentGateways: paymentGateways.join(','), gameDayOrder }),
    });
  }

  static async DeleteStoredCard(userId: string, cardId: string): Promise<any> {
    return await APIService.deleteData(`${baseUserPath}/${userId}/cards/${cardId}`);
  }

  static async SendOtp(payload: OTPType): Promise<any> {
    return await APIService.postData(`${baseAuthPath}/sendotp`, payload);
  }

  static async VerifyOtp(payload: VerifyOTPType): Promise<any> {
    return await APIService.postData(`${baseAuthPath}/verify-otp`, payload);
  }

  static async RegisterDevice(id: string, payload: RegisterDevice) {
    return await APIService.postData(`users/${id}/register`, payload);
  }
}
