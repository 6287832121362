import { APIService } from './APIService';

const basePath = '/auth/twilio';

export type GetAccessTokenType = {
  username: string;
  deviceOS?: string;
};

export class ChatService {
  static async GetAccessToken(payload: GetAccessTokenType): Promise<any> {
    return await APIService.postData(`${basePath}`, payload);
  }
}
