import React from 'react';
import { colors, fonts } from 'shared/theme';
import styled, { css, keyframes } from 'styled-components';
import { Modal, Image } from 'semantic-ui-react';
import closeSearchBtn from '../../../assets/icons/closeSearchBtn.png';
import { ModalButton } from 'components/Atoms/Button';

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${fonts.BOLD};
  font-size: 18px;
  letter-spacing: -0.2px;
  text-align: center;
  margin-bottom: 15px;
`;

const closeStyle = {
  width: '12px',
  height: '12px',
};

const ModalContent = styled.div`
  font-family: ${fonts.REGULAR};
  color: ${colors.black};
  font-size: 18px;
  letter-spacing: -0.2px;
  text-align: center;
  line-height: normal;
`;

const ButtonCopy = styled.span`
  top: 1px;
  position: relative;
`;

const noButtonStyles = `
  position: absolute;
`;

const fadeInUpAnimation = keyframes`
  from {
    transform: translate3d(0,40px,0)
  }
  to {
    opacity: 1
  }
`;

const StyledModal = styled(Modal)`
  opacity: 0;
  animation-name: ${fadeInUpAnimation};
  animation-duration: 0.5;
  border-radius: 10px;
`;

const CloseIconWrapper = styled.div<{ noButton: boolean }>`
  display: flex;
  justify-content: flex-end;
  top: 15px;
  right: 15px;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: fixed;
    height: 44px;
    width: 44px;
    top: 0;
    right: 0;
  }

  ${({ noButton }) =>
    noButton
      ? css`
          ${noButtonStyles}
        `
      : ''};
`;

type ButtonProps = {
  copy: string;
  outline?: boolean;
  onClick: () => void;
};

const Button = ({ copy, outline, onClick }: ButtonProps) => (
  <ModalButton onClick={onClick} outline={outline}>
    <ButtonCopy>{copy}</ButtonCopy>
  </ModalButton>
);

type TitleProps = {
  copy: string;
};

const Title = ({ copy }: TitleProps) => <ModalTitle>{copy}</ModalTitle>;

type ContentProps = {
  children: React.ReactNode;
};

const Content = ({ children }: ContentProps) => <ModalContent>{children}</ModalContent>;

type ModalProps = {
  hideCloseX?: boolean;
  showModal: boolean;
  onCloseModal: any;
  children: React.ReactNode[];
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  noButton?: boolean;
};

const AnimatedModal = ({
  hideCloseX,
  showModal,
  onCloseModal,
  children,
  size = 'mini',
  noButton = false,
}: ModalProps) => {
  return (
    <StyledModal
      aria-hidden={!showModal}
      centered={true}
      open={showModal}
      dimmer={true}
      onClose={onCloseModal}
      closeIcon={
        !hideCloseX && (
          <CloseIconWrapper role='button' aria-label='close modal' noButton={noButton}>
            <Image style={closeStyle} alt='Close Button' src={closeSearchBtn} />
          </CloseIconWrapper>
        )
      }
      size={size}
    >
      <Modal.Content style={{ borderRadius: '10px' }}>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
    </StyledModal>
  );
};

AnimatedModal.Button = Button;
AnimatedModal.Title = Title;
AnimatedModal.Content = Content;

export default AnimatedModal;
