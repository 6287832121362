import OpenTab from 'shared/models/OpenTab';
import { ApiResponse, APIService } from './APIService';

const basePath = '/opentabs';

export interface OpenTabDto {
  name: string;
  tabBalance: number;
  userId?: string;
  suiteId?: string;
  eventId?: string;
  alcoholAllowed: boolean;
  isActive: boolean;
}

export interface OpenTabUpdateDto {
  name?: string;
  tabBalance: number | undefined;
  userId?: string;
  suiteId?: string;
  eventId?: string;
  alcoholAllowed?: boolean;
  isActive?: boolean;
}

export interface InviteDto {
  phoneNumber: string;
  countryCode: String;
  name: string;
  tabOwner?: String;
  message: string;
  link: string;
}

export interface SaveGuestDto {
  phoneNumber: string;
  name: string;
  deliveryLocationId?: string;
  eventId?: string;
  venueId?: string;
  tabId?: string;
  suiteName?: string;
  tabOwnerName?: string;
  countryCode?: string;
}

export interface InviteModel {
  message?: string;
  statusCode?: number;
}

export class OpenTabService {
  static async GetTab(suiteId: String, eventId: String): Promise<ApiResponse<OpenTab>> {
    return await APIService.getData(`${basePath}/${suiteId}/${eventId}`);
  }

  static async SaveTab(data: OpenTabDto): Promise<ApiResponse<OpenTab>> {
    return await APIService.postData(`${basePath}/save`, data);
  }

  static async UpdateTab(data: OpenTabUpdateDto, openTabId: string): Promise<ApiResponse<OpenTab>> {
    return await APIService.postData(`${basePath}/update/${openTabId}`, data);
  }

  static async SendInvite(data: InviteDto): Promise<any> {
    return await APIService.postData(`${basePath}/sendinvite`, data);
  }

  static async SaveGuest(data: SaveGuestDto): Promise<any> {
    return await APIService.postData(`${basePath}/save-guests`, data);
  }
}
