import { Grid } from 'semantic-ui-react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

const StyledColumn = styled(Grid.Column)<{ index: number; fade: boolean }>`
  margin-top: ${({ index }) => (index > 2 ? `30px !important` : '')};
  opacity: ${({ fade }) => (fade ? 0.5 : 1)};
`;

const PriceQuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .product-price {
    font-size: 18px;
    font-family: ${fonts.REGULAR};
    color: ${colors.charcoalGrey};
  }
`;

const QuantityControlContainer = styled.div`
  display: flex;
  align-content: end;
  justify-content: space-evenly;
  .quantity {
    font-family: ${fonts.MEDIUM};
    color: ${colors.charcoalGrey} !important;
    font-size: 16px;
    margin: 5px;
  }

  i.circular.icon {
    background-color: hsl(180, 3.4482758620689524%, 94.31372549019608%);
    color: rgb(183, 183, 183);
    box-shadow: none !important;
    width: 2em !important;
    height: 2em !important;
    line-height: 1 !important;
    cursor: pointer;
  }
`;

export { StyledColumn, QuantityControlContainer, PriceQuantityContainer };
