import React, { ReactNode } from 'react';
import { StepperContainer, StepContainer, StepDot } from './styled';
import { Image } from 'semantic-ui-react';
import CheckMark from '../../assets/icons/checkMark.png';

export function Stepper({ direction, children, activeKey }: StepperProps) {
  const keyIndex = children.findIndex((element: any) => element && element.key === activeKey);

  const newChildren = children.map((entry: any, index: number) =>
    React.cloneElement(entry, {
      ...entry.props,
      isActive: index === keyIndex,
      isDone: index < keyIndex,
      direction,
    }),
  );

  const itemCount = children ? children.length : 0;
  return (
    <StepperContainer
      className='stepper-container'
      {...{ direction, itemCount }}
    >
      {newChildren}
    </StepperContainer>
  );
}

export function Step({ isActive, isDone, direction, children }: StepProps) {
  return (
    <StepContainer
      isActive={isActive}
      isDone={isDone}
      direction={direction}
      className={`stepper-step ${isActive ? 'active' : ''}`}
    >
      <StepDot
        isActive={isActive}
        isDone={isDone}
        direction={direction}
        className={`step-dot ${isActive || isDone ? 'done' : ''}`}
      >
        {(isActive || isDone) && direction === 'vertical' && <Image src={CheckMark} />}
      </StepDot>
      {children}
    </StepContainer>
  );
}

type StepperProps = {
  direction?: string;
  activeKey: string;
  children: ReactNode[];
};

type StepProps = {
  isActive?: boolean;
  isDone?: boolean;
  direction?: string;
  key: string;
  children: ReactNode;
};
