import classnames from 'classnames';
import ComboProduct from 'components/ComboProduct';
import FloatingLabelInput from 'components/FloatingLabelInput';
import React, { useEffect, useState } from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';
import IProduct from '../../shared/models/Product';
import './index.scss';

interface ProductConfiguratorProps {
  alcoholAgeRestriction: number;
  alcoholMessage: string;
  bottomPadding?: boolean;
  currencyPrefix: string;
  isStoreActive: boolean;
  onAddItemToCartClick: (productNotes: string) => Promise<void>;
  orderInstructionsAvailable: boolean;
  product: IProduct;
  selectedComboItems: SelectedComboItem[];
  setSelectedComboItems: React.Dispatch<React.SetStateAction<SelectedComboItem[]>>;
}

export interface SelectedComboItem {
  id: string;
  name: string;
  parentId: string;
  productId: string;
  price: number;
  required?: boolean;
  type: string;
  quantity: number;
}

const ProductConfigurator = (props: ProductConfiguratorProps) => {
  const {
    alcoholAgeRestriction,
    alcoholMessage,
    bottomPadding,
    currencyPrefix,
    isStoreActive,
    onAddItemToCartClick,
    orderInstructionsAvailable,
    product,
    selectedComboItems,
    setSelectedComboItems,
  } = props;

  const [areSelectionsValid, setAreSelectionsValid] = useState<boolean>(false);
  const [isAlcoholChecked, setAlcoholCheck] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [productNotes, setProductNotes] = useState<string>('');

  const onCheckAlcohol = (e: any, { checked }: any) => setAlcoholCheck(checked);

  const getSelectionsAreValid = (): boolean => {
    if (product.alcohol && !isAlcoholChecked) return false;

    if (product.subCategories) {
      for (const category of product.subCategories) {
        const selectedCount = selectedComboItems.filter(items => items.parentId === category.id)
          .length;

        if (category.required && selectedCount <= 0) return false;
        if (!category.multiselect && selectedCount > 1) return false;
        if (category.selectionLimit && selectedCount > category.selectionLimit) return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const valid = getSelectionsAreValid();
    setAreSelectionsValid(valid);
  }, [isAlcoholChecked, product, selectedComboItems]);

  const addToBag = async () => {
    setIsSubmitting(true);

    await onAddItemToCartClick(productNotes);

    setIsSubmitting(false);
  };

  return (
    <div className="product-configurator">
      <div className="product-configurator__options">
        <Segment>
          <div className="title">{product.name}</div>
          <div className="description">{product.description}</div>
          <div className="price">
            {!product.isFree && `${currencyPrefix}${Number(product.price).toFixed(2)}`}
          </div>
          {product.hasSubProducts && (
            <ComboProduct
              currencyPrefix={currencyPrefix}
              product={product}
              selectedComboItems={selectedComboItems}
              setSelectedComboItems={setSelectedComboItems}
            />
          )}
          {product.alcohol && (
            <div className="product-configurator__alcohol-warning">
              <h5>CONFIRM YOU ARE {alcoholAgeRestriction}+</h5>
              <span>Required</span>
              <Checkbox
                aria-label={`Yes, I am over ${alcoholAgeRestriction} & have a government issued ID as proof.`}
                checked={isAlcoholChecked}
                onChange={onCheckAlcohol}
                label={`Yes, I am over ${alcoholAgeRestriction} & have a government issued ID as proof.`}
              />
              <p>
                {alcoholMessage ||
                  'Upon delivery, one ID will need to be presented for every one beer ordered. No exceptions.'}
              </p>
            </div>
          )}
          {orderInstructionsAvailable && (
            <div className="product-configurator__notes">
              <FloatingLabelInput
                id="product-notes-input"
                type="text"
                name="product-notes-input"
                isRequired={false}
                isDisabled={false}
                placeholder="Special Instructions"
                noMargin={true}
                onChange={({ value }: any) => setProductNotes(value)}
                maxLength={75}
                descriptor={
                  productNotes.length > 0
                    ? `${75 - productNotes.length} characters remaining`
                    : 'Optional'
                }
              />
            </div>
          )}
        </Segment>
      </div>

      {isStoreActive && (
        <div className="product-configurator__add-to-bag">
          <button
            className={classnames((isSubmitting || !areSelectionsValid) && 'disabled')}
            disabled={isSubmitting || !areSelectionsValid}
            onClick={addToBag}
          >
            Add to Bag
          </button>
        </div>
      )}
      {/* This is necessary for this component to work on both regular and suites */}
      {bottomPadding && <div className="product-configurator__bottom-padding" />}
    </div>
  );
};

export default ProductConfigurator;
