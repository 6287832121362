import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/theme';

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.primaryColor};
  color: white;
  border-radius: 10px;
  width: 100%;
  font-family: ${fonts.MEDIUM};
  padding: 10px 20px;
  transition: 0.3s;
  letter-spacing: 1.08px;
  font-size: 18px;
  border: none;

  &:focus {
    outline: 1px dotted ${({ theme }) => theme.primaryColor};
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${colors.poweredGrey};
  }
`;

type Props = {
  children?: React.ReactNode;
  onClick: any;
  disabled?: boolean;
};

const SubmitButton = ({ children, onClick, disabled, ...props }: Props) => {
  return (
    <StyledButton disabled={disabled} onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

export default SubmitButton;
