import React from 'react';
import { useHistory } from 'react-router-dom';
import { fonts } from 'shared/theme';
import styled from 'styled-components';
import { BackChevron } from './ProductHeader/styled';

const HeaderWrapper = styled.header`
  display: flex;
  margin: 0 1em 0 1em;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 30px;
  justify-content: center;
`;

const Title = styled.h1<{ whiteText: boolean }>`
  font-family: ${fonts.SUPER};
  color: ${({ theme, whiteText }) => (whiteText ? 'white' : `${theme.primaryColor}`)};
  font-size: 18px;
  letter-spacing: 0;
  height: 0;
  min-height: 0;
  margin: 0;
  line-height: 0;
  position: relative;
  top: 2px;
  text-transform: uppercase;
`;

const ButtonWrapper = styled.button`
  background: none;
  padding: 0;
  border: none;
  position: absolute;
  left: 0;
  margin-left: 1em;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
`;

type ProfileHeaderProps = {
  title?: string;
  whiteText?: boolean;
};

const ProfileHeader = ({ title, whiteText = false }: ProfileHeaderProps) => {
  const history = useHistory();

  return (
    <HeaderWrapper>
      <ButtonWrapper aria-label="Go back" onClick={history.goBack}>
        <BackChevron shaded={whiteText} title="Go back icon" />
      </ButtonWrapper>
      <Title whiteText={whiteText}>{title}</Title>
    </HeaderWrapper>
  );
};

export default ProfileHeader;
