import { IDeliveryLocation, ILineItem, OrderType } from './Cart';
import Store from './Store';
import IUser from './User';

enum CurrencyCode {
  USD = 'USD',
  ZAR = 'ZAR',
  AED = 'AED',
  CAD = 'CAD',
}

export enum OrderSource {
  FANFOOD = 'FANFOOD',
  GRUBBRR = 'GRUBBRR',
  TDM = 'TDM',
}

export enum OrderStatus {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
  FULFILLED = 'FULFILLED',
  IN_PROCESS = 'IN_PROCESS',
  NEW = 'NEW',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  PRE_ORDER = 'PRE_ORDER',
  READY = 'READY',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  READY_FOR_RUNNER = 'READY_FOR_RUNNER',
  SUB_ORDER = 'SUB_ORDER',
}

export interface Order {
  cartId: string;
  convenienceFee: number;
  serviceFee: number;
  convenienceFeeTax: number;
  createdTime: string;
  currency: CurrencyCode;
  discountAmt: number;
  eventId?: string;
  id: string;
  instructions: string;
  lineItems: ILineItem[];
  location?: IDeliveryLocation[];
  orderDoneTime: Date;
  orderGroupId: string;
  orderNo: number;
  orderOutForDeliveryTime?: Date;
  orderPrepStartTime: Date;
  orderReadyTime: Date;
  platform: string;
  platformCreditAmt: number;
  prepDueTime: Date;
  promotionId?: string;
  receiptUrl?: string;
  refundStatus: string;
  scheduledTime: Date;
  source: OrderSource;
  sourceOrderId: string;
  status: OrderStatus;
  store: Store;
  storeId: string;
  subTotal: number;
  suite?: string;
  taxAmt: number;
  taxCalculated: boolean;
  tipAmt?: number;
  total: number;
  type: OrderType;
  user: IUser;
  userId: string;
  venueId: string;
  venueTipShare: number;
}
