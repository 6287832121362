import { Stripe } from '@stripe/stripe-js';
import cardLock from 'assets/icons/cardLock.svg';
import CaretRight from 'assets/icons/caretRightBlack.png';
import CloseIconBtn from 'assets/icons/closeSearchBtn.png';
import RedCheck from 'assets/icons/redCheck.svg';
import TrashIcon from 'assets/icons/trash.png';
import ApplePayMark from 'assets/images/ApplePayMark.png';
import GooglePayMark from 'assets/images/GooglePayMark.png';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Divider, Form, Grid, Icon, Image, List, Modal } from 'semantic-ui-react';
import { useAppContext } from '../../containers/App/AppContext';
import { usePaymentContext } from '../../containers/PaymentDelivery/context';
import { useVenueContext } from '../../hooks/useVenueContext';
import { UserService } from '../../services/UserService';
import { PlatformPaymentType } from '../../shared/constants';
import OpenTab from '../../shared/models/OpenTab';
import OrderGroup from '../../shared/models/OrderGroup';
import { PaymentGateway } from '../../shared/models/Venue';
import { formatCurrency } from '../../shared/utils';
import SquareCard from '../Square/components/SquareCard';
import StripeForm from '../StripeForm';
import {
  CardBody,
  CardWrapper,
  CreditCardContainer,
  EditLabel,
  MobilePayImage,
  PaymentMethodHeader,
  PaymentMethodsContainer,
  SlideOptions,
  StatementCharge,
  StyledCard,
} from './styled';

export enum PaymentMethodType {
  CreditCard,
  Defer,
  MobilePay,
  Tab,
}

export interface CreditCard {
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
  id: string;
}

export interface PaymentMethod {
  orderGroupId?: string;
  orderGroupSpendingLimit?: string;
  paymentMethodType: PaymentMethodType;
  selectedCard?: CreditCard;
}

export interface IPaymentCardProps {
  isOrderFree: boolean;
  currency: string;
  orderGroups?: OrderGroup[];
  paymentType?: string;
  selectedPaymentMethod: PaymentMethod;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  showOpenTabOption: boolean;
  stripe?: Stripe | null;
  openTabValues?: OpenTab;
  useNewPayment?: boolean;
  creditCardsCheck?: any;
  gameDayOrder?: boolean;
  useDeferPayment?: boolean;
}

interface ICreditCardPicker {
  creditCards: CreditCard[];
  deployedCard: string | undefined;
  editMode: boolean;
  nativePay: boolean;
  onDeleteCard: (id: string) => Promise<void>;
  paymentType?: string;
  selectedPaymentMethod: PaymentMethod;
  setOpenAddCC: Dispatch<SetStateAction<boolean>>;
  toggleDeployed: (id: string) => () => void;
  updatePaymentMethod: (updatedPaymentMethod: Partial<PaymentMethod>) => void;
}

const CreditCardPicker = ({
  editMode,
  paymentType,
  creditCards,
  selectedPaymentMethod,
  updatePaymentMethod,
  deployedCard,
  toggleDeployed,
  onDeleteCard,
  setOpenAddCC,
  nativePay,
}: ICreditCardPicker) => {
  const primaryCard =
    selectedPaymentMethod.paymentMethodType === PaymentMethodType.CreditCard &&
    creditCards.find(cc => cc.id === selectedPaymentMethod.selectedCard?.id);

  return (
    <div>
      <List divided verticalAlign="middle">
        {!editMode ? (
          <>
            {nativePay && !primaryCard && (
              <List.Item key={paymentType}>
                <CreditCardContainer deployed={true}>
                  <Grid>
                    <Grid.Row columns={2}>
                      <MobilePayImage
                        src={
                          paymentType === PlatformPaymentType.android ? GooglePayMark : ApplePayMark
                        }
                      />
                      <Grid.Column width={8} className="number">
                        {paymentType === PlatformPaymentType.android ? 'Google Pay' : 'Apple Pay'}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </CreditCardContainer>
              </List.Item>
            )}
            {primaryCard && (
              <List.Item key={primaryCard.id}>
                <CreditCardContainer deployed={false}>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Icon className={'credit card outline'}></Icon>
                      <Grid.Column width={8} className="number">
                        {`${primaryCard.card.brand
                          .charAt(0)
                          .toUpperCase()}${primaryCard.card.brand.slice(1)}`}
                        <span>****</span>
                        {primaryCard.card.last4}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </CreditCardContainer>
              </List.Item>
            )}
          </>
        ) : (
          <>
            {creditCards &&
              creditCards.map((cc: CreditCard) => (
                <List.Item key={cc.id}>
                  <CreditCardContainer deployed={deployedCard === cc.id}>
                    <Grid
                      onClick={() =>
                        updatePaymentMethod({
                          selectedCard: cc,
                          paymentMethodType: PaymentMethodType.CreditCard,
                        })
                      }
                    >
                      <Grid.Row columns={2}>
                        <Icon className={'credit card outline'}></Icon>
                        <Grid.Column width={8} className="number">
                          {`${cc.card.brand.charAt(0).toUpperCase()}${cc.card.brand.slice(1)}`}
                          <span>****</span>
                          {cc.card.last4}
                        </Grid.Column>
                        <Grid.Column width={6} className="selected-card">
                          {selectedPaymentMethod.paymentMethodType ===
                            PaymentMethodType.CreditCard &&
                            selectedPaymentMethod.selectedCard?.id === cc.id && (
                              <Image src={RedCheck}></Image>
                            )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <SlideOptions deployed={deployedCard === cc.id}>
                      <Image className="caret" src={CaretRight} onClick={toggleDeployed(cc.id)} />
                      <div className="actions">
                        <Image
                          className="trash"
                          onClick={() => onDeleteCard(cc.id)}
                          src={TrashIcon}
                        />
                      </div>
                    </SlideOptions>
                  </CreditCardContainer>
                </List.Item>
              ))}
            {paymentType && (
              <List.Item key={paymentType}>
                <CreditCardContainer deployed={true}>
                  <Grid
                    onClick={() =>
                      updatePaymentMethod({
                        paymentMethodType: PaymentMethodType.MobilePay,
                        selectedCard: undefined,
                      })
                    }
                  >
                    <Grid.Row columns={2}>
                      <MobilePayImage
                        src={
                          paymentType === PlatformPaymentType.android ? GooglePayMark : ApplePayMark
                        }
                      />
                      <Grid.Column width={8} className="number">
                        {paymentType === PlatformPaymentType.android ? 'Google Pay' : 'Apple Pay'}
                      </Grid.Column>
                      <Grid.Column width={6} className="selected-card">
                        {selectedPaymentMethod.paymentMethodType === PaymentMethodType.MobilePay &&
                          !selectedPaymentMethod.selectedCard && <Image src={RedCheck}></Image>}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </CreditCardContainer>
              </List.Item>
            )}
          </>
        )}
        {!paymentType && creditCards.length <= 0 && editMode && (
          <>
            <p className="item">No credit cards have been added yet</p>
          </>
        )}
      </List>
      {editMode && (
        <Button className="add-card" onClick={() => setOpenAddCC(true)}>
          + Add New Payment Method
        </Button>
      )}
    </div>
  );
};

export const PaymentCard = ({
  isOrderFree,
  currency,
  orderGroups,
  paymentType,
  selectedPaymentMethod,
  setIsLoading,
  setSelectedPaymentMethod,
  openTabValues,
  showOpenTabOption,
  useNewPayment,
  creditCardsCheck,
  gameDayOrder,
}: IPaymentCardProps) => {
  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [openAddCC, setOpenAddCC] = useState<boolean>(false);
  const [deployedCard, setDeployedCard] = useState<string | undefined>(undefined);
  const [editCard, setEditCards] = useState(false);
  const { user } = useAppContext();
  const { venue } = useVenueContext();
  const { useDeferPayment } = usePaymentContext();
  const [openOrderGroup, setOpenOrderGroup] = useState<OrderGroup | undefined>(undefined);

  const nativePay = !!(
    paymentType &&
    (paymentType === PlatformPaymentType.apple || paymentType === PlatformPaymentType.android)
  );

  const getUserPayementCard = async (userId: string, gameDayOrder?: any) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await UserService.GetStoredCards(userId, gameDayOrder, {
        paymentGateways: [venue?.paymentGateway ?? PaymentGateway.STRIPE],
      });
      if (data) {
        setCreditCards(data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast(error.response.data.message);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (nativePay) {
        updatePaymentMethod({
          paymentMethodType: PaymentMethodType.MobilePay,
          selectedCard: undefined,
        });
      }
      if (gameDayOrder && openTabValues?.isActive && !useNewPayment) {
        getUserPayementCard(openTabValues.userId, gameDayOrder);
        return;
      }
      if (!user || !venue || (creditCards.length > 0 && !useNewPayment)) {
        return;
      }
      getUserPayementCard(user.id, gameDayOrder);
    };
    init();
  }, [user, paymentType, venue, openTabValues, useNewPayment]);

  useEffect(() => {
    creditCardsCheck(creditCards);
    if (venue?.deferPaymentCapture && venue.allowOpenTabs && !openTabValues && gameDayOrder) {
      updatePaymentMethod({
        paymentMethodType: PaymentMethodType.Defer,
        selectedCard: undefined,
      });
      return;
    }
    if (venue?.deferPaymentCapture && !venue.allowOpenTabs) {
      updatePaymentMethod({
        paymentMethodType: PaymentMethodType.Defer,
        selectedCard: undefined,
      });
      return;
    }
    if (creditCards.length > 0) {
      updatePaymentMethod({
        paymentMethodType: PaymentMethodType.CreditCard,
        selectedCard: creditCards[0],
      });
    }

    if (creditCards.length === 0 && !nativePay) {
      setEditCards(true);
    }
  }, [creditCards, paymentType]);

  useEffect(() => {
    if (creditCards.length > 0 && !useDeferPayment) {
      updatePaymentMethod({
        paymentMethodType: PaymentMethodType.CreditCard,
        selectedCard: creditCards[0],
      });
    }
    if (creditCards.length > 0 && useDeferPayment) {
      updatePaymentMethod({
        paymentMethodType: PaymentMethodType.Defer,
        selectedCard: undefined,
      });
    }
  }, [useDeferPayment]);

  useEffect(() => {
    if (!orderGroups || orderGroups.length <= 0) return;

    setOpenOrderGroup(orderGroups[0]);
    updatePaymentMethod({ orderGroupId: orderGroups[0].id });
  }, [orderGroups]);

  const updatePaymentMethod = (updatedPaymentMethod: Partial<PaymentMethod>) => {
    setSelectedPaymentMethod({ ...selectedPaymentMethod, ...updatedPaymentMethod });
    setEditCards(false);
  };

  const saveCC = async (token: string | undefined) => {
    if (token && user && venue) {
      setIsLoading(true);
      try {
        const { data } = await UserService.SaveCard(user.id, {
          paymentMethodId: token,
          paymentGateway: venue.paymentGateway ?? PaymentGateway.SQUARE,
        });
        setCreditCards((cards: CreditCard[]) => [data, ...cards]);

        updatePaymentMethod({
          paymentMethodType: PaymentMethodType.CreditCard,
          selectedCard: data,
        });
      } catch (error) {
        toast(error.response.data.message || 'Something went wrong. Please try again!!');
      } finally {
        setIsLoading(false);
        setEditCards(false);
      }
    }
  };

  const toggleDeployed = (id: string) => () => {
    if (deployedCard === id) {
      setDeployedCard(undefined);
    } else {
      setDeployedCard(id);
    }
  };

  const onDeleteCard = async (id: string) => {
    if (!user) {
      return;
    }
    try {
      setIsLoading(true);
      await UserService.DeleteStoredCard(user.id, id);

      const newCards = creditCards.filter((card: CreditCard) => card.id !== id);
      setCreditCards(newCards);
      setIsLoading(false);
      if (newCards.length === 0 && nativePay) {
        updatePaymentMethod({
          paymentMethodType: PaymentMethodType.MobilePay,
          selectedCard: undefined,
        });
        setEditCards(false);
        return;
      }
    } catch (error) {
      toast(error.response.data.message);
      setIsLoading(false);
    }
    setEditCards(true);
  };

  if (isOrderFree) {
    return null;
  }

  const CardForm = () => {
    switch (venue?.paymentGateway) {
      case PaymentGateway.SQUARE:
        return <SquareCard handleCardSave={saveCC} setOpenCC={setOpenAddCC} />;
      case PaymentGateway.STRIPE:
      default:
        return <StripeForm onSave={saveCC} setOpenCC={setOpenAddCC} />;
    }
  };

  return (
    <>
      <PaymentMethodsContainer>
        <StyledCard>
          <CardWrapper>
            <PaymentMethodHeader>
              <span>Payment Method</span>
              <Image src={cardLock} />
            </PaymentMethodHeader>
            {(!editCard && useNewPayment) ||
            (!editCard && !openTabValues?.isActive) ||
            (!editCard && !gameDayOrder) ? (
              <EditLabel onClick={() => setEditCards(prev => !prev)} attached="top right">
                Edit
              </EditLabel>
            ) : (
              ''
            )}
            <Divider />
            {gameDayOrder && openTabValues && !useNewPayment && !useDeferPayment ? (
              <CardBody>
                <div>Tab Payment</div>
                <div>${openTabValues.tabBalance} Available</div>
              </CardBody>
            ) : (
              <>
                {(selectedPaymentMethod.paymentMethodType === PaymentMethodType.CreditCard ||
                  selectedPaymentMethod.paymentMethodType === PaymentMethodType.MobilePay) && (
                  <CreditCardPicker
                    {...{
                      setOpenAddCC,
                      selectedPaymentMethod,
                      creditCards,
                      toggleDeployed,
                      onDeleteCard,
                      paymentType,
                      updatePaymentMethod,
                      deployedCard,
                      editMode: editCard,
                      nativePay,
                    }}
                  />
                )}
              </>
            )}
            {selectedPaymentMethod.paymentMethodType === PaymentMethodType.Defer && (
              <div>
                Once you place your order, you are all set until your event date. Payment will be
                collected at the venue.
              </div>
            )}
            {selectedPaymentMethod.paymentMethodType === PaymentMethodType.Tab && (
              <div>
                <Form>
                  {openOrderGroup && (
                    <>
                      <Form.Radio
                        aria-label="Add to existing tab"
                        checked={!!selectedPaymentMethod.orderGroupId}
                        label="Add to existing tab"
                        onClick={() => updatePaymentMethod({ orderGroupId: openOrderGroup.id })}
                      />
                      <p>Card: {openOrderGroup.paymentMethodName}</p>
                      {openOrderGroup.spendingLimit > 0 && (
                        <p>
                          Balance: {formatCurrency(openOrderGroup.spendingLimitBalance, currency)}
                        </p>
                      )}
                    </>
                  )}
                  {!openOrderGroup && (
                    <Form.Radio
                      aria-label="Open a new tab"
                      checked={!selectedPaymentMethod.orderGroupId}
                      label="Open a new tab"
                      onClick={() => updatePaymentMethod({ orderGroupId: undefined })}
                    />
                  )}
                </Form>

                {!selectedPaymentMethod.orderGroupId && (
                  <div>
                    <Form>
                      <Form.Input
                        aria-label="Spending limit"
                        label="Spending limit"
                        name="orderGroupSpendingLimit"
                        onChange={e =>
                          updatePaymentMethod({
                            orderGroupSpendingLimit: e.target.value.replace(/\D/, ''),
                          })
                        }
                        type="tel" // type "tel" forces numeric keyboard on mobile
                        value={selectedPaymentMethod.orderGroupSpendingLimit || '0'}
                      />
                    </Form>

                    <CreditCardPicker
                      {...{
                        setOpenAddCC,
                        selectedPaymentMethod,
                        creditCards,
                        toggleDeployed,
                        onDeleteCard,
                        paymentType,
                        updatePaymentMethod,
                        deployedCard,
                        editMode: editCard,
                        nativePay,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </CardWrapper>
          <StatementCharge>Statement charge will appear as FanFood</StatementCharge>
        </StyledCard>
      </PaymentMethodsContainer>
      <Modal
        size="tiny"
        open={openAddCC}
        onClose={() => setOpenAddCC(false)}
        closeIcon={
          <Image
            style={{
              position: 'absolute',
              top: '13px',
              right: '13px',
              height: '12px',
              width: 'auto',
              cursor: 'pointer',
              zIndex: 10,
            }}
            src={CloseIconBtn}
          />
        }
      >
        <Modal.Content>
          <CardForm />
        </Modal.Content>
      </Modal>
    </>
  );
};
