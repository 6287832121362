import ChangeOrderLocationModal from 'containers/ChangeOrderLocationModal';
import { useSuiteContext } from 'containers/Suite/Steps/SuiteContext';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BasePath } from 'shared/constants';
import { ISuiteVenue, IVenueLocation } from 'shared/models/Venue';
import { isEmpty } from 'underscore';
import './index.scss';

export interface SuiteSelectionProps {
  venues: ISuiteVenue[];
}

const SuiteSelection = (props: SuiteSelectionProps) => {
  const history = useHistory();

  const { cart, setSelectedEvent, setSuite } = useSuiteContext();
  const [canNavigate, setCanNavigate] = React.useState<boolean>(true);
  const [suiteToNavigate, setSuiteToNavigate] = React.useState<IVenueLocation | undefined>(
    undefined,
  );

  const navigate = () => {
    if (!suiteToNavigate) return;

    setSuite(suiteToNavigate);

    setCanNavigate(true);
    history.push(`/${BasePath.SUITE}/${suiteToNavigate.id}`);
  };

  useEffect(() => {
    setSelectedEvent(undefined);
  }, []);

  useEffect(() => {
    if (!suiteToNavigate) return;

    const { id, venueId, name } = suiteToNavigate;

    // mismatch, continue to raise modal
    if (
      cart &&
      cart.venueId &&
      cart.location &&
      (cart.suite !== name || cart.venueId !== venueId)
    ) {
      setCanNavigate(false);
      return;
    }

    // navigate
    navigate();
  }, [suiteToNavigate]);

  if (isEmpty(props.venues)) {
    return (
      <div className="suite-selection">
        <h2>Suite Pre-Orders</h2>
        <p>
          Please contact the venue with whom you reserved your suite to give you access to Suite
          Pre-Orders.
        </p>
      </div>
    );
  }
  return (
    <div className="suite-selection">
      <h2>Select your suite</h2>

      {props.venues.map((venue: ISuiteVenue, vix: number) => {
        if (isEmpty(venue.suites)) return null;

        return (
          <div className="suite-selection__venue" key={vix}>
            <h3>{venue.name}</h3>
            <div className="suite-selection__venue__valid-locations">
              {venue.suites.map((suite: IVenueLocation, lix: number) => {
                return (
                  <div
                    className="suite-selection__venue__valid-locations__valid-location"
                    key={lix}
                  >
                    <div
                      className="suite-selection__venue__valid-locations__valid-location__suites__suite"
                      onClick={() => setSuiteToNavigate(suite)}
                    >
                      {suite.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}

      {suiteToNavigate && (
        <ChangeOrderLocationModal
          newLocation={suiteToNavigate.name}
          onAccept={navigate}
          onCloseModal={() => setSuiteToNavigate(undefined)}
          showModal={!canNavigate}
        />
      )}
    </div>
  );
};

export default SuiteSelection;
