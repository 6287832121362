import Venue, { ISuiteVenue, IVenueLocation } from '../shared/models/Venue';
import { ApiResponse, APIService, IPaginationResponse } from './APIService';

const basePath = '/venues';
const venueLocationPath = '/venue-locations';

export type LocationRequestType = {
  radius?: number;
  lng: number;
  lat: number;
  venueType?: string;
  includeInactive?: boolean;
};
export interface ActiveSponsor {
  data: SponsorData;
}

export interface VenueSearchParams {
  search: string;
  type?: string;
  includeInactive?: boolean;
  limit?: number;
}

export interface SponsorData {
  id: string;
  createdTime: Date;
  modifiedTime: Date;
  title: string;
  active: boolean;
  isDeleted: boolean;
  venueId: string;
  sponsors: Sponsor[];
}

export interface Sponsor {
  id: string;
  createdTime: Date;
  modifiedTime: Date;
  name: string;
  imageUrl: string;
  sponsorModuleId: string;
  isDeleted: boolean;
}

export interface IAccessibleSuiteVenue {
  id: string;
  imageUrl: string;
  locations: [];
}

export interface IVenueInvite {
  allEvents: boolean;
  eventIds: string[];
  id: string;
  role: UserVenueRole;
  storeIds: string[];
  suiteIds: string[];
  venueId: string;
}

export enum UserVenueRole {
  VENUE_MANAGER = 'VENUE_MANAGER',
  STORE_MANAGER = 'STORE_MANAGER',
  STORE_EMPLOYEE = 'STORE_EMPLOYEE',
  RUNNER = 'RUNNER',
  SUITE_ATTENDANT = 'SUITE_ATTENDANT',
  SUITE_CUSTOMER = 'SUITE_CUSTOMER',
}

export class VenueService {
  static async GetAllVenues(
    formData: VenueSearchParams,
  ): Promise<ApiResponse<IPaginationResponse<Venue>>> {
    return await APIService.getData(basePath, formData);
  }

  static async GetOneVenue(id: string): Promise<ApiResponse<Venue>> {
    return await APIService.getData(`${basePath}/${id}`);
  }

  static async GetOneVenueBySlug(id: string): Promise<any> {
    return await APIService.getData(`${basePath}/n/${id}`);
  }

  static async GetAllSuggested(locationRequest: LocationRequestType): Promise<any> {
    return await APIService.getData(`${basePath}/suggested`, locationRequest);
  }

  static async GetAccessibleSuiteVenues(options: {
    limit: number;
  }): Promise<ApiResponse<ISuiteVenue[]>> {
    return await APIService.getData(`${basePath}/suites/accessible?limit=${options.limit}`);
  }

  static async getActiveSponsorModule(venueId: string): Promise<ActiveSponsor> {
    return await APIService.getData(`${basePath}/${venueId}/active-sponsor-module`);
  }

  static async GetTenantBuildingAndEvents(venueId: string): Promise<any> {
    return await APIService.getData(`${basePath}/${venueId}/events`);
  }

  static async AcceptVenueInvite(inviteId: string): Promise<ApiResponse<IVenueInvite>> {
    return await APIService.postData(`venue-invites/accept/${inviteId}`);
  }

  static async getVenueLocation(suiteId: string): Promise<ApiResponse<IVenueLocation>> {
    return await APIService.getData(`${venueLocationPath}/${suiteId}`);
  }

  static async getVenueLocations(
    venueId: string,
    storeId?: string,
  ): Promise<ApiResponse<IVenueLocation[]>> {
    return await APIService.getData(`${basePath}/${venueId}/locations`, {
      ...(storeId && { storeId }),
    });
  }
}
