// utils
import { getDate, getYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { get } from 'lodash';
import { SubSelectionType } from './ComboTypes';

const development: boolean = process.env.NODE_ENV === 'development';

export const isDev = (): boolean => {
  return development;
};

export const isMobileDevice = {
  iOS: () => {
    return !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
  },
  android: () => {
    return !!navigator.userAgent && /Android/.test(navigator.userAgent);
  },
};

export const formatEta = (confidenceIntervalMs: number, etaMs: number) => {
  const confidenceIntervalMinutes = Math.ceil(confidenceIntervalMs / 1000 / 60);
  const etaMinutes = Math.ceil(etaMs / 1000 / 60);

  // min and max are used by default from the API. in the case where the confidence interval exceeds the
  // ETA (which would cause a negative ETA), sensible lower bound limits of `min: 5` and `max: 10` have been set.
  const etaLowerBound = 5;
  const min = Math.max(etaMinutes - confidenceIntervalMinutes, etaLowerBound);

  const etaUpperBound = 10;
  const max = Math.max(etaMinutes + confidenceIntervalMinutes, etaUpperBound);

  return `${min}-${max} MIN`;
};

type TimeData = {
  date: string;
  eventDateTime?: boolean;
  selectedEventDate?: boolean;
  timezone: string;
};

export const formatDateTime = (data: TimeData) => {
  const { date, eventDateTime = false, selectedEventDate = false, timezone } = data;
  const dateObj = new Date(date);
  const zonedDate = utcToZonedTime(dateObj, timezone);
  const day = getDate(zonedDate);

  if (eventDateTime) {
    const dayName = zonedDate.toLocaleString('en-US', { weekday: 'short' });
    const month = zonedDate.toLocaleString('en-US', { month: 'short' });
    const time = zonedDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return `${dayName}, ${month} ${day} | ${time}`;
  }

  if (selectedEventDate) {
    const month = zonedDate.toLocaleString('en-US', { month: 'long' });
    const year = getYear(zonedDate);
    return `${month} ${day}, ${year}`;
  }
};

export const isValidUUIDv4 = (text: string): boolean => {
  const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;
  return uuidV4Regex.test(text);
};

export const getProductImage = (product: any): string | undefined => {
  if (product.imageUrl) {
    return product.imageUrl;
  }
  if (product.globalProduct && product.globalProduct.imageUrl) {
    return product.globalProduct.imageUrl;
  }

  return undefined;
};

export const getCurrencyPrefix = (currency: string | undefined) => {
  const currencyValues = {
    USD: '$',
    AED: 'AED',
    ZAR: 'R',
    CAD: '$',
  };

  return get(currencyValues, [currency ?? 'USD'], '$');
};

export const formatCurrency = (amount: number, currency?: string) => {
  if (!currency || !['AED', 'CAD', 'USD', 'ZAR'].includes(currency.toUpperCase())) {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  return amount.toLocaleString(undefined, { style: 'currency', currency });
};

export const collectSubProducts = (current: any, collection: any[]): void => {
  current.forEach((ss: any) => {
    if (ss.subSelections) {
      collectSubProducts(ss.subSelections, collection);
    } else if (ss.type === SubSelectionType.PRODUCT) {
      collection.push(ss);
    }
  });
};
