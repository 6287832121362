import React from 'react';
import { TransitionablePortal, Modal, Image } from 'semantic-ui-react';
import closeSearchBtn from '../../../assets/icons/closeSearchBtn.png';
import { ModalButton } from 'components/Atoms/Button';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';
import { useSuiteContext } from './SuiteContext';

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${fonts.BOLD};
  font-size: 18px;
  letter-spacing: -0.2px;
  text-align: center;
  margin-bottom: 15px;
`;

const closeStyle = {
  width: '12px',
  height: '12px',
  float: 'right',
  marginTop: '15px',
  marginRight: '15px',
  cursor: 'pointer',
};

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.p`
  font-family: ${fonts.REGULAR};
  color: ${colors.black};
  font-size: 18px;
  letter-spacing: -0.2px;
  text-align: center;
  line-height: normal;
`;
const EventChangeWarnModal = () => {
  const {
    setDisplayEventChangeWarningModal,
    displayChangeWarningModal,
    confirmEventChange,
  } = useSuiteContext();

  return (
    <TransitionablePortal
      open={displayChangeWarningModal}
      transition={{ animation: 'fade up', duration: 500 }}
    >
      <Modal
        centered={true}
        open={displayChangeWarningModal}
        onClose={() => setDisplayEventChangeWarningModal(false)}
        closeIcon={<Image style={closeStyle} alt='Close Button' src={closeSearchBtn} />}
        style={{ borderRadius: '10px' }}
        size='mini'
      >
        <Modal.Content style={{ borderRadius: '10px' }}>
          <Modal.Description>
            <ModalTitle>HELLO</ModalTitle>
            <ModalContent>
              If you select a new event date your bag will be emptied and you’ll need to begin the
              process from the beginning.{' '}
            </ModalContent>
            <ModalButtonContainer>
              <ModalButton onClick={() => confirmEventChange()}>OK, GOT IT</ModalButton>
            </ModalButtonContainer>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

export default EventChangeWarnModal;
