import { OrderType } from '../shared/models/Cart';
import Store from '../shared/models/Store';
import { ApiResponse, APIService, IPaginationResponse } from './APIService';

export enum StoreTypes {
  REGULAR = 'REGULAR',
  SUITE_KITCHEN = 'SUITE_KITCHEN',
}

interface GetAllStoresProps {
  limit?: number;
  page?: number;
  orderType?: OrderType;
  orderAheadAvailable?: boolean;
  orderNowAvailable?: boolean;
  deliveryLocationName?: string;
  deliveryLocationValue?: string;
  type?: StoreTypes;
  venueId: string;
}

export interface IEtas {
  confidenceIntervalMs?: number;
  deliveryEtaMs?: number;
  pickupEtaMs?: number;
  valid: boolean;
}

export class StoreService {
  static async GetAllStores(
    formData: GetAllStoresProps,
  ): Promise<ApiResponse<IPaginationResponse<Store>>> {
    return await APIService.getData('/stores', formData);
  }

  static async GetOneStore(id: string): Promise<ApiResponse<Store>> {
    return await APIService.getData(`/stores/${id}`);
  }

  static async GetAssignedSuiteStore(suiteId: string): Promise<ApiResponse<Store>> {
    return await APIService.getData(`/stores/suites/${suiteId}`);
  }

  static async GetStoreEtas(storeId: string): Promise<ApiResponse<IEtas>> {
    return await APIService.getData(`/stores/${storeId}/eta`);
  }
}
