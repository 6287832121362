import React from 'react';
import {
  FanfoodLoveContainer,
  BackgroundImage,
  BackgroundGradient,
  CaptionContainer,
  ShareCard,
} from './styled';
import { CardContainer } from '../../../shared/global-styled';
import { Image, Card, List } from 'semantic-ui-react';
import ShareIcon from 'assets/icons/shareIcon.png';
import TwitterIcon from 'assets/icons/twitter.png';
import FacebookIcon from 'assets/icons/facebook.png';
import { ShareLink } from '../../../shared/constants';
import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';

export default function FanfoodLove() {
  const shareText = encodeURIComponent(`Skip the lines & never miss a moment with me ${ShareLink}`);
  const subject = `Thought you might like this`;

  return (
    <FanfoodLoveContainer>
      <ProfileHeader title='Fanfood Love' whiteText={true} />
      <BackgroundImage />
      <BackgroundGradient />
      <CaptionContainer>
        <div>Get friends FanFooding!</div>
        <Image
          src={ShareIcon}
          target='_blank'
          href={`mailto:?subject=${subject}&body=${shareText}`}
        />
        <span>Share FanFood & help your friends skip the line too!</span>
      </CaptionContainer>
      <CardContainer>
        <ShareCard>
          <Card>
            <Card.Header>Spread the FanFood Love!</Card.Header>
            <Card.Content>
              <List divided verticalAlign='middle'>
                <List.Item
                  as='a'
                  target='_blank'
                  href={`https://twitter.com/intent/tweet?text=${shareText}`}
                >
                  <Image src={TwitterIcon} />
                  <List.Content>Twitter</List.Content>
                </List.Item>
                <List.Item
                  as='a'
                  target='_blank'
                  href={`https://www.facebook.com/sharer/sharer.php?display=popup&u=${ShareLink}&quote=${shareText}`}
                >
                  <Image src={FacebookIcon} />
                  <List.Content>Facebook</List.Content>
                </List.Item>
                {/* <List.Item>
                                    <Image src={InstagramIcon} />
                                    <List.Content>
                                        Instagram
                                    </List.Content>
                                </List.Item> */}
              </List>
            </Card.Content>
          </Card>
        </ShareCard>
      </CardContainer>
    </FanfoodLoveContainer>
  );
}
