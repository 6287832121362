import { Card, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import checkMark from '../../assets/icons/checkMark.png';
import { colors, fonts } from '../../shared/theme';

export const CardWrapper = styled.div`
  padding: 21px;
`;

export const StyledCard = styled(Card)`
  background: #f4ffff !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 5px 1px #d4d4d5, 0 0 0 1px #f4f4f7 !important;
`;

export const PaymentMethodsContainer = styled.div`
  .ui.card {
    min-height: 80px;
    width: 100%;
  }
  .ui.list {
    margin-top: 10px;
    margin-left: -21px;
    margin-right: -21px;
    padding: 0px 21px;
    position: relative;
    overflow: hidden;
  }
  .item {
    padding: 15px !important;
    font-family: ${fonts.LIGHT};
    font-size: 16px;
    color: ${colors.charcoalGrey};
    position: relative;

    .ui.grid.row {
      padding-top: 5px !important;
    }
  }
  button.ui.button.add-card {
    background: none;
    font-weight: 200;
    color: ${colors.niceBlue};
    text-align: left;
    padding: 0px;
    font-size: 14px;
    font-family: ${fonts.REGULAR};
    font-weight: lighter;
  }

  .two.column.row {
    align-items: center;
    padding: 0;
  }

  .ui.form .field {
    margin: 10px 0;

    &:last-child {
      margin-bottom: 10px;
    }

    .checkbox {
      label {
        color: ${colors.greyCombo} !important;
        font-size: 16px !important;
        font-family: ${fonts.LIGHT};
        margin: 10px 0;

        &::before {
          top: -3px !important;
          height: 20px !important;
          width: 20px !important;
        }

        &::after {
          top: -3px !important;
          left: 0 !important;
          width: 20px !important;
          height: 20px !important;
          background-color: ${colors.white} !important;
          content: '' !important;
        }
      }

      &.checked {
        label::before {
          background-color: ${({ theme }) => theme.primaryColor} !important;
          border-color: ${({ theme }) => theme.primaryColor} !important;
        }
        label::after {
          background-color: unset !important;
          background-image: url(${checkMark});
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 11px !important;
          height: 8px !important;
          top: 3px !important;
          left: 4px !important;
        }
      }
    }
  }
`;

export const PaymentMethodItems = styled.ul`
  list-style: none;
  margin: 0;
  position: absolute;
  top: -40px;
  padding: 0;

  li {
    background: ${colors.lightGrey};
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 82px;
    margin-left: 15px;
    position: relative;
    text-align: center;
    width: 82px;

    .ui.image {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 45px;
    }
  }

  li.active {
    background: ${colors.seaFoam};
  }

  li:first-child {
    margin-left: 0;
  }
`;

export const CreditCardContainer = styled.div<{ deployed: boolean }>`
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  .ui.grid {
    position: relative;
    left: ${props => (props.deployed ? '35px' : '0px')};
    transition: ease-out 0.2s;
    .column:last-child {
      padding-right: 0px;
    }
  }
  .number {
    padding-left: 0px;
    padding-top: 5px;
    text-align: left;
    span {
      letter-spacing: 3px;
    }
  }
  .ui.image {
    display: inline-block;
    margin-right: 10px;
    bottom: 3px;
  }
  .selected-card {
    text-align: right;
    font-family: ${fonts.REGULAR};
    font-weight: lighter;
    padding: 0px !important;
    margin-right: 10px !important;
  }
`;

export const SlideOptions = styled.div<{ deployed: boolean }>`
  height: 20px;
  position: absolute;
  width: 55px;
  background: transparent;
  left: ${props => (props.deployed ? '-21px' : '-55px')};
  top: 0px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  align-content: center;
  transition: ease-out 0.2s;
  .ui.image.caret {
    height: 12px;
    width: auto;
    align-self: center;
    right: 0px;
    top: 13px;
    position: absolute;
    margin-right: 8px;
    cursor: pointer;
  }
  .actions {
    align-self: center;
    top: 12px;
    left: 10px;
    position: relative;
    .pencil {
      height: 21px;
      cursor: pointer;
    }
    .trash {
      height: 15px;
      cursor: pointer;
    }
  }
`;

export const PaymentMethodHeader = styled.div`
  display: inline-flex;
  font-size: 18px;
  font-family: ${fonts.REGULAR};
  color: ${colors.charcoalGrey};

  span {
    font-size: 18px;
    font-family: ${fonts.REGULAR};
    color: ${colors.charcoalGrey};
  }

  .ui.image {
    margin-left: 10px;
    width: 13px;
    height: 14px;
  }
`;

export const MobilePayImage = styled.img`
  display: inline-block;
  max-width: 6% !important;
  margin-left: -35px;
`;

export const EditLabel = styled(Label)`
  &.ui.top.right.attached.label {
    background: transparent;
    color: ${colors.niceBlue};
    padding: 22px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    cursor: pointer;
    font-family: ${fonts.REGULAR};
  }
`;

export const StatementCharge = styled.div`
  background: #dedfdf;
  display: flex;
  justify-content: center;
  font-family: ${fonts.MEDIUM};
  font-size: 13px;
  color: ${colors.charcoalGrey};
  padding: 5px;
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
`
