import styled, { css } from 'styled-components';
import { colors, fonts } from '../../shared/theme';

const orderDetails = css<{ background?: string }>`
  .ui.segment.order_details {
    .ui.container.grid {
      font-size: 14px;
      font-family: ${fonts.REGULAR};

      .row {
        padding: 5px 0px 0px;
        &:last-child {
          padding-bottom: 5px;
        }
        .column {
          padding: 0px 10px;
        }
      }

      &.totals {
        background .row {
          padding: 5px 0px 0px;
          &:last-child {
            padding-bottom: 5px;
          }
          &.bold {
            font-family: ${fonts.MEDIUM};
            font-weight: lighter;
          }
        }
      }

      .refunded-column {
        color: ${({ theme }) => theme.primaryColor};
        font-family: ${fonts.REGULAR};
        font-weight: lighter;
        text-decoration-line: line-through;
        margin-top: -4px;
        padding-top: 0px;
        margin-bottom: 6px;
      }
    }
  }
`;

export const OpenOrderContainer = styled.div<{ showSummary?: boolean }>`
  .ui.segment {
    background: rgb(71, 67, 80);
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 30px 20px 25px;

    .chat-icon {
      position: absolute;
      top: 14px;
      right: 14px;
      height: 24px;
      cursor: pointer;
      img {
        height: 24px;
        width: auto;
      }
    }

    .chat-icon {
      min-height: 24px;
      margin-bottom: -12px;
      cursor: pointer;
      img {
        width: 24px;
        float: right;
        margin-right: -5px;
        margin-top: -5px;
      }
    }

    .chat-icon {
      min-height: 24px;
      margin-bottom: -12px;
      cursor: pointer;
      img {
        width: 24px;
        float: right;
        margin-right: -5px;
        margin-top: -5px;
      }
    }

    .title {
      color: #c93852;
      font-family: ${fonts.MEDIUM};
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    .estimate {
      font-family: ${fonts.REGULAR};
      margin-bottom: 10px;
    }

    .toggle_details {
      display: flex;
      justify-content: center;
      margin-top: 5px;

      .ui.image.caret-dropdown {
        cursor: pointer;
        transform: translateY(-50%);
        height: 10px;
        transition: 0.4s ease-in-out;
        transform: rotate(0);
        width: 17px;
        &:after,
        &:before {
          content: '';
          height: 8px;
          transition: 0.5s ease-in-out;
          width: 1px;
        }
        &:before {
          transform: rotate(-135deg);
        }
        &:after {
          transform: rotate(135deg);
        }
        &.active {
          transform: rotate(0);
          margin-top: 10px;
          transform: translate(0, -6px);
          &:before {
            transform: rotate(-45deg);
          }
          &:after {
            transform: rotate(45deg);
          }
        }
      }
    }
    .ui.segment.order_details {
      .ui.container.grid {
        font-size: 14px;
        font-family: ${fonts.REGULAR};

        .row {
          padding: 5px 0px 0px;
          &:last-child {
            padding-bottom: 5px;
          }
          .column {
            padding: 0px 10px;
          }
        }

        &.totals {
          .row {
            padding: 5px 0px 0px;
            &:last-child {
              padding-bottom: 5px;
            }
            &.bold {
              font-family: ${fonts.MEDIUM};
              font-weight: lighter;
            }
          }
        }
      }
    }
  }
`;

export const PastOrderContainer = styled.div`
  width: 100%;
  .ui.segment {
    font-size: 18px;
    padding: 14px;

    .chat-icon {
      position: absolute;
      top: 21px;
      right: 14px;
      height: 24px;
      cursor: pointer;
      img {
        height: 24px;
        width: auto;
      }
    }

    .ui.container.grid {
      margin: 0 !important;
      justify-content: space-between;

      .title {
        color: ${colors.niceBlue};
        font-family: ${fonts.MEDIUM};
        text-transform: uppercase;
        padding: 0;
        cursor: pointer;
        text-align: left;

        span.store {
          color: ${colors.charcoalGrey};
          font-weight: lighter;
          font-family: ${fonts.REGULAR};
          font-size: 16px;
          display: block;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        img.ui.image {
          height: 7px;
          width: auto;
          margin-left: 7px;
          display: inline-flex;
        }
      }

      .date {
        display: flex;
        align-items: center;
        font-family: ${fonts.REGULAR};
        font-size: 16px;
        margin-right: 20px;
        color: ${colors.charcoalGrey};
        /* align-self: center; */
        text-align: left;
      }
    }
  }
`;

export const PastOrderSummary = styled.div`
  .ui.button {
    border: 1px solid ${colors.niceBlue};
    color: ${colors.niceBlue};
    background: none;
    text-transform: uppercase;
    font-family: ${fonts.REGULAR};
    font-size: 12px;
    font-weight: lighter;
    display: block;
    margin: 15px 0px 10px;

    &:active,
    &:focus,
    &:hover {
      background: none;
      color: ${colors.niceBlue};
    }
  }

  .ui.label {
    background: #c93852;
    color: ${colors.white};
    font-family: ${fonts.MEDIUM};
    font-size: 12px;
    font-weight: lighter;
    margin: 10px 0px;
    text-transform: uppercase;
  }

  .ui.container.grid {
    font-size: 14px;
    font-family: ${fonts.REGULAR};

    .row {
      padding: 5px 0px 0px;
      &:last-child {
        padding-bottom: 5px;
      }
      .column {
        padding: 0px 10px;
      }
    }

    &.totals {
      background: ${colors.paleGrey};
      .row {
        padding: 5px 0px 0px;
        &:last-child {
          padding-bottom: 5px;
        }
        &.bold {
          font-family: ${fonts.MEDIUM};
          font-weight: lighter;
        }
      }
    }

    .refunded-column {
      color: ${({ theme }) => theme.primaryColor};
      font-family: ${fonts.REGULAR};
      font-weight: lighter;
      text-decoration-line: line-through;
      margin-top: -4px;
      padding-top: 0px;
      margin-bottom: 6px;
    }
  }
`;
