export interface ICategory {
  active: boolean;
  id: string;
  name: string;
  priority: number;
  type: CategoryType;
}

export enum CategoryType {
  COMBO = 'COMBO',
  MERCHANDISE = 'MERCHANDISE',
  OPTIONS = 'OPTIONS',
  STANDALONE = 'STANDALONE',
}
