import React, { useState } from 'react';
import { Button, Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import CloseSearchBtn from '../../assets/icons/closeSearchBtn.png';
import useCartContext from '../../containers/Cart/context';
import { colors, fonts } from '../../shared/theme';
import FloatingLabelInput from '../FloatingLabelInput';

type Props = {
  suiteVersion?: boolean;
};

type StyledProps = {
  showInput: boolean;
  suiteVersion?: boolean;
};

const AddPromoCodeContainer = styled.div<StyledProps>`
    ${props => !props.showInput && 'margin-top: 15px;'}
    ${props =>
      props.showInput &&
      `
        padding: 20px;
        background: #f2f2f2;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: calc(100% + 42px);
        position: relative;
        left: -21px;
        margin-bottom: -16px;
        .ui.grid>.row {
            justify-content: center;
            padding: 0;
        }
    `}
    ${props =>
      props.suiteVersion &&
      `
        width: 100% !important;
        margin-bottom: 0px !important;
        left: 0px !important;
        padding 0px 0px 20px !important;
    `}
    .ui.image {
        height: 10px;
        width: auto;
    }
    button.ui.button {
        background: none;
        font-weight: lighter;
        color: ${colors.niceBlue};
        padding: 0px;
        font-family: ${fonts.REGULAR};
        font-size: 14px;
    }
    .two.column.row {
        align-items: center;
    }
`;

const AddPromoCode = ({ suiteVersion }: Props) => {
  const { cart, addPromoToCart } = useCartContext();
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputHasError, setInputHasError] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const descriptor = 'Only 1 Promo Code per order.';
  const [warningMessage, setWarningMessage] = useState('Please enter a valid code.');
  const [isCodeInvalid, setIsCodeInvalid] = useState(true);
  const toggleShowInput = () => {
    setIsCodeInvalid(false);
    setShowInput(!showInput);
  };

  const onPromoCodeChanged = ({ value, hasError }: any) => {
    setInputHasError(hasError);
    setIsCodeInvalid(false);
    setPromoCodeValue(value);
  };

  const applyPromoCode = async () => {
    setIsLoading(true);
    if (promoCodeValue.trim() !== '') {
      try {
        await addPromoToCart(promoCodeValue);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsCodeInvalid(true);
        setWarningMessage(`We're sorry, but this code is invalid`);
      }
    }
  };

  return (
    <AddPromoCodeContainer showInput={showInput} suiteVersion={suiteVersion}>
      {cart?.promotionId ? (
        <span>Promo Code Applied</span>
      ) : showInput ? (
        <Grid className="promo-code-input">
          <Grid.Row>
            <Grid.Column floated="right" onClick={toggleShowInput} width={4} textAlign="right">
              <Image src={CloseSearchBtn} style={{ cursor: 'pointer' }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={13}>
              <FloatingLabelInput
                id="add-promo-input"
                type="text"
                name="add-promo-input"
                isRequired={false}
                isDisabled={false}
                value={promoCodeValue}
                placeholder="Promo Code"
                onChange={onPromoCodeChanged}
                descriptor={descriptor}
                errorMsg={warningMessage}
                showError={isCodeInvalid ? true : inputHasError}
                pattern={isCodeInvalid ? / / : /^(?!\s*$).+/}
                noMargin={true}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Button loading={isLoading} disabled={inputHasError} onClick={applyPromoCode}>
                APPLY
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Button onClick={toggleShowInput} className="addPromoCode">
          + Add Promo Code
        </Button>
      )}
    </AddPromoCodeContainer>
  );
};

export default AddPromoCode;
