import styled from 'styled-components';
import { Grid, Responsive } from 'semantic-ui-react';
import HomeHeader from 'assets/images/HomeHeader.jpg';
import { colors, fonts } from 'shared/theme';

export const HomeContainer = styled(Responsive)`
  background: url(${HomeHeader}) no-repeat center center;
  background-size: cover;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  margin: auto;
  padding-top: 10px;
  .grid {
    margin: 0 !important;
  }
`;

export const GridContainer = styled(Grid)`
  display: flex;
  .logo {
    width: 140px;
    margin-bottom: 20px;
  }
  .ui.input {
    height: 80px;
    > input {
      width: 100%;
      font-size: 40px;
      text-align: center;
      border-radius: 8px;
      border: solid 3px ${({ theme }) => theme.primaryColor};
      font-family: ${fonts.LIGHT};
      background-color: transparent;
      color: ${colors.secondary};
      letter-spacing: 20px;
      &:active,
      &:focus {
        color: ${colors.secondary};
        background-color: transparent;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .logo {
      width: 110px;
    }
  }
`;

export const Spacer = styled.div`
  flex: 1;
  display: flex;
`;

export const ResendContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    margin-left: 15px;
    height: 18px;
    cursor: pointer;
  }
  span {
    color: #00e3ff;
    cursor: pointer;
  }
`;

export const PhoneInputContainer = styled.div`
  padding: 2em 0;
  .PhoneInput {
    .PhoneInputCountry {
      display: none;
    }
    input[type='tel'] {
      height: 80px;
      font-size: 40px;
      text-align: center;
      border-radius: 8px;
      border: solid 3px ${({ theme }) => theme.primaryColor};
      width: 100%;
      font-family: ${fonts.LIGHT};
      background-color: transparent;
      color: ${colors.secondary};
    }
  }
`;
