import React, { useEffect, useState } from 'react';
import { useEventContext } from '../../../containers/App/AppContext';
import useCartContext from '../../../containers/Cart/context';
import { useCartHandler } from '../../../hooks/handlers/cart';
import { useVenueContext } from '../../../hooks/useVenueContext';
import { OrderSelection } from '../OrderTypeToggle/styled';
import { DropDownArrow, OrderTimeDetails, OrderTimeSummary } from './styled';

interface OrderTimeDropDown {
  openModal: () => void;
  orderNowAvailable: boolean;
  orderAheadAvailable: boolean;
}

interface SelectedText {
  text: string;
  bold: boolean;
}

export const OrderTimeDropDown = ({
  openModal,
  orderAheadAvailable,
  orderNowAvailable,
}: OrderTimeDropDown) => {
  const { cart } = useCartContext();
  const { updateCart } = useCartHandler();
  const { event } = useEventContext();
  const { venue } = useVenueContext();
  const [{ text, bold }, setSelectedText] = useState<SelectedText>({
    text: 'Select Date/Time',
    bold: false,
  });

  useEffect(() => {
    const renderSelectedText = () => {
      let selectedText = {
        text: 'Select order time',
        bold: true,
      };

      if (!orderAheadAvailable && !orderNowAvailable) {
        return { text: 'Unavailable', bold: true };
      }
      if (event && event.scheduledTime) {
        const { scheduledTime, id } = event;
        const formattedDate = new Date(scheduledTime).toLocaleString('en-US', {
          month: 'numeric',
          day: '2-digit',
        });

        const formattedTime = new Date(scheduledTime).toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        });

        return { ...selectedText, text: `${formattedDate} - ${formattedTime.toLocaleLowerCase()}` };
      }

      if (orderNowAvailable) {
        return selectedText;
      }

      return { text: 'Select Date/Time', bold: false };
    };
    if (venue) {
      setSelectedText(renderSelectedText());
    }
  }, [event, orderNowAvailable, orderAheadAvailable]);

  const handleOnClick = () => {
    if (!orderNowAvailable && !orderAheadAvailable) {
      return;
    }

    openModal();
  };

  return (
    <OrderSelection className="order-selection" onClick={() => handleOnClick()} align={'flex-end'}>
      <OrderTimeDetails>
        <OrderTimeSummary bold={bold}>{text}</OrderTimeSummary>
        <div>
          <DropDownArrow className="angle down"></DropDownArrow>
        </div>
      </OrderTimeDetails>
    </OrderSelection>
  );
};
