import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const PaymentContainer = styled.div<{ clientHeight: number }>`
  img.ui.image.title-image {
    display: inline;
    max-width: 11px;
    margin-left: 6px;
  }
  .ui.fluid.button {
    max-width: 450px;
    background: ${({ theme }) => theme.primaryColor};
    text-transform: uppercase;
    color: white;
    margin-bottom: 40px;
    font-family: ${fonts.MEDIUM};
    font-size: 18px;
    font-weight: lighter;
  }

  .my-bag,
  .my-location {
    padding: 10px 5px 5px !important;
  }

  .ui.card {
    max-width: 99%;
    width: 99%;
    margin: 0 auto;
  }

  .toggle-divider {
    margin-bottom: 0 !important;
  }

  display: flex;
  flex-direction: column;
  max-height: ${props => `${props.clientHeight}px`};

  main {
    height: ${props => `calc(${props.clientHeight}px - 70px)`};
    overflow-y: auto;
  }

  footer {
    padding: 10px 15px 15px 15px;
    button.ui.fluid.button {
      background: ${({ theme }) => theme.primaryColor};
      color: ${colors.secondary};
      text-transform: uppercase;
      height: 46px;
      max-width: 450px;
      margin: 0 auto;
      border-radius: 10px;
      font-size: 18px;
    }
  }
`;

export const CartIcon = styled.div<{ expand: boolean }>`
  margin-left: 10px;
  align-self: center;

  .ui.image {
    height: ${({ expand }) => (expand ? '30px' : '25px')};
    width: ${({ expand }) => (expand ? '25px' : 'auto')};
  }
`;

export const BagItemCount = styled.span<{ count: number; shaded: boolean }>`
  position: absolute;
  left: 0;
  transform: ${({ count }) => (count > 9 ? 'translate(3%, 25%)' : 'translate(65%, 50%)')};
  bottom: ${({ count }) => (count > 9 ? '22%' : '35%')};
  font-family: ${fonts.MEDIUM};
  font-size: 14px;
  color: ${({ theme }) => theme.primaryColor};
  z-index: 2;
`;
