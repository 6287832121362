import Header from 'components/Atoms/Header';
import { Grid, Image } from 'semantic-ui-react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

const StyledLogo = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background: none !important;
  height: auto;
  width: 84px;
  margin-top: 22px;
  margin-left: 30px;
  @media (max-width: 800px) {
    width: 50px;
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 26px;
  }

  :focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
`;

const StyledGrid = styled(Grid)<{ backgroundimage: string }>`
  background: ${props => `url(${props.backgroundimage}) no-repeat`};
  background-size: cover;
  height: 200px;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 25%,
    rgba(0, 0, 0, 0.9) 80%,
    rgba(0, 0, 0, 0.7) 95%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const GreyRow = styled(Grid.Row)`
  &.centered.row {
    background-color: ${colors.searchBarGridBackground};
    box-shadow: 5px 10px 10px ${colors.searchBarGridBackground};
  }
`;

const BagNumberContainer = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  padding: 0;
  background: none !important;
  :focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
  @media (max-width: 800px) {
    margin: 15px 0;
  }
`;

const ProfileButtonContainer = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background: none !important;
  display: inline-flex;
  align-items: center;
  color: ${colors.secondary};
  margin-right: 10px;
  margin-top: 30px;
  :focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
  @media (max-width: 800px) {
    margin: 15px 0;
  }
`;

const BagWrapper = styled.div`
  margin-bottom: 5px;
  @media (max-width: 800px) {
    margin-right: 10px;
  }
  @media (min-width: 800px) {
    margin-left: 10px;
    margin-top: 30px;
  }
`;

const BagCounter = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  color: white;
  font-family: ${fonts.BOLD};
  font-size: 15px;
`;

const StyledBag = styled(Image)`
  height: 32px;
  width: 22px;
`;

const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.primaryColor} !important;
`;

const UserIconStyles = {
  width: 22,
  height: 29,
  marginRight: 15,
};

const SearchIcon = {
  width: 20,
  height: 21,
  marginRight: 13,
  bottom: 3,
};

const Calendar = styled(Image)`
  margin: 0 10px;
  bottom: 2px;
`;

const closeStyle = {
  width: '12px',
  height: '12px',
  float: 'right',
  marginTop: '15px',
  marginRight: '15px',
  cursor: 'pointer',
};

const SelectDate = styled.button`
  color: ${colors.white};
  font-size: 23px;
  font-family: ${fonts.REGULAR};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: transparent;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 75%;
    margin-bottom: 5px;
  }
`;

export {
  CenteredContent,
  SelectDate,
  closeStyle,
  Calendar,
  SearchIcon,
  UserIconStyles,
  StyledBag,
  BagCounter,
  BagWrapper,
  BagNumberContainer,
  GreyRow,
  StyledLogo,
  StyledGrid,
  StyledHeader,
  ProfileButtonContainer,
};
