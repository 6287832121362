import CircleCheck from 'assets/icons/circleCheck.png';
import SuiteStepperMobile from 'components/Organisms/SuiteStepper/Mobile';
import { IStep } from 'containers/Suite/Steps';
import { useSuiteContext } from 'containers/Suite/Steps/SuiteContext';
import React, { useEffect } from 'react';
import { Button, Grid, Step } from 'semantic-ui-react';
import {
  ButtonText,
  ButtonWrapper,
  CheckCircle,
  Circle,
  Container,
  MobileWrapper,
  StepItem,
  StyledButton,
  StyledColumn,
  StyledRow,
} from './styled';

export interface SuiteStepperProps {
  steps: IStep[];
}

const SuiteStepper = ({ steps }: SuiteStepperProps) => {
  const { setSelectedIndex, selectedIndex, isMobile } = useSuiteContext();

  useEffect(() => {
    setSelectedIndex(0);
  }, []);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handleNextPress = () => {
    setSelectedIndex(index => (index + 1 < steps.length ? index + 1 : steps.length));
  };

  const isSelected = (index: number): boolean => selectedIndex === index;

  const renderStep = (stepIndex: number, label: string): JSX.Element => {
    const isStepSelected = isSelected(stepIndex);

    return (
      <StyledButton key={stepIndex} onClick={() => handleClick(stepIndex)}>
        <StyledColumn>
          <Container selected={isStepSelected}>
            {isStepSelected ? <CheckCircle src={CircleCheck} /> : <Circle />}
            <Step selected={isStepSelected}>Step {stepIndex + 1}</Step>
            <StepItem selected={isStepSelected}>{label}</StepItem>
          </Container>
        </StyledColumn>
      </StyledButton>
    );
  };

  if (isMobile) {
    return (
      <MobileWrapper>
        <SuiteStepperMobile steps={steps} />
      </MobileWrapper>
    );
  }
  return (
    <Grid padded divided="vertically">
      <StyledRow columns={steps.length + 2}>
        {steps.map((step: IStep, index: number) => renderStep(index, step.name))}

        {/* always render Checkout last. this step is special and is not included in the list of steps */}
        {renderStep(steps.length, 'Checkout')}

        <ButtonWrapper>
          <Button disabled={selectedIndex >= steps.length} onClick={handleNextPress}>
            <ButtonText>Next</ButtonText>
          </Button>
        </ButtonWrapper>
      </StyledRow>
    </Grid>
  );
};

export default SuiteStepper;
