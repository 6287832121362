import { includes } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { IPartnerInvite, PartnerService } from '../../services/PartnerService';
import { BasePath, Partners } from '../../shared/constants';

interface IPartnerRouteParams {
  orderId: string;
  partner: string;
}

export default function PartnerInvite() {
  const { partner, orderId } = useParams<IPartnerRouteParams>();
  const [partnerInvite, setPartnerInvite] = useState<IPartnerInvite>();
  const history = useHistory();

  useEffect(() => {
    if (partnerInvite) {
      return;
    }

    if (!includes(Object.values(Partners), partner)) {
      history.push(`/404`);
    }

    (async () => {
      try {
        const { data } = await PartnerService.acceptInvite(partner as Partners, { orderId });
        setPartnerInvite(data);
      } catch (error) {
        toast(error?.response?.data.message);
        history.replace('/404');
      }
    })();
  }, [orderId]);

  useEffect(() => {
    if (!partnerInvite) {
      return;
    }

    const { invite } = partnerInvite;

    history.replace(`/${BasePath.INVITES}/${invite.id}`);
  }, [partnerInvite]);

  return null;
}
