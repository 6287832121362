import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';
import checkMark from '../../assets/icons/checkMark.png';

type ComboProps = {
  readonly first: boolean;
};

export const SelectionWrapper = styled.div`
  display: flex;
  margin: 10px;
`;

export const SubproductPrice = styled.div`
  font-family: ${fonts.LIGHT};
  color: ${colors.charcoalGrey};
  letter-spacing: -0.24px;
  font-size: 16px;
  padding-left: 10px;
`;

export const ComboCategoryContainer = styled.div<ComboProps>`
  .title {
    color: ${colors.greyCombo} !important;
    font-family: ${fonts.MEDIUM};
    text-align: left !important;
    font-size: 18px !important;
    padding: ${props =>
    props.first ? '1em 0px 1em 0px !important' : '0px 0px 1em 0px !important'};
    line-height: 26px;
    border-top: none !important;
    position: relative;
    .divider {
      margin: 0px 0px 1em 0px !important;
    }
  }
  .content {
    padding: 0px !important;
  }
  .checked.radio {
    label::before {
      background-color: ${({ theme }) => theme.primaryColor} !important;
      border-color: ${({ theme }) => theme.primaryColor} !important;
    }
    label::after {
      background-color: unset !important;
      background-image: url(${checkMark});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px !important;
      height: 25px !important;
      top: -5px !important;
      left: -2px !important;
    }
  }

  .checkbox-container {
    .checkbox label {
      color: ${colors.greyCombo} !important;
      font-size: 16px !important;
      font-family: ${fonts.LIGHT};
      margin-bottom: 16px;
    }
    .checkbox label::before {
      top: -3px !important;
      height: 20px !important;
      width: 20px !important;
    }
    .checkbox label::after {
      top: -3px !important;
      left: 0 !important;
      width: 20px !important;
      height: 20px !important;
      background-color: ${colors.white} !important;
      content: '' !important;
    }

    .checked.checkbox {
      label::before {
        background-color: ${({ theme }) => theme.primaryColor} !important;
        border-color: ${({ theme }) => theme.primaryColor} !important;
      }
      label::after {
        background-color: unset !important;
        background-image: url(${checkMark});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 11px !important;
        height: 8px !important;
        top: 3px !important;
        left: 4px !important;
      }
    }
  }

  .negative-margin-top {
    margin-top: -12px !important;
  }
  .radio label {
    color: ${colors.greyCombo} !important;
    font-size: 16px !important;
    font-family: ${fonts.LIGHT};
    margin-bottom: 16px;
  }
  .radio label::before {
    top: -3px !important;
    height: 20px !important;
    width: 20px !important;
  }
  .radio label::after {
    top: -3px !important;
    left: 0 !important;
    width: 20px !important;
    height: 20px !important;
    background-color: ${colors.white} !important;
  }
`;

export const RequiredContainer = styled.div`
  color: ${colors.red};
  font-family: ${fonts.MEDIUM};
  line-height: 16px;
  font-size: 12px;
  padding-bottom: 10px;
`;

export const SelectionTitle = styled.div`
  line-height: 26px;
  font-size: 14px;
  color: ${colors.greyCombo} !important;
  font-family: ${fonts.MEDIUM};
`;

export const DescriptionContainer = styled.span`
  display: block;
  line-height: 21px;
  font-size: 13px;
  color: ${colors.grey} !important;
  font-family: ${fonts.LIGHT};
`;

export const CaretContainer = styled.span`
  position: absolute;
  top: 50%;
  right: 20px;
  img {
    height: 7px;
    width: 13px;
  }
`;

export const SelectionLimit = styled.div`
  font-family: ${fonts.REGULAR};
  font-size: 14px;
  color: #474350;
  padding-left: 5px;
`;

export const SelectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuantityControlContainer = styled.div`
  margin-left: 20px;
`;
