import { SquarePayments } from '../types';
import { loadSquare } from './load';

const SquareVersion = 'v1';

const getSquareSrcUrl = (applicationId: string): string => {
  let src = '';

  if (applicationId.startsWith('sq0idp-')) {
    src = 'https://web.squarecdn.com/';
  }

  if (applicationId.startsWith('sandbox-sq0idb-')) {
    src = 'https://sandbox.web.squarecdn.com/';
  }

  if (src.length === 0) {
    throw new Error('Invalid application Id');
  }
  src += `${SquareVersion}/square.js`;

  return src;
};

export default async function Square(
  applicationId: string,
  locationId: string,
): Promise<SquarePayments | null> {
  const squareCdn = getSquareSrcUrl(applicationId);

  const square = await loadSquare(squareCdn);

  if (!square) {
    return null;
  }

  return square.payments(applicationId, locationId);
}
