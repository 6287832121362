import OrderGroup, { OrderGroupOptions } from 'shared/models/OrderGroup';
import { Order, OrderSource } from '../shared/models/Order';
import { ApiResponse, APIService, ApiVersion,IPaginationResponse } from './APIService';

const basePath = '/orders';

export type GetUserOrderType = {
  userId: string;
};

export type ChargeOrderType = {
  addToOrderGroup: boolean;
  cardId?: string;
  deferred: boolean;
  orderGroupEventId?: string;
  orderGroupId?: string;
  orderGroupSpendingLimit?: number;
  stripeToken?: string;
};

export type CreateOrderType = {
  cartId: string;
  userId: string;
  storeId: string;
  tipAmt: number;
  eventId?: string | null;
  scheduledTime?: string | null;
  location?: any;
};

export interface CheckoutDto {
  cartId: string;
  finalPrice: number;
  tipAmt: number;
  source?: OrderSource;
  sourceOrderId?: string;
  paymentMethodId?: string;
  savePaymentMethod?: boolean;
  deferred?: boolean;
  addToOrderGroup?: boolean;
  orderGroupId?: string;
  orderGroupSpendingLimit?: number;
  tabId?: string | null;
  tabOrderType?: string | null;
  tabBalance?: number | null
  givexData?: any
}

export class OrderService {
  static async GetUserOrders(
    payload: GetUserOrderType,
  ): Promise<ApiResponse<IPaginationResponse<Order>>> {
    return await APIService.getData(`${basePath}/past`, payload);
  }

  static async GetOneOrder(id: string): Promise<ApiResponse<Order>> {
    return await APIService.getData(`${basePath}/${id}`);
  }

  static async CreateOrder(payload: CreateOrderType): Promise<ApiResponse<Order>> {
    return await APIService.postData(basePath, payload);
  }

  static async CheckOut(payload: CheckoutDto): Promise<ApiResponse<Order>> {
    return await APIService.postData(basePath, payload, ApiVersion.V2);
  }

  static async ChargeOrder(payload: ChargeOrderType, orderId: string): Promise<any> {
    return await APIService.postData(`${basePath}/${orderId}/charge`, payload);
  }

  static async CancelOrder(orderId: string): Promise<any> {
    return await APIService.postData(`${basePath}/${orderId}/cancel`, {});
  }

  static async GetCurrentOrderGroups(
    venueId: string,
    suite: string,
  ): Promise<{ data: OrderGroupOptions }> {
    return await APIService.getData(`${basePath}/orderGroups/${venueId}/current`, {
      suite,
    });
  }

  static async GetOrderGroups(
    venueId: string,
    suite: string,
    eventId: string,
  ): Promise<{ data: OrderGroup[] }> {
    return await APIService.getData(`${basePath}/orderGroups/${venueId}`, {
      eventId,
      suite,
    });
  }
}
