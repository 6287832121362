import closeMenu from 'assets/icons/closeSearchBtn.png';
import Packages from 'components/Organisms/Packages';
import SecureCheckout from 'components/Organisms/SecureCheckout';
import SuiteHeader from 'components/Organisms/SuiteHeader';
import SuiteSideMenu from 'components/Organisms/SuiteSideMenu';
import { useAppContext } from 'containers/App/AppContext';
import { chain, groupBy, isEmpty } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EventData, useSwipeable } from 'react-swipeable';
import { Dimmer, Grid, Segment, Sidebar } from 'semantic-ui-react';
import { ProductService } from 'services/ProductService';
import { StoreService } from 'services/StoreService';
import styled from 'styled-components';
import OrderSummary from '../../../components/Molecules/OrderSummary';
import SuiteStepper from '../../../components/Organisms/SuiteStepper';
import { useStoreContext } from '../../../hooks/useStoreContext';
import { useVenueContext } from '../../../hooks/useVenueContext';
import { VenueService } from '../../../services';
import { BasePath } from '../../../shared/constants';
import { OrderType } from '../../../shared/models/Cart';
import { CategoryType, ICategory } from '../../../shared/models/Category';
import IProduct from '../../../shared/models/Product';
import { CloseButtonContainer } from '../../App/Home/styled';
import EventChangeWarnModal from './EventChangeWarnModal';
import { useSuiteContext } from './SuiteContext';

const SideBarPushable = styled(Sidebar.Pushable)`
  &.pushable {
    transform: none !important;

    .ui.sidebar {
      transform: none !important;
    }
  }
`;

const SubHeaderWrapper = styled.div`
  max-width: 1125px;
  margin: 10px auto 30px;
`;

const SelectedViewWrapper = styled.div`
  margin-top: auto;
`;

const StyledDimmer = styled(Dimmer)`
  background-color: rgba(0, 0, 0, 0.66) !important;
  min-height: 100%;
`;

const UnassignedMessage = styled.div`
  font-size: 32px;
  margin: 80px auto;
  text-align: center;
`;

const Dimmable = styled(Dimmer.Dimmable)`
  min-height: 100%;
`;

export interface IStep {
  categories: ICategoryWithProducts[];
  name: string;
}

export interface ICategoryWithProducts extends ICategory {
  products: IProduct[];
}

interface StepsRouteProps {
  suiteId: string;
}

const useSwipeEffect = ({
  setSelectedIndex,
  selectedIndex,
  steps,
}: {
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  selectedIndex: number;
  steps: IStep[];
}) => {
  const eventHandler = (e: EventData) => {
    const { dir } = e;
    if (dir === 'Right' && selectedIndex - 1 >= 0) {
      setSelectedIndex(selectedIndex - 1);
      return;
    }
    if (dir === 'Left' && selectedIndex + 1 <= steps.length) {
      setSelectedIndex(selectedIndex + 1);
      return;
    }

    e.event.preventDefault();
  };

  const config = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  };

  const handlers = useSwipeable({ onSwiped: eventHandler, ...config });

  return handlers;
};

const Steps = () => {
  const { suiteId } = useParams<StepsRouteProps>();
  const history = useHistory();

  const { setOrderType } = useAppContext();
  const {
    cart,
    clearCart,
    selectedIndex,
    setSelectedIndex,
    suite,
    setSuite,
    isMobile,
    buttonLoadingId,
  } = useSuiteContext();
  const { venue, setVenue } = useVenueContext({
    venueId: suite?.venueId,
  });
  const { store, setStore } = useStoreContext();
  const [steps, setSteps] = useState<IStep[]>([]);
  const [visible, setVisible] = useState(false);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [hasSuiteKitchen, setHasSuiteKitchen] = useState(true);
  const swipeHandlers = useSwipeEffect({ setSelectedIndex, selectedIndex, steps });

  useEffect(() => {
    return () => {
      setStore(undefined);
      setVenue(undefined);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!suite) {
        try {
          const { data } = await VenueService.getVenueLocation(suiteId);
          setSuite(data);
        } catch (error) {
          history.replace(`/${BasePath.SUITE}`);
        }
      }

      setOrderType(OrderType.DELIVERY);

      // set context on every load
      if (
        cart &&
        suite &&
        cart.venueId &&
        cart.suite &&
        (cart.suite !== suite.name || cart.venueId !== suite.venueId)
      ) {
        await clearCart();
      }
    })();
  }, [suiteId]);

  useEffect(() => {
    (async () => {
      const { data } = await StoreService.GetAssignedSuiteStore(suiteId);
      if (data) {
        setStore(data);
      } else {
        setHasSuiteKitchen(false);
      }
    })();
  }, [suiteId]);

  useEffect(() => {
    const fetchStoreCategories = async () => {
      if (!store) return;

      const {
        data: { items: newProducts },
      } = await ProductService.GetAllProducts({
        active: true,
        limit: 500,
        storeId: store.id,
      });

      const {
        data: { items: newCategories },
      } = await ProductService.GetAllCategories({
        active: true,
        storeId: store.id,
        type: [CategoryType.MERCHANDISE, CategoryType.STANDALONE, CategoryType.COMBO].join(','),
      });

      const categoryProducts = groupBy(newProducts, p => p.categoryId);
      const [merchandiseCategories, otherCategories] = chain(newCategories)
        .map(
          (category: ICategory): ICategoryWithProducts => ({
            ...category,
            products: categoryProducts[category.id],
          }),
        )
        .filter((category: ICategoryWithProducts) => !isEmpty(category.products))
        .partition((category: ICategoryWithProducts) => category.type === CategoryType.MERCHANDISE)
        .value();

      const suiteSteps: IStep[] = [
        {
          categories: otherCategories,
          name: `Food & Drink`,
        },
        {
          categories: merchandiseCategories,
          name: `Upgrades`,
        },
      ].filter(step => step.categories.length > 0);

      setSteps(suiteSteps);
      setCategoriesLoaded(true);
    };
    if (store?.id) {
      fetchStoreCategories();
    }
  }, [store]);

  const Stepper = () => {
    if (!store) {
      return null;
    }

    if (selectedIndex >= steps.length) {
      return (
        <SubHeaderWrapper>
          <SecureCheckout {...{ store }} />
        </SubHeaderWrapper>
      );
    }

    return (
      <Segment basic loading={!!buttonLoadingId}>
        <Grid padded columns={isMobile ? 1 : 2} divided>
          <Grid.Column width={isMobile ? 16 : 11}>
            <Packages categories={steps[selectedIndex].categories} />
          </Grid.Column>

          <Grid.Column width={5} only={'computer'}>
            <OrderSummary showCheckoutButton stepsLength={steps.length} type="BAG" />
          </Grid.Column>
        </Grid>
      </Segment>
    );
  };

  const selectedView = Stepper();

  return (
    <SideBarPushable>
      <Sidebar
        as={Segment}
        animation="slide out"
        vertical
        visible={visible}
        width="wide"
        direction="right"
        style={{ padding: 0, backgroundColor: 'white' }}
      >
        <CloseButtonContainer src={closeMenu} onClick={() => setVisible(false)} />
        <SuiteSideMenu />
      </Sidebar>
      <Sidebar.Pusher>
        <SuiteHeader handleSetVisibleMenu={setVisible} suite={suite} />
        {!hasSuiteKitchen && (
          <UnassignedMessage>This Suite is not assigned to a Suite Kitchen</UnassignedMessage>
        )}
        {store?.id && categoriesLoaded && (
          <>
            <SubHeaderWrapper>
              <SuiteStepper steps={steps} />
            </SubHeaderWrapper>

            <SelectedViewWrapper {...swipeHandlers}>{selectedView}</SelectedViewWrapper>
          </>
        )}
        <EventChangeWarnModal />
      </Sidebar.Pusher>
    </SideBarPushable>
  );
};

export default Steps;
