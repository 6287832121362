import HomeHeader from 'assets/images/hamburger-bg.png';
import Header from 'components/Atoms/Header';
import { Grid, Image, Responsive } from 'semantic-ui-react';
import { fonts } from 'shared/theme';
import styled from 'styled-components';

interface StyledProps {
  width: number;
}

export const FooterContainer = styled.div`
  margin: 40px 0;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const SingleTileContainer = styled.div`
  padding-left: 10em;
  padding-right: 10em;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const NearByText = styled.h3`
  color: #fff;
  font-size: 20px;
  font-family: ${fonts.SUPER};
  text-align: center;
`;

export const StyledLogo = styled(Image)`
  width: 110px;
  height: 75px;
  margin-bottom: 25px;

  @media (max-width: 480px) {
    width: 100px;
    height: 69px;
    margin-bottom: 60px;
    display: none !important;
  }
`;

export const StyledHeader = styled(Header)`
  font-size: 50px !important;
  position: relative;
  z-index: 1;
  margin-bottom: 10px !important;
  @media (max-width: 480px) {
    line-height: 0.87;
    font-size: 24px !important;
  }
`;

export const HomeContainer = styled(Responsive)`
  background: url(${HomeHeader}) no-repeat center center;
  background-size: cover;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: auto;
  padding-top: 50px;
  .grid {
    margin: 0 !important;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 30px;
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    z-index: 3;
    :focus {
      outline: thin dotted ${({ theme }) => theme.primaryColor};
    }
    img {
      width: 26px;
    }
  }
`;

export const GridContainer = styled(Grid)<StyledProps>`
  flex: 1;
  display: flex;
  min-height: ${props => {
    let finalWidth = 'unset';
    if (props.width < 400) {
      finalWidth = '55vh';
    } else if (props.width < 600) {
      finalWidth = '40vh';
    } else if (props.width < 800) {
      finalWidth = '30vh';
    } else if (props.width < 1200) {
      finalWidth = '20vh';
    }
    return finalWidth;
  }};
  .logo {
    width: 180px;
  }
  .subtitle-search {
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 24px;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  .title {
    padding-top: 40px;
  }
  .first-row {
    flex-direction: column !important;
    padding-top: 40px !important;
  }
  .row-bottom {
    margin-top: 45px;
  }
  @media screen and (max-width: 800px) {
    .logo {
      width: 140px;
    }
    .first-row {
      justify-content: flex-start !important;
    }
  }
`;

export const VenuesContainer = styled.div`
  width: 100%;
  max-height: 36em;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const CloseButtonIcon = styled(Image)`
  height: 18px;
`;

export const CloseButtonContainer = styled(Image)`
  &&& {
    position: absolute;
    height: 18px;
    right: 10px;
    top: 10px;
    z-index: 10;
    cursor: pointer;
  }
`;
