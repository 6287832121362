import React from 'react';
import styled from 'styled-components';
import { Grid, Button } from 'semantic-ui-react';
import BackgroundImage from '../../assets/images/background_404.jpg';
import { fonts, colors } from '../../shared/theme';
import { Link } from 'react-router-dom';

const Title = styled.div`
  font-family: ${fonts.SUPER};
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  font-size: 24px;
  line-height: 26px;
  color: ${({ theme }) => theme.primaryColor};
`;

const Subtitle = styled.div`
  max-width: 185px;
  font-family: ${fonts.REGULAR};
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.charcoalGrey};
  margin: -1.5em auto auto;
`;

const StyledContainer = styled.div`
  height: 100%;
  padding: 150px 20px;
  background-size: cover;
  background: url(${BackgroundImage}) top;
  .ui.fluid.button {
    background: ${({ theme }) => theme.primaryColor};
    color: ${colors.secondary};
    text-transform: uppercase;
    height: 46px;
    max-width: 450px;
    margin: 0 auto;
  }
`;

const GoToHomeButtonContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 0;
  padding: 0 16px;
  width: 100%;
  .ui.button {
    font-family: ${fonts.MEDIUM};
    font-size: 18px;
    text-transform: uppercase;
  }
`;

function VenueSearch() {
  return (
    <StyledContainer>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Title>Oh No!</Title>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Subtitle>It looks like we dropped the ball, er fries.</Subtitle>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <GoToHomeButtonContainer>
        <Link to={{ pathname: '/' }}>
          <Button fluid>Go to Home</Button>
        </Link>
      </GoToHomeButtonContainer>
    </StyledContainer>
  );
}

export default VenueSearch;
