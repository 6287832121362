import SubmitButton from 'components/Atoms/Button/Submit';
import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';
import { format } from 'libphonenumber-js';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, CheckboxProps, Form, Label, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import FloatingLabelInput from '../../../components/FloatingLabelInput';
import { UserService, UserType } from '../../../services/UserService';
import IUser from '../../../shared/models/User';
import { saveSessionValues } from '../../../shared/SessionAuthPersist';
import { colors, fonts } from '../../../shared/theme';
import { useAppContext } from '../../App/AppContext';

const ButtonWrapper = styled.div`
  display: flex;
  margin: 10px 10px 0 10px;
`;

const CancelButton = styled(SubmitButton)`
  background-color: ${colors.inputBorderBottom};
  flex: 1;
  margin-right: 8px;
`;

const StyledContainer = styled.div`
  .ui.basic.clearing.segment {
    padding-right: 0px;
    padding-left: 0px;
  }
  .ui.segment {
    padding: 20px;
    margin: 5px 10px;
    border-radius: 10px;
    table {
      width: 100%;
    }

    .ui.top.right.attached.label {
      background: transparent;
      color: ${colors.niceBlue};
      padding: 22px;
      text-transform: uppercase;
      cursor: pointer;
    }

    form .fields {
      justify-content: center;
      text-align: center;
      button.ui.button {
        background: ${colors.inputBorderBottom};
        color: white;
        width: 115px;
        font-size: 18px;
        font-family: ${fonts.MEDIUM};
        text-transform: uppercase;
        padding: 16px 21px;
      }
      .ui.primary.button {
        background: ${({ theme }) => theme.primaryColor};
        width: 134px;
      }
    }
    form .field {
      color: ${colors.charcoalGrey};
      font-family: ${fonts.REGULAR};
      margin-top: 15px;

      > label {
        font-size: 13px;
        font-weight: lighter;
      }

      > div {
        display: flex;
        align-items: center;
        font-size: 15px;

        .ui.toggle.checkbox {
          margin: 0px 5px;
          width: 38px;

          label::after {
            background: ${colors.white};
            box-shadow: none;
            width: 15px;
            height: 15px;
            top: 0.2em;
          }

          label::before {
            height: 21px;
            width: 38px;
          }

          input ~ label {
            ::after {
              left: 0.25em;
            }
            ::before {
              background: ${colors.grey};
            }
          }

          input:checked ~ label {
            ::after {
              left: 1.4em;
            }
            ::before {
              background: ${({ theme }) => theme.primaryColor} !important;
            }
          }
        }
      }
    }
  }
  h1 {
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 200;
  }
`;

type Errors = {
  firstName: string | null;
  [index: string]: string | null;
};

const Account = () => {
  const { user, setUser, setShowLoader } = useAppContext();
  const [isEditing, setIsEditing] = useState(false);

  const [errors, setErrors] = useState<Errors>({ firstName: null, lastName: null, email: null });
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(false);

  const [userInfo, setUserInfo] = useState<Partial<IUser> | undefined>(user);
  const phoneNumber = `+${user?.countryCode}${user?.phoneNumber}`;

  const isEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const noSpecialCharacters = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;

  const disableButtons = useCallback(() => {
    setDisableUpdateBtn(true);

    if (setDisableUpdateBtn) {
      setDisableUpdateBtn(true);
    }
  }, [setDisableUpdateBtn]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    const correctValues = Object.values(errors).every(value => value === null);
    const userValuesMissing = Object.values(userInfo).some(value => value === '');

    if (userValuesMissing) {
      setIsEditing(true);
      disableButtons();
      return;
    }

    if (!correctValues) {
      disableButtons();
    } else {
      setDisableUpdateBtn(false);
    }
  }, [userInfo, errors, disableButtons]);

  const handleOnChange = ({ name, value }: { name: string; value: string }) => {
    if (name !== 'email' && value.length >= 1 && !noSpecialCharacters.test(value)) {
      setErrors({ ...errors, [name]: 'Please use letters only' });
      setUserInfo(prev => ({ ...prev, [name]: value }));
    } else {
      setErrors({ ...errors, [name]: null });
      setUserInfo(prev => ({ ...prev, [name]: value }));
    }

    if (name === 'email') {
      if (value.length < 1) {
        disableButtons();
      } else if (!isEmail.test(value)) {
        setErrors({ ...errors, email: 'Invalid email' });
        disableButtons();
      } else {
        setErrors({ ...errors, email: null });
        setUserInfo(prev => ({ ...prev, email: value }));
      }
    }
  };

  const handleToggleChange = async (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    e.preventDefault();

    const { name } = data;
    const fieldName = name as keyof typeof userInfo;

    if (!user || !userInfo) {
      return;
    }

    setUserInfo(prev => ({ ...(prev && { ...prev, [fieldName]: !prev[fieldName] }) }));

    try {
      const { data } = await UserService.UpdateNotifications(user.id, {
        [fieldName]: !userInfo[fieldName],
      });

      const updatedUser = {
        ...user,
        ...data,
      };
      setUser(updatedUser);
      saveSessionValues(updatedUser);
    } catch (error) {
      toast('Notifications cannot be updated at this time');
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!user || !userInfo) {
      return;
    }
    setShowLoader(true);
    const { firstName, lastName, email } = userInfo;
    const payload: UserType = {
      firstName: firstName?.trim() || user.firstName,
      lastName: lastName?.trim() || user.lastName,
      email: email?.trim() || user.email,
    };

    try {
      const { data } = await UserService.UpdateUser(user.id, payload);
      const updatedUser = {
        ...user,
        ...data,
      };
      setUser(updatedUser);
      saveSessionValues(updatedUser);
      toast('User info updated!');
      setIsEditing(false);
    } catch (error) {
      toast('Unable to update your profile at this time. Please try again later.');
    }
    setShowLoader(false);
  };

  const handleCancel = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsEditing(false);
    setUserInfo(undefined);
  };

  return (
    <>
      <StyledContainer>
        <ProfileHeader title="Account" />
        <Segment>
          <Label onClick={() => setIsEditing(prev => !prev)} attached="top right">
            EDIT
          </Label>
          <form>
            <FloatingLabelInput
              isRequired={true}
              placeholder="First Name"
              name="firstName"
              type="text"
              isDisabled={!isEditing}
              readOnly={!isEditing}
              value={userInfo?.firstName || ''}
              onChange={handleOnChange}
              errorMsg={errors.firstName}
              showError={errors.firstName}
            />
            <FloatingLabelInput
              isRequired={true}
              readOnly={!isEditing}
              placeholder="Last Name"
              name="lastName"
              isDisabled={!isEditing}
              value={userInfo?.lastName || ''}
              onChange={handleOnChange}
              type="text"
              errorMsg={errors.lastName}
              showError={errors.lastName}
            />

            <FloatingLabelInput
              isRequired={false}
              placeholder="Phone Number"
              name="phoneNumber"
              value={format(phoneNumber, 'NATIONAL')}
              type="text"
              isDisabled={true}
              readOnly
            />

            <FloatingLabelInput
              isRequired={true}
              placeholder="Email"
              name="email"
              readOnly={!isEditing}
              value={userInfo?.email || ''}
              isDisabled={!isEditing}
              onChange={handleOnChange}
              type="email"
              errorMsg={errors.email}
              showError={errors.email}
            />
            <Form.Field>
              <label>Allow Push Notifications</label>
              <div>
                No
                <Checkbox
                  toggle
                  name="sendPushNotifications"
                  checked={userInfo?.sendPushNotifications ?? false}
                  onChange={handleToggleChange}
                />
                Yes
              </div>
            </Form.Field>
            <Form.Field>
              <label>Allow SMS</label>
              <div>
                No
                <Checkbox
                  toggle
                  name="sendSMSNotifications"
                  checked={userInfo?.sendSMSNotifications ?? false}
                  onChange={handleToggleChange}
                />
                Yes
              </div>
            </Form.Field>
          </form>
        </Segment>
      </StyledContainer>
      {isEditing && (
        <ButtonWrapper>
          <CancelButton onClick={handleCancel}>CANCEL</CancelButton>
          <SubmitButton disabled={disableUpdateBtn} onClick={handleSubmit}>
            UPDATE
          </SubmitButton>
        </ButtonWrapper>
      )}
    </>
  );
};

export default Account;
