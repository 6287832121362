import { chain } from 'lodash';
import { useAppContext } from '../../containers/App/AppContext';
import useCartContext from '../../containers/Cart/context';
import { LineItemDto } from '../../services/CartService';
import { AvailabilityType } from '../../shared/models/Product';

interface CartValidation {
  checkAlcoholOverLimit: (lineItemDto: LineItemDto) => boolean;
  checkCartOrderTypeItemsInvalid: (availabilityType: AvailabilityType) => boolean;
}

export const useCartValidation = (): CartValidation => {
  const { cart } = useCartContext();
  const { venue } = useAppContext();

  const checkAlcoholOverLimit = (lineItem: LineItemDto) => {
    if (!cart || !venue) {
      return false;
    }
    const { lineItems } = cart;
    const { alcoholLimit } = venue;
    const quantityToAdd =
      chain(lineItems)
        .filter(({ productId, alcohol }) => productId === lineItem.productId && alcohol)
        .sumBy(item => item.quantity)
        .value() + lineItem.quantity;

    return quantityToAdd > alcoholLimit;
  };

  const checkCartOrderTypeItemsInvalid = (availabilityType: AvailabilityType) => {
    if (!cart) {
      return false;
    }

    const { lineItems } = cart;

    const invalidItems = lineItems.filter(
      item =>
        item.availabilityType !== AvailabilityType.ALL &&
        item.availabilityType !== availabilityType,
    );

    return !!invalidItems.length;
  };

  return { checkAlcoholOverLimit, checkCartOrderTypeItemsInvalid };
};
