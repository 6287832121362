import styled from 'styled-components';
import { fonts, colors } from './theme';

export const CardContainer = styled.div`
  margin: 17px auto;
  .ui.card {
    padding: 21px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 0px 5px 1px #d4d4d5, 0 0 0 1px #f4f4f7;
    .title {
      padding: 0px;
      font-size: 20px;
      font-family: ${fonts.LIGHT};
      color: ${colors.charcoalGrey};
      font-weight: lighter;
    }
  }
`;
