import OrderPlacedBackground from 'components/OrderPlacedBackground/OrderPlacedBackground';
import ProductHeader from 'components/Organisms/PWA/Headers/ProductHeader/ProductHeader';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Card, Grid, Image, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import circleCheck from '../../assets/icons/circleCheck.png';
import CloseIcon from '../../assets/icons/closeSearchBtn.png';
import OrderCard from '../../components/OrderCard';
import OrderDetails from '../../components/OrderDetails';
import { useVenueContext } from '../../hooks/useVenueContext';
import { OrderService } from '../../services/OrderService';
import { OrderType } from '../../shared/models/Cart';
import { colors, fonts } from '../../shared/theme';
import { useAppContext } from '../App/AppContext';
import { ProductService } from 'services';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
export const OrderPlacedContainer = styled.div`
  .ui.card {
    margin: 60px auto !important;
    text-align: center;
    padding: 0px;
    box-shadow: none;
    .content {
      .header {
        text-transform: uppercase;
        color: ${({ theme }) => theme.primaryColor} !important;
        font-family: ${fonts.SUPER};
        font-weight: lighter !important;
      }
      .description {
        margin: 20px 0;
        font-size: 18px;
        font-family: ${fonts.REGULAR};
        .main-description {
          font-size: 18px;
        }
        .sub-description {
          font-size: 15px;
          margin-top: 10px;
          color: ${colors.grey};
        }
      }
    }
    .image {
      background: transparent;
      margin-top: 45px;
    }
    .content {
      border: 0;
      .ui.center.grid {
        margin-top: 15px;
        .row {
          padding-top: 0px;
          padding-bottom: 10px;
        }
      }
    }
    .ui.teal.basic.button {
      border-color: ${colors.niceBlue};
      color: ${colors.niceBlue} !important;
      text-transform: uppercase;
      font-size: 12px;
      font-family: ${fonts.REGULAR};
      &.details {
        box-shadow: none !important;
        margin-bottom: 17px;
      }
    }
  }
  .ui.card > .image > img,
  .ui.cards > .card > .image > img {
    display: block;
    height: 66px;
    width: 66px;
    margin: 0 auto;
  }
`;

const CloseIconContainer = styled.div`
  .ui.image {
    position: absolute;
    height: 12px;
    width: auto;
    top: 28px;
    right: 28px;
    z-index: 1;
  }
`;
const closeButton = styled.div`
  color: 'primary',
  backgroundColor: '#fff',
  '&:hover': {
    color: '#009bc5',
    backgroundColor: '#fff',
  },
  minWidth: '128px',
  minHeight: '56px',
  borderRadius: '1',
  marginTop: '16px',
  flex: '1',
  marginRight: '40px',
  border: '1px solid #009bc5',
  maxWidth: '130px',
  maxHeight: '40px',
  minWidth: '129px',
 minHeight: '40px',

`;

const TrackCardInfo = styled.div<{ imageProvided?: boolean }>`
  background-color: white;
  border-radius: 10px;
  max-height: 30em;
  position: relative;
  margin-bottom: 20px;
  box-shadow: ${props => (props.imageProvided ? '-2px 10px 19px -6px rgba(168, 168, 168, 1)' : 0)};
`;

const InfoContainer = styled.div`
  padding: 0px 20px 40px 20px;
`;

interface OrderPlacedRouteParams {
  orderId: string;
}

const OrderPlaced = () => {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [outOfStockItems, setOutOfStockItems] = useState<any[]>([]);
  const [outOfStockPopUp, setOutOfStockPopUp] = useState(false);
  const { orderId } = useParams<OrderPlacedRouteParams>();
  const history = useHistory();

  const {
    setShowLoader,
    user,
    setOrderType,
    order: placedOrder,
    setOrder: setPlacedOrder,
    setDeliveryLocationId,
    setDeliveryLocation,
  } = useAppContext();

  const { venue } = useVenueContext({
    venueId: placedOrder?.venueId,
  });
  // const getProductIdsFromOrder = async (placedOrder: any) => {
  //   const produtIds: any[] = [];
  //   for (let i = 0; i < placedOrder.lineItems.length; i++) {
  //     const currentItem: any = placedOrder.lineItems[i];
  //     if (!produtIds.includes(currentItem?.product?.id)) {
  //       produtIds.push(currentItem?.product?.id);
  //     }
  //     for (const subSelectionCategory of currentItem?.subSelections) {
  //       for (const subSelection of subSelectionCategory.subSelections) {
  //         if (subSelection?.productId && !produtIds.includes(subSelection?.productId)) {
  //           produtIds.push(subSelection?.productId);
  //         }
  //       }
  //     }
  //   }
  //   const outOfStockProducts: any[] = [];
  //   if (produtIds.length > 0) {
  //     for (let j = 0; j < produtIds.length; j++) {
  //       const { data } = await ProductService.GetOneProduct(produtIds[j]);
  //       if (data && data.countAsInventory && data.stock < 1) {
  //         outOfStockProducts.push(data.name);
  //       }
  //     }
  //   }
  //   setOutOfStockItems(outOfStockProducts);
  //   if (outOfStockProducts.length > 0) {
  //     setOutOfStockPopUp(true);
  //   }
  // };
  useEffect(() => {
    setDeliveryLocation(undefined);
    setDeliveryLocationId(undefined);
    setOrderType(OrderType.PICKUP);
    return () => {
      setPlacedOrder(undefined);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        setShowLoader(true);
        const { data: order } = await OrderService.GetOneOrder(orderId);
        setPlacedOrder(order);
        if (order.id !== orderId) {
          await history.push('/404');
        }
      } catch (error) {
        if (error.response.data.message === 'Order does not exist!') {
          await history.push('/404');
        } else {
          toast(error.response.data.message);
        }
      } finally {
        setShowLoader(false);
      }
    };
    if (!placedOrder || placedOrder.id !== orderId) {
      init();
    }
  }, [orderId, history, setShowLoader]);
  // useEffect(() => {
  //   (async () => {
  //     if (placedOrder) {
  //       await getProductIdsFromOrder(placedOrder);
  //     }
  //   })();
  // }, [placedOrder]);

  if (!placedOrder) return <></>;

  const InfoCard = () => {
    if (!placedOrder) return <></>;

    return (
      <TrackCardInfo imageProvided={!!placedOrder.store.imageUrl}>
        {placedOrder && (
          <OrderDetails
            order={placedOrder}
            onClose={() => setShowOrderDetails(false)}
            open={showOrderDetails}
          />
        )}
        <OrderPlacedContainer>
          <Card>
            <Image src={circleCheck} wrapped ui={false} />
            <Card.Content>
              <Card.Header>Thank you, {user?.firstName || placedOrder.user.firstName}!</Card.Header>
              <Card.Description>
                <div className="main-description">
                  Order #{placedOrder.orderNo} has been placed.
                </div>
                {!placedOrder?.store.bypassOrderFulfillment && (
                  <div className="sub-description">We'll text you when its ready.</div>
                )}
                {venue && venue.deferPaymentCapture && (
                  <div className="sub-description">
                    A venue representative will reach out shortly to discuss payment.
                  </div>
                )}
              </Card.Description>
              <Grid textAlign="center">
                {placedOrder && !placedOrder.store.bypassOrderFulfillment && (
                  <Grid.Row>
                    <Modal
                      size="tiny"
                      trigger={
                        <Button basic color="teal" className="trackOrder">
                          Track Order
                        </Button>
                      }
                      basic
                      closeIcon={
                        <CloseIconContainer>
                          <Image src={CloseIcon} />
                        </CloseIconContainer>
                      }
                    >
                      <Modal.Content>
                        <OrderCard order={placedOrder} />
                      </Modal.Content>
                    </Modal>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Button
                    className="details orderDetails"
                    onClick={() => setShowOrderDetails(true)}
                    basic
                    color="teal"
                  >
                    Order Details
                  </Button>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        </OrderPlacedContainer>
        {/* <Dialog open={outOfStockPopUp} maxWidth="sm">
          <DialogTitle>Following Products are now out of Stock and Marked Inactive</DialogTitle>
          <DialogContent>
            <div className="dialogue-box">
              <ul style={{ listStyle: 'inside maroon circle' }}>
                {outOfStockItems.map((product, index) => (
                  <li key={index}>{product}</li>
                ))}
              </ul>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Button
                  onClick={() => {
                    setOutOfStockPopUp(false);
                    setOutOfStockItems([]);
                  }}
                  className={closeButton}
                >
                  close
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog> */}
      </TrackCardInfo>
    );
  };

  return (
    <>
      {!!placedOrder.store.imageUrl && (
        <OrderPlacedBackground background={placedOrder.store.imageUrl} />
      )}
      <ProductHeader returnToVenue shaded={!!placedOrder.store.imageUrl} />
      <InfoContainer>
        <InfoCard />
      </InfoContainer>
    </>
  );
};

export default OrderPlaced;
