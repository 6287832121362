import { assign, Machine } from 'xstate';

interface AuthContext {
  phoneNumber: string;
  code: string;
  step: 1 | 2;
  errorMessage: string;
}

const defaultErrorMessage = 'There was an error sending the code. Please, try again!';

const authMachine = Machine<AuthContext>(
  {
    id: 'auth',
    initial: 'idle',
    context: {
      phoneNumber: '',
      code: '',
      step: 1,
      errorMessage: defaultErrorMessage,
    },
    states: {
      idle: {
        entry: 'goToStep1',
        on: {
          SEND_REQUEST: 'sendingOTP',
          TYPING: { target: 'idle', actions: 'assignPhoneNumber' },
        },
      },
      sendingOTP: { entry: 'clearCode', on: { SUCCESS: 'waitingCode', REJECT: 'failure' } },
      failure: {
        entry: 'assignErrorResponse',
        on: {
          TYPING: { target: 'idle', actions: 'assignPhoneNumber' },
          SEND_REQUEST: 'sendingOTP',
          EDIT_NUMBER: 'idle',
        },
      },
      waitingCode: {
        entry: 'goToStep2',
        on: {
          VALIDATE: 'validatingCode',
          REJECT: 'rejectedError',
          SEND_REQUEST: 'sendingOTP',
          EDIT_NUMBER: 'idle',
          TYPING: { target: 'waitingCode', actions: 'assignCode' },
        },
      },
      rejectedError: {
        entry: 'assignErrorResponse',
        on: { TYPING: { target: 'waitingCode', actions: 'assignCode' }, EDIT_NUMBER: 'idle' },
      },
      validatingCode: {
        on: { ERROR: 'rejectedError', AUTHENTICATED: 'authenticated' },
      },
      authenticated: { type: 'final' },
      invalid: { type: 'final' },
    },
  },
  {
    actions: {
      assignPhoneNumber: assign({ phoneNumber: (context, event) => event.number }),
      assignCode: assign({ code: (context, event) => event.number }),
      clearCode: assign({ code: (context, event) => '' }),
      assignErrorResponse: assign({ errorMessage: (context, event) => event.message }),
      goToStep2: assign({ step: (context, event) => 2 }),
      goToStep1: assign({ step: (context, event) => 1 }),
    },
  },
);

export default authMachine;
