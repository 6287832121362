import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

interface ProductDetailsContainerProps {
  readonly bg: string;
  clientHeight?: number;
}

export const ProductDetailsContainer = styled.div<ProductDetailsContainerProps>`
  background: url(${props => props.bg});
  height: ${props => `calc(${props.clientHeight}px - 70px)`};
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  overflow-y: scroll;

  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .ui.grid.container {
    margin: auto !important;
  }
  .title {
    font-size: 20px;
    color: ${colors.charcoalGrey};
    font-family: ${fonts.MEDIUM};
  }
  .description {
    font-size: 13px;
    color: ${colors.grey};
    margin: 5px 0px;
  }
  .price {
    font-size: 16px;
    color: ${colors.charcoalGrey};
    font-family: ${fonts.MEDIUM};
  }
`;

export const ActionContainer = styled.div`
  width: 100%;
  min-height: 46px;
  .button-container {
    .column {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
`;

export const ProductImageContainer = styled.div<ProductDetailsContainerProps>`
  position: absolute;
  background: url(${props => props.bg});
  top: 0px;
  left: 0px;
  max-height: 40vh;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
