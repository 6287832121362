import React, { ReactNode, useEffect, useState } from 'react';
import createContextWrapper from '../../hooks/CreateContext';
import {
  Card,
  NativePay,
  PaymentMethod,
  SquarePaymentRequest,
  SquarePayments,
  TokenResult,
} from './types';

interface SquareContext {
  initCard: (container: string | HTMLElement) => Promise<Card | null>;
  initNativePay: (type: string, paymentRequest: any) => Promise<NativePay | null>;
  squarePayments: SquarePayments | null;
  tokenize: (paymentMethod: PaymentMethod) => Promise<TokenResult | undefined | null>;
}

interface ISquareProviderProps {
  children: ReactNode;
  square: PromiseLike<SquarePayments | null>;
}

const [useSquareContext, SquareContextProvider] = createContextWrapper<SquareContext>();

const SquareProvider = ({ square, children }: ISquareProviderProps) => {
  const [squarePayments, setSquarePayments] = useState<SquarePayments | null>(null);
  useEffect(() => {
    const loadSquare = async () => {
      try {
        const payments = await square;
        setSquarePayments(payments);
      } catch (error) {
        console.debug('Square js load error');
      }
    };
    if (!squarePayments) {
      loadSquare();
    }
  }, []);

  const initNativePay = async (
    type: string,
    paymentRequest: SquarePaymentRequest,
  ): Promise<any> => {
    let nativePay: NativePay | null = null;

    if (!squarePayments) {
      return nativePay;
    }

    try {
      if (type === 'android') {
        nativePay = await squarePayments.googlePay(paymentRequest);
      }
    } catch (error) {
      console.debug('Error initiating native pay');
    }

    return nativePay;
  };

  const initCard = async (container: string | HTMLElement) => {
    let card: Card | null = null;

    if (!squarePayments) {
      return null;
    }

    try {
      card = await squarePayments.card();
      await card.attach(container);
    } catch (error) {
      console.debug('Error creating Square Card');
    }

    return card;
  };

  const tokenize = async (paymentMethod: PaymentMethod) => {
    let token = null;
    try {
      token = await paymentMethod.tokenize();
      switch (token.status) {
        case 'OK':
          return token;
        default:
          return null;
      }
    } catch (error) {
      console.debug(token?.errors);
    }
    return token;
  };

  return (
    <SquareContextProvider value={{ squarePayments, initNativePay, initCard, tokenize }}>
      {children}
    </SquareContextProvider>
  );
};

export { useSquareContext, SquareProvider };
