import closeMenu from 'assets/icons/closeSearchBtn.png';
import Footer from 'components/Organisms/Footer';
import SuiteHeader from 'components/Organisms/SuiteHeader';
import SuiteSideMenu from 'components/Organisms/SuiteSideMenu';
import { CloseButtonContainer } from 'containers/App/Home/styled';
import React, { useEffect, useState } from 'react';
import { Image, Modal, Segment, Sidebar, TransitionablePortal } from 'semantic-ui-react';
import { VenueService } from 'services/VenueService';
import { isEmpty } from 'underscore';
import { Button } from '../../../components/Atoms/Button';
import Header from '../../../components/Atoms/Header';
import Spacer, { SpacerSizes } from '../../../components/Atoms/Spacer';
import { closeStyle } from '../../../components/Organisms/SuiteHeader/styled';
import SuiteSelection from '../../../components/Organisms/SuiteSelection';
import { ISuiteVenue } from '../../../shared/models/Venue';

const Home = () => {
  const [accessibleVenues, setAccessibleVenues] = useState<ISuiteVenue[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchAccessibleVenues = async () => {
      try {
        const { data } = await VenueService.GetAccessibleSuiteVenues({ limit: 10000 });
        setAccessibleVenues(data);

        if (isEmpty(data)) {
          setOpenModal(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccessibleVenues();
  }, []);

  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Segment}
        animation="overlay"
        vertical
        visible={visible}
        width="wide"
        direction="right"
        style={{ padding: 0, backgroundColor: 'white' }}
      >
        <CloseButtonContainer src={closeMenu} onClick={() => setVisible(false)} />
        <SuiteSideMenu />
      </Sidebar>
      <Sidebar.Pusher>
        <SuiteHeader handleSetVisibleMenu={setVisible} />
        <SuiteSelection venues={accessibleVenues} />
        <Footer />
      </Sidebar.Pusher>
      <TransitionablePortal open={openModal} transition={{ animation: 'fade up', duration: 500 }}>
        <Modal
          centered={true}
          open={true}
          onClose={() => setOpenModal(false)}
          closeIcon={<Image style={closeStyle} alt="Close Button" src={closeMenu} />}
          style={{ borderRadius: '10px' }}
          size="mini"
        >
          <Modal.Content style={{ borderRadius: '10px' }}>
            <Modal.Description>
              <Spacer size={SpacerSizes.TINY} />
              <Header size={22} font="MEDIUM" textAlign="center" letterSpacing="-1.2px" as="h1">
                We’re Sorry!
              </Header>
              <Spacer size={SpacerSizes.TINY} />
              <Header size={16} font="LIGHT" textAlign="center" letterSpacing="-0.5px" as="h4">
                It doesn’t look like your suite account has been created yet.
              </Header>
              <Spacer size={SpacerSizes.TINY} />
              <Header size={16} font="LIGHT" textAlign="center" letterSpacing="-0.5px" as="h4">
                Please contact the venue with whom you reserved your suite to confirm they have the
                correct phone number.
              </Header>
              <Spacer size={SpacerSizes.TINY} />
              <Button type="solidRed" onClick={() => setOpenModal(false)}>
                Close
              </Button>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    </Sidebar.Pushable>
  );
};

export default Home;
