import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Card, Divider, Grid, List } from 'semantic-ui-react';
import { useAppContext } from '../../../containers/App/AppContext';
import useCartContext from '../../../containers/Cart/context';
import { BasePath } from '../../../shared/constants';
import { CardContainer } from '../../../shared/global-styled';
import { ILineItem } from '../../../shared/models/Cart';
import { LineItemComponent } from '../Item/LineItem/LineItem';
import { CartNote } from '../Note';
import { MyBagTitle } from '../styled';

interface MyBagBody {
  items: ILineItem[] | undefined;
  currency: string;
}

const MyBagBody = ({ items, currency }: MyBagBody) => {
  const lineItems = (items ?? []).sort((itemA, itemB) => {
    const nameA = itemA.name.toUpperCase();
    const nameB = itemB.name.toUpperCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  return (
    <>
      <List verticalAlign="middle">
        {lineItems.map((item, index) => (
          <LineItemComponent index={index} currency={currency} key={item.id} lineItem={item} />
        ))}
      </List>
    </>
  );
};

export const MyBagCard = () => {
  const { cart, numberOfCartItems } = useCartContext();
  const { store, venue } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    if (numberOfCartItems == 0 && venue && store) {
      history.replace(`/${BasePath.CONSUMER}/stores/${venue.id}/products/${store.id}`, {
        return: true,
      });
    }
  }, [numberOfCartItems]);

  if (!cart) {
    return null;
  }
  return (
    <CardContainer>
      <Card className="my-bag">
        <Card.Content>
          <Card.Header>
            <Grid>
              <Grid.Row>
                <MyBagTitle floated="left" width={6}>
                  My Bag
                </MyBagTitle>
              </Grid.Row>
            </Grid>
          </Card.Header>
          <Divider />
          <MyBagBody items={cart.lineItems} currency={cart.currency} />
        </Card.Content>
        <CartNote />
      </Card>
    </CardContainer>
  );
};
