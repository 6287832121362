import React, { useEffect, useState } from 'react';
import { withRouter, Switch, useHistory } from 'react-router';
import { TransitionContainer } from '../../hoc/RouterTransition/styled';
import RouterTransition from '../../hoc/RouterTransition';
import { fbAnalytics } from './AppContext';

function AppTransition({ location, children }: any) {
  if (fbAnalytics) {
    fbAnalytics.logEvent('page_view', { page_path: location.pathname });
  }

  const history = useHistory();
  const { return: returning } = location.state || {};
  const timeOutAnimation = 400;
  const [isConsumer, setIsConsumer] = useState(false);

  useEffect(() => {
    const rootDiv = document.getElementById('root');
    const evalConsumer = /\/consumer/.test(location.pathname);
    setIsConsumer(evalConsumer);
    if (rootDiv) {
      if (evalConsumer) {
        setTimeout(() => {
          rootDiv.classList.add('root');
        }, timeOutAnimation);
      } else {
        setTimeout(() => {
          rootDiv.classList.remove('root');
        }, timeOutAnimation);
      }
    }
  }, [location]);

  return (
    <TransitionContainer back={returning || history.action === 'POP'} isConsumer={isConsumer}>
      <RouterTransition count={location.key} timeout={timeOutAnimation}>
        <Switch location={location}>{children}</Switch>
      </RouterTransition>
    </TransitionContainer>
  );
}

export default withRouter(AppTransition);
