import { AnyEventObject, assign, Machine } from 'xstate';

export interface SearchResult {
  id: string;
  title: string;
}

interface SearchBarContext {
  queryLength: number;
  results: SearchResult[] | undefined;
}

const searchBarMachine = Machine<SearchBarContext>(
  {
    id: 'searchBar',
    initial: 'idle',
    context: {
      queryLength: 0,
      results: undefined,
    },
    states: {
      idle: { on: { TYPING: 'loading' } },
      loading: {
        entry: 'assignQueryLength',
        on: {
          RESOLVE: [
            {
              target: 'showingResults',
              actions: assign({ results: (context, event) => event.results }),
              cond: 'noResultsAndLongQuery',
            },
            { target: 'empty' },
          ],
          REJECT: {
            target: 'failure',
            actions: assign({ results: (context, event) => undefined }),
          },
          TYPING: 'loading',
          CLEAR: 'idle',
        },
      },
      showingResults: { on: { TYPING: 'loading', CLEAR: 'idle' } },
      failure: { on: { TYPING: 'loading', CLEAR: 'idle' } },
      empty: { on: { TYPING: 'loading', CLEAR: 'idle' } },
    },
  },
  {
    actions: {
      assignQueryLength: assign({ queryLength: (context, event) => event.query.length }),
    },
    guards: {
      noResultsAndLongQuery: (context: SearchBarContext, event: AnyEventObject) => {
        return context.queryLength <= 8 || event.results.length > 0;
      },
    },
  },
);

export default searchBarMachine;
