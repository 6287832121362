import React from 'react';
import { withRouter, Switch, useHistory } from 'react-router';
import { TransitionContainer } from 'hoc/RouterTransition/styled';
import RouterTransition from 'hoc/RouterTransition';

function MenuTransition({ location, children }: any) {
  const history = useHistory();
  const { return: returning } = location.state || {};
  const timeOutAnimation = 400;

  return (
    <TransitionContainer back={returning || history.action === 'POP'} isConsumer={true}>
      <RouterTransition count={location.key} timeout={timeOutAnimation}>
        <Switch location={location}>{children}</Switch>
      </RouterTransition>
    </TransitionContainer>
  );
}

export default withRouter(MenuTransition);
