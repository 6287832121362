import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const OrderCardContainer = styled(Segment)`
  .ui.card {
    width: 100%;
    box-shadow: none;
    border-radius: 10px;

    .header {
      text-align: right;
      border-bottom: none;

      .image {
        height: 12px;
        width: auto;
        position: absolute;
        right: 13px;
        top: 13px;
        cursor: pointer;
      }
    }
    .content.main {
      border-top: none;

      .stepper-container {
        .stepper-step {
          &.active {
            color: ${colors.seaFoam};
            font-family: ${fonts.MEDIUM};
          }

          .step-dot {
            background-color: ${colors.charcoalGrey};

            &.done {
              background-color: ${colors.seaFoam};
            }
          }
        }
      }
    }

    .content {
      &:not(.main) {
        background: ${colors.lightGrey};
        border-radius: 0px 0px 10px 10px !important;
        border-top: none;
        color: ${colors.charcoalGrey};

        .column {
          text-align: center;
          font-family: ${fonts.MEDIUM};
          font-size: 16px;
          color: ${colors.charcoalGrey};
          font-weight: lighter;
          padding: 0px;
        }
      }
    }
  }
  .location {
    margin: auto;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: ${fonts.LIGHT};
    color: ${colors.charcoalGrey};
    img {
      height: 20px;
      width: 15px;
    }
  }

  .est-label {
    text-align: center;
    font-size: 18px;
    font-family: ${fonts.REGULAR};
    color: ${colors.charcoalGrey};
  }

  .link-icon {
    font-size: 12px;
    font-family: ${fonts.REGULAR};
    font-weight: 500;
    color: ${colors.niceBlue};
  }

  .reduce {
    font-size: 15px !important;
  }
`;

export const TitleContainer = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  text-align: center;
  font-family: ${fonts.SUPER};
  font-size: 18px;
  font-weight: lighter;
  text-transform: uppercase;
`;

export const EstimateContainer = styled.div`
  color: ${colors.charcoalGrey};
  font-family: ${fonts.REGULAR};
  font-size: 18px;
  font-weight: lighter;
  text-align: center;
`;

export const EstimatedTime = styled.div`
  text-align: center;
  font-family: ${fonts.REGULAR};
  color: ${colors.charcoalGrey};
  font-size: 18px;
`;

// for horizontal steppers
export const StepperTextContainer = styled.div`
  width: 75px;
  text-align: center;
`;
