import AnimatedModal from 'components/Molecules/AnimatedModal/AnimatedModal';
import ProductHeader from 'components/Organisms/PWA/Headers/ProductHeader/ProductHeader';
import ProductConfigurator, { SelectedComboItem } from 'containers/ProductConfigurator';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeliveryLocation } from '../../hooks/Location';
import { useStoreContext } from '../../hooks/useStoreContext';
import { useVenueContext } from '../../hooks/useVenueContext';
import useWindowHeight from '../../hooks/UseWindowHeight';
import { ProductService } from '../../services';
import { CreateCartDto, ISubCategorySelectionDto, LineItemDto } from '../../services/CartService';
import { BasePath } from '../../shared/constants';
import { OrderType } from '../../shared/models/Cart';
import IProduct, { ISubCategory } from '../../shared/models/Product';
import { getCurrencyPrefix, getProductImage } from '../../shared/utils';
import { useAppContext, useEventContext } from '../App/AppContext';
import useCartContext from '../Cart/context';
import { ProductListingRouteProps } from '../ProductListing';
import { ProductDetailsContainer, ProductImageContainer } from './styled';

interface ProductDetailsRouteProps extends ProductListingRouteProps {
  productId: string;
}

export default function ProductDetails() {
  const { addItemToCart, cart, addLineItem } = useCartContext();
  const { productId, venueId, storeId } = useParams<ProductDetailsRouteProps>();
  const { user, setShowLoader, orderType } = useAppContext();
  const { venue } = useVenueContext({
    venueId,
  });
  const { store } = useStoreContext({
    storeId,
  });
  const { deliveryLocation, deliveryLocationId, deliveryLocationInput } = useDeliveryLocation();

  const { event } = useEventContext();
  const history = useHistory();

  const [product, setProduct] = useState<IProduct | undefined>(undefined);

  const [showEmptyCartModal, setShowEmptyCartModal] = useState<boolean>(false);
  const currencyPrefix = getCurrencyPrefix(venue?.currency);
  const clientHeight = useWindowHeight();
  const [selectedComboItems, setSelectedComboItems] = useState<SelectedComboItem[]>([]);

  useEffect(() => {
    const getProductOnPageRefresh = async () => {
      try {
        setShowLoader(true);
        const { data } = await ProductService.GetOneProduct(productId);

        setProduct({ ...data, imageUrl: getProductImage(data) });
        const defaultSelection: Array<SelectedComboItem> = [];
        if (data.subCategories && data.subCategories.length) {
          data.subCategories.forEach((category: any) => {
            const categoryRequiresSelection = get(category, ['required'], false) === true;
            const categoryHasOnlyOneProduct = category.subProducts.length === 1;

            for (const product of category.subProducts) {
              if ((categoryRequiresSelection && categoryHasOnlyOneProduct) || product.autoSelect) {
                defaultSelection.push({
                  id: product.id,
                  parentId: product.parentId,
                  productId: product.productId,
                  name: product.name,
                  price: product.price,
                  required: categoryRequiresSelection,
                  type: 'PRODUCT',
                  quantity: 1,
                });
              }
            }
          });
        }
        setSelectedComboItems(prev => [...prev, ...defaultSelection]);
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        if (error?.response.data.message === 'Product not found') {
          history.push('/404');
        } else {
          toast(error.response.data.message);
        }
      }
    };
    getProductOnPageRefresh();
  }, [history, productId]);

  const storeIsClosedWarning = () => toast('Store is currently closed');

  const buildSubSelections = (): ISubCategorySelectionDto[] => {
    if (!product || isEmpty(product?.subCategories)) {
      return [];
    }

    return product.subCategories.map((c: ISubCategory) => ({
      id: c.id,
      subSelections: selectedComboItems
        .filter(selectedComboItem => selectedComboItem.parentId === c.id)
        .map(({ id, quantity }) => ({
          id,
          quantity,
        })),
    }));
  };

  const onAddItemToCartClick = async (productNotes: string) => {
    if (!product) {
      return;
    }

    if (!store?.active) {
      storeIsClosedWarning();
      return;
    }

    const subSelections = buildSubSelections();

    const lineItem: LineItemDto = {
      productId: product.id,
      quantity: 1,
      ...(!isEmpty(subSelections) && { subSelections }),
      ...(productNotes && { instructions: productNotes }),
    };
    const location = orderType === OrderType.DELIVERY && deliveryLocation;

    const payload: CreateCartDto = {
      lineItem,
      orderType: orderType,
      storeId,
      ...(user?.id && { userId: user.id }),
      ...(event?.id && { eventId: event.id }),
      ...(event?.scheduledTime && { scheduledTime: event.scheduledTime }),
      ...(location && { location }),
      ...(deliveryLocationId && { deliveryLocationId }),
      ...(deliveryLocationInput && { deliveryLocationInput }),
    };

    let cartItem = cart?.lineItems.find(item => productId === item.productId);

    let cartId = cart?.id;

    if (cartId && cartItem && cartItem.id) {
      const { id, quantity, alcohol } = cartItem;
      await addLineItem(lineItem, quantity, id, alcohol);
    } else {
      cartId = await addItemToCart(payload);
    }

    if (cartId) {
      history.replace(`/${BasePath.CONSUMER}/stores/${venueId}/products/${storeId}`, {
        return: true,
      });
    } else {
      history.goBack();
    }
    setShowLoader(false);
  };

  const showProductImage = !!(store?.showProductImages && product?.imageUrl);

  return (
    <ProductDetailsContainer bg={''} clientHeight={clientHeight}>
      <ProductHeader shaded={showProductImage} />
      <AnimatedModal
        showModal={showEmptyCartModal}
        onCloseModal={() => setShowEmptyCartModal(false)}
      >
        <AnimatedModal.Title copy="YOUR BAG IS EMPTY" />
        <AnimatedModal.Content>{`Check out your options to fix that!`}</AnimatedModal.Content>
      </AnimatedModal>

      <ProductImageContainer bg={(showProductImage && product?.imageUrl) || ''} />
      <div
        style={{
          position: 'relative',
          top: showProductImage ? 150 : 5,
        }}
      >
        {store && product && (
          <ProductConfigurator
            alcoholMessage={venue?.alcoholMessage ?? ''}
            alcoholAgeRestriction={venue?.alcoholAgeRestriction ?? 21}
            currencyPrefix={currencyPrefix}
            isStoreActive={store.active}
            onAddItemToCartClick={onAddItemToCartClick}
            orderInstructionsAvailable={store.orderInstructionsAvailable}
            product={product}
            selectedComboItems={selectedComboItems}
            setSelectedComboItems={setSelectedComboItems}
          />
        )}
      </div>
    </ProductDetailsContainer>
  );
}
