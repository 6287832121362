import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';
import useWindowHeight from 'hooks/UseWindowHeight';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Grid, Image, Input, Segment } from 'semantic-ui-react';
import plusIcon from '../../assets/icons/plus.png';
import sendIcon from '../../assets/icons/send.png';
import ChatBubble from '../../components/ChatBubble';
import { useAppContext } from '../App/AppContext';
import { ChatContainer } from './styled';

export default function Chat(props: any) {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [channel, setChannel] = useState();
  const { orderId, venueId, storeId } = props.match.params;
  const { chatClient, user } = useAppContext();
  const clientHeight = useWindowHeight();

  useEffect(() => {
    const setupChannel = async () => {
      if (chatClient && Object.keys(chatClient).length > 0) {
        let newChannel;
        const chatUniqueName = `Order #${orderId}`;
        try {
          newChannel = await chatClient.getChannelByUniqueName(chatUniqueName);
        } catch (error) {
          newChannel = await chatClient.createChannel({
            uniqueName: chatUniqueName,
            friendlyName: storeId,
          });
          await newChannel.invite(venueId);
        }

        if (newChannel) {
          if (newChannel.state.status !== 'joined') {
            newChannel = await newChannel.join();
          }
          newChannel.on('messageAdded', (obj: any) => {
            setMessages(msgs => [...msgs, obj]);
            scrollToBottom();
          });
          const oldMessages = await newChannel.getMessages();
          setMessages(oldMessages.items);
          scrollToBottom();
          setChannel(newChannel);
        }
      }
    };

    async function getChannel() {
      try {
        setIsLoading(true);
        if (!channel) {
          await setupChannel();
        }
        setIsLoading(false);
      } catch (error) {
        toast(error);
        setIsLoading(false);
      }
    }

    getChannel();
    return function cleanup() {
      if (channel) {
        channel._unsubscribe();
      }
    };
  }, [orderId, venueId, storeId, chatClient, channel]);

  const sendMessageToChannel = async () => {
    if (message !== '') {
      setIsLoading(true);
      await channel.sendMessage(message);
      setMessage('');
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    const scrollable = document.getElementById('scrollable');
    if (scrollable) {
      scrollable.scrollTop = scrollable.scrollHeight;
    }
  };

  const handleMessageChange = (temporalMessage: string) => {
    setMessage(temporalMessage);
  };

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      sendMessageToChannel();
    }
  };

  return (
    <ChatContainer clientHeight={clientHeight}>
      <ProfileHeader title={`Order Chat #${orderId}`} />
      <main>
        <Segment id="scrollable" loading={isLoading}>
          {messages.map((item, index) => (
            <ChatBubble
              key={index}
              message={item.state.body}
              isUser={item.state.author === user?.phoneNumber}
            />
          ))}
        </Segment>
      </main>
      <footer>
        <Grid columns={1} className="input-container">
          <Input
            onChange={e => handleMessageChange(e.target.value)}
            onKeyDown={(e: any) => handleKeyDown(e.key)}
            disabled={!channel}
            value={message}
            icon={<Image className="absolute-icon" src={plusIcon} />}
            iconPosition="left"
            className="invisible-input"
            placeholder="Type message"
            action={<Image className="chat-icon" onClick={sendMessageToChannel} src={sendIcon} />}
            transparent
          />
        </Grid>
      </footer>
    </ChatContainer>
  );
}
