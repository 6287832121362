import { colors, fonts } from '../../../shared/theme';
import chicagoRiverBlur from '../assets/chicagoRiverBlur.jpg';
import styled from 'styled-components';

export const HomePageContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const HomeBackground = styled.div`
  background: url(${chicagoRiverBlur}) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: clamp(280px, 50%, 410px);
`;

export const SubHeader = styled.div`
  color: ${colors.white};
  font-family: ${fonts.REGULAR};
  font-size: 18px;
  text-align: center;
  line-height: 1.3;
  margin-top: 15px;
  margin-bottom: 23px;
`;

export const HomeHeaderContainer = styled.div`
  display: grid;
  place-items: center center;
`;

export const HomeHeader = styled.div`
  color: ${colors.white};
  font-family: ${fonts.REGULAR};
  font-size: clamp(16px, 1rem + 2vw, 26px);
  text-align: center;
  line-height: 1.3;
  max-width: 750px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin-top: 30px;

  @media (min-width: 600px) and (max-width: 899px) {
    max-width: 540px;
  }
`;

export const NavContainer = styled.div`
  font-family: ${fonts.MEDIUM};
  font-size: 16.5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
`;

export const HomeHeaderContent = styled.div`
  padding: 0 10px;
`;

export const NavLink = styled.a`
  color: ${colors.white};
  cursor: pointer;
`;

export const BuildingCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  @media (min-width: 375px) {
    margin: 0 40px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-decoration: none;
`;

export const BuildingCardTitle = styled.h2`
  margin-top: 77px !important;
  font-family: ${fonts.SUPER};
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};

  @media (max-width: 375px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const BuildingCardSubtitle = styled.div`
  font-family: ${fonts.LIGHT};
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.charcoalGrey};
  margin-bottom: 37px;
`;

export const RestaurantTitle = styled.h2`
  margin-top: 77px;
  margin-bottom: 44px;
  font-family: ${fonts.SUPER};
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
`;

export const AboutSectionContainer = styled.div`
  background-color: ${colors.lightGrey};
  padding-bottom: 40px;
`;

export const AboutTitle = styled.h2`
  padding-top: 60px;
  margin-bottom: 38px;
  font-family: ${fonts.SUPER};
  font-size: 30px;
  letter-spacing: -0.45px;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
`;

export const AboutRightText = styled.div`
  color: ${colors.charcoalGrey};
  line-height: 1.33;

  @media (min-width: 800px) {
    margin-left: 24px;
  }
`;

export const AboutTextWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(1em, 1fr) 6fr 4fr minmax(1em, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 20px;
  }
`;

export const AboutLeftText = styled.div`
  color: ${colors.charcoalGrey};
  line-height: 1.33;
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor};
  padding-bottom: 30px;
  padding-right: clamp(5px, 5vw, 60px);

  @media (min-width: 800px) {
    grid-column: 2;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.primaryColor};
  }
`;

export const AboutSubTitle = styled.div`
  font-family: ${fonts.REGULAR};
  font-size: clamp(21px, (1rem + 1vw), 25px);
`;

export const AboutLeftTextBody = styled.div`
  font-family: ${fonts.LIGHT};
  font-size: 18px;
  margin-top: 10px;
`;

export const AboutBoldSubTitle = styled.div`
  font-family: ${fonts.BOLD};
  font-size: 17px;
  margin-top: 20px;
`;

export const AboutLeftRightBody = styled.div`
  font-family: ${fonts.LIGHT};
  font-size: 17px;
  margin-top: 10px;
  line-height: 1.33;
  margin-bottom: 20px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.primaryColor};
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
`;

export const ContactTitle = styled.div`
  font-family: ${fonts.SUPER};
  font-size: clamp(22px, (1rem + 1vw), 30px);
  letter-spacing: -0.45px;
  text-align: center;
  color: ${colors.white};
`;

export const ContactSubtitle = styled.div`
  font-family: ${fonts.REGULAR};
  font-size: clamp(18px, (1rem + 1vw), 22px);
  font-weight: 300;
  line-height: 1.33;
  text-align: center;
  color: ${colors.white};
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const ShowMore = styled.div`
  color: #147c9e;
  font-family: ${fonts.REGULAR};
  font-size: 15px;
  text-align: center;
  margin: 15px 0 0 0;
  cursor: pointer;
`;

export const BuildingCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
