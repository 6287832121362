import React from 'react';
import { useHistory } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { BasePath } from 'shared/constants';
import { fonts } from 'shared/theme';
import styled from 'styled-components';

export const OutlineButton = styled.button`
  margin: 0;
  background: transparent;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding: 0;
  height: 65px;
  width: 220px;
  cursor: pointer;
  display: grid;
  grid-auto-rows: 20px;
  align-content: center;
  align-items: center;
  transition: 0.2s;

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor};
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
  }
`;

export const OutlineButtonLink = styled.a`
  margin: 0;
  background: transparent;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding: 0;
  height: 65px;
  width: 230px;
  cursor: pointer;
  display: grid;
  grid-auto-rows: 20px;
  align-content: center;
  align-items: center;
  transition: 0.2s;

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor};
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
  }
`;

export const TopText = styled.span`
  font-family: ${fonts.MEDIUM};
  letter-spacing: 1px;
  font-size: 16.5px;
`;

export const BottomText = styled.span`
  font-size: 13.5px;
  letter-spacing: 0.81px;
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 480px) {
    grid-template-columns: none;
  }
`;

const SingleTile = () => {
  const history = useHistory();

  return (
    <Grid centered padded stackable>
      <Grid.Row columns={1}>
        <ButtonContainer>
          <OutlineButton onClick={() => history.push(`/${BasePath.BUILDING_EATS}`)}>
            <TopText>BuildingEats</TopText>
          </OutlineButton>
          <OutlineButton onClick={() => history.push(`/${BasePath.SUITE}`)}>
            <TopText>Suite Pre-Orders</TopText>
          </OutlineButton>
        </ButtonContainer>
      </Grid.Row>
    </Grid>
  );
};

export default SingleTile;
