import { colors } from 'shared/theme';
import { APIService } from './APIService';

declare const ENVARS: any;
const basePath = '/settings';

export interface IWhiteLabelContext {
  isWhiteLabeled: boolean;
  venueId?: string;
}

export class SettingsService {
  static async GetSettingsFromUrl(): Promise<IWhiteLabelContext> {
    const url = window.location.origin;

    const defaultContext: IWhiteLabelContext = {
      isWhiteLabeled: false,
    };

    if (ENVARS.KNOWN_DOMAINS.includes(url)) {
      return defaultContext;
    }

    try {
      const { data: context } = await APIService.getData(basePath, { url });

      // override default context where applicable
      Object.assign(defaultContext, context);
    } catch { } // ignore errors

    return defaultContext;
  }
}
