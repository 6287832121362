import { Grid } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { colors, fonts } from '../../shared/theme';

const buttonStyles = css`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
`;

export const OpenNoteIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 7px;
`;

export const CloseNoteIcon = styled.img`
  width: 10px;
  margin-right: 7px;
`;

export const AddNoteBtn = styled.button`
  ${buttonStyles};
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -6px;
    width: 186px;
    height: 30px;
  }
`;

export const NoteLabel = styled.div`
  font-family: ${fonts.REGULAR};
  color: #6e6e6e;
  font-size: 0.86em;
`;

export const SavedNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const SavedNoteEditContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NoteText = styled.div`
  font-family: ${fonts.LIGHT};
  font-size: 1.14em;
  color: #474350;
  padding-top: 3px;
  word-break: break-word;
`;

export const NoteSaveButton = styled.button<{ noteLength: number }>`
  ${buttonStyles};
  align-items: center;
  position: absolute;
  right: 0;
  transition: transform 0.3s;
  transform: ${({ noteLength }) =>
    noteLength > 33 ? 'translate(-4%, -20px)' : 'translate(-4%, 190%)'};
  font-family: ${fonts.REGULAR};
  color: ${colors.niceBlue};
  font-size: 0.86em;

  &:after {
    content: '';
    position: absolute;
    right: -8px;
    top: -17px;
    width: 45px;
    height: 45px;
  }
`;

export const NoteSaveContainer = styled.div`
  display: flex;
`;

export const AddNoteText = styled.span.attrs(({ className }) => ({
  className,
}))`
  color: ${colors.niceBlue};
  font-family: ${fonts.REGULAR};
  letter-spacing: -0.21px;
  padding-top: 3px;
`;

export const EditNoteButton = styled.button<{ noteLength: number }>`
  ${buttonStyles};
  position: relative;
  color: ${colors.niceBlue};
  font-size: 0.86em;
  transform: ${({ noteLength }) => noteLength > 33 && 'translate(-1em, -40px)'};

  &:after {
    content: '';
    position: absolute;
    right: -8px;
    top: -12px;
    width: 45px;
    height: 45px;
  }
`;

export const NotePlaceholder = styled.span`
  color: #9a9999 !important;
  font-family: ${fonts.REGULAR}!important;
`;

export const AddNoteTextExample = styled.span`
  font-family: ${fonts.REGULAR};
  color: #9a9999;
  letter-spacing: -0.21px;
  margin-left: 3px;
  padding-top: 3px;
`;

export const TotalContainer = styled.div`
  .ui.card.summary {
    line-height: 26px;
    margin: 1em auto;
    border-radius: 10px;
    width: 100%;
    padding: 10px 10px 5px;
    .ui.grid {
      .row {
        padding: 0px;
        font-family: ${fonts.REGULAR};
        font-weight: lighter;
        font-size: 14px;
        color: ${colors.charcoalGrey};
        .right {
          text-align: right;
        }

        &.total {
          font-family: ${fonts.MEDIUM};
        }
      }
    }
    .content {
      padding: 10px;

      .ui.list {
        margin: 10px auto;
        .content {
          padding: 0px;
          > .ui.grid {
            padding: 0px;
            background: transparent;
            .row {
              font-size: 14px;
              font-family: ${fonts.REGULAR};
              font-weight: lighter;
              line-height: 18px;
            }
          }
        }
      }

      > .ui.grid {
        margin: -1px -20px;

        padding-bottom: 10px;
        padding-top: 10px;
        > .row {
          padding: 0px 7px;
          line-height: 20px;
        }
      }

      .header {
        font-family: ${fonts.LIGHT};
        font-weight: lighter;

        > .ui.container {
          margin: 0px !important;
          justify-content: space-between;
          .title {
            padding: 0px;
            font-size: 18px;
            color: ${colors.charcoalGrey};
            font-family: ${fonts.REGULAR}!important;
            .ui.image {
              height: 7px;
              margin-right: 10px;
            }
          }
          .total {
            padding: 0px;
            font-size: 16px;
            color: ${colors.charcoalGrey};
            font-family: ${fonts.MEDIUM};
          }
        }
      }

      .promo-code {
        margin: 1px 1px;

        > .promo-code-input {
          margin-bottom: -16px;
        }
      }
    }
  }
`;

export const MyBagTitle = styled(Grid.Column)`
  font-size: 18px;
  font-family: ${fonts.REGULAR};
  color: ${colors.charcoalGrey};
  font-weight: normal;
  min-width: 50%;
`;

export const LineItem = styled.div`
  .line-item-title {
    color: ${colors.charcoalGrey};
    font-family: ${fonts.MEDIUM};
    font-size: 16px;
  }
  .line-item-description {
    color: #6a6a6a;
    font-family: ${fonts.LIGHT};
    font-size: 12px;
  }
  .line-item-price {
    text-align: right;
  }
  .ui.grid > .row {
    > .line-item-subproduct {
      color: ${colors.charcoalGrey};
      font-family: ${fonts.LIGHT};
      font-size: 16px;
      margin-left: 10px;
    }
    &.no-padding-bottom {
      padding-bottom: 0;
    }
    &.no-padding-top {
      padding-top: 0;
    }
  }
`;

export const ChangeComboContent = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  top: 0px;
  max-width: 400px;
  margin: auto;

  .ui.fluid.card {
    border-radius: 10px;
  }

  .ui.card > .content {
    border-top: none;
    padding: 0;
    margin-bottom: 40px;

    .ui.basic.button {
      display: block;
      margin: auto;
      box-shadow: none;
      text-transform: uppercase;
      font-size: 12px;
      font-family: ${fonts.REGULAR};
      color: ${colors.niceBlue} !important;

      &:focus,
      &:active,
      &:hover {
        box-shadow: none;
        color: ${colors.niceBlue};
      }
    }
  }
`;

export const CloseButton = styled.img`
  width: 12px;
  height: 12px;
  float: right;
  cursor: pointer;
  z-index: 1;
  position: relative;
  top: 25px;
  right: 25px;
`;

export const TitleContainer = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  font-family: ${fonts.SUPER};
  color: ${({ theme }) => theme.primaryColor};
  font-weight: normal;
  margin: 40px auto 20px;

  span {
    display: block;
    color: ${colors.charcoalGrey};
    font-family: ${fonts.REGULAR};
    font-size: 18px;
    text-transform: initial;
    max-width: 205px;
    margin: 10px auto;
    line-height: 20px;
  }
`;

export const ComboItem = styled.div`
  font-size: 16px;
  font-family: ${fonts.MEDIUM};
  color: ${colors.charcoalGrey};
  line-height: 19px;
`;

export const ItemNotes = styled.div`
  font-family: ${fonts.LIGHT};
  line-height: 15px;
  font-size: 12px;
  color: ${({ theme }) => theme.primaryColor};
`;
