import closeSearchBtn from 'assets/icons/closeSearchBtn.png';
import React from 'react';
import { Image, Modal, Segment, TransitionablePortal } from 'semantic-ui-react';
import { Event } from '../../../services/EventsService';
import Venue from '../../../shared/models/Venue';
import { EventWeekCard } from '../../Atoms/DateTimeCard';
import NoEventsMessage from '../../Molecules/NoEventsMessage';
import { ScrollContainer } from '../SuiteHeader/styled';

interface IEventsModal {
  openEventsModal: boolean;
  events: Event[] | undefined;
  venue: Venue | undefined;
  onClose: () => void;
  selectedEvent: Event | undefined;
  handleEventSelect: (event: Event) => void;
}

const EventsModal = ({
  openEventsModal,
  events,
  venue,
  onClose,
  selectedEvent,
  handleEventSelect,
}: IEventsModal) => {
  return (
    <TransitionablePortal
      open={openEventsModal}
      transition={{ animation: 'fade up', duration: 500 }}
    >
      <Modal
        centered={true}
        open={openEventsModal}
        onClose={onClose}
        style={{ borderRadius: '10px' }}
        closeIcon={
          <Image
            style={{
              width: '15px',
              height: '15px',
              float: 'right',
              marginTop: '15px',
              marginRight: '15px',
              cursor: 'pointer',
            }}
            alt="Close Button"
            src={closeSearchBtn}
          />
        }
        size="small"
      >
        <Modal.Header>Events</Modal.Header>
        <Modal.Content>
          <Segment raised>
            <ScrollContainer>
              {venue && events?.length
                ? events.map((event: Event, index: number) => {
                    const { id, name, startDate } = event;
                    return (
                      <EventWeekCard
                        selected={selectedEvent && selectedEvent.id === id}
                        key={id}
                        index={index + 1}
                        arrayLength={events.length}
                        value={id}
                        handleOnClick={value => handleEventSelect(event)}
                        eventDate={startDate}
                        name={name}
                        timeFormat={'LLL d - hh:mm a'}
                      />
                    );
                  })
                : events && <NoEventsMessage handleEventSelect={handleEventSelect} />}
            </ScrollContainer>
          </Segment>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

export default EventsModal;
