import OpenTab from 'components/OpenTab';
import { useAppContext } from 'containers/App/AppContext';
import { useSuiteContext } from 'containers/Suite/Steps/SuiteContext';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react';
import { OpenTabService } from 'services/OpenTabService';
import { OrderService } from 'services/OrderService';
import OpenTabModel from 'shared/models/OpenTab';
import OrderGroup from 'shared/models/OrderGroup';
import Store from 'shared/models/Store';
import styled from 'styled-components';
import { PaymentProvider, usePaymentContext } from '../../../containers/PaymentDelivery/context';
import {
  usePaymentHandlers,
  usePaymentMethod,
  useTipHook,
} from '../../../hooks/Payment/payment.hooks';
import { useVenueContext } from '../../../hooks/useVenueContext';
import { colors, fonts } from '../../../shared/theme';
import { Button } from '../../Atoms/Button';
import Header from '../../Atoms/Header';
import NoPaddingColumn from '../../Atoms/NoPaddingColumn';
import OrderSummary from '../../Molecules/OrderSummary';
import OrderRecipient from '../../OrderRecipient';
import { PaymentCard, PaymentMethodType } from '../../PaymentMethods';
import { TipCard } from '../../TipCard';
import { PaymentContainer } from './styled';

const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.primaryColor};
  padding-bottom: 10px;
`;

const PaymentCardTab = styled.div`
  background: transparent;
  color: ${colors.niceBlue};
  padding: 22px;
  padding-left: 0;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  cursor: pointer;
  font-family: ${fonts.REGULAR};
`;

interface ISecureCheckoutProps {
  store: Store;
}

const SecureCheckout = ({ store }: ISecureCheckoutProps) => {
  const { cart, selectedEvent, suite } = useSuiteContext();
  const { user } = useAppContext();
  const { venue } = useVenueContext();
  const [openTabValues, setOpenTabValues] = useState<OpenTabModel>();
  const [orderGroups, setOrderGroups] = useState<OrderGroup[]>([]);
  const { openTab, setOpenTab } = useAppContext();
  const {
    useNewPayment,
    setUseNewPayment,
    useDeferPayment,
    setUseDeferPayment,
  } = usePaymentContext();
  const [isCardLength, setIsCardLength] = useState<boolean>(false);
  const [userCard, setUserCard] = useState<any>([]);
  const { handleChangeTip, tip } = useTipHook();
  const {
    payButtonDisabled,
    isSubmitting,
    setIsSubmitting,
    handlePlaceOrder,
  } = usePaymentHandlers();
  const {
    stripe,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    currency,
    isOrderFree,
    showOpenTabOption,
  } = usePaymentMethod();

  const isDeliveryTippingEnabled: boolean =
    store?.enableDeliveryTips !== null ? !!store?.enableDeliveryTips : !!venue?.enableDeliveryTips;

  useEffect(() => {
    (async () => {
      if (venue && suite && selectedEvent) {
        try {
          const { data } = await OrderService.GetOrderGroups(venue.id, suite.id, selectedEvent.id);
          if (data.length > 0) {
            setOrderGroups(data);
          }
        } catch (error) {
          toast('No order groups found');
        }
      }
      if (venue && suite && user && selectedEvent) {
        const { data } = await OpenTabService.GetTab(suite.id, selectedEvent.id);
        if (data?.id) {
          setOpenTabValues(data);
          setOpenTab(data);
        } else {
          setOpenTab(undefined);
        }
      }
    })();
  }, [selectedEvent, suite, venue]);

  useEffect(() => {
    if (!useDeferPayment) {
      setSelectedPaymentMethod({
        paymentMethodType: PaymentMethodType.CreditCard,
        selectedCard: userCard[0],
      });
    }
    if (useDeferPayment) {
      setSelectedPaymentMethod({
        paymentMethodType: PaymentMethodType.Defer,
        selectedCard: undefined,
      });
    }
  }, [useDeferPayment]);

  const placeSuiteOrder = async () => {
    if (!selectedEvent) {
      toast('Please select an event');
      return;
    }

    await handlePlaceOrder();
  };

  const creditCardsCheckFunction = (value: []) => {
    if (value.length > 0) {
      setIsCardLength(true);
      setUserCard(value);
    } else {
      setIsCardLength(false);
    }
  };

  const handleDeferPayment = () => {
    setUseDeferPayment(!useDeferPayment);
  };

  return (
    <PaymentContainer basic loading={isSubmitting}>
      <OrderRecipient />
      <TipCard
        tip={tip}
        onChange={handleChangeTip}
        showTip={!!(isDeliveryTippingEnabled && cart)}
      />
      {venue?.deferPaymentCapture && venue.allowOpenTabs && (
        <PaymentCardTab onClick={handleDeferPayment}>
          {useDeferPayment ? 'Use Tab' : 'Use Defer Payment'}
        </PaymentCardTab>
      )}
      {venue?.allowOpenTabs && store.allowOpenTabs && !useDeferPayment && (
        <OpenTab
          venueDetails={venue}
          openTabValues={openTabValues}
          user={user}
          suiteId={suite?.id}
          eventId={selectedEvent?.id}
        />
      )}
      <PaymentCard
        {...{
          isOrderFree,
          currency,
          orderGroups,
          selectedPaymentMethod,
          stripe,
          setSelectedPaymentMethod,
          showOpenTabOption,
          setIsLoading: setIsSubmitting,
          openTabValues: openTabValues,
          useNewPayment: useNewPayment,
          creditCardsCheck: creditCardsCheckFunction,
        }}
      />

      <OrderSummary showCheckoutButton={false} tip={tip} type="CHECKOUT" />
      {cart && (
        <Grid centered padded>
          <Grid.Row>
            <NoPaddingColumn mobile={16} tablet={16} computer={4} widescreen={4}>
              <Button
                type="solidRed"
                onClick={() => placeSuiteOrder()}
                disabled={payButtonDisabled}
              >
                Place Order
              </Button>
            </NoPaddingColumn>
          </Grid.Row>
        </Grid>
      )}
    </PaymentContainer>
  );
};

const SecureCheckoutWrapper = ({ store }: ISecureCheckoutProps) => {
  return (
    <PaymentProvider isSuite={true}>
      <SecureCheckout store={store} />
    </PaymentProvider>
  );
};

export default SecureCheckoutWrapper;
