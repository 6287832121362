import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BasePath } from 'shared/constants';
import styled from 'styled-components';
import FFlogo from '../../../assets/images/fanFoodLogo.png';
import { VenueService } from '../../../services/VenueService';
import { colors, fonts } from '../../../shared/theme';
import BELogo from '../assets/BE_logo.png';
import chicagoRiverBlur from '../assets/chicagoRiverBlur.jpg';
import BuildingPageRestaurantCard from '../BuildingPageRestaurantCard';
import Footer from '../Footer';
import PagePositionTop from '../PagePositionTop';

const BuildingsBackground = styled.div`
  background: url(${chicagoRiverBlur}) no-repeat;
  background-size: 100vw auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: black;
`;

const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 280px;

  @media (min-width: 600px) and (max-width: 899px) {
    width: 384px;
  }

  @media (min-width: 900px) {
    width: 410px;
  }
`;

const BuildingPageHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0px 30px 0px;
`;

const BuildingPageHeader = styled.div`
  font-family: ${fonts.BOLD};
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.white};
  border: 2px solid rgba(128, 128, 128, 0.5);
  padding: 0px 35px 0px 35px;
  border-radius: 8px;
`;

const RestaurantContainer = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr 10px;
  min-height: 0vw;

  @media (min-width: 768px) {
    grid-template-columns: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 23.8095))), 180px) 1fr min(
        max(20px, calc(1.25rem + ((1vw - 7.68px) * 23.8095))),
        180px
      );
  }
`;

const BuildingName = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${fonts.SUPER};
  font-size: 28px;
  text-align: center;
  margin-top: 45px;
  line-height: 1.2;

  @media (max-width: 375px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

interface BuildingRouteProps {
  buildingId: string;
}

interface BuildingProps extends RouteComponentProps<BuildingRouteProps> {}

const BuildingsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const NoEvents = styled.div`
  font-family: AkzidenzGroteskBESuper;
  font-size: 20px;
  margin: 30px;
  text-align: center;
  width: 100%;
`;

interface BuildingVenue {
  name: string;
  imageUrl: string;
  description: string;
}

interface Event {
  id: string;
  active: boolean;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  eventImage: string;
}

const Buildings = (props: BuildingProps) => {
  const { buildingId } = props.match.params;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [buildingVenue, setBuildingVenue] = useState<BuildingVenue | undefined>(undefined);

  const fetchEvents = async (buildingId: string) => {
    setIsLoading(true);
    try {
      const { data } = await VenueService.GetTenantBuildingAndEvents(buildingId);
      setBuildingVenue(data);
      setEvents(sortBy(data.events, ['startDate']));
      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching events: ', error);
    }
  };

  useEffect(() => {
    if (buildingId) {
      (async () => {
        await fetchEvents(buildingId);
      })();
    }
  }, [buildingId]);

  const renderEvents = (): JSX.Element => {
    if (events.length <= 0) return <NoEvents>No upcoming events found at this location.</NoEvents>;

    return (
      <RestaurantContainer>
        {events.map((event: any, index: number) => (
          <BuildingPageRestaurantCard
            key={index}
            image={event.venue?.imageUrl || FFlogo}
            eventName={event.name}
            description={event.description}
            availability={event.availability || null}
            startDate={event.startDate}
            endDate={event.endDate}
            restaurantOptions={event.restaurantOptions || null}
            venueId={event.venueId}
            eventId={event.id}
            buildingId={buildingId}
          />
        ))}
      </RestaurantContainer>
    );
  };

  return (
    <BuildingsPageContainer>
      <header>
        <BuildingsBackground>
          <PagePositionTop />
          <LogoContainer>
            <Link to={`/${BasePath.BUILDING_EATS}`}>
              <Logo src={BELogo} alt="buildingeats logo" />
            </Link>
          </LogoContainer>
          <BuildingPageHeaderContainer>
            <BuildingPageHeader>{buildingVenue?.name}</BuildingPageHeader>
          </BuildingPageHeaderContainer>
        </BuildingsBackground>
      </header>
      <main>
        <BuildingName>SELECT YOUR RESTAURANT &amp; TIME SLOT</BuildingName>
        {isLoading ? <div>LOADING...</div> : renderEvents()}
      </main>

      <Footer />
    </BuildingsPageContainer>
  );
};

export default Buildings;
