import React from 'react';
import { useEffect } from 'react';

const BookingTool = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className='meetings-iframe-container'
      data-src='https://app.hubspot.com/meetings/jake275/45?embed=true'
    ></div>
  );
};

export default BookingTool;
