import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { Card, Icon, Input, Loader } from 'semantic-ui-react';
import { OpenTabService } from 'services/OpenTabService';
import styled from 'styled-components';
import { CardContainer } from '../../shared/global-styled';
import { colors, fonts } from '../../shared/theme';

declare const ENVARS: any;
const StyledContainer = styled.div`
  width: 80%;
  margin: auto;
  font-family: ${fonts.REGULAR};

  .ui.basic.button {
    margin-top: -4px;
    margin-left: -30px;
    position: relative;
    box-shadow: none;
    font-family: ${fonts.REGULAR};
    font-size: 0.9em;
    font-weight: lighter;
    color: ${colors.niceBlue}!important;
    text-transform: uppercase;
    padding-right: 10px;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      color: ${colors.niceBlue}!important;
    }
  }
  @media only screen and (max-width: 575px) {
    width: 95%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const MainContainer = styled.div`
  padding: 20px;
  @media only screen and (max-width: 575px) {
    padding: 10px;
  }
`;

const ShareTabTitleContainer = styled.div`
  .title {
    margin-bottom: 10px;
    font-size: 35px !important;
    font-weight: 800 !important;
  }
  .title-2 {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 575px) {
    .title {
      font-size: 25px !important;
    }
  }
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  width: 60%;
  .guest-icon {
    margin-top: 7px;
    cursor: pointer;
  }
  .ui.input {
    width: 100%;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
const InputBox = styled.div`
  margin-right: 5px;
  width: 100%;
  height: 35px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 20px;
`;
const GuestButton = styled.button`
  border: 1px solid green;
  color: #000;
  background: #fff;
  padding: 10px 50px;
  cursor: pointer;
`;
const PhoneInputContainer = styled.div`
  width: 100%;
  .PhoneInput {
    margin-right: 5px;
    .PhoneInputCountry {
      display: none;
    }
    input[type='tel'] {
      border-radius: 5px;
      padding: 10px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      background-color: transparent;
    }
  }
`;

type orderDataProps = {
  venueId?: string;
  deliveryLocationId?: string;
  eventId?: string;
  tabId?: string;
  suite?: string;
};

type tabShareProps = {
  tabOwner?: string;
  venueId?: string;
  orderData: orderDataProps | undefined;
};

const TabShare = ({ tabOwner, venueId, orderData }: tabShareProps) => {
  const [guestList, setGuestList] = useState<Array<{ name: string; number: string }>>([]);
  const [guestName, setGuestName] = useState<string>();
  const [guestNumber, setGuestNumber] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const AddList = () => {
    if (guestName && guestNumber && guestName !== '' && guestNumber !== '') {
      const phoneNumber = isValidPhoneNumber(guestNumber.toString());
      if (!phoneNumber) {
        return toast('Invalid Phone Number');
      }
      const getListData = [...guestList];
      getListData.push({
        name: guestName,
        number: guestNumber,
      });
      setGuestList(getListData);
      setGuestName('');
      setGuestNumber('');
    } else {
      toast('All fields are required');
    }
  };

  const RemoveList = (index: number) => {
    const getListData = [...guestList];
    getListData.splice(index, 1);
    setGuestList(getListData);
  };

  const handleInputChange = (phoneNumber: string) => {
    if (!phoneNumber) {
      return;
    }
    setGuestNumber(phoneNumber.toString());
  };

  const SendInvite = async () => {
    setShowLoader(true);
    if (guestList.length > 0 && orderData) {
      for (let i = 0; i < guestList.length; i++) {
        const { nationalNumber, countryCallingCode } = parsePhoneNumber(
          guestList[i].number.toString(),
        );

        const InvitePayload = {
          phoneNumber: nationalNumber.toString(),
          name: guestList[i].name,
          venueId: orderData.venueId,
          suiteId: orderData.deliveryLocationId,
          eventId: orderData.eventId,
          tabId: orderData.tabId,
          suiteName: orderData.suite,
          countryCode: countryCallingCode.toString(),
          tabOwnerName: tabOwner,
          link: `${ENVARS.KNOWN_DOMAINS}/consumer/stores/${orderData.venueId}?dl=${orderData.deliveryLocationId}`,
        };
        try {
          const { data } = await OpenTabService.SaveGuest(InvitePayload);
          if (data.data.success && i + 1 == guestList.length) {
            toast('success, Guests will be invited on game day');
            setShowLoader(false);
          }
        } catch (err) {
          toast('Invite sent to guests, but there is something wrong.');
        }
      }
      setGuestList([]);

      // for (let i = 0; i < guestList.length; i++) {
      //   const { nationalNumber, countryCallingCode } = parsePhoneNumber(guestList[i].number.toString());
      //   const InvitePayload = {
      //     phoneNumber: nationalNumber.toString(),
      //     countryCode: countryCallingCode.toString(),
      //     name: guestList[i].name.toString(),
      //     tabOwner: tabOwner,
      //     message: '',
      //     link: `${ENVARS.KNOWN_DOMAINS}/consumer/stores/${venueId}`
      //   }
      //   try {
      //     const { data } = await OpenTabService.SendInvite(InvitePayload);
      //     if (data.data.success && (i + 1) == guestList.length) {
      //       toast('Invite sent successfully')
      //       setShowLoader(false)
      //     }
      //   }
      //   catch(err) {
      //     toast('Invite sent to guests, but there is something wrong.')
      //   }
      // }
      // setGuestList([])
    } else {
      setShowLoader(false);
      return toast('Add the guest first');
    }
  };
  return (
    <>
      <StyledContainer>
        <CardContainer>
          <Card>
            <MainContainer>
              <ShareTabTitleContainer>
                <div className="title">Share your tab with guests</div>
                <div className="title-2">
                  Guests will receive an SMS text with a link to purchase items on your tab during
                  the event
                </div>
              </ShareTabTitleContainer>
              <InputContainer>
                <FlexContainer>
                  <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setGuestName(e.target.value)
                    }
                    value={guestName}
                    placeholder="Enter name"
                  ></Input>
                  <PhoneInputContainer>
                    <PhoneInput
                      defaultCountry="US"
                      country="US"
                      displayInitialValueAsLocalNumber
                      showCountrySelect={false}
                      placeholder="(312) 924-2857"
                      value={guestNumber.toString()}
                      limitMaxLength
                      onChange={handleInputChange}
                    />
                  </PhoneInputContainer>
                  <Icon className="guest-icon" onClick={AddList} name="plus circle" size="large" />
                </FlexContainer>
                {guestList.map((d, index) => {
                  return (
                    <FlexContainer key={index}>
                      <InputBox placeholder="Enter name">
                        <div>
                          <b>{index + 1}.</b> {d.name}
                        </div>
                        <div>{d.number}</div>
                      </InputBox>
                      <Icon
                        className="guest-icon"
                        onClick={() => RemoveList(index)}
                        name="minus circle"
                        size="large"
                      />
                    </FlexContainer>
                  );
                })}
              </InputContainer>
              <FlexContainer>
                <Loader active={showLoader} size="large" />
                <GuestButton disabled={showLoader} onClick={SendInvite}>
                  Send to Guests
                </GuestButton>
              </FlexContainer>
            </MainContainer>
          </Card>
        </CardContainer>
      </StyledContainer>
    </>
  );
};

export default TabShare;
