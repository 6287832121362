declare const ENVARS: any;
export const STRIPE_API_KEY = ENVARS.STRIPE.API_KEY;
export const SQUARE_APPLICATION_ID = ENVARS.SQUARE?.APPLICATION_ID;
export const SQUARE_LOCATION_ID = ENVARS.SQUARE?.LOCATION_ID;
export const STRIPE_API_URL = 'https://js.stripe.com/v3/';
export const firebaseConfig = {
  apiKey: 'AIzaSyDSmD29nGYR4-FeGlaf85JxT_93zQ4zaD0',
  authDomain: 'fanfoodconsumer.firebaseapp.com',
  databaseURL: 'https://fanfoodconsumer.firebaseio.com',
  projectId: 'fanfoodconsumer',
  storageBucket: 'fanfoodconsumer.appspot.com',
  messagingSenderId: '764850553476',
  appId: '1:764850553476:web:2f125561bac2de1c6578d1',
  measurementId: 'G-TDYJ4YLVR7',
};

export const PlatformPaymentType = {
  android: 'android',
  apple: 'apple',
};

export const OrderStatus = {
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  NEW: 'NEW',
  PRE_ORDER: 'PRE_ORDER',
  IN_PROCESS: 'IN_PROCESS',
  READY_FOR_RUNNER: 'READY_FOR_RUNNER',
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  REFUNDED: 'REFUNDED',
  DONE: 'DONE',
  PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
  CANCELLED: 'CANCELLED',
};

export const RefundStatus = {
  FULL: 'FULL',
};

export const ShareLink = 'http://onelink.to/q9zmky';

export const AppStoreURL = {
  android: 'https://play.google.com/store/apps/details?id=com.fanfoodapp.fanfoodapp&hl=en_US',
  ios: 'https://apps.apple.com/us/app/fanfood-app/id1153019375?l=es&ls=1',
};

export const PolicyUrls = {
  PRIVACY_POLICY_URL: 'https://www.iubenda.com/privacy-policy/66005007',
  TERMS_OF_USE_URL: 'https://www.fanfoodapp.com/legal',
};

export enum BasePath {
  CONSUMER = 'consumer',
  SUITE = 'suite',
  BUILDING_EATS = 'buildingeats',
  INVITES = 'invites',
  PARTNERS = 'partners',
}

export enum VenueType {
  TENANT_BUILDING = 'TENANT_BUILDING',
  STADIUM = 'STADIUM',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  GOLF_COURSE = 'GOLF_COURSE',
  SPORTS_COMPLEX = 'SPORTS_COMPLEX',
  CUSTOM = 'CUSTOM',
}

export enum Partners {
  CUBS = 'cubs',
}
