import BackgroundImage from 'assets/images/footballFansClap.jpg';
import 'react-phone-number-input/style.css';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

const HelpText = styled.h1`
  color: ${colors.white};
  display: flex;
  height: 60px;
  justify-content: center;
  margin: 30px auto 0;
  max-width: 300px;
`;

export const TopHelpText = styled(HelpText as any)`
  align-items: flex-end;
  font-family: ${fonts.MEDIUM};
  font-size: 22px;
  height: 60px;
`;

export const BottomHelpText = styled(HelpText as any)`
  align-items: flex-start;
  font-size: 18px;
  font-family: ${fonts.LIGHT};
  height: 30px;
  justify-content: center;

  > span {
    color: ${colors.cyanBlue};
    margin-right: 4px;
  }

  .ui.image {
    height: 16px;
    width: auto;
    margin-left: 10px;
    display: inline-block;
    cursor: pointer;
  }
`;

export const SubmitButton = styled.div`
  position: fixed;
  bottom: 65px;
  width: calc(100% - 30px);
  left: 15px;

  .ui.button {
    color: white;
    background: ${({ theme }) => theme.primaryColor};
    text-transform: uppercase;
    font-size: 18px;
    width: 100%;
    max-width: 400px;
    border-radius: 10px;

    &:hover,
    &:focus {
      color: white;
      background: ${({ theme }) => theme.primaryColor};
    }

    &:active {
      color: white;
      background: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export const StyledContainer = styled.main`
  text-align: center;
  background: url(${BackgroundImage});
  height: 100%;
  padding: 50px 20px;
  background-size: cover;
  background-position: top;

  .ui.grid > .row {
    padding-bottom: 3px;
  }

  > .ui.grid {
    margin-top: 30px;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 15px;
  .ui.image {
    height: 12px;
    width: auto;
    cursor: pointer;
  }
`;

export const PhoneNumberInputContainer = styled.div`
  margin: auto;
  max-width: 280px;

  .react-tel-input {
    .form-control {
      padding-bottom: 20px;
      padding-top: 20px;
      width: 280px;
      font-size: 22px;
      height: 80px;
    }

    .country-list {
      width: 280px;
    }
  }
`;

export const OtpInput = styled.input`
  background: ${colors.white};
  border: 1px solid #cacaca;
  border-radius: 5px;
  outline: none;
  font-size: 48px;
  text-align: center;
  font-family: ${fonts.LIGHT};
  width: 280px;
  padding: 20px 15px 15px 15px;
  height: 80px;
  vertical-align: middle;
  color: ${({ theme }) => theme.primaryColor};
  &:active,
  &:focus {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const PrivacyTermsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 290px;
  width: 100%;
  color: ${colors.white};
  font-family: ${fonts.LIGHT};
  font-size: 14px;
  font-weight: lighter;
  text-align: center;
  button {
    color: ${colors.cyanBlue};
    cursor: pointer;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
`;
