import { ApiResponse, APIService } from './APIService';

const basePath = '/givex';

export class GiveXService {  
    static async checkBalance(data: any): Promise<ApiResponse<any>> {
      return await APIService.postData(`${basePath}/balance`, data);
    }

    static async applyRedemption(data: any): Promise<ApiResponse<any>> {
      return await APIService.postData(`${basePath}/applyRedemption`, data);
    }
}