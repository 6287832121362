import MenuIconGrey from 'assets/icons/menuIconGrey.png';
import WhiteUserIcon from 'assets/icons/userIcon.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { BasePath } from 'shared/constants';
import { useAppContext, useBuildingEatsContext } from '../../../../../containers/App/AppContext';
import { useVenueContext } from '../../../../../hooks/useVenueContext';
import {
  BackChevron,
  BackChevronButton,
  ButtonWrapper,
  ChildrenWrapper,
  HeaderIcon,
  HeaderWrapper,
  IconWrapper,
} from './styled';

type ProductHeaderProps = {
  children?: React.ReactNode;
  returnToVenue?: boolean;
  shaded?: boolean;
};

const ProductHeader = ({ children, returnToVenue, shaded = false }: ProductHeaderProps) => {
  const history = useHistory();
  const { order } = useAppContext();
  const { venue } = useVenueContext();
  const { buildingEatsVenueId, setBuildingEatsVenueId } = useBuildingEatsContext();

  const returnVenueId = returnToVenue && (order?.venueId ?? venue?.id);

  const handleUserClick = () => {
    history.push(`/${BasePath.CONSUMER}/profile`);
  };

  const handleNavigateBack = () => {
    if (buildingEatsVenueId && order) {
      setBuildingEatsVenueId(undefined);
      history.replace(`/${BasePath.BUILDING_EATS}/${buildingEatsVenueId}`, { return: true });
      return;
    }
    if (returnVenueId) {
      history.replace(`/${BasePath.CONSUMER}/stores/${returnVenueId}`, { return: true });
      return;
    }
    history.goBack();
  };

  return (
    <>
      <HeaderWrapper>
        <BackChevronButton shaded={shaded} aria-label="Go back" onClick={handleNavigateBack}>
          <BackChevron
            className={(shaded && 'order-placed') || ''}
            shaded={shaded}
            title="Go back icon"
          />
        </BackChevronButton>

        <IconWrapper shaded={shaded} columnNumber={0}>
          <ButtonWrapper aria-label="Go to user menu" onClick={handleUserClick}>
            <HeaderIcon src={shaded ? WhiteUserIcon : MenuIconGrey} alt="User Icon" />
          </ButtonWrapper>
        </IconWrapper>
      </HeaderWrapper>

      <ChildrenWrapper>{children}</ChildrenWrapper>
    </>
  );
};

export default ProductHeader;
