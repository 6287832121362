import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import { UserService } from './UserService';
import { firebaseConfig } from '../shared/constants';

export class NotificationService {
  static async startNotificationService(userId: string) {
    if (firebase.messaging.isSupported()) {
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        firebase.messaging().useServiceWorker(registration);
      }

      try {
        const messaging = firebase.messaging();
        await Notification.requestPermission();
        const token = await messaging.getToken();
        if (token) {
          await UserService.RegisterDevice(userId, {
            deviceId: token,
            deviceOS: 'android',
          });
        }
      } catch (e) {
        if (e.code === 'messaging/token-unsubscribe-failed') {
          window.location.reload(false);
        }
      }
    }
  }
}
