import React from 'react';
import { colors } from '../../../shared/theme';
import styled from 'styled-components';
import { EventTimeSlot } from '../../../components/Organisms/Modals/OrderAhead';

const TimeSlot = styled.div`
  height: 31px;
  width: 152px;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 15.5px;
  color: ${({ theme }) => theme.primaryColor};
  margin: 10px;
  padding-top: 2px;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${colors.white};
    cursor: pointer;
  }
`;

const RestaurantAvailabilityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const RestaurantAvailability = ({ startTime, endTime }: EventTimeSlot) => {
  return (
    <RestaurantAvailabilityContainer>
      <TimeSlot>
        {startTime} - {endTime}
      </TimeSlot>
    </RestaurantAvailabilityContainer>
  );
};

export default RestaurantAvailability;
