import DownChevron from 'assets/icons/chevronDownGrey.png';
import SubmitButton from 'components/Atoms/Button/Submit';
import AnimatedModal from 'components/Molecules/AnimatedModal/AnimatedModal';
import { isEmpty, last } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { first } from 'underscore';
import { useAppContext } from '../../../../containers/App/AppContext';
import { useDeliveryLocation } from '../../../../hooks/Location';
import { IVenueLocation } from '../../../../shared/models/Venue';
import {
  FieldLabel,
  ModalSubTitle,
  StyledDownChevron,
  StyledField,
  StyledInput,
  StyledSelect,
  StyledSelectWithoutPlaceholder,
  SubmitButtonWrapper,
} from './styled';

type DeliveryLocationModalProps = {
  showModal: boolean;
  handleOnCloseModal: () => void;
};

export const DeliveryLocationModal = ({
  showModal,
  handleOnCloseModal,
}: DeliveryLocationModalProps): JSX.Element => {
  const { venueLocations } = useAppContext();
  const { selectedDeliveryLocations, updateDeliveryLocationId } = useDeliveryLocation();

  const [location, setLocation] = useState<IVenueLocation[]>(selectedDeliveryLocations ?? []);
  const [customInput, setCustomInput] = useState<string | undefined>();

  const deliveryLocation = last(location);
  const submitEnabled =
    !!deliveryLocation?.isDeliveryLocation && (!deliveryLocation?.isCustomInput || !!customInput);
  const isEditingLocation = selectedDeliveryLocations && selectedDeliveryLocations.length > 0;

  useEffect(() => setLocation(selectedDeliveryLocations), [selectedDeliveryLocations]);

  if (!venueLocations) {
    return <></>;
  }

  const handleSetParentLocation = (event: React.SyntheticEvent, { value }: { value: string }) => {
    const parentLocation = venueLocations.find(vl => vl.id === value);
    if (!parentLocation) {
      return;
    }
    setLocation([parentLocation]);
    setCustomInput(undefined);
  };

  const handleOnChange = (index: number, newLocationId: string) => {
    const remaining = location.splice(0, index + 1);
    const parent = last(remaining);
    console.log({ remaining, newLocationId });

    const newLocation = (parent?.locations ?? []).find(vl => vl.id === newLocationId);
    if (!newLocation) {
      return;
    }
    setLocation([...remaining, newLocation]);
    setCustomInput(undefined);
  };

  const handleSubmit = () => {
    updateDeliveryLocationId(location, customInput);
    handleOnCloseModal();
  };

  const renderOptions = (locations: IVenueLocation[]) => {
    return locations.map(vl => ({
      key: vl.id,
      text: vl.name,
      value: vl.id,
    }));
  };

  return (
    <AnimatedModal showModal={showModal} onCloseModal={() => handleOnCloseModal()}>
      <AnimatedModal.Title copy={isEditingLocation ? 'EDIT YOUR LOCATION' : 'ADD YOUR LOCATION'} />
      <ModalSubTitle>See nearby Pick Up and available Delivery options</ModalSubTitle>
      <AnimatedModal.Content>
        <Form>
          <StyledField>
            <StyledSelect
              icon={false}
              placeholder="Select Your Location"
              options={!isEmpty(venueLocations) ? renderOptions(venueLocations) : []}
              onChange={handleSetParentLocation}
              value={first(location)?.id}
            />
          </StyledField>

          <Form.Group unstackable>
            {location.map((loc: IVenueLocation, index: number) => {
              const title = loc.categoryName || loc.name;

              if (loc.isDeliveryLocation && loc.isCustomInput) {
                return (
                  <StyledField key={loc.id}>
                    <FieldLabel htmlFor={loc.categoryName}>{title}</FieldLabel>
                    <StyledInput
                      fluid
                      onChange={(event: any) => setCustomInput(event.target.value)}
                      id={loc.id}
                      name={loc.categoryName}
                    />
                  </StyledField>
                );
              }

              if (isEmpty(loc.locations)) {
                return null;
              }

              let nextSelectedValue: string | undefined = undefined;
              if (index < location.length - 1) {
                nextSelectedValue = location[index + 1].id;
              }

              return (
                <StyledField key={loc.id}>
                  <FieldLabel htmlFor={loc.categoryName}>{title}</FieldLabel>
                  <StyledSelectWithoutPlaceholder
                    aria-label={loc.categoryName}
                    compact
                    icon={<StyledDownChevron src={DownChevron} alt="down chevron icon" />}
                    options={renderOptions(loc.locations)}
                    onChange={(event: React.SyntheticEvent, { value }: { value: string }) =>
                      handleOnChange(index, value)
                    }
                    name={loc.categoryName}
                    value={nextSelectedValue}
                  />
                </StyledField>
              );
            })}
          </Form.Group>
        </Form>
      </AnimatedModal.Content>
      <SubmitButtonWrapper>
        <SubmitButton disabled={!submitEnabled} onClick={() => handleSubmit()}>
          {isEditingLocation ? 'UPDATE MY LOCATION' : 'ADD MY LOCATION'}
        </SubmitButton>
      </SubmitButtonWrapper>
    </AnimatedModal>
  );
};
