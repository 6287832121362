import React from 'react';
import Spacer, { SpacerSizes } from '../../Atoms/Spacer';
import { Button } from '../../Atoms/Button';
import Header, { Size } from '../../Atoms/Header';
import { Grid, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import magnifyingGlass from 'assets/icons/search.svg';

interface SuiteHeaderProps {
  handleEventSelect: any;
}

const NoEventsMessage = (props: SuiteHeaderProps) => {
  return (
    <Grid centered>
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={12} computer={8} widescreen={6}>
          <Spacer size={SpacerSizes.MEDIUM} />
          <Header as='h1' font='BOLD' textAlign='center' letterSpacing='-0.5px'>
            Whoops. We’re Sorry!
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={12} tablet={10} computer={10} widescreen={8}>
          <Header as='h3' textAlign='center' size={Size.INFIMUM} letterSpacing='0.5px'>
            There are no scheduled events at this venue for the following seven days.
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={14} tablet={10} computer={8} widescreen={6}>
          <Button onClick={() => props.handleEventSelect(null, null, null)} type='solidRed'>
            Explore Packages
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={12} computer={8} widescreen={6}>
          <Spacer size={SpacerSizes.SMALL} />
          <Link to='/' style={{ justifyContent: 'center', display: 'inline-flex', width: '100%' }}>
            <Image src={magnifyingGlass} alt='Return to search' style={{ marginRight: 10 }} />{' '}
            <Header as='h6' size={Size.MINUSCULE} letterSpacing='0.5px' style={{ paddingTop: 5 }}>
              Return to Search
            </Header>
          </Link>
          <Spacer size={SpacerSizes.MEDIUM} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NoEventsMessage;
