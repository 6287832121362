import chatBlueCircle from 'assets/icons/chatBlueCircle.png';
import emailBlueCircle from 'assets/icons/emailBlueCircle.png';
import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Grid, GridRow, Image, Segment } from 'semantic-ui-react';
import { OrderService } from '../../../services/OrderService';
import { BasePath } from '../../../shared/constants';
import { useAppContext } from '../../App/AppContext';
import { AuthenticatedView, HelpContainer, UnauthenticatedView } from './styled';

export default function Help(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [showChatButton, setShowChatButton] = useState(false);
  const { user } = useAppContext();

  useEffect(() => {
    const init = async () => {
      if (user && user.authToken) {
        setIsLoading(true);
        try {
          const orders = await OrderService.GetUserOrders({ userId: user.id });
          setIsLoading(false);
          if (orders.data.itemCount > 0) {
            setShowChatButton(true);
          } else {
            setShowChatButton(false);
          }
        } catch (error) {
          toast(error.response.data.message);
          setShowChatButton(false);
          setIsLoading(false);
        }
      } else {
        setShowChatButton(false);
      }
    };
    init();
  }, [user]);

  return (
    <HelpContainer>
      <ProfileHeader title="Help" />
      <Segment className="help-container" loading={isLoading}>
        <Grid columns={1}>
          {!showChatButton && (
            <UnauthenticatedView>
              <GridRow>
                <h4>How do I contact FanFood?</h4>
                <h5>Please email us with your questions, comments or problems.</h5>
              </GridRow>
              <GridRow>
                <a href="mailto:support@fanfoodapp.com">
                  <Image src={emailBlueCircle} />
                  support@fanfoodapp.com
                </a>
              </GridRow>
            </UnauthenticatedView>
          )}
          {showChatButton && (
            <AuthenticatedView>
              <GridRow>
                <h4>How do I contact FanFood?</h4>
                <h3>CHAT DIRECTLY WITH VENUE</h3>
                <Image
                  onClick={() => props.history.push(`/${BasePath.CONSUMER}/order-history`)}
                  src={chatBlueCircle}
                />
              </GridRow>
              <GridRow>
                <h5>If you still need help, contact us via email.</h5>
                <h3>CONTACT FANFOOD SUPPORT</h3>
                <a href="mailto:support@fanfoodapp.com">
                  <Image src={emailBlueCircle} />
                  support@fanfoodapp.com
                </a>
              </GridRow>
            </AuthenticatedView>
          )}
        </Grid>
      </Segment>
    </HelpContainer>
  );
}
