import CloseWhite from 'assets/icons/closeWhite.png';
import EditWhite from 'assets/icons/EditWhite.png';
import {
  CountryCallingCode,
  format,
  isValidNumber,
  NationalNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Grid, Image } from 'semantic-ui-react';
import { useVenueContext } from '../../hooks/useVenueContext';
import { UserService } from '../../services/UserService';
import { BasePath, PolicyUrls } from '../../shared/constants';
import { saveSessionValues } from '../../shared/SessionAuthPersist';
import { fbAnalytics, useAppContext } from '../App/AppContext';
import useCartContext from '../Cart/context';
import {
  BottomHelpText,
  CloseButtonContainer,
  OtpInput,
  PhoneNumberInputContainer,
  PrivacyTermsContainer,
  StyledContainer,
  SubmitButton,
  TopHelpText,
} from './styled';

const step1TextDefault = 'Enter Your Phone Number to Sign In or Sign Up';
const step1TextFromCheckout = 'Enter your phone number to receive order status updates';
const step2Text = 'Enter Your 6-Digit PIN';
const step1Info = 'We’ll text you a 6-Digit PIN';

interface PhoneNumberState {
  countryCallingCode: CountryCallingCode;
  country: string;
  nationalNumber: NationalNumber;
}

function UserAuth() {
  const location = useLocation();
  const history = useHistory();

  const [step, setStep] = useState(1);
  const [phoneNumberState, setPhoneNumberState] = useState<PhoneNumberState>({
    countryCallingCode: '',
    country: 'us',
    nationalNumber: '',
  });
  const [otpCode, setOtpCode] = useState('');
  const { setUser, setShowLoader } = useAppContext();
  const { cart } = useCartContext();
  const { venue } = useVenueContext();

  const step1Text = location.pathname.startsWith(`/${BasePath.CONSUMER}/payment`)
    ? step1TextFromCheckout
    : step1TextDefault;

  const phoneNumber = `+${phoneNumberState.countryCallingCode}${phoneNumberState.nationalNumber}`;

  useEffect(() => {
    (async () => {
      if (otpCode.length === 6) {
        await verifyOtp();
      }
    })();
  }, [otpCode]);

  const sendOtp = async () => {
    try {
      setShowLoader(true);
      const { countryCallingCode, nationalNumber } = phoneNumberState;
      const {
        data: {
          data: { success },
        },
      } = await UserService.SendOtp({
        countryCode: countryCallingCode.toString(),
        phoneNumber: nationalNumber.toString(),
      });
      if (success) {
        setShowLoader(false);
        setStep(2);
      }
    } catch (error) {
      toast('The phone number is not valid!');
    }
  };

  const onPhoneNumberChange = (value: string, data: any) => {
    const phoneInput = data?.countryCode !== phoneNumberState.country ? '' : value;
    try {
      const { nationalNumber, countryCallingCode } = parsePhoneNumber(
        `+${data?.dialCode}${phoneInput}`,
      );
      setPhoneNumberState({
        country: data?.countryCode,
        countryCallingCode,
        nationalNumber: nationalNumber === countryCallingCode ? '' : nationalNumber,
      });
    } catch (error) {
      setPhoneNumberState({
        countryCallingCode: data?.dialCode,
        country: data?.countryCode,
        nationalNumber: phoneInput,
      });
    }
  };

  const onOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOtpCode(value);
  };

  const verifyOtp = async () => {
    try {
      setShowLoader(true);

      const { countryCallingCode, nationalNumber } = phoneNumberState;

      const {
        data: { data },
      } = await UserService.VerifyOtp({
        countryCode: countryCallingCode.toString(),
        phoneNumber: nationalNumber.toString(),
        otpCode,
        createUser: true,
      });
      saveSessionValues(data);
      setUser(data);
      fbAnalytics.logEvent('session_start');
      if (location.pathname && location.pathname === `/${BasePath.CONSUMER}/auth`) {
        if (cart && cart.id) {
          history.push(`/${BasePath.CONSUMER}/cart/${cart.id}`);
        } else if (venue && venue.id) {
          history.push(`/${BasePath.CONSUMER}/stores/${venue.id}`);
        } else {
          history.push('/');
        }
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      toast('The code is not valid!');
    }
  };

  return (
    <StyledContainer>
      <CloseButtonContainer>
        <Image src={CloseWhite} onClick={history.goBack} alt="close icon" />
      </CloseButtonContainer>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <TopHelpText>{step === 1 ? step1Text : step2Text}</TopHelpText>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <PhoneNumberInputContainer>
              {step === 1 ? (
                <PhoneInput
                  country={phoneNumberState.country}
                  disableSearchIcon={false}
                  enableSearch={true}
                  onChange={onPhoneNumberChange}
                  specialLabel={''}
                  value={phoneNumberState.nationalNumber as string}
                  disableCountryGuess={true}
                  disableCountryCode={true}
                  placeholder={'(123) 456-7890'}
                  inputProps={{
                    autocomplete: 'tel-national',
                  }}
                />
              ) : (
                <OtpInput type="tel" onChange={onOtpChange} placeholder="112233" maxLength={6} />
              )}
            </PhoneNumberInputContainer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <BottomHelpText>
              {step === 1 ? (
                step1Info
              ) : (
                <>
                  <span onClick={sendOtp}>Resend PIN</span> to {format(phoneNumber, 'NATIONAL')}
                  <Image
                    src={EditWhite}
                    onClick={() => {
                      setStep(1);
                      setPhoneNumberState(prevState => ({ ...prevState, nationalNumber: '' }));
                    }}
                    alt="Edit phone number"
                  />
                </>
              )}
            </BottomHelpText>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {step === 1 && (
        <>
          <SubmitButton>
            <Button disabled={!(phoneNumber && isValidNumber(phoneNumber))} onClick={sendOtp}>
              SUBMIT
            </Button>
          </SubmitButton>
          <PrivacyTermsContainer>
            By selecting the Submit button you agree to our{' '}
            <button
              onClick={() => {
                window.location.href = PolicyUrls.TERMS_OF_USE_URL;
                return null;
              }}
            >
              Terms of Use
            </button>{' '}
            and{' '}
            <button
              onClick={() => {
                window.location.href = PolicyUrls.PRIVACY_POLICY_URL;
                return null;
              }}
            >
              {' '}
              Privacy Policy
            </button>
            .
          </PrivacyTermsContainer>
        </>
      )}
    </StyledContainer>
  );
}

export default UserAuth;
