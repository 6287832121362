import React from 'react';

import { colors, fonts } from '../../../shared/theme';
import styled from 'styled-components';

const Restaurant = styled.div`
  font-family: ${fonts.REGULAR};
  font-size: 18px;
  color: ${colors.charcoalGrey};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  margin-bottom: 40px;

  @media (min-width: 1000px) {
    margin: 0 clamp(100px, 100px + 5vw, 250px) 40px;
    grid-gap: 45px 20px;
  }
`;

type RestaurantProps = {
  restaurants: Array<string>;
};

const Restaurants = ({ restaurants }: RestaurantProps) => {
  return (
    <Container>
      {restaurants.map((restaurant, index) => (
        <Restaurant key={index}>{restaurant}</Restaurant>
      ))}
    </Container>
  );
};

export default Restaurants;
