import { format } from 'date-fns';
import React from 'react';
import { Card, Grid, Image, Segment } from 'semantic-ui-react';
import locationSymbol from '../../assets/icons/locationSymbol.png';
import { IDeliveryLocation, OrderType } from '../../shared/models/Cart';
import { Order } from '../../shared/models/Order';
import { getOrderStatus } from '../OrderStatus';
import { Step, Stepper } from '../Stepper';
import { EstimatedTime, OrderCardContainer, TitleContainer } from './styled';

interface OrderCardProps {
  order: Order;
}

export default function OrderCard({ order }: OrderCardProps) {
  const step = getOrderStatus(order);
  const pickup = order.type === OrderType.PICKUP;

  const orderLocation = () => {
    if (pickup) {
      return order.store.name;
    }
    return (order?.location || [])
      .map(({ name, value }: IDeliveryLocation) => `${name} ${value}`)
      .join(', ');
  };

  const formatDateTimeString = () => {
    const dateTime = new Date(order.scheduledTime);
    const formattedDateTime = format(dateTime, 'M/d @ h:mm a');
    if (pickup) {
      return `Est. Pick Up ${formattedDateTime}`;
    }

    return `Est. Delivery ${formattedDateTime}`;
  };

  return (
    <OrderCardContainer>
      <Card>
        <Card.Content className="main">
          <Segment basic>
            <TitleContainer>Order #{order.orderNo}</TitleContainer>
            {order.scheduledTime && <EstimatedTime>{`${formatDateTimeString()}`}</EstimatedTime>}
          </Segment>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column>
                {/* Intentionally repetitive; prevents error of undefined JSX elements */}
                {pickup ? (
                  <Stepper direction="vertical" activeKey={step}>
                    <Step key="NEW">Kitchen Alerted</Step>
                    <Step key="IN_PROCESS">Being Prepared</Step>
                    <Step key="READY">Ready for Pick Up</Step>
                  </Stepper>
                ) : (
                  <Stepper direction="vertical" activeKey={step}>
                    <Step key="NEW">Kitchen Alerted</Step>
                    <Step key="IN_PROCESS">Being Prepared</Step>
                    <Step key="READY">Delivering</Step>
                    <Step key="FINALIZING">Delivered</Step>
                  </Stepper>
                )}
                {pickup ? (
                  <span className="location">
                    <Image src={locationSymbol} /> {orderLocation()}
                  </span>
                ) : (
                  <span className="location reduce">{orderLocation()}</span>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </OrderCardContainer>
  );
}
