import MenuIcon from 'assets/icons/menuIcon.png';
import NotificationCard from 'components/Atoms/PWA/Cards/Notification';
import SkeletonLoader from 'components/Molecules/SkeletonLoader';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';
import { BasePath } from 'shared/constants';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';
import { useVenueContext, VenueSponsor } from '../../../../hooks/useVenueContext';
import Store from '../../../../shared/models/Store';
import Venue from '../../../../shared/models/Venue';
import { BackChevron, BackChevronButton } from './ProductHeader/styled';

const SlantedButtonContainer = styled.div`
  margin-top: 15px;
  bottom: 25px;
  position: relative;
`;

const VenueSponsorContainer = styled.div`
  color: #fff;
  font-family: ${fonts.LIGHT};
  font-size: 14px;
  position: relative;
  top: -20px;
  left: -15px;
  display: flex;

  span {
    flex: none;
    margin-left: 5px;
    margin-top: 5px;
  }

  span.sponsorName {
    font-family: ${fonts.REGULAR};
    margin-right: 10px;
    position: relative;
    top: -1px;
    white-space: pre;
  }
`;

export const BackgroundImageGrid = styled(Grid)<{ background: string }>`
  background: ${({ background }) => (background ? `url(${background})` : 'none')};
  background-size: cover;
  background-position: center;
  height: 150px;
  padding-left: 1em !important;
  padding-right: 1em !important;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 25%,
    rgba(0, 0, 0, 0.9) 80%,
    rgba(0, 0, 0, 0.5) 95%,
    rgba(0, 0, 0, 0) 100%
  );

  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const StyledRowRight = styled(Grid.Row)`
  padding-bottom: 0 !important;
  justify-content: space-between !important;
`;

const CartProfileContainer = styled.div`
  padding: inherit;
`;

const StyledLeftRow = styled(Grid.Row)`
  padding-top: 0 !important;
`;

const StyledBagIcon = styled(Image)`
  width: 17px;
  height: 24px;
  margin-right: 15px;
`;

const StyledUserIcon = styled(Image)`
  width: 24px;
  height: 25px;
`;

const VenueName = styled.h2`
  font-family: ${fonts.LIGHT};
  color: ${colors.white};
  font-size: 20px;
  letter-spacing: -0.3;
  margin-left: 5px;
`;
const VenueNameContainer = styled.div`
  display: flex;
  background: transparent;
  border: none;
`;

export const BagItemCount = styled.span<{ count: number }>`
  position: absolute;
  transform: ${({ count }) => (count > 9 ? 'translate(3%, 50%)' : 'translate(60%, 50%)')};
  bottom: 36%;
  left: 0;
  font-family: ${fonts.REGULAR};
  font-size: 13px;
  color: white;
`;

const ButtonWrapper = styled.button`
  background: none;
  padding: 0;
  border: none;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
`;
const MarqueeContainer = styled.div<{
  animate: number | undefined;
  sponsorsWidth: number | undefined;
  parentWidth: number | undefined;
}>`
  max-width: ${({ parentWidth }) => parentWidth}px;

  .marquee {
    width: ${({ sponsorsWidth }) => sponsorsWidth}px;
    white-space: nowrap;
    overflow: hidden;
  }

  .marquee span {
    white-space: pre;
    display: inline-block;
    padding-left: ${({ animate }) => (animate === 2 ? '100%' : 'unset')};
    will-change: transform;
    /* show the marquee just outside the paragraph */
    animation: marquee 25s linear ${({ animate }) => (animate === 2 ? 'infinite' : '')};
    animation-play-state: ${({ animate }) => (animate === 0 ? 'paused' : 'running')};
  }

  /* Make it move */

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  /* Respect user preferences about animations */

  @media (prefers-reduced-motion: reduce) {
    .marquee {
      white-space: normal;
    }
    .marquee span {
      animation: none;
      padding-left: 0;
    }
  }
`;

const LoadingHeaderContainer = styled.div`
  margin-top: -3px;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 170px;
`;

const SlantedButtonSkeleton = styled.div`
  align-self: center;
  position: absolute;
  top: 120px;
  width: calc(100% - 2.25rem);
  height: 50px;
  border-radius: 10px;
`;

interface HeaderProps {
  entity: Venue | Store | undefined;
  venueSponsor?: VenueSponsor | undefined;
  children?: React.ReactNode;
  handleBack: () => void;
}

const StoreListingHeader = ({ entity, venueSponsor, children, handleBack }: HeaderProps) => {
  const history = useHistory();
  const [sponsorsWidth, setSponsorsWidth] = useState<number | undefined>();
  const [parentWidth, setParentWidth] = useState<number | undefined>();
  const [animate, setAnimate] = useState<number | undefined>(0);
  const VenueSponsorRef: any = useRef();
  const { venue } = useVenueContext({});

  const isLoading = !entity;

  const sponsorsString = (venueSponsor?.sponsors ?? []).map(({ name }: any) => name).join('   ');

  const handleUserClick = () => {
    history.push(`/${BasePath.CONSUMER}/profile`);
  };

  useEffect(() => {
    if (!venueSponsor) {
      return;
    }
    const venueSponsorTimeout = setTimeout(() => {
      if (VenueSponsorRef.current) {
        const venueSponsorWidth = VenueSponsorRef.current.offsetWidth;
        const parentWidth = VenueSponsorRef.current.parentElement.offsetWidth;
        const spanWidth = VenueSponsorRef.current.children[0].offsetWidth;
        const marqueeWidth = venueSponsorWidth - spanWidth - 30;
        const spanSponsorsList = VenueSponsorRef.current.children[1].children[0].children[0];
        setSponsorsWidth(marqueeWidth);
        setParentWidth(parentWidth - spanWidth - 40);
        spanSponsorsList?.addEventListener('animationend', () => {
          setAnimate(2);
        });
        if (spanSponsorsList && spanSponsorsList.offsetWidth >= marqueeWidth) {
          setTimeout(() => setAnimate(1), 2000);
        }
      }
    }, 100);

    return () => clearTimeout(venueSponsorTimeout);
  }, [venueSponsor]);

  const renderLoading = (): JSX.Element => (
    <>
      <LoadingHeaderContainer>
        <SkeletonLoader height={140} />
        <SlantedButtonSkeleton>
          <SkeletonLoader height={50} />
        </SlantedButtonSkeleton>
      </LoadingHeaderContainer>
    </>
  );

  if (isLoading) {
    return renderLoading();
  }

  return (
    <>
      <BackgroundImageGrid padded background={entity?.imageUrl ?? venue?.imageUrl ?? ''}>
        <StyledRowRight>
          <BackChevronButton
            className={'backButton'}
            shaded={false}
            aria-label="Go back"
            onClick={handleBack}
          >
            <BackChevron shaded={true} title="Go back icon" />
          </BackChevronButton>
          <CartProfileContainer>
            <ButtonWrapper
              className={'cart-profile'}
              aria-label="Go to user menu"
              onClick={handleUserClick}
            >
              <StyledUserIcon alt="User Icon" src={MenuIcon} />
            </ButtonWrapper>
          </CartProfileContainer>
        </StyledRowRight>
        <StyledLeftRow>
          <VenueNameContainer>
            <VenueName>{entity?.name ?? ''}</VenueName>
          </VenueNameContainer>
        </StyledLeftRow>
        {!isEmpty(venueSponsor) && (
          <VenueSponsorContainer ref={VenueSponsorRef}>
            <span>{venueSponsor?.title}:</span>
            <MarqueeContainer
              parentWidth={parentWidth}
              sponsorsWidth={sponsorsWidth}
              animate={animate}
            >
              <div className="marquee">
                <span className="sponsorName">{sponsorsString}</span>
              </div>
            </MarqueeContainer>
          </VenueSponsorContainer>
        )}
      </BackgroundImageGrid>
      <SlantedButtonContainer>{children}</SlantedButtonContainer>
      <NotificationCard />
    </>
  );
};

export default StoreListingHeader;
