import styled from 'styled-components';

export const TransitionContainer = styled.div<{ back: boolean; isConsumer: boolean }>`
  height: 100%;
  .slide-appear {
    ${props =>
      props.isConsumer
        ? `
                transform: ${props.back ? 'translateX(-100%)' : 'translateX(100%)'};
                position: absolute;
                width: 100%;
                height: 100%;`
        : `opacity: 0.01;`}
  }

  .slide-appear.slide-appear-active {
    ${props =>
      props.isConsumer
        ? `
                transform: translateX(0);
                transition: all 200ms linear 200ms;`
        : `
                opacity: 1;
                transition: opacity 500ms ease-in;`}
  }

  .slide-enter {
    ${props =>
      props.isConsumer
        ? `
                transform: ${props.back ? 'translateX(-100%)' : 'translateX(100%)'};
                position: absolute;
                width: 100%;
                height: 100%;`
        : `opacity: 0.01;`}
  }

  .slide-enter.slide-enter-active {
    ${props =>
      props.isConsumer
        ? `
                transform: translateX(0);
                transition: all 200ms linear 200ms;`
        : `
                opacity: 1;
                transition: opacity 500ms ease-in;`}
  }

  .slide-exit {
    ${props =>
      props.isConsumer
        ? `
                  transform: translateX(0);
                  position: absolute;
                  width: 100%;
                  height: 100%;`
        : 'opacity: 1;'};
  }

  .slide-exit.slide-exit-active {
    ${props =>
      props.isConsumer
        ? `
                transform: ${props.back ? 'translateX(100%)' : 'translateX(-100%)'};
                transition: all 200ms linear 200ms;`
        : `
                opacity: 0.01;
                transition: opacity 300ms ease-in;`}
  }
`;
