import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { colors } from '../../../shared/theme';

const SkeletonLoader = ({
  color,
  highlightColor,
  duration,
  height,
  width,
  count,
}: {
  color?: string;
  highlightColor?: string;
  duration?: number;
  height?: number;
  width?: number;
  count?: number;
}) => {
  return (
    <SkeletonTheme
      color={color || colors.skeletonGrey}
      highlightColor={highlightColor || colors.secondary}
    >
      <Skeleton duration={duration || 2} height={height} width={width} count={count} />
    </SkeletonTheme>
  );
};

export default SkeletonLoader;
