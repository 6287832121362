import circleCheck from 'assets/icons/circleCheck.png';
import Header, { Size } from 'components/Atoms/Header';
import Spacer, { SpacerSizes } from 'components/Atoms/Spacer';
import TabShare from 'components/OpenTab/TabShare';
import OrderDetails from 'components/OrderDetails';
import Footer from 'components/Organisms/Footer';
import SuiteHeader from 'components/Organisms/SuiteHeader';
import useWindowWidth from 'hooks/UseWindowWidth';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Image, Segment } from 'semantic-ui-react';
import { OrderService } from 'services/OrderService';
import { colors } from 'shared/theme';
import styled from 'styled-components';
import { useAppContext } from '../../App/AppContext';

export const ScreenContainer = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const SegmentContainer = styled(Segment)`
  &&& {
    padding-top: 80px !important;
    flex: 1;
    .button,
    .button:hover,
    .button:active,
    .button:focus {
      color: ${colors.niceBlue} !important;
      box-shadow: 0 0 0 1px ${colors.niceBlue} inset;
    }
  }
`;

const PrimaryHeader = styled(Header)`
  color: ${({ theme }) => theme.primaryColor};
`;

interface SuiteOrderPlacedRouteProps {
  orderId: string;
}

interface SuiteOrderPlacedProps extends RouteComponentProps<SuiteOrderPlacedRouteProps> {}

const SuiteOrderPlaced = (props: SuiteOrderPlacedProps) => {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, order: placedOrder, setOrder: setPlacedOrder } = useAppContext();
  const {
    params: { orderId },
  } = props.match;
  const history = useHistory();
  const width = useWindowWidth();

  useEffect(() => {
    return () => {
      setPlacedOrder(undefined);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const { data: order } = await OrderService.GetOneOrder(orderId);
        setPlacedOrder(order);
        if (order.id !== orderId) {
          history.replace('/404');
        }
      } catch (error) {
        if (error.response.data.message === 'Order does not exist!') {
          history.replace('/404');
        } else {
          toast(error.response.data.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (!placedOrder || placedOrder.id !== orderId) {
      init();
    }
  }, [orderId, history]);

  return (
    <ScreenContainer>
      <SuiteHeader />
      {placedOrder && (
        <OrderDetails
          order={placedOrder}
          onClose={() => setShowOrderDetails(false)}
          open={showOrderDetails}
          suite={true}
        />
      )}
      <SegmentContainer
        basic
        loading={isLoading}
        textAlign="center"
        fluid={width < 800 ? false : 'very'}
      >
        <Image src={circleCheck} wrapped ui={false} />
        <Spacer size={SpacerSizes.MINI} />
        <PrimaryHeader as="h2" font="BOLD" textAlign="center" upperCase>
          Thank you, {user?.firstName}!
        </PrimaryHeader>
        <Spacer size={SpacerSizes.MINI} />
        <Header as="h4" textAlign="center" size={Size.MINUSCULE}>
          Order #{placedOrder?.orderNo} has been placed
        </Header>
        <Spacer size={SpacerSizes.SMALL} />
        <Header as="h4" textAlign="center" size={Size.MINUSCULE}>
          You will receive an email confirmation shortly
        </Header>
        <Spacer size={SpacerSizes.MEDIUM} />
        <Button onClick={() => setShowOrderDetails(true)} basic fluid={width < 800}>
          Order Details
        </Button>
      </SegmentContainer>
        <TabShare tabOwner={user?.firstName} venueId={placedOrder?.venueId} orderData={placedOrder}/>
      <Footer />
    </ScreenContainer>
  );
};

export default SuiteOrderPlaced;
