import AddButtonIcon from 'assets/icons/addButtonBlue.png';
import MinusButtonIcon from 'assets/icons/minusButtonBlue.png';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Card, Loader } from 'semantic-ui-react';
import { useAppContext } from '../../../containers/App/AppContext';
import useCartContext from '../../../containers/Cart/context';
import { useCartHandler } from '../../../hooks/handlers/cart';
import FloatingLabelInput from '../../FloatingLabelInput';
import {
  AddNoteBtn,
  AddNoteText,
  AddNoteTextExample,
  CloseNoteIcon,
  EditNoteButton,
  NoteLabel,
  NotePlaceholder,
  NoteSaveButton,
  NoteSaveContainer,
  NoteText,
  OpenNoteIcon,
  SavedNoteContainer,
  SavedNoteEditContainer,
} from '../styled';

interface NoteState {
  isEditing: boolean;
  showInput: boolean;
  showNote: boolean;
  showLoader: boolean;
  note: string;
  focused: boolean;
  showAddNoteButton: boolean;
}

interface NoteContainer {
  noteState: NoteState;
  setNoteState: Dispatch<SetStateAction<NoteState>>;
  cartInstructions: string | undefined;
  handleAddNoteClick: () => void;
}

export const CartNote = () => {
  const { cart } = useCartContext();
  const { store } = useAppContext();

  const [noteState, setNoteState] = useState<NoteState>({
    isEditing: false,
    showInput: false,
    showAddNoteButton: true,
    showLoader: false,
    showNote: false,
    note: cart?.instructions ?? '',
    focused: false,
  });

  useEffect(() => {
    if (cart && cart.instructions) {
      setNoteState({
        isEditing: false,
        showInput: false,
        showAddNoteButton: false,
        showNote: true,
        showLoader: false,
        note: cart.instructions,
        focused: false,
      });
    } else {
      setNoteState(prev => ({
        ...prev,
        showAddNoteButton: true,
      }));
    }
  }, [cart]);

  const handleAddNoteClick = () => {
    setNoteState(prev => ({
      ...prev,
      showInput: true,
      showAddNoteButton: false,
    }));
  };

  if (!store?.orderInstructionsAvailable) {
    return null;
  }

  return (
    <Card.Content style={{ borderTop: 'none' }}>
      <>
        {noteState.showAddNoteButton && (
          <AddNoteBtn onClick={handleAddNoteClick} aria-expanded={noteState.showInput}>
            {noteState.showInput ? (
              <CloseNoteIcon alt="minus icon" src={MinusButtonIcon} />
            ) : (
              <OpenNoteIcon alt="plus icon" src={AddButtonIcon} />
            )}

            <AddNoteText className="addNote">Add Note</AddNoteText>
            <AddNoteTextExample> (extra napkins, etc.)</AddNoteTextExample>
          </AddNoteBtn>
        )}
      </>
      <NotesContainer
        cartInstructions={cart?.instructions}
        noteState={noteState}
        setNoteState={setNoteState}
        handleAddNoteClick={handleAddNoteClick}
      />
    </Card.Content>
  );
};

const NotesContainer = ({
  cartInstructions,
  noteState,
  setNoteState,
  handleAddNoteClick,
}: NoteContainer) => {
  const { updateCart } = useCartHandler();
  const specialNotesDescriptor = noteState.note
    ? `${75 - noteState.note.length} characters remaining`
    : undefined;

  const handleNoteBlurFocus = ({ focus, blur }: { focus?: boolean; blur?: boolean }) => {
    if (focus) {
      setNoteState(prev => ({
        ...prev,
        focused: true,
      }));
    }

    if (blur && !noteState.note) {
      setNoteState(prev => ({
        ...prev,
        focused: false,
      }));
    }
  };
  const onNoteChange = (value: string) => {
    setNoteState(prev => ({
      ...prev,
      note: value,
    }));
  };

  const handleSaveOrEdit = async (e: any) => {
    if (e.currentTarget.textContent === 'EDIT') {
      setNoteState(prev => ({
        ...prev,
        isEditing: true,
        showInput: true,
        showAddNoteButton: true,
        focused: true,
      }));
      return;
    }

    if (e.currentTarget.textContent === 'SAVE') {
      if (!noteState.note) {
        setNoteState(prev => ({
          ...prev,
          showAddNoteButton: true,
          showInput: false,
        }));
        return;
      }

      if (noteState.note && noteState.note.trim().length === 0) {
        setNoteState(prev => ({
          ...prev,
          showAddNoteButton: true,
          showInput: false,
        }));
        return;
      }
      setNoteState(prev => ({
        ...prev,
        showInput: false,
        showAddNoteButton: false,
        showLoader: true,
      }));

      await updateCart({ instructions: noteState.note })();

      setNoteState(prev => ({
        ...prev,
        showLoader: false,
      }));
    }
  };

  if (noteState.showLoader) {
    return <Loader active size="tiny" inline="centered" />;
  }

  if (noteState.showInput) {
    return (
      <NoteSaveContainer>
        <FloatingLabelInput
          id="note"
          name="note"
          type="textarea"
          noMargin={true}
          isRequired={false}
          isDisabled={false}
          maxLength={75}
          autoComplete="true"
          value={noteState.note}
          onBlur={() => handleNoteBlurFocus({ blur: true })}
          onFocus={() => handleNoteBlurFocus({ focus: true })}
          placeholder={
            noteState.focused ? (
              <NotePlaceholder>Note</NotePlaceholder>
            ) : (
              <NotePlaceholder>Type your note here...</NotePlaceholder>
            )
          }
          descriptor={specialNotesDescriptor}
          onChange={({ value }: { value: string }) => onNoteChange(value)}
        >
          <NoteSaveButton
            noteLength={(noteState.note && noteState.note.length) || 0}
            onClick={e => handleSaveOrEdit(e)}
          >
            SAVE
          </NoteSaveButton>
        </FloatingLabelInput>
      </NoteSaveContainer>
    );
  }

  if (cartInstructions && !noteState.showAddNoteButton) {
    return (
      <SavedNoteContainer>
        <NoteLabel>Note</NoteLabel>
        <SavedNoteEditContainer>
          <NoteText>{cartInstructions}</NoteText>
          <EditNoteButton
            noteLength={(noteState.note && noteState.note.length) || 0}
            onClick={e => handleAddNoteClick()}
          >
            EDIT
          </EditNoteButton>
        </SavedNoteEditContainer>
      </SavedNoteContainer>
    );
  }
  return null;
};
