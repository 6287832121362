import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';

export const PaymentContainer = styled(Segment)`
  &&& {
    .icon {
      display: inline;
      max-width: 15px;
      margin-left: 5px;
      margin-top: -5px;
    }
  }
`;
