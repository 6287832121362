import React, { ReactChild, useEffect, useState } from 'react';
import { Card, Divider, List } from 'semantic-ui-react';
import useCartContext from '../../containers/Cart/context';
import { CardContainer } from '../../shared/global-styled';
import { getCurrencyPrefix } from '../../shared/utils';
import { TipCardHeader, TipCustomInput, TipOption, TipSelector, Wrapper } from './styled';

interface ITipCard {
  showTip?: boolean;
  onChange: (amount: number) => void;
  tip: number;
}

interface Tip {
  id: string;
  label: string;
  percent: number;
  value: number | string;
}

interface TipState {
  tip: Tip;
  isCustomTip: boolean;
}

const tips: Tip[] = [
  { id: 'tip2', label: '15%', percent: 0.15, value: 0 },
  { id: 'tip3', label: '20%', percent: 0.2, value: 0 },
  { id: 'tip4', label: '25%', percent: 0.25, value: 0 },
];

const customTip: Tip = {
  id: 'custom',
  label: 'Custom',
  percent: 0,
  value: 0,
};

export const TipCard = ({ showTip = true, onChange, tip }: ITipCard) => {
  const { cart } = useCartContext();
  const currencyPrefix = getCurrencyPrefix(cart?.currency);
  const [tipState, setTip] = useState<TipState>({
    tip: { ...tips[1], value: (cart?.subTotal ?? 0) * tips[1].percent },
    isCustomTip: false,
  });

  useEffect(() => {
    if (showTip) {
      onChange((cart?.subTotal ?? 0) * tipState.tip.percent);
    }
  }, [cart, showTip]);

  const onTipPercentageClick = (tipPercentage: Tip) => {
    const newTipValue = (cart?.subTotal ?? 0) * tipPercentage.percent;
    setTip({
      tip: { ...tipPercentage, value: newTipValue },
      isCustomTip: false,
    });
    onChange(newTipValue);
  };

  const isTipSelected = (tip: Tip) => !tipState.isCustomTip && tipState.tip.percent === tip.percent;

  const onCustomTipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTip = e.currentTarget.value.replace('$', '');
    if (newTip === '' || isNaN(+newTip)) {
      const defaultTip = (cart?.subTotal ?? 0) * tips[1].percent;
      setTip({
        tip: { ...tips[1], value: defaultTip },
        isCustomTip: false,
      });

      onChange(defaultTip);
    } else {
      setTip({
        tip: {
          ...customTip,
          value: newTip.match(/[0-9]+\.$|[0-9]+\.[0-9]*$/g) ? newTip : Number(newTip),
        },
        isCustomTip: true,
      });

      onChange(+newTip);
    }
  };
  return (
    <>
      {showTip && (
        <CardContainer>
          <Card>
            <TipCardHeader>
              <div className="title">Tips</div>
              <div className="tip_amount">
                {currencyPrefix}
                {tip.toFixed(2)}
              </div>
            </TipCardHeader>
            <Divider />
            <p className="subtitle">100% of tips go to our hardworking staff</p>
            <Wrapper>
              <List horizontal>
                <TipSelector>
                  {tips.map(percentTip => {
                    return (
                      <TipComponent
                        key={percentTip.id}
                        tip={percentTip}
                        tipSelected={isTipSelected(percentTip)}
                        handleTip={onTipPercentageClick}
                      ></TipComponent>
                    );
                  })}
                  <TipCustomInput
                    inputMode={'decimal'}
                    onChange={onCustomTipChange}
                    placeholder={'Custom'}
                    value={tipState.isCustomTip ? `${currencyPrefix}${tipState.tip.value}` : ''}
                    selected={tipState.isCustomTip}
                  />
                </TipSelector>
              </List>
            </Wrapper>
          </Card>
        </CardContainer>
      )}
    </>
  );
};

interface ITipComponent {
  tip: Tip;
  tipSelected?: boolean;
  handleTip: (tip: Tip) => void;
  children?: ReactChild;
}

const TipComponent = ({ tip, tipSelected = false, handleTip }: ITipComponent) => {
  return (
    <TipOption onClick={() => handleTip(tip)} selected={tipSelected}>
      {tip.label}
    </TipOption>
  );
};
