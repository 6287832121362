import circleExclaim from 'assets/icons/circleExclaim.png';
import closeSearchBtn from 'assets/icons/closeSearchBtn.png';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { BasePath } from 'shared/constants';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';
import { useAppContext } from '../../../../containers/App/AppContext';

const CloseButton = styled.button`
  margin-top: 10px;
  margin-right: 10px;
  padding: 0;
  border: none;
  background: transparent;

  &::hover {
    cursor: pointer;
  }
`;

const CloseButtonIcon = styled(Image)`
  height: 12px;
  width: 12px;
`;

const StyledCard = styled.div`
  border-radius: 10px;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
`;

const CardAlertHeader = styled.div`
  font-family: ${fonts.SUPER};
  color: ${({ theme }) => theme.primaryColor};
  font-size: 18px;
  letter-spacing: 0;
  text-align: center;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DidWeDoWrong = styled.div`
  font-size: 16px;
  font-family: ${fonts.MEDIUM};
  letter-spacing: 0;
  color: ${colors.charcoalGrey};
  margin: 20px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LetUsKnow = styled.button`
  background: none;
  border: none;
  font-family: ${fonts.REGULAR};
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #147c9e;
  margin-bottom: 30px;
`;

const FeedBackWrapper = styled.div`
  background-color: ${colors.lightGrey};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 10px 10px;
`;

const OrderText = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const NotificationCard = () => {
  const history = useHistory();
  const { orderCancelled, setOrderCancelled } = useAppContext();

  const navigateToChatScreen = () => {
    if (orderCancelled?.orderNo) {
      history.push(
        `/${BasePath.CONSUMER}/chat/${orderCancelled.orderNo}/${orderCancelled.venueId}/${orderCancelled.store.id}`,
      );
    }
    setOrderCancelled(undefined);
  };

  return (
    <>
      {orderCancelled?.id && (
        <StyledCard role="alert">
          <CloseButtonWrapper>
            <CloseButton aria-label="Close Alert" onClick={() => setOrderCancelled(undefined)}>
              <CloseButtonIcon src={closeSearchBtn} />
            </CloseButton>
          </CloseButtonWrapper>

          <ContentWrapper>
            <Image centered src={circleExclaim} />

            <OrderText>
              <CardAlertHeader>{`YOUR ORDER #${orderCancelled.orderNo}`}</CardAlertHeader>
              <CardAlertHeader>{` HAS BEEN CANCELLED`}</CardAlertHeader>
            </OrderText>

            <FeedBackWrapper>
              <DidWeDoWrong>Was it something we did?</DidWeDoWrong>
              <LetUsKnow aria-label="Go to customer support chat" onClick={navigateToChatScreen}>
                LET US KNOW
              </LetUsKnow>
            </FeedBackWrapper>
          </ContentWrapper>
        </StyledCard>
      )}
    </>
  );
};

export default NotificationCard;
