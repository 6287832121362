import CheckedCircle from 'assets/icons/smGreenCheckCircle.svg';
import GreyCircle from 'assets/icons/smGreyCircle.svg';
import RedCircle from 'assets/icons/smRedCircle.svg';
import { useSuiteContext } from 'containers/Suite/Steps/SuiteContext';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SuiteStepperProps } from '..';
import {
  CheckCircle,
  CirclesWrapper,
  CircleWrapper,
  Container,
  ContainerBorderBottom,
  SkipButton,
  StepItem,
  StyledRow,
} from './styled';

const SuiteStepperMobile = ({ steps }: SuiteStepperProps) => {
  const { setSelectedIndex, selectedIndex } = useSuiteContext();

  const handleClick = (index?: number) => {
    if (typeof index !== 'undefined') {
      setSelectedIndex(index);
    } else {
      selectedIndex < steps.length ? setSelectedIndex(selectedIndex + 1) : setSelectedIndex(0);
    }
  };

  const circleStatus = (index: number) => {
    if (selectedIndex === index) {
      return <CheckCircle src={RedCircle} />;
    } else if (selectedIndex > index) {
      return <CheckCircle src={CheckedCircle} />;
    } else {
      return <CheckCircle src={GreyCircle} />;
    }
  };

  const selectedStep = steps[selectedIndex];
  return (
    <Grid>
      <Grid.Column>
        <Grid.Row>
          <CirclesWrapper>
            {[...Array(steps.length + 1)].map((value: undefined, index: number) => (
              <CircleWrapper key={index} onClick={() => handleClick(index)}>
                {circleStatus(index)}
              </CircleWrapper>
            ))}
          </CirclesWrapper>
          <SkipButton onClick={() => handleClick()}>Skip</SkipButton>
          <ContainerBorderBottom>
            <StyledRow>
              <Container>
                <StepItem>{selectedStep?.name || 'Checkout'}</StepItem>
              </Container>
            </StyledRow>
          </ContainerBorderBottom>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

export default SuiteStepperMobile;
