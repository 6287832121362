import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { VenueService } from '../../services';
import { IVenueInvite, UserVenueRole } from '../../services/VenueService';
import { getManagerPortalWebLink } from '../../services/WebLinksService';
import { BasePath } from '../../shared/constants';
import { useAppContext } from '../App/AppContext';

interface IVenueInviteRouteParams {
  inviteId: string;
}

const humanReadableRoles = {
  [UserVenueRole.VENUE_MANAGER]: 'Venue Manager',
  [UserVenueRole.STORE_MANAGER]: 'Store Manager',
  [UserVenueRole.STORE_EMPLOYEE]: 'Store Employee',
  [UserVenueRole.RUNNER]: 'FanFood Runner',
  [UserVenueRole.SUITE_ATTENDANT]: 'Suite Attendant',
  [UserVenueRole.SUITE_CUSTOMER]: 'Suite Customer',
};

export const VenueInvite = () => {
  const { inviteId } = useParams<IVenueInviteRouteParams>();
  const { setShowLoader } = useAppContext();
  const history = useHistory();
  const [invite, setInvite] = useState<IVenueInvite | undefined>(undefined);

  const getSuccessMessage = (role: UserVenueRole) => {
    if (
      [
        UserVenueRole.VENUE_MANAGER,
        UserVenueRole.STORE_MANAGER,
        UserVenueRole.STORE_EMPLOYEE,
      ].includes(role)
    ) {
      return null;
    }
    return `You have been added as a ${humanReadableRoles[role]}`;
  };

  const suiteRedirection = () => {
    if (!invite) {
      return history.replace(`/${BasePath.SUITE}`);
    }

    const { eventIds, suiteIds } = invite;
    const suiteId = (suiteIds || []).length === 1 ? suiteIds[0] : null;
    const eventId = (eventIds || []).length === 1 ? eventIds[0] : null;

    if (suiteId && eventId) {
      return history.replace(`/${BasePath.SUITE}/${suiteId}?eventId=${eventId}`);
    }

    if (suiteId) {
      return history.replace(`/${BasePath.SUITE}/${suiteId}`);
    }

    return history.replace(`/${BasePath.SUITE}`);
  };

  useEffect(() => {
    if (!inviteId) {
      history.replace(`/`);
    }

    (async () => {
      try {
        setShowLoader(true);
        const { data: invite } = await VenueService.AcceptVenueInvite(inviteId);

        const successMessage = getSuccessMessage(invite.role);
        if (successMessage) {
          toast.info(successMessage);
        }
        setInvite(invite);
      } catch (error) {
        toast.error('Invalid invite code.');
        history.replace(`/`);
      }
      setShowLoader(false);
    })();
  }, [inviteId]);

  useEffect(() => {
    if (!invite) {
      return;
    }

    switch (invite.role) {
      case UserVenueRole.VENUE_MANAGER:
      case UserVenueRole.STORE_MANAGER:
      case UserVenueRole.STORE_EMPLOYEE:
        return window.location.replace(getManagerPortalWebLink(invite.venueId));
      case UserVenueRole.RUNNER:
      case UserVenueRole.SUITE_ATTENDANT:
        return history.replace(`/`);
      case UserVenueRole.SUITE_CUSTOMER:
        return suiteRedirection();
    }
  }, [invite]);

  return null;
};
