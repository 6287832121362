import CaretDown from 'assets/icons/caretDown.png';
import CaretUp from 'assets/icons/caretUp.png';
import React, { useState } from 'react';
import { Card, Divider, Grid, Image } from 'semantic-ui-react';
import { useAppContext } from '../../../containers/App/AppContext';
import useCartContext from '../../../containers/Cart/context';
import { CardContainer } from '../../../shared/global-styled';
import { getCurrencyPrefix } from '../../../shared/utils';
import AddPromoCode from '../../AddPromoCode';
import { TotalContainer } from '../styled';

interface IOrderCharge {
  display?: boolean | undefined;
  title: string;
  currencyPrefix: string;
  charge: number;
  className?: string;
  style?: object;
}

interface ICartSummary {
  showTip?: boolean;
  tip?: number;
  showSummary?: boolean;
}

interface IOrderChargeList {
  currencyPrefix: string;
  handleChargeAmount: (chargeType: string) => number;
  showTip?: boolean;
}

enum OrderChargeEnum {
  SUBTOTAL = 'Subtotal',
  SERVICE = 'Service',
  PICKUPCHARGES = 'Pickup Charge',
  DELIVERYCHARGES = 'Delivery Charge',
  DISCOUNT = 'Discount',
  TIP = 'Tip',
  TAX = 'Tax',
  TOTAL = 'Total',
  GIVEX = 'Loaded Value',
}

enum SERVICETYPES {
  TaxName = 'Marketing & Entertainment Fee',
  VenueID = 'a7cb917a-571d-47c9-9416-783e9b8b50fa',
}

const OrderCharge = ({
  display = true,
  title,
  currencyPrefix,
  charge,
  className,
  style,
}: IOrderCharge) => {
  const { cart } = useCartContext();
  return (
    <>
      {display && (
        <Grid.Row {...(className && { className })}>
          <Grid.Column floated="left" width={10}>
            {title == OrderChargeEnum.SERVICE && cart?.venueId == SERVICETYPES.VenueID
              ? SERVICETYPES.TaxName
              : title}
          </Grid.Column>
          <Grid.Column floated="right" width={6} {...(style && { style })}>
            {currencyPrefix}
            {charge.toFixed(2)}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

const OrderChargeList = ({
  handleChargeAmount,
  showTip = false,
  currencyPrefix,
}: IOrderChargeList) => {
  const { cart } = useCartContext();
  const { store, venue, givexData } = useAppContext();
  const calculateTaxes =
    store?.calculateTaxes !== null ? store?.calculateTaxes : !!venue?.calculateTaxes;

  const renderCharge = (charge: OrderChargeEnum, key: number) => {
    console.log(charge, 'charge');
    switch (charge) {
      case OrderChargeEnum.DISCOUNT:
        return (
          <OrderCharge
            key={key}
            title={charge}
            currencyPrefix={`- ${currencyPrefix}`}
            charge={handleChargeAmount(charge)}
            display={cart && cart?.discountAmt > 0}
            style={{ color: 'red' }}
          />
        );
      case OrderChargeEnum.GIVEX:
        return (
          <OrderCharge
            key={key}
            title={charge}
            currencyPrefix={`- ${currencyPrefix}`}
            charge={handleChargeAmount(charge)}
            display={givexData?.balance && givexData?.balance > 0 ? true : false}
            style={{ color: 'red' }}
          />
        );
      case OrderChargeEnum.TIP:
        return (
          <OrderCharge
            key={key}
            title={charge}
            currencyPrefix={currencyPrefix}
            charge={handleChargeAmount(charge)}
            display={showTip}
          />
        );
      case OrderChargeEnum.TAX:
        return (
          <OrderCharge
            key={key}
            title={charge}
            currencyPrefix={currencyPrefix}
            charge={handleChargeAmount(charge)}
            display={calculateTaxes}
          />
        );
      case OrderChargeEnum.TOTAL:
        return (
          <>
            <Divider />
            <OrderCharge
              key={key}
              title={charge}
              currencyPrefix={currencyPrefix}
              charge={handleChargeAmount(charge)}
              className={'total'}
            />
          </>
        );
      default:
        return (
          <OrderCharge
            key={key}
            title={charge}
            currencyPrefix={currencyPrefix}
            charge={handleChargeAmount(charge)}
          />
        );
    }
  };

  return cart?.orderType === 'PICKUP' ? (
    <Grid>
      {Object.values(OrderChargeEnum).map(
        (charge, index) => charge !== OrderChargeEnum.DELIVERYCHARGES  && renderCharge(charge, index),
      )}
   </Grid>
  ) : (
    <Grid>
      {Object.values(OrderChargeEnum).map(
        (charge, index) => charge !== OrderChargeEnum.PICKUPCHARGES  && renderCharge(charge, index),
      )}
    </Grid>
  );
};

export const CartSummary = ({ showTip = false, tip = 0, showSummary = false }: ICartSummary) => {
  const { cart } = useCartContext();
  const { givexData } = useAppContext();
  const [displaySummary, setDisplaySummary] = useState(showSummary);
  const currencyPrefix = getCurrencyPrefix(cart?.currency);

  const handleChargeAmount = (chargeType: string): number => {
    const defaultValue = 0;
    switch (chargeType) {
      case OrderChargeEnum.DISCOUNT:
        return (cart?.promotionId && cart?.discountAmt) || defaultValue;
      case OrderChargeEnum.GIVEX:
        return (givexData?.balance && givexData?.balance) || defaultValue;
      case OrderChargeEnum.SERVICE:
        return cart?.serviceFee ?? defaultValue;
      case OrderChargeEnum.PICKUPCHARGES:
        return cart?.convenienceFee ?? defaultValue;
      case OrderChargeEnum.DELIVERYCHARGES:
        return cart?.convenienceFee ?? defaultValue;
      case OrderChargeEnum.SUBTOTAL:
        return cart?.subTotal ?? defaultValue;
      case OrderChargeEnum.TIP:
        return tip;
      case OrderChargeEnum.TAX:
        return cart?.tax ?? defaultValue;
      case OrderChargeEnum.TOTAL: {
        return givexData?.balance
          ? (cart?.total ?? defaultValue) - givexData.balance + (showTip ? tip : 0)
          : (cart?.total ?? defaultValue) + (showTip ? tip : 0);
      }
      default:
        return defaultValue;
    }
  };

  if (!cart) {
    return null;
  }
  return (
    <CardContainer>
      <TotalContainer>
        <Card className="summary">
          <Card.Content>
            <Card.Header>
              <Grid
                container
                className="segment-title"
                floated="left"
                onClick={() => setDisplaySummary(!displaySummary)}
              >
                <div
                  className="title"
                  onClick={() => {
                    setDisplaySummary(!displaySummary);
                  }}
                >
                  Order Summary{' '}
                  {displaySummary ? <Image src={CaretUp} /> : <Image src={CaretDown} />}
                </div>
                <div className="total">
                  {currencyPrefix}
                  {handleChargeAmount(OrderChargeEnum.TOTAL).toFixed(2)}
                </div>
              </Grid>
            </Card.Header>
            <Divider />
            {displaySummary && (
              <OrderChargeList
                handleChargeAmount={handleChargeAmount}
                showTip={showTip}
                currencyPrefix={currencyPrefix}
              />
            )}
            <div className="promo-code">{cart && <AddPromoCode />}</div>
          </Card.Content>
        </Card>
      </TotalContainer>
    </CardContainer>
  );
};
