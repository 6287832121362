const colors = {
  primary: '#DA112E', // this is the default theme but can be overridden
  secondary: '#FFFFFF',
  lightGrey: '#f1f1f1',
  placeholderGrey: '#d6d6d6',
  blueLink: '#0dbad4',
  navigationBlue: '#4a90e2',
  niceBlue: '#147c9e',
  black: '#47435D',
  white: '#FEFEFE',
  grey: '#696969',
  greyCombo: '#404446',
  poweredGrey: '#c1c1c1',
  grape: '#43504a',
  description: '#636363',
  tabGrey: '#707070',
  seaFoam: '#09a999',
  deliveryBtnText: '#9a9999',
  charcoalGrey: '#474350',
  pureGrey: '#7e7e7e',
  iconGrey: '#acaab0',
  paleGrey: '#ededed',
  bubbleBlue: '#00E0FE',
  inputTextGrey: '#757575',
  inputPlaceholderGrey: '#989998',
  red: '#eb0000',
  cyanBlue: '#0fe0ff',
  greyButton: '#f0f1f1',
  footerBackground: '#d8d8d8',
  footerText: '#9d9d9d',
  inputBorderBottom: 'rgba(110,110,110,1)',
  inputFontColor: '#474d50',
  searchBarGridBackground: '#2e2d35',
  greyClose: '#80868b',
  borderInput: '#d8d7d8',
  toastYellow: '#ffed00',
  brownishGrey: '#6e6e6e',
  suiteBackground: '#bf2026',
  skeletonGrey: '#e1e1e1',
};

const fonts = {
  SUPER: 'AkzidenzGroteskBESuper',
  MEDIUM: 'AkzidenzGroteskBEMd',
  REGULAR: 'AkzidenzGroteskBERegular',
  LIGHT: 'AkzidenzGroteskBELight',
  BOLD: 'AkzidenzGroteskBEBold',
};

export { colors, fonts };
