import closeSearchBtn from 'assets/icons/closeSearchBtn.png';
import { chain } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon, Image, List, Modal, Segment, TransitionablePortal } from 'semantic-ui-react';
import { useAppContext } from '../../../containers/App/AppContext';
import { useSuiteContext } from '../../../containers/Suite/Steps/SuiteContext';
import { useStoreContext } from '../../../hooks/useStoreContext';
import { useVenueContext } from '../../../hooks/useVenueContext';
import { ILineItem } from '../../../shared/models/Cart';
import IProduct from '../../../shared/models/Product';
import { fonts } from '../../../shared/theme';
import { getProductImage } from '../../../shared/utils';
import { LineItemComponent } from '../../ItemsSummary/Item/LineItem/LineItem';
import { ProductCard } from '../../ProductCard';
import { PriceQuantityContainer, QuantityControlContainer } from './styled';

interface IProductCardComponent {
  currenctyPrefix: string;
  product: IProduct;
  setSelectedProductId: Dispatch<SetStateAction<string | undefined>>;
}

interface ILineItemModal {
  closeModal: () => void;
  open: boolean;
  product: IProduct;
}

interface IPriceQuantity {
  currencyPrefix: string;
  isFree: boolean;
  price?: number;
  quantity: number;
  updateQuantity: (value: 1 | -1) => Promise<void>;
}

const ProductCardComponent = ({
  product,
  currenctyPrefix,
  setSelectedProductId,
}: IProductCardComponent) => {
  const {
    packageQuantity,
    handlePackageSelection,
    selectedEvent,
    buttonLoadingId,
  } = useSuiteContext();
  const { user } = useAppContext();
  const { venue } = useVenueContext();
  const { store } = useStoreContext();
  const [itemQuantity, setItemQuantity] = useState(0);
  const [openLineItem, setOpenLineItem] = useState(false);

  useEffect(() => {
    const itemCount = () => {
      const productQuantity = chain(packageQuantity || [])
        .filter(packageData => packageData.id === product.id)
        .sumBy(packageData => packageData.quantity)
        .value();

      setItemQuantity(productQuantity);
    };
    itemCount();
  }, [packageQuantity]);

  const handleAddToCart = async (quantityIncrement: 1 | -1) => {
    if (!store || !product) {
      return;
    }
    if (selectedEvent?.id && user) {
      await handlePackageSelection({
        userId: user.id,
        storeId: store.id,
        productId: product.id,
        quantityIncrement,
        eventId: selectedEvent.id,
      });
    } else {
      toast('Please select an event before selecting items.');
      return;
    }
  };

  const handlePlusOrMinus = async (value: -1 | 1) => {
    if (!selectedEvent) {
      toast('Please select an event before selecting items.');
      return;
    }
    if ((product.alcohol || product.hasSubProducts) && value !== -1) {
      setSelectedProductId(product.id);
      return;
    }

    if (product.hasSubProducts && value === -1) {
      setOpenLineItem(true);
      return;
    }

    await handleAddToCart(value);
  };

  if (!venue) return <></>;

  const handleProductCardClick = () => {
    if (
      product.hasSubProducts ||
      product.alcohol ||
      (product.description && product.description.length > 200)
    ) {
      setSelectedProductId(product.id);
    }
  };

  return (
    <>
      <ProductCard
        currencyPrefix={currenctyPrefix}
        description={product.description}
        hasSubProducts={product.hasSubProducts}
        imageurl={getProductImage(product)}
        isFree={product.price <= 0}
        onCardClick={handleProductCardClick}
        price={product.price.toString()}
        quantity={itemQuantity}
        storeIsActive={!!store?.active}
        title={product.name}
        updateQuantity={handlePlusOrMinus}
        showProductImages={true}
        maxDescription={200}
        CustomQuantityControl={
          <PriceQuantityComponent
            isFree={product.price <= 0}
            price={product.price}
            currencyPrefix={currenctyPrefix}
            quantity={itemQuantity}
            updateQuantity={handlePlusOrMinus}
          />
        }
      />
      {openLineItem && (
        <LineItemModal
          open={openLineItem}
          closeModal={() => setOpenLineItem(false)}
          product={product}
        />
      )}
    </>
  );
};

const PriceQuantityComponent = ({
  price,
  quantity,
  isFree,
  currencyPrefix,
  updateQuantity,
}: IPriceQuantity) => {
  return (
    <PriceQuantityContainer>
      <div className="product-price">{price && !isFree ? `${currencyPrefix}${price}` : ''}</div>
      <QuantityControlContainer>
        {quantity > 0 && (
          <>
            <Icon
              onClick={(e: MouseEvent | TouchEvent) => {
                e.stopPropagation();
                updateQuantity(-1);
              }}
              circular
              className="minus"
            ></Icon>
            <span className="quantity">{`x${quantity}`}</span>
          </>
        )}
        <Icon
          onClick={(e: MouseEvent | TouchEvent) => {
            e.stopPropagation();
            updateQuantity(1);
          }}
          circular
          className="plus"
        ></Icon>
      </QuantityControlContainer>
    </PriceQuantityContainer>
  );
};

const LineItemModal = ({ product, open, closeModal }: ILineItemModal) => {
  const { cart, updateLineItem } = useSuiteContext();
  const [lineItems, setLineItems] = useState<ILineItem[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!cart) {
      return;
    }

    const lineItems = cart.lineItems.filter(item => item.productId === product.id);
    setLineItems(lineItems);
  }, [product, cart]);

  useEffect(() => {
    if (!cart || lineItems?.length === 0) {
      closeModal();
    }
  }, [cart, lineItems]);

  const handlePlusOrMinus = async (value: 1 | -1, lineItem: ILineItem) => {
    setLoading(true);

    await updateLineItem(lineItem.id, lineItem.quantity + value);

    setLoading(false);
  };

  return (
    <TransitionablePortal open={open} transition={{ animation: 'fade up', duration: 500 }}>
      <Modal
        centered={true}
        open={open}
        onClose={closeModal}
        closeIcon={
          <Image
            style={{
              width: '15px',
              height: '15px',
              float: 'right',
              marginTop: '15px',
              marginRight: '15px',
              cursor: 'pointer',
            }}
            alt="Close Button"
            src={closeSearchBtn}
          />
        }
        size="small"
      >
        <Modal.Header style={{ borderRadius: '10px', fontFamily: `${fonts.REGULAR}` }}>
          {product.name}
        </Modal.Header>
        <Modal.Content style={{ borderRadius: '10px' }}>
          <Segment loading={loading}>
            <List>
              {lineItems?.map((lineItem, index) => {
                return (
                  <LineItemComponent
                    lineItem={lineItem}
                    index={index}
                    currency="usd"
                    CustomQuantitySelector={
                      <PriceQuantityComponent
                        isFree={lineItem.productPrice <= 0}
                        currencyPrefix={'$'}
                        quantity={lineItem.quantity}
                        updateQuantity={value => handlePlusOrMinus(value, lineItem)}
                      />
                    }
                  />
                );
              })}
            </List>
          </Segment>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

export { PriceQuantityComponent, LineItemModal, ProductCardComponent };
