import styled from 'styled-components';
import { fonts } from 'shared/theme';
import { Image } from 'semantic-ui-react';

export const ProductHeaderWrapper = styled.header`
  display: flex;
  margin: 0 1em 0 1em;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const HeaderIcon = styled(Image)`
  height: 25px;
  width: auto;
`;

export const BackChevron = styled(Image)`
  height: 11px;
  width: auto;
`;

export const AddMoreItems = styled.div`
  font-family: ${fonts.REGULAR};
  color: #147c9e;
  letter-spacing: 0;
  font-size: 15px;
  top: 2px;
  position: relative;
  margin-left: 10px;
`;

export const BackButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.button`
  background: none;
  padding: 0;
  border: none;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
`;
