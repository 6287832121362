import React from 'react';
import styled from 'styled-components';
import { fonts } from 'shared/theme';

const Link = styled.a`
  color: white;
  font-family: ${fonts.LIGHT};
  font-size: 15px;
  margin: 0 20px;
`;

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Link target='_blank' rel='noopener' href='https://www.fanfoodapp.com/contact'>
        Feedback
      </Link>
      <Link target='_blank' rel='noopener' href='https://www.fanfoodapp.com/faq'>
        FAQ
      </Link>
      <Link target='_blank' rel='noopener' href='https://www.fanfoodapp.com/legal'>
        Legal
      </Link>
      <Link target='_blank' rel='noopener' href='https://www.fanfoodapp.com/legal'>
        Accessibility
      </Link>
    </Wrapper>
  );
};

export default Footer;
