import React, { Dispatch, SetStateAction } from 'react';
import { ProductCardComponent } from '.';
import { ICategoryWithProducts } from '../../../containers/Suite/Steps';
import IProduct from '../../../shared/models/Product';
import { StyledColumn } from './styled';

interface PackageCardProps {
  category: ICategoryWithProducts;
  currencyPrefix: string;
  selectedProductId: string | undefined;
  setSelectedProductId: Dispatch<SetStateAction<string | undefined>>;
}

const PackageCard = ({
  category,
  currencyPrefix,
  selectedProductId,
  setSelectedProductId,
}: PackageCardProps) => {
  return (
    <>
      {category.products.map((product: IProduct) => (
        <StyledColumn
          fade={!!(selectedProductId && selectedProductId !== product.id)}
          key={product.id}
        >
          <ProductCardComponent
            setSelectedProductId={setSelectedProductId}
            product={product}
            currenctyPrefix={currencyPrefix}
          />
        </StyledColumn>
      ))}
    </>
  );
};

export default PackageCard;
