import closeSearchBtn from 'assets/icons/closeSearchBtn.png';
import { flatMap, isEmpty } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, Grid, Image, Modal, TransitionablePortal } from 'semantic-ui-react';
import { useAppContext } from '../../../containers/App/AppContext';
import { SelectedComboItem } from '../../../containers/ProductConfigurator';
import { useSuiteContext } from '../../../containers/Suite/Steps/SuiteContext';
import { useStoreContext } from '../../../hooks/useStoreContext';
import { useVenueContext } from '../../../hooks/useVenueContext';
import { ProductService } from '../../../services';
import {
  CreateCartDto,
  ISubCategorySelectionDto,
  LineItemDto,
} from '../../../services/CartService';
import { OrderType } from '../../../shared/models/Cart';
import IProduct, { ISubCategory } from '../../../shared/models/Product';
import Store from '../../../shared/models/Store';
import Venue from '../../../shared/models/Venue';
import { getCurrencyPrefix, getProductImage } from '../../../shared/utils';
import ComboProduct from '../../ComboProduct';
import FloatingLabelInput from '../../FloatingLabelInput';
import { PackageDetailContainer, PackageImageContainer } from './styled';

interface IPackageDetail {
  closeDetails: () => void;
  selectedProductId: string | undefined;
  setSelectedProductId: Dispatch<SetStateAction<string | undefined>>;
}

interface IPackageView {
  isMobile: boolean;
  product: IProduct | undefined;
  addToBagButtonDisabled: boolean;
  addToBag: () => Promise<void>;
}

interface IPackageDetails {
  closeDetails: () => void;
  currencyPrefix: string;
  onAddItemToCartClick: (productNotes: string) => Promise<void>;
  product: IProduct | undefined;
  selectedComboItems: SelectedComboItem[];
  setSelectedComboItems: Dispatch<SetStateAction<SelectedComboItem[]>>;
  isMobile: boolean;
  store: Store | undefined;
  venue: Venue | undefined;
}

export const PackageDetail = ({
  selectedProductId,
  closeDetails,
  setSelectedProductId,
}: IPackageDetail) => {
  const { venue } = useVenueContext();
  const { store } = useStoreContext();
  const { user } = useAppContext();
  const { suite, addItemToCart, selectedEvent, isMobile } = useSuiteContext();
  const currencyPrefix = getCurrencyPrefix(venue?.currency);
  const [product, setProduct] = useState<IProduct>();
  const [selectedComboItems, setSelectedComboItems] = useState<SelectedComboItem[]>([]);

  const showImage = product && getProductImage(product);

  useEffect(() => {
    return () => {
      setSelectedProductId(undefined);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!selectedProductId) {
        return;
      }
      try {
        const { data } = await ProductService.GetOneProduct(selectedProductId);

        const selectedProduct = {
          ...data,
          isFree: data.price <= 0,
        };

        setProduct(selectedProduct);

        if (data?.subCategories?.length) {
          const defaultSelection: SelectedComboItem[] = data.subCategories.map((category: any) => {
            const categoryHasOnlyOneProduct = category.subProducts.length === 1;
            return category.subProducts
              .filter((product: any) => product.autoSelect)
              .map((product: any) => {
                return {
                  id: product.id,
                  parentId: product.parentId,
                  productId: product.productId,
                  name: product.name,
                  price: product.price,
                  required: category.required,
                  type: 'PRODUCT',
                  quantity: 1,
                };
              });
          });
          setSelectedComboItems(prev => flatMap([...prev, ...defaultSelection]));
        }
      } catch (error) {
        toast(error?.response?.data?.message);
      }
    })();
  }, [selectedProductId]);

  const buildSubSelections = (): ISubCategorySelectionDto[] => {
    if (!product) return [];

    if (product.subCategories && product.subCategories.length) {
      return product.subCategories.map((c: ISubCategory) => ({
        id: c.id,
        subSelections: selectedComboItems
          .filter(selectedComboItem => selectedComboItem.parentId === c.id)
          .map(({ id, quantity }: SelectedComboItem) => ({
            id,
            quantity,
          })),
      }));
    }

    return [];
  };

  const onAddItemToCartClick = async (productNotes: string) => {
    if (!product || !suite || !user || !store) return;

    const subSelections = buildSubSelections();
    const lineItem: LineItemDto = {
      productId: product.id,
      quantity: 1,
      instructions: productNotes,
      ...(!isEmpty(subSelections) && { subSelections }),
    };

    const payload: CreateCartDto = {
      eventId: selectedEvent?.id,
      lineItem,
      orderType: OrderType.DELIVERY, // suites is delivery-only
      storeId: store.id,
      deliveryLocationId: suite.id,
      userId: user.id,
      ...(selectedEvent?.id && {
        scheduledTime: selectedEvent.scheduledTime || selectedEvent.startDate,
      }),
    };

    await addItemToCart(payload);

    closeDetails();
  };

  return (
    <TransitionablePortal open={true} transition={{ animation: 'fade right', duration: 1000 }}>
      <Modal centered={true} open={true} onClose={closeDetails} size="large">
        <Modal.Content>
          <PackageDetailContainer>
            <Grid className="ui product grid" padded columns={2}>
              <Grid.Row columns={isMobile || !showImage ? 1 : 2}>
                <PackageSelection
                  {...{
                    product,
                    store,
                    venue,
                    onAddItemToCartClick,
                    selectedComboItems,
                    setSelectedComboItems,
                    currencyPrefix,
                    closeDetails,
                    isMobile,
                  }}
                />
              </Grid.Row>
            </Grid>
          </PackageDetailContainer>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

const PackageSelection = ({
  product,
  store,
  venue,
  selectedComboItems,
  setSelectedComboItems,
  currencyPrefix,
  onAddItemToCartClick,
  closeDetails,
  isMobile,
}: IPackageDetails) => {
  const [areSelectionsValid, setAreSelectionsValid] = useState<boolean>(false);
  const [isAlcoholChecked, setAlcoholCheck] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [productNotes, setProductNotes] = useState<string>('');

  const onCheckAlcohol = (e: any, { checked }: any) => setAlcoholCheck(checked);
  const addToBagButtonDisabled = isSubmitting || !areSelectionsValid;

  const getSelectionsAreValid = (): boolean => {
    if (product?.alcohol && !isAlcoholChecked) return false;

    if (product?.subCategories) {
      for (const category of product.subCategories) {
        const selectedCount = selectedComboItems.filter(items => items.parentId === category.id)
          .length;

        if (category.required && selectedCount <= 0) return false;
        if (!category.multiselect && selectedCount > 1) return false;
        if (category.selectionLimit && selectedCount > category.selectionLimit) return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const valid = getSelectionsAreValid();
    setAreSelectionsValid(valid);
  }, [isAlcoholChecked, product, selectedComboItems]);

  const addToBag = async () => {
    setIsSubmitting(true);

    await onAddItemToCartClick(productNotes);

    setIsSubmitting(false);
  };

  if (!store || !product || !venue) {
    return null;
  }
  return (
    <>
      <Grid.Column>
        <Grid.Row columns={2}>
          <Grid.Column className="header">{product?.name}</Grid.Column>
          <Grid.Column>
            <Image
              onClick={closeDetails}
              className="close-icon"
              src={closeSearchBtn}
              style={{ width: 18 }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="meta">{product?.description}</Grid.Row>

        <Grid.Row>
          {product.hasSubProducts && (
            <ComboProduct
              currencyPrefix={currencyPrefix}
              product={product}
              selectedComboItems={selectedComboItems}
              setSelectedComboItems={setSelectedComboItems}
            />
          )}
          {product.alcohol && (
            <div className="product-configurator__alcohol-warning">
              <h5>CONFIRM YOU ARE {venue.alcoholAgeRestriction}+</h5>
              <span>Required</span>
              <Checkbox
                aria-label={`Yes, I am over ${venue.alcoholAgeRestriction} & have a government issued ID as proof.`}
                checked={isAlcoholChecked}
                onChange={onCheckAlcohol}
                label={`Yes, I am over ${venue.alcoholAgeRestriction} & have a government issued ID as proof.`}
              />
              <p>
                {venue.alcoholMessage ||
                  'Upon delivery, one ID will need to be presented for every one beer ordered. No exceptions.'}
              </p>
            </div>
          )}
          {store.orderInstructionsAvailable && (
            <div className="product-configurator__notes">
              <FloatingLabelInput
                id="product-notes-input"
                type="text"
                name="product-notes-input"
                isRequired={false}
                isDisabled={false}
                placeholder="Special Instructions"
                noMargin={true}
                onChange={({ value }: any) => setProductNotes(value)}
                maxLength={75}
                descriptor={
                  productNotes.length > 0
                    ? `${75 - productNotes.length} characters remaining`
                    : 'Optional'
                }
              />
            </div>
          )}
        </Grid.Row>
        {isMobile && (
          <Button fluid className="addtoBag" disabled={addToBagButtonDisabled} onClick={addToBag}>
            Add to Bag
          </Button>
        )}
      </Grid.Column>
      <PackageView {...{ product, addToBag, addToBagButtonDisabled, isMobile }} />
    </>
  );
};

const PackageView = ({ product, isMobile, addToBagButtonDisabled, addToBag }: IPackageView) => {
  if (isMobile) {
    return null;
  }

  const productImage = product && getProductImage(product);

  return (
    <Grid.Column stretched>
      <PackageImageContainer>
        <Image spaced ui wrapped fluid src={productImage ?? ''} />
        <div className="addToBag">
          <Button fluid className="addtoBag" disabled={addToBagButtonDisabled} onClick={addToBag}>
            Add to Bag
          </Button>
        </div>
      </PackageImageContainer>
    </Grid.Column>
  );
};
