import 'firebase/analytics';
import firebaseInstance from 'firebase/app';
import React, { ReactChild, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { Event, EventsService } from 'services/EventsService';
import { IWhiteLabelContext, SettingsService } from 'services/SettingsService';
import { colors } from 'shared/theme';
import styled, { ThemeProvider } from 'styled-components';
import { Client } from 'twilio-chat';
import createContextWrapper from '../../hooks/CreateContext';
import { CartService } from '../../services/CartService';
import { ChatService, GetAccessTokenType } from '../../services/ChatService';
import { NotificationService } from '../../services/NotificationService';
import { firebaseConfig } from '../../shared/constants';
import ICart, { IDeliveryLocation, OrderType } from '../../shared/models/Cart';
import OpenTab from '../../shared/models/OpenTab';
import GivexData from '../../shared/models/Givex';
import { Order } from '../../shared/models/Order';
import Store from '../../shared/models/Store';
import Theme from '../../shared/models/Theme';
import IUser from '../../shared/models/User';
import Venue, { IVenueLocation } from '../../shared/models/Venue';
import {
  getLastVisit,
  getSavedCart,
  getSessionValues,
  hasValidSession,
  removeCart,
  saveCart,
  saveLastVisit,
} from '../../shared/SessionAuthPersist';
firebaseInstance.initializeApp(firebaseConfig);

export const fbAnalytics = firebaseInstance.analytics();

const LoaderContainer = styled.div<{ show: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  display: ${props => (props.show ? 'flex' : 'none')};
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  justify-content: center;
  align-items: center;

  .ui.loader:after {
    border-color: ${({ theme }) => theme.primaryColor} transparent transparent;
  }
`;

interface IAppContext {
  buildingEatsVenueId: string | undefined;
  cart: ICart | undefined;
  chatClient: any;
  deliveryLocation: IDeliveryLocation[] | undefined;
  deliveryLocationId: string | undefined;
  deliveryLocationInput: string | undefined;
  event: Event | undefined;
  order: Order | undefined;
  orderCancelled: Order | undefined;
  orderType: OrderType;
  setBuildingEatsVenueId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCart: (cart: ICart | undefined) => void;
  setDeliveryLocation: React.Dispatch<React.SetStateAction<IDeliveryLocation[] | undefined>>;
  setDeliveryLocationId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDeliveryLocationInput: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEvent: React.Dispatch<React.SetStateAction<Event | undefined>>;
  setOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderCancelled: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderType: React.Dispatch<React.SetStateAction<OrderType>>;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setStore: React.Dispatch<React.SetStateAction<Store | undefined>>;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  setVenue: React.Dispatch<React.SetStateAction<Venue | undefined>>;
  setVenueLocations: React.Dispatch<React.SetStateAction<IVenueLocation[] | undefined>>;
  setOpenTab: React.Dispatch<React.SetStateAction<OpenTab | undefined>>;
  openTab: OpenTab | undefined;
  givexData: GivexData | undefined;
  setGivexData: React.Dispatch<React.SetStateAction<GivexData | undefined>>;
  store: Store | undefined;
  theme: Theme;
  user: IUser | undefined;
  venue: Venue | undefined;
  venueLocations: IVenueLocation[] | undefined;
  whiteLabelContext: IWhiteLabelContext | undefined;
}

type Props = {
  children: ReactChild;
};

const [useAppContext, AppContextProvider] = createContextWrapper<IAppContext>();

const AppProvider = ({ children }: Props) => {
  const [cart, setCartState] = useState<ICart | undefined>(undefined);
  const [venue, setVenue] = useState<Venue | undefined>(undefined);
  const [store, setStore] = useState<Store | undefined>(undefined);
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [chatClient, setChatClient] = useState({});
  const [deliveryLocation, setDeliveryLocation] = useState<IDeliveryLocation[] | undefined>(
    undefined,
  );
  const [deliveryLocationId, setDeliveryLocationId] = useState<string | undefined>();
  const [deliveryLocationInput, setDeliveryLocationInput] = useState<string | undefined>();
  const [venueLocations, setVenueLocations] = useState<IVenueLocation[] | undefined>(undefined);
  const [orderCancelled, setOrderCancelled] = useState<Order | undefined>(undefined);
  const [whiteLabelContext, setWhiteLabelContext] = useState<IWhiteLabelContext | undefined>(
    undefined,
  );
  const [theme, setTheme] = useState<Theme>({ primaryColor: colors.primary });

  const [event, setEvent] = useState<Event | undefined>(undefined);
  const [buildingEatsVenueId, setBuildingEatsVenueId] = useState<string | undefined>(undefined);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.PICKUP);
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [openTab, setOpenTab] = useState<OpenTab | undefined>(undefined);
  const [givexData, setGivexData] = useState<GivexData | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const context = await SettingsService.GetSettingsFromUrl();

      (async () => {
        const savedCart = getSavedCart();
        if (!cart && savedCart) {
          try {
            const { data } = await CartService.GetCart(savedCart.id);
            if (venue && venue.id !== data.venueId) {
              return;
            }
            setCart(data);
            setOrderType(data.orderType);
            if (data.orderType === OrderType.DELIVERY) {
              setDeliveryLocation(data.location);
              setDeliveryLocationId(data.deliveryLocationId);
            }
            if (data.eventId) {
              const { data: cartEvent } = await EventsService.getEvent(data.eventId);
              setEvent({
                ...cartEvent,
                scheduledTime: data.scheduledTime,
              });
            }
          } catch (error) {}
        }
      })();

      setWhiteLabelContext(context);
      setShowLoader(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (cart && user && cart.userId !== user.id) {
        const { data } = await CartService.UpdateCart(cart.id, {
          userId: user.id,
        });

        setCart(data);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (venue && event?.venueId && venue.id !== event.venueId) {
      setEvent(undefined);
    }
  }, [venue]);

  useEffect(() => {
    const newTheme: Theme = {
      primaryColor: colors.primary,
    };

    if (!venue) {
      setTheme(newTheme);
      return;
    }

    // overwrite any available props with `venue.theme`
    Object.assign(newTheme, venue.theme);

    setTheme(newTheme);
  }, [venue]);

  const root: any = document.getElementById('root');

  if (root) {
    if (showLoader) {
      root.style.overflow = 'hidden';
    } else {
      root.style.overflow = '';
    }
  }

  const setCart = (cart: ICart | undefined) => {
    const savedCart = getSavedCart();
    if (cart && (!savedCart || cart.id !== savedCart.id)) {
      saveCart({ id: cart.id });
    }
    if (!cart) {
      removeCart();
    }
    setCartState(cart);
  };

  useEffect(() => {
    const checkLastVisit = () => {
      const lastVisitTimespan = getLastVisit();
      if (!lastVisitTimespan) {
        firebaseInstance.analytics().logEvent('first_open');
      }
      saveLastVisit(Date.now());
    };

    const setSavedUser = () => {
      if (hasValidSession()) {
        const savedSession = getSessionValues();
        setUser(savedSession);
      }
    };
    checkLastVisit();
    setSavedUser();
  }, []);

  useEffect(() => {
    const getAccessToken = async (identifier: string) => {
      const payload: GetAccessTokenType = {
        username: identifier,
      };
      const {
        data: { jwt },
      } = await ChatService.GetAccessToken(payload);

      return jwt;
    };

    const initChatClient = async (identifier: string) => {
      try {
        const jwt = await getAccessToken(identifier);
        const c = await Client.create(jwt);
        setChatClient(c);

        c.on('tokenAboutToExpire', async () => {
          try {
            const token = await getAccessToken(identifier);
            const updatedClient = await c.updateToken(token);

            setChatClient(updatedClient);
          } catch (error) {
            toast(error.response.data.message);
          }
        });

        c.on('pushNotification', obj => {
          // tslint:disable-next-line: no-console
          console.log('pushNotification', obj);
        });
        return;
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error);
      }
    };

    const initializeListeners = async () => {
      if (hasValidSession() && user?.phoneNumber) {
        await initChatClient(user.phoneNumber);
        await NotificationService.startNotificationService(user.id);
        if (firebaseInstance.messaging.isSupported()) {
          const messaging = firebaseInstance.messaging();
          messaging.onMessage(async payload => {
            // toast(`${payload.data.twi_title}. ${payload.data.twi_body}`);
          });
        }
      }
    };

    initializeListeners();
  }, [user]);

  return (
    <AppContextProvider
      value={{
        cart,
        chatClient,
        deliveryLocation,
        deliveryLocationId,
        deliveryLocationInput,
        event,
        order,
        orderCancelled,
        orderType,
        setCart,
        setDeliveryLocation,
        setDeliveryLocationId,
        setDeliveryLocationInput,
        setEvent,
        setOrder,
        setOrderCancelled,
        setOrderType,
        setShowLoader,
        setStore,
        setTheme,
        setUser,
        setVenue,
        setVenueLocations,
        setOpenTab,
        openTab,
        givexData,
        setGivexData,
        store,
        theme,
        user,
        venue,
        venueLocations,
        whiteLabelContext,
        buildingEatsVenueId,
        setBuildingEatsVenueId,
      }}
    >
      <LoaderContainer show={showLoader}>
        <Loader active inline="centered" />
      </LoaderContainer>
      {whiteLabelContext && (
        <>
          <ThemeProvider {...{ theme }}>{children}</ThemeProvider>
          <style>{`
            :root {
              --color-primary: ${theme.primaryColor}; // this works with sass variables
            }
          `}</style>
        </>
      )}
    </AppContextProvider>
  );
};

export const useBuildingEatsContext = () => {
  const { buildingEatsVenueId, setBuildingEatsVenueId } = useAppContext();
  return { buildingEatsVenueId, setBuildingEatsVenueId };
};

export const useEventContext = () => {
  const { event, setEvent } = useAppContext();

  return { event, setEvent };
};

export { useAppContext, AppProvider, AppContextProvider };
