import ICart, { IDeliveryLocation, OrderType, PromoType } from '../shared/models/Cart';
import { ApiResponse, APIService, ApiVersion } from './APIService';

const basePath = '/cart';
export interface CreateCartDto {
  deliveryLocationId?: string;
  deliveryLocationInput?: string;
  eventId?: string;
  instructions?: string;
  lineItem?: LineItemDto;
  location?: IDeliveryLocation[];
  orderType: OrderType;
  scheduledTime?: string;
  storeId: string;
  userId?: string;
}

export interface LineItemDto {
  instructions?: string;
  productId: string;
  quantity: number;
  subSelections?: ISubCategorySelectionDto[];
}

export interface ISubCategorySelectionDto {
  id: string;
  subSelections?: ISubProductSelectionDto[];
}

export interface ISubProductSelectionDto {
  id: string;
  quantity: number;
}

export interface UpdateCartDto {
  deliveryLocationId?: string;
  eventId?: string | null;
  location?: IDeliveryLocation[];
  orderType?: OrderType;
  scheduledTime?: string | null;
  instructions?: string;
  userId?: string;
  gameDayOrder?: boolean;
}

export interface UpdateLineItemDto {
  quantity: number;
}

export class CartService {
  static async CreateCart(payload: CreateCartDto): Promise<ApiResponse<ICart>> {
    return await APIService.postData(basePath, payload, ApiVersion.V2);
  }

  static async UpdateCart(cartId: string, payload: UpdateCartDto): Promise<ApiResponse<ICart>> {
    return await APIService.updateData(`${basePath}/${cartId}`, payload, ApiVersion.V2);
  }

  static async GetCart(id: string): Promise<ApiResponse<ICart>> {
    return await APIService.getData(`${basePath}/${id}`, undefined, ApiVersion.V2);
  }

  static async DeleteCart(id: string): Promise<any> {
    return await APIService.deleteData(`${basePath}/${id}`, ApiVersion.V2);
  }

  static async AddLineItem(cartId: string, payload: LineItemDto): Promise<ApiResponse<ICart>> {
    return await APIService.postData(`${basePath}/${cartId}/items`, payload, ApiVersion.V2);
  }

  static async UpdateLineItem(
    cartId: string,
    itemId: string,
    payload: UpdateLineItemDto,
  ): Promise<ApiResponse<ICart>> {
    return await APIService.updateData(
      `${basePath}/${cartId}/items/${itemId}`,
      payload,
      ApiVersion.V2,
    );
  }

  static async ApplyPromo(cartId: string, payload: PromoType): Promise<ApiResponse<ICart>> {
    return await APIService.postData(`${basePath}/${cartId}/promo`, payload, ApiVersion.V2);
  }
}
