import { useEffect, useState } from 'react';

export default function useWindowHeight() {
  const [height, setHeight] = useState(
    Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
  );

  useEffect(() => {
    const handleResize = () =>
      setHeight(Math.max(document.documentElement.clientHeight, window.innerHeight || 0));
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return height;
}
