import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ProductContextProvider } from 'containers/ProductListing/product-context';
import React from 'react';
import { SquareProvider } from '../../components/Square/context';
import Square from '../../components/Square/utils/square';
import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID, STRIPE_API_KEY } from '../../shared/constants';
import { SuiteContextProvider } from '../Suite/Steps/SuiteContext';
import { AppProvider } from './AppContext';
import AppRouter from './AppRouter';

const stripePromise = loadStripe(STRIPE_API_KEY);
const squarePromise = Square(SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID);

class App extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <AppProvider>
        <Elements stripe={stripePromise}>
          <SquareProvider square={squarePromise}>
            <SuiteContextProvider>
              <ProductContextProvider>
                <AppRouter />
              </ProductContextProvider>
            </SuiteContextProvider>
          </SquareProvider>
        </Elements>
      </AppProvider>
    );
  }
}

export default App;
