import React from 'react';
import { Loader } from 'semantic-ui-react';
import { debounce } from 'underscore';
import {
  SearchInputContainer,
  InputContainer,
  StyledCloseIcon,
  StyledSearchIcon,
  StyledInput,
} from './styled';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import BlueSearchIcon from 'assets/icons/search-blue.png';
import closeGray from 'assets/icons/closeGray.png';

const SearchInput = (props: any) => {
  const { loading, clearSearch, isTyping, onChange, onKeyDown } = props;

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ) => {
    onChange({
      target: {
        value: data.value,
      },
    });
  };

  return (
    <SearchInputContainer>
      <InputContainer className="search-control">
        {isTyping && <StyledCloseIcon onClick={clearSearch} src={closeGray} alt='Close Icon' />}
        <StyledInput
          fluid
          id='input-search'
          onChange={debounce(handleSearchChange, 500)}
          onKeyDown={onKeyDown}
          loading={false}
          transparent
          autoComplete='off'
          placeholder='e.g., location name, address, zip, etc.'
          autoFocus
          size='huge'
          aria-label='e.g., location name, address, zip, etc.'
        />
        {!loading && <StyledSearchIcon src={BlueSearchIcon} alt='Search Icon' />}
        {loading && <Loader active inline size='small' />}
      </InputContainer>
    </SearchInputContainer>
  );
};
export default SearchInput;
