import CaretRightTeal from 'assets/icons/caretRightTeal.png';
import creditCardIconGreen from 'assets/icons/creditCardIconGreen.png';
import TrashIcon from 'assets/icons/trash.png';
import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Image, List, Modal, Segment } from 'semantic-ui-react';
import { UserService } from 'services/UserService';
import StripeForm from '../../../components/StripeForm';
import { PaymentGateway } from '../../../shared/models/Venue';
import { useAppContext } from '../../App/AppContext';
import {
  CreditCardContainer,
  SavedPaymentsContainer,
  SlideOptions,
  StyledContainer,
} from './styled';

function Payment() {
  const { user } = useAppContext();
  const [creditCards, setCreditCards] = useState<any[]>([]);
  const [openAddCC, setOpenAddCC] = useState(false);
  const [deployedCard, setDeployedCard] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!user) {
        return;
      }
      try {
        setIsLoading(true);
        const {
          data: { data },
        } = await UserService.GetStoredCards(user.id);
        setCreditCards(data);
        setIsLoading(false);
      } catch (error) {
        toast(error.response.data.message);
        setIsLoading(false);
      }
    };
    init();
  }, [user]);

  const onDeleteCard = async (id: string) => {
    if (!user) {
      return;
    }
    try {
      setIsLoading(true);
      await UserService.DeleteStoredCard(user.id, id);
      const newCards = creditCards.filter((card: any) => card.id !== id);
      setCreditCards(newCards);
      setIsLoading(false);
    } catch (error) {
      toast(error.response.data.message);
      setIsLoading(false);
    }
  };

  const toggleDeployed = (id: string) => () => {
    if (deployedCard === id) {
      setDeployedCard(undefined);
    } else {
      setDeployedCard(id);
    }
  };

  const saveCC = async (token: string | undefined) => {
    setIsLoading(true);
    if (token && user) {
      try {
        const { data } = await UserService.SaveCard(user.id, {
          paymentGateway: PaymentGateway.STRIPE,
          paymentMethodId: token,
        });
        setCreditCards((cards: any) => [...cards, data]);
      } catch (error) {
        toast(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <StyledContainer>
      <ProfileHeader title="Payment" />
      <SavedPaymentsContainer>
        <Segment loading={isLoading}>
          <h2>Saved Payment Methods</h2>
          <List divided verticalAlign="middle">
            {creditCards &&
              creditCards.map((cc: any) => (
                <List.Item key={cc.id}>
                  <CreditCardContainer deployed={deployedCard === cc.id}>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column width={10} className="number">
                          {cc.card.brand}
                          <span>....</span>
                          {cc.card.last4}
                        </Grid.Column>
                        <Grid.Column width={6} className="expiracy">
                          Exp: {cc.card.exp_month}/{cc.card.exp_year.toString().substring(2, 4)}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <SlideOptions deployed={deployedCard === cc.id}>
                      <Image
                        className="caret"
                        src={CaretRightTeal}
                        onClick={toggleDeployed(cc.id)}
                      />
                      <div className="actions">
                        <Image
                          className="trash"
                          onClick={() => onDeleteCard(cc.id)}
                          src={TrashIcon}
                        />
                      </div>
                    </SlideOptions>
                  </CreditCardContainer>
                </List.Item>
              ))}
          </List>
        </Segment>
      </SavedPaymentsContainer>
      <Modal size="tiny" open={openAddCC} onClose={() => setOpenAddCC(false)} closeIcon>
        <Modal.Content>
          <StripeForm onSave={saveCC} setOpenCC={setOpenAddCC} />
        </Modal.Content>
      </Modal>
      <Segment>
        <h2>Add Payment Method</h2>
        <Button className="add-cc-btn" onClick={() => setOpenAddCC(true)} circular>
          <Image src={creditCardIconGreen} />
        </Button>
      </Segment>
    </StyledContainer>
  );
}

export default Payment;
