import React from 'react';
import {
  CardContainer,
  VenueName,
  VenueInfoContainer,
  DistanceSpan,
  Location,
  BottomLink,
  BottomContainer,
} from './styled';
import caretRightTeal from 'assets/icons/blue-arrow.png';

type VenueProps = {
  venue: any;
  onVenueSelect: (venueId: string) => void;
};

const Venue = ({ venue, onVenueSelect }: VenueProps) => {
  const { name, city, state, id, distance } = venue;
  return (
    <CardContainer onClick={() => onVenueSelect(id)}>
      <VenueInfoContainer>
        <div>
          <VenueName>{name}</VenueName>
          <Location>
            {city}, {state}
          </Location>
        </div>
        {distance > 0 && <DistanceSpan>{(distance || 0).toFixed(2)} Mi</DistanceSpan>}
      </VenueInfoContainer>
      <BottomContainer>
        <BottomLink>
          ORDER NOW <img src={caretRightTeal} alt='arrow-icon' />
        </BottomLink>
      </BottomContainer>
    </CardContainer>
  );
};

Venue.defaultProps = {
  venue: {},
  onVenueSelect: () => {},
};

export default Venue;
