import React from 'react';

import { colors, fonts } from '../../../shared/theme';
import BookingTool from '../Forms/BookingTool';
import chicagoRiverBlur from '../assets/chicagoRiverBlur.jpg';
import Footer from '../Footer';
import styled from 'styled-components';
import BELogo from '../assets/BE_logo.png';
import { Link } from 'react-router-dom';
import { BasePath } from 'shared/constants';

const AppointmentsBackground = styled.div`
  background: url(${chicagoRiverBlur}) no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  background-size: cover;

  @media (min-height: 1000px) {
    height: 100%;
  }
`;

const AppointmentsHeader = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
  color: ${colors.white};
  font-family: ${fonts.MEDIUM};
  font-size: 26px;
  text-align: center;
`;

const AppointmentsFooter = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 50vw;
`;

const Appointments = () => {
  return (
    <AppointmentsBackground>
      <div>
        <LogoContainer>
          <Link to={`/${BasePath.BUILDING_EATS}`}>
            <Logo src={BELogo} alt="buildingeats logo" />
          </Link>
        </LogoContainer>

        <AppointmentsHeader>Select a time to schedule your Get Started session</AppointmentsHeader>
        <BookingTool />
      </div>
      <AppointmentsFooter>
        <Footer transparent={true} />
      </AppointmentsFooter>
    </AppointmentsBackground>
  );
};

export default Appointments;
