import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const Message = styled.p`
  font-size: 16px;
  text-align: center;
  font-family: ${fonts.REGULAR};
  color: ${colors.charcoalGrey};
  font-weight: normal;
`;

export const Footer = styled.p`
  font-size: 14px;
  text-align: center;
  font-family: ${fonts.REGULAR};
  color: ${colors.brownishGrey};
  font-weight: normal;
`;
