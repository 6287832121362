import React from 'react';
import { useHistory } from 'react-router-dom';
import Store from '../../../../../shared/models/Store';
import CheckoutHeader from '../CheckoutHeader';
import { BackChevron, BackChevronButton } from '../ProductHeader/styled';
import { ProductHeaderWrapper } from './styled';

type CartHeaderProps = {
  store: Store | undefined;
  onClick: () => void;
};

const CartHeader = ({ onClick, store }: CartHeaderProps) => {
  const history = useHistory();

  return (
    <ProductHeaderWrapper>
      <BackChevronButton shaded={false} aria-label="Go back" onClick={onClick || history.goBack}>
        <BackChevron title="Go back icon" />
      </BackChevronButton>
      <CheckoutHeader store={store} />
    </ProductHeaderWrapper>
  );
};

export default CartHeader;
