/**
 * Collection of functions to handle storage of the session
 */
import decodeJwt from 'jwt-decode';

export const SESSION_KEY = '_sessionInfo';
const LAST_VISIT = '_last_visit';
const CART_INFO = '_cart_info';

declare const window: any;

export const getLocalValue = (key: string) => {
  const localValue = window.localStorage.getItem(key);
  if (localValue === 'undefined' || localValue === undefined || localValue === null) {
    return null;
  }
  return localValue;
};

export const getSessionStorageValue = (key: string) => {
  const value = window.sessionStorage.getItem(key);
  return value ?? null;
};

export const saveSessionStorageValue = (key: string, value: any) =>
  window.sessionStorage.setItem(key, JSON.stringify(value));

const removeSesionStorageValue = (key: string) => window.sessionStorage.removeItem(key);

/**
 * Returns the stored session as json
 * @export
 * @returns {Object} the session value
 */
export const getSessionValues = () => {
  return JSON.parse(getLocalValue(SESSION_KEY));
};

/**
 * Returns the last visit date
 * @export
 * @returns {number} the timespan value
 */
export const getLastVisit = () => {
  // tslint:disable-next-line: radix
  return parseInt(getLocalValue(LAST_VISIT));
};

/**
 * Save the last visit date to easy access later
 * @export
 * @param {number} timespan the timespan value
 */
export const saveLastVisit = (timespan: number) => {
  window.localStorage.setItem(LAST_VISIT, timespan);
};

/**
 * Save the session value to easy access later
 * @export
 * @param {String} session the session to store
 */
export const saveSessionValues = (session: any) => {
  window.localStorage.setItem(SESSION_KEY, JSON.stringify(session));
};

/**
 * Validates the token
 * 1. Checks if it contains the user id
 * 2. Checks if it has expired
 */
export function validToken(token: string) {
  const jwtInfo: any = decodeJwt(token);
  if (!jwtInfo.id) {
    return false;
  }

  if (jwtInfo.exp) {
    const now = Date.now();
    const expires = jwtInfo.exp * 1000;

    if (expires < now) {
      return false;
    }
  }
  return true;
}

/**
 * Validate the stored session
 * 1. Check if exists
 * 2. Check if the jwt token has expired
 * @export
 * @returns {Boolean} true: valid session, false: invalid session
 */
export const hasValidSession = () => {
  const session = getSessionValues();
  if (session && session.authToken) {
    return validToken(session.authToken);
  }
  return false;
};

/**
 * Update the session stored session value from a JWT token
 * @export
 * @param {String} token the JWT token value
 */
export const updateSessionValuesFromJWT = (token: string) => {
  const jwtInfo: any = decodeJwt(token);
  const session = {
    authToken: token,
    createdAt: jwtInfo.iat,
    expiresIn: jwtInfo.exp,
    user: { id: jwtInfo.id },
  };
  window.localStorage.setItem(SESSION_KEY, JSON.stringify(session));
};

/**
 * Clears all values related to the stored session
 * @export
 */
export const clearSession = () => {
  window.localStorage.removeItem(SESSION_KEY);
  window.localStorage.removeItem(LAST_VISIT);
  window.sessionStorage.removeItem(CART_INFO);
};

/**
 * Returns the saved cart id
 * @export
 * @returns {string} the cart id
 */
export const getSavedCart = () => {
  return JSON.parse(getSessionStorageValue(CART_INFO));
};

/**
 * Save the cart content
 * @export
 * @param {string} cartId of the cart to store
 */
export const saveCart = (cart: any) => {
  saveSessionStorageValue(CART_INFO, cart);
};

export const removeCart = () => {
  removeSesionStorageValue(CART_INFO);
};
