import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const CardContainer = styled.div`
  background-color: #fff;
  max-width: 30em;
  border-radius: 10px;
  padding: 21px;
  width: inherit;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 7em;
  }
`;

export const VenueInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VenueName = styled.div`
  font-size: 18px;
  font-family: ${fonts.MEDIUM};
  color: ${({ theme }) => theme.primaryColor};
  text-align: left;
`;

export const Location = styled.div`
  font-size: 14px;
  font-family: ${fonts.LIGHT};
  color: ${colors.charcoalGrey};
  margin-top: 5px;
  text-align: left;
`;

export const DistanceSpan = styled.span`
  font-size: 14px;
  font-family: ${fonts.MEDIUM};
  color: ${colors.charcoalGrey};
`;

export const BottomLink = styled.span`
  font-size: 12px;
  margin-left: 10px;
  color: ${colors.niceBlue};
  font-family: ${fonts.REGULAR};

  img {
    width: 6px;
    top: 1px;
    position: relative;
  }
`;

export const BottomContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;
