import React from 'react';

import { colors, fonts } from '../../../shared/theme';
import styled from 'styled-components';

const Button = styled.button<{ color: any; disabled?: boolean }>`
  background-color: ${props =>
    props.disabled ? colors.charcoalGrey : props.color ? props.color : props.theme.primaryColor};
  color: ${colors.white};
  font-family: ${fonts.MEDIUM};
  padding: 14px 18px;
  border-radius: 10px;
  border: none;
  text-transform: uppercase;
  font-size: 16.5px;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
`;

type ButtonProps = {
  text: string;
  color?: string;
  onClick?: any;
  disabled?: boolean;
};

const StyledButton = ({ text, color, onClick, disabled }: ButtonProps) => {
  return (
    <Button onClick={onClick} color={color ? color : null} disabled={disabled}>
      {text}
    </Button>
  );
};

export default StyledButton;
