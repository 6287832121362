import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default function RouterTransition({ count, timeout, children }: any) {
  return (
    <TransitionGroup component={null}>
      <CSSTransition in={true} appear={true} key={count} timeout={timeout} classNames={'slide'}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}
