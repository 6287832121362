export enum AvailabilityType {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
  ALL = 'ALL',
}

export default interface IProduct {
  active: boolean;
  alcohol: boolean;
  availabilityType: AvailabilityType;
  categoryId: string;
  description: string;
  globalProduct: any;
  hasSubProducts: boolean;
  id: string;
  imageUrl?: string;
  isFree: boolean;
  name: string;
  price: number;
  stock: number;
  subCategories: ISubCategory[];
  countAsInventory: boolean;
}

export interface ISubCategory {
  categoryId: string;
  id: string;
  multiselect: boolean;
  name: string;
  required: boolean;
  selectionLimit: number;
  subProducts: ISubProduct[];
}

export interface ISubProduct {
  autoSelect: boolean;
  categoryId: string;
  id: string;
  name: string;
  parentId: string;
  price: number;
  productId: string;
  quantityLimit: number;
  active : boolean;
}
