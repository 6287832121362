import styled from 'styled-components';
import { colors } from 'shared/theme';
import { Divider, Image, Input } from 'semantic-ui-react';

export const SearchInputContainer = styled.div`
  margin-top: 46px;
  display: flex;
  justify-content: center;
`;

export const StyledCloseIcon = styled.img`
  width: 12.5px;
  height: 12.5px;
  margin-right: 18px !important;
`;

export const InputContainer = styled.div`
  background-color: #fff;
  width: 30em;
  border-radius: 30px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .input {
    flex: 1;
  }
  img,
  .loader {
    margin-right: 1em;
    margin-left: 0.4em;
  }
  .loader {
    margin-left: 1em !important;
  }
  input {
    margin-top: 0.75em !important;
    margin-bottom: 0.75em !important;
    color: ${colors.charcoalGrey} !important;
    padding-left: 1em !important;
  }
  input::placeholder {
    color: ${colors.charcoalGrey} !important;
    opacity: 0.78;
    font-style: italic;
  }

  @media (max-width: 480px) {
    width: 22em;
  }
`;

export const DividerContainer = styled(Divider)`
  border-bottom: 2px solid rgba(255, 255, 255, 0.8) !important;
  margin: 0 0 2em 0 !important;
`;

export const StyledSearchIcon = styled(Image)`
  width: 23px;
  height: 23px;
  margin-left: 20px !important;

  @media (max-width: 480px) {
    width: 18px;
    height: 18px;
    margin-left: 15px !important;
  }
`;

export const StyledInput = styled(Input)`
  @media (max-width: 320px) {
    input {
      ::placeholder,
      ::-webkit-input-placeholder {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 410px) and (min-width: 321px) {
    input {
      ::placeholder,
      ::-webkit-input-placeholder {
        font-size: 16px;
      }
    }
  }

  @media (min-width: 411px) {
    input {
      ::placeholder,
      ::-webkit-input-placeholder {
        font-size: 19px;
      }
    }
  }
`;
