import { Image } from 'semantic-ui-react';
import { colors } from 'shared/theme';
import styled, { css, keyframes } from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  margin: 0 1em 0 1em;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 10px;
  position: relative;
`;

export const BackChevron = styled.i<{ shaded?: boolean; alt?: string; className?: string }>`
  position: absolute;
  width: 0.9em;
  height: 0.9em;
  background: transparent;
  border-top: 2px solid ${({ shaded, theme }) => (shaded ? '#ffff' : theme.primaryColor)};
  border-right: 2px solid ${({ shaded, theme }) => (shaded ? '#ffff' : theme.primaryColor)};
  box-shadow: 0 0 0 lightgrey;
  margin-left: 0.1em;
  transform: translate3d(0, -50%, 0) rotate(-135deg);

  &.order-placed {
    margin-top: 0.5em;
  }
`;

export const HeaderIcon = styled(Image)`
  height: 25px;
  width: auto;
`;

const shadedIconWrapper = `
justify-content: center;
  width: auto;
  padding: 0 1em 0 1em;
  height: 40px;
  position: absolute;
  right: -1em;
  background: ${colors.charcoalGrey};
  border-radius: 5px 0 0 5px;
`;

export const IconWrapper = styled.div<{ shaded: boolean; columnNumber?: number }>`
  display: grid;
  grid-template-columns: ${({ columnNumber }) =>
    columnNumber ? `repeat(${1 + columnNumber}, 1fr)` : '1fr'};

  grid-gap: 5px;

  ${({ shaded }) =>
    shaded
      ? css`
          ${shadedIconWrapper}
        `
      : ''}
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem 0 1rem;
`;

const jump = keyframes`
  0% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(-10px)
  }
  0% {
    transform: translateY(0)
  }
`;

const jumpAnimation = css`
  animation: ${jump} 300ms ease-in-out forwards;
`;

type ButtonWrapper = {
  animateBag?: boolean;
};

export const ButtonWrapper = styled.button.attrs<ButtonWrapper>(({ className }) => ({
  className,
}))<ButtonWrapper>`
  background: none;
  padding: 0;
  border: none;
  position: relative;

  ${({ animateBag }) => (animateBag ? jumpAnimation : null)}

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
`;

const BackButtonShade = `
  background: ${colors.charcoalGrey};
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -3px;
`;

export const BackChevronButton = styled.button<{ shaded: boolean }>`
  background: none;
  padding: 0;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }

  ${({ shaded }) =>
    shaded
      ? css`
          ${BackButtonShade}
        `
      : ''}
`;
