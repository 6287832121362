import CircleMinusIcon from 'assets/icons/circleMinus.png';
import CirclePlusIcon from 'assets/icons/circlePlus.png';
import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

interface QuantityControlProps {
  canDecrementQuantity: boolean;
  changeQuantity: (value: number) => void;
  quantity: number;
  showAddButton?: boolean;
}

export const TrashIcon = styled(Icon)`
  &.icon.trash {
    width: 15px;
    height: auto;
    display: block;
    top: -2px;
    position: relative;
    color: #7b7b7b;
  }
`;

const CirclePlus = styled(Image)`
  height: 19px;
  width: 19px;
  max-width: unset !important;
`;

type CircleButton = {
  hide?: boolean;
};

const CircleButton = styled.button.attrs<CircleButton>(({ className }) => ({
  className,
}))<CircleButton>`
  border: none;
  background: none;
  padding: 0;
  position: relative;
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }

  &:after {
    content: '';
    position: absolute;
    right: -60%;
    top: -60%;
    width: 45px;
    height: 45px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Quantity = styled.span<{ hide?: boolean; subtle?: boolean }>`
  font-family: ${fonts.MEDIUM};
  color: ${({ subtle }) => (subtle ? 'lightgray' : colors.charcoalGrey)} !important;
  font-size: 16px;
  margin: 0 10px;
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
`;

const MinusAndQuantityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QuantityControl = ({
  changeQuantity,
  canDecrementQuantity,
  quantity,
  showAddButton = true,
}: QuantityControlProps) => (
  <Container>
    <MinusAndQuantityContainer>
      <CircleButton
        hide={!canDecrementQuantity || quantity < 1}
        onClick={e => {
          e.stopPropagation();
          changeQuantity(-1);
        }}
        aria-label="Decrease item count by 1"
        className="minusButton"
      >
        {quantity > 1 ? (
          <CirclePlus src={CircleMinusIcon} alt="minus icon" />
        ) : (
          <TrashIcon className="trash" alt="trash icon" />
        )}
      </CircleButton>
      <Quantity data-testid="quantity" subtle={quantity <= 0}>
        x{quantity}
      </Quantity>
    </MinusAndQuantityContainer>
    <CircleButton
      hide={!showAddButton}
      onClick={e => {
        e.stopPropagation();
        changeQuantity(1);
      }}
      aria-label="Increase item count by 1"
      className="plusButton"
    >
      <CirclePlus src={CirclePlusIcon} alt="plus icon" />
    </CircleButton>
  </Container>
);
