import DownChevron from 'assets/icons/chevronDownGrey.png';
import SubmitButton from 'components/Atoms/Button/Submit';
import AnimatedModal from 'components/Molecules/AnimatedModal/AnimatedModal';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useDeliveryLocation } from '../../../../hooks/Location';
import { IDeliveryLocation } from '../../../../shared/models/Cart';
import {
  FieldLabel,
  ModalSubTitle,
  PlaceHolder,
  PlaceholderWrapper,
  StyledDownChevron,
  StyledField,
  StyledInput,
  StyledSelect,
  StyledSelectWithoutPlaceholder,
  SubmitButtonWrapper,
} from './styled';

type ModalLocationProps = {
  showModal: boolean;
  handleOnCloseModal: () => void;
  handleSubmitLocation: (location: IDeliveryLocation[] | undefined) => Promise<void>;
};

const ModalLocation = ({
  showModal,
  handleOnCloseModal,
  handleSubmitLocation,
}: ModalLocationProps) => {
  const { deliveryLocation, validDeliveryLocations } = useDeliveryLocation();
  const [disabled, setDisabled] = useState(true);
  const [userLocation, setUserLocation] = useState<IDeliveryLocation[]>(deliveryLocation ?? []);

  const toggleParentLocation = (!isEmpty(userLocation) && userLocation[0].name) || '';

  useEffect(() => {
    if (showModal && deliveryLocation && deliveryLocation.length > 0) {
      setUserLocation(deliveryLocation);
    }
  }, [deliveryLocation, showModal]);

  useEffect(() => {
    if (showModal) {
      validate() ? setDisabled(false) : setDisabled(true);
    }
  }, [userLocation, showModal]);

  const validateUserLocation = () =>
    !userLocation.some(location => !location.name || !location.value);

  const validate = () => {
    return !isEmpty(userLocation) && validateUserLocation();
  };

  const handleSetParentLocation = (event: React.SyntheticEvent, { value }: { value: string }) => {
    const locationOptions = validDeliveryLocations[value];

    const result =
      locationOptions?.map((locationOption: any) => ({
        name: locationOption.name,
        value: '',
      })) ?? [];

    setUserLocation(result);
  };

  const handleOnChange = (
    event: React.SyntheticEvent,
    { name, value }: { name: string; value: string },
  ) => {
    const findLocationToChange = userLocation.find(({ name: lname }) => lname === name);
    setUserLocation(currentLocation => {
      const locations = currentLocation.map(location => {
        if (location.name === name) {
          return { name, value };
        }
        return location;
      });
      if (!findLocationToChange) {
        locations.push({ name, value });
      }
      return locations;
    });
  };

  const renderParentOptions = () => {
    const parentLocationOptions = Object.entries(validDeliveryLocations).map(([key], index) => {
      return {
        key: index,
        text: key,
        value: key,
      };
    });
    return parentLocationOptions;
  };

  const renderDropdownOptions = (options: Array<string>) => {
    return options.map((option, index) => {
      return {
        key: index,
        text: option,
        value: option,
      };
    });
  };

  const renderPlaceHolder = () => {
    if (!isEmpty(userLocation)) {
      return userLocation[0].name;
    } else {
      return 'Select Your Location';
    }
  };
  const userLocationValue = (name: string) => {
    return userLocation.find(location => location.name === name)?.value;
  };
  const renderUserValues = (fieldName: string) => {
    return (
      !isEmpty(userLocation) &&
      toggleParentLocation === userLocation[0].name &&
      userLocationValue(fieldName)
    );
  };

  return (
    <AnimatedModal showModal={showModal} onCloseModal={() => handleOnCloseModal()}>
      <AnimatedModal.Title
        copy={
          deliveryLocation && deliveryLocation.length > 0
            ? 'EDIT YOUR LOCATION'
            : 'ADD YOUR LOCATION'
        }
      />
      <ModalSubTitle>See nearby Pick Up and available Delivery options</ModalSubTitle>
      <AnimatedModal.Content>
        <Form>
          <StyledField>
            <StyledSelect
              aria-label={showModal && renderPlaceHolder()}
              icon={false}
              placeholder={
                <PlaceholderWrapper>
                  <PlaceHolder>{showModal && renderPlaceHolder()}</PlaceHolder>
                  <StyledDownChevron src={DownChevron} alt="down chevron icon" />
                </PlaceholderWrapper>
              }
              options={!isEmpty(validDeliveryLocations) ? renderParentOptions() : []}
              onChange={handleSetParentLocation}
            />
          </StyledField>

          <Form.Group unstackable>
            {validDeliveryLocations[toggleParentLocation] &&
              validDeliveryLocations[toggleParentLocation].map(
                (locationOption: any, index: number) => (
                  <StyledField key={index}>
                    <FieldLabel htmlFor={locationOption.name}>{locationOption.name}</FieldLabel>
                    {locationOption.value.length === 0 ? (
                      <StyledInput
                        fluid
                        onChange={handleOnChange}
                        id={locationOption.name}
                        name={locationOption.name}
                        value={renderUserValues(locationOption.name)}
                      />
                    ) : (
                      <>
                        <StyledSelectWithoutPlaceholder
                          aria-label={locationOption.name}
                          compact
                          icon={<StyledDownChevron src={DownChevron} alt="down chevron icon" />}
                          options={renderDropdownOptions(locationOption.value)}
                          onChange={handleOnChange}
                          name={locationOption.name}
                          value={renderUserValues(locationOption.name)}
                        />
                      </>
                    )}
                  </StyledField>
                ),
              )}
          </Form.Group>
        </Form>
      </AnimatedModal.Content>
      <SubmitButtonWrapper>
        <SubmitButton disabled={disabled} onClick={() => handleSubmitLocation(userLocation)}>
          {deliveryLocation && deliveryLocation.length > 0
            ? 'UPDATE MY LOCATION'
            : 'ADD MY LOCATION'}
        </SubmitButton>
      </SubmitButtonWrapper>
    </AnimatedModal>
  );
};

export default ModalLocation;
