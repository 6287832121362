import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Divider, Grid, Image, List, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import ChatIcon from '../../assets/icons/chatIcon.png';
import CircleLocation from '../../assets/icons/circleLocation.png';
import CloseIconBtn from '../../assets/icons/closeSearchBtn.png';
import { useAppContext } from '../../containers/App/AppContext';
import { BasePath, OrderStatus, RefundStatus } from '../../shared/constants';
import { IDeliveryLocation } from '../../shared/models/Cart';
import { Order } from '../../shared/models/Order';
import { colors } from '../../shared/theme';
import { collectSubProducts, getCurrencyPrefix } from '../../shared/utils';
import CancelOrder from '../CancelOrder';
import {
  LocationActionsContainer,
  LocationContainer,
  OrderDetailsActionsContainer,
  SummaryContainer,
} from './styled';

const OrderDetailsContainer = styled.div`
  .ui.large.modal {
    > .header {
      padding: 0 !important;
    }
    .ui.image.close-icon {
      position: absolute;
      height: 12px;
      width: auto;
      right: 13px;
      top: 13px;
    }
  }
  img.ui.centered.image {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .ui.card {
    margin: 0 auto;
    border: 0;
    box-shadow: none;
  }
  .ui.card > .content > .header {
    color: ${({ theme }) => theme.primaryColor};
    text-transform: uppercase;
    text-align: center;
  }
  .grid-totals {
    background: ${colors.lightGrey};
  }
  .ui.divider {
    margin-right: 25px;
    margin-left: 25px;
  }
`;

interface OrderDetailModalProps {
  order: Order;
  open: boolean;
  onClose: () => void;
  suite?: boolean;
}

const OrderDetails = ({ order, onClose, open, suite }: OrderDetailModalProps) => {
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const { setOrderCancelled } = useAppContext();
  const history = useHistory();
  const currencyPrefix = getCurrencyPrefix(order.currency);

  const onCancelledOrder = () => {
    setOrderCancelled(order);
    onClose();
    if (suite) {
      history.replace(`/suite`);
    } else {
      history.replace(`/${BasePath.CONSUMER}/stores/${order.venueId}`, {});
    }
  };

  const navigateToChatScreen = () => {
    history.push(`/${BasePath.CONSUMER}/chat/${order.orderNo}/${order.venueId}/${order.storeId}`);
  };

  const getSeatLocation = () => {
    if (order && order.location) {
      return (order?.location || [])
        .map(({ name, value }: IDeliveryLocation) => `${name} ${value}`)
        .join(', ');
    }
    return '';
  };

  return (
    <>
      {order && order.id && (
        <>
          <Modal size={'fullscreen'} open={showCancelOrder}>
            <CancelOrder
              onCancelledOrder={onCancelledOrder}
              orderId={order.id}
              onClose={() => setShowCancelOrder(false)}
              onChatIconClick={navigateToChatScreen}
            />
          </Modal>
          <Modal
            size={'tiny'}
            open={open}
            onClose={onClose}
            closeIcon={
              <Image
                style={{
                  position: 'absolute',
                  top: '13px',
                  right: '13px',
                  height: '12px',
                  width: 'auto',
                }}
                src={CloseIconBtn}
              />
            }
          >
            <OrderDetailsContainer>
              <Modal.Content>
                {order.type === 'DELIVERY' && (
                  <LocationContainer>
                    <Image src={CircleLocation} />
                    <div className="title">Delivery Location</div>
                    <div className="location">{getSeatLocation()}</div>
                    <LocationActionsContainer>
                      <Button onClick={navigateToChatScreen}>
                        <Image src={ChatIcon} />
                      </Button>
                    </LocationActionsContainer>
                  </LocationContainer>
                )}
                {order.type === 'PICKUP' && (
                  <LocationContainer>
                    <Image src={CircleLocation} />
                    {suite ? (
                      <div className="title">Delivery Date &amp; Location</div>
                    ) : (
                      <div className="title">Pick up Location</div>
                    )}
                    <div className="location">
                      <span>{order && order.store ? order.store.name : ''}</span>
                      <span>{order && order.store ? order.store.location : ''}</span>
                    </div>
                    {!suite && (
                      <LocationActionsContainer>
                        <Button onClick={navigateToChatScreen}>
                          <Image src={ChatIcon} />
                        </Button>
                      </LocationActionsContainer>
                    )}
                  </LocationContainer>
                )}
                <Divider />
                <SummaryContainer>
                  <Card fluid>
                    <Card.Header>
                      <div className="title">Order Summary</div>
                      <List verticalAlign="middle">
                        {order.lineItems.map((lineItem: any) => {
                          // eslint-disable-next-line prefer-const
                          let subProducts: any[] = [];
                          collectSubProducts(lineItem.subSelections || [], subProducts);

                          return (
                            <List.Item key={lineItem.id}>
                              <List.Header>
                                <Grid>
                                  <Grid.Row
                                    className={`${subProducts.length > 0 && 'no-padding-bottom'}`}
                                  >
                                    <Grid.Column floated="left" width={10}>
                                      {`${lineItem.product.name}${
                                        lineItem.quantity > 1 ? ` (x${lineItem.quantity})` : ''
                                      }`}
                                    </Grid.Column>
                                    <Grid.Column floated="right" width={6} textAlign="right">
                                      {currencyPrefix}
                                      {Number(lineItem.product.price * lineItem.quantity).toFixed(
                                        2,
                                      )}
                                    </Grid.Column>
                                  </Grid.Row>
                                  {subProducts.map((sp: any, ii: number, spArray: any[]) => (
                                    <Grid.Row
                                      className={`no-padding-top ${ii < spArray.length - 1 &&
                                        'no-padding-bottom'}`}
                                      key={ii}
                                    >
                                      <Grid.Column className="sub-item" floated="left" width={10}>
                                        {`${sp.name}${sp.quantity > 1 ? ` (x${sp.quantity})` : ''}`}
                                      </Grid.Column>
                                      <Grid.Column floated="right" width={5} textAlign="right">
                                        {currencyPrefix}
                                        {Number(sp.price * (sp.quantity || 1)).toFixed(2)}
                                      </Grid.Column>
                                    </Grid.Row>
                                  ))}
                                </Grid>
                              </List.Header>
                            </List.Item>
                          );
                        })}
                      </List>
                    </Card.Header>
                    <Card.Content>
                      <Grid className="grid-totals">
                        <Grid.Row>
                          <Grid.Column floated="left" width={10}>
                            Subtotal
                          </Grid.Column>
                          <Grid.Column floated="right" width={6} textAlign="right">
                            {currencyPrefix}
                            {Number(order.subTotal).toFixed(2)}
                          </Grid.Column>
                        </Grid.Row>
                        {order.discountAmt > 0 && (
                          <Grid.Row>
                            <Grid.Column floated="left" width={10}>
                              Discount
                            </Grid.Column>
                            <Grid.Column
                              style={{ color: 'red' }}
                              floated="right"
                              width={6}
                              textAlign="right"
                            >
                              - {currencyPrefix}
                              {Number(order.discountAmt).toFixed(2)}
                            </Grid.Column>
                          </Grid.Row>
                        )}
                        {order.serviceFee > 0 && (
                          <Grid.Row>
                            <Grid.Column floated="left" width={10}>
                              Service Charge
                            </Grid.Column>
                            <Grid.Column floated="right" width={6} textAlign="right">
                              {currencyPrefix}
                              {Number(order.serviceFee).toFixed(2)}
                            </Grid.Column>
                          </Grid.Row>
                        )}
                        <Grid.Row>
                          <Grid.Column floated="left" width={10}>
                            {order.type === 'PICKUP' ? 'Pickup Charge' : 'Delivery Charge'}
                          </Grid.Column>
                          <Grid.Column floated="right" width={6} textAlign="right">
                            {currencyPrefix}
                            {Number(order.convenienceFee).toFixed(2)}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column floated="left" width={10}>
                            Tip
                          </Grid.Column>
                          <Grid.Column floated="right" width={6} textAlign="right">
                            {currencyPrefix}
                            {Number(order.tipAmt).toFixed(2)}
                          </Grid.Column>
                        </Grid.Row>
                        {order.taxCalculated && (
                          <Grid.Row>
                            <Grid.Column floated="left" width={10}>
                              Tax
                            </Grid.Column>
                            <Grid.Column floated="right" width={6} textAlign="right">
                              {currencyPrefix}
                              {Number(order.taxAmt).toFixed(2)}
                            </Grid.Column>
                          </Grid.Row>
                        )}
                        <Grid.Row className="total">
                          <Grid.Column floated="left" width={10}>
                            Total
                          </Grid.Column>
                          <Grid.Column floated="right" width={6} textAlign="right">
                            {currencyPrefix}
                            {Number(order.total).toFixed(2)}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <OrderDetailsActionsContainer>
                        <Grid textAlign="center">
                          <Grid.Row>
                            {(order.status === OrderStatus.NEW ||
                              order.status === OrderStatus.PRE_ORDER) &&
                              order.refundStatus !== RefundStatus.FULL && (
                                <Button
                                  className="cancel-button"
                                  onClick={() => setShowCancelOrder(true)}
                                  basic
                                >
                                  Cancel Order
                                </Button>
                              )}
                          </Grid.Row>
                        </Grid>
                      </OrderDetailsActionsContainer>
                    </Card.Content>
                  </Card>
                </SummaryContainer>
              </Modal.Content>
            </OrderDetailsContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default OrderDetails;
