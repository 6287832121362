import FloatingLabelInput from 'components/FloatingLabelInput';
import React, { useState } from 'react';
import { Button, Grid, Image, Card, Divider, Label } from 'semantic-ui-react';
import { GiveXService } from 'services/GiveXService';
import { colors, fonts } from '../../shared/theme';
import styled from 'styled-components';
import CloseSearchBtn from '../../assets/icons/closeSearchBtn.png';
import { CardContainer } from '../../shared/global-styled';
import { toast } from 'react-toastify';
import { useAppContext } from '../../containers/App/AppContext';
import useCartContext from '../../containers/Cart/context';
import { QrReader } from 'react-qr-reader';
type Props = {
    suiteVersion?: boolean;
  };
  
  type StyledProps = {
    showInput: boolean;
    suiteVersion?: boolean;
  };
  const StyledContainer = styled.div`
  .ui.top.right.attached.label {
    background: transparent;
    color: ${colors.niceBlue};
    padding: 22px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    cursor: pointer;
    font-family: ${fonts.REGULAR};
  }

  .title {
    margin-bottom: 10px;
    p {
      color: ${({ theme }) => theme.primaryColor};
      font-family: ${fonts.REGULAR};
      font-size: 12px;
    }
  }

  .ui.basic.button {
    margin-top: -4px;
    margin-left: -30px;
    position: relative;
    box-shadow: none;
    font-family: ${fonts.REGULAR};
    font-size: 0.9em;
    font-weight: lighter;
    color: ${colors.niceBlue}!important;
    text-transform: uppercase;
    padding-right: 10px;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      color: ${colors.niceBlue}!important;
    }
  }
`;

const BalanceContainer = styled.div<StyledProps>`
${props => !props.showInput && 'margin-top: 15px;text-align: center;'}
${props =>
  props.showInput &&
  `
    text-align: center;
    padding: 20px;
    background: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: calc(100% + 42px);
    position: relative;
    left: -21px;
    margin-bottom: -21px;
   
    .ui.grid>.row {
        justify-content: center;
        padding: 0;
     
    }
    
    
`}
.title{
    font-family: AkzidenzGroteskBEMd;
    color:black;
}
button.ui.button {
    background: none;
    font-weight: lighter;
    color: ${colors.niceBlue};
    padding: 0px;
    font-family: ${fonts.REGULAR};
    font-size: 14px;
}

`
  const AddPromoCodeContainer = styled.div<StyledProps>`
      ${props => !props.showInput && 'margin-top: 15px;'}
      ${props =>
        props.showInput &&
        `
          padding: 20px;
          background: #f2f2f2;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          width: calc(100% + 42px);
          position: relative;
          left: -21px;
          margin-bottom: -21px;
          .ui.grid>.row {
              justify-content: center;
              padding: 0;
          }
      `}
      .ui.image {
          height: 10px;
          width: auto;
      }
      button.ui.button {
          background: none;
          font-weight: lighter;
          color: ${colors.niceBlue};
          padding: 0px;
          font-family: ${fonts.REGULAR};
          font-size: 14px;
      }
      .two.column.row {
          align-items: center;
      }
  `;
type TipValue = {
  tip: number;
}  

const GiveX = ({tip}: TipValue) => {
    const [totalBalance, setTotalBalance] = useState<number>()
    const [unit, setUnit] = useState()
    const [showInput, setShowInput] = useState<boolean>(false);
    const [showScan, setShowScan] = useState<boolean>(false);
    const [giveXCodeValue, setGiveXCodeValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { givexData, setGivexData } = useAppContext()
    const [startScan, setStartScan] = useState<boolean>(false);
    const { cart } = useCartContext();
    const descriptor = 'Only 1 card per order.';

    const onGiveXCodeChanged = ({ value, hasError }: any) => {
      setGiveXCodeValue(value);
    };
    const toggleShowInput = () => {
      setShowInput(!showInput);
    };
    const toggleShowScan = () => {
      setShowScan(!showScan);
      setStartScan(true);
    };

    const applyGiveXCode = async (qrValue?: string) => {
      setIsLoading(true)
        const payload = {
            "jsonrpc":"2.0",
            "method":"dc_994",
            "id":"",
            "params":[
                "en","","37633","8KJqGFAOdiUxVXev","","",""
            ]
        }
      payload.params[4] = qrValue ? qrValue : giveXCodeValue;
      const data = await GiveXService.checkBalance(payload)
      console.log(data)
      const {result} = data?.data
      if (result.length > 3 && result[2] > 0) {
        setTotalBalance(result[2])
        setUnit(result[5])
      } else {
        setGiveXCodeValue('')
        toast('The card number is invalid or have no balance left')
        setShowInput(false)
      }
      setIsLoading(false)
      
    };

    const applyToCart = () => {
      let calculateGivexBalance

      if ((cart && totalBalance) && totalBalance > cart?.total) {
        calculateGivexBalance = cart.total + tip
        toast('Maximum discount is applied on your order, remaining balance is left on your card.')
      } else {
        calculateGivexBalance = totalBalance
      }
      setShowInput(false)
      setTotalBalance(calculateGivexBalance)
      const givex = {
        cardNumber: giveXCodeValue,
        balance: Number(calculateGivexBalance)
      }
      setGivexData(givex)
    }

  return (
    <>
    {!givexData?.cardNumber &&
      <StyledContainer>
          <CardContainer>
            <Card>
              <Card.Header>
                <div className="title">GiveX</div>
                <Divider />
                { !totalBalance ? <AddPromoCodeContainer showInput={showInput}>
                  { showInput ? 
                  <Grid className="promo-code-input">
                  <Grid.Row>
                      <Grid.Column floated="right" onClick={toggleShowInput} width={4} textAlign="right">
                      <Image src={CloseSearchBtn} style={{ cursor: 'pointer',display: 'inline-block' }} />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                      <Grid.Column width={13}>
                      <FloatingLabelInput
                          id="add-promo-input"
                          type="text"
                          name="add-promo-input"
                          isRequired={false}
                          isDisabled={false}
                          value={giveXCodeValue}
                          placeholder="Givex Code"
                          onChange={onGiveXCodeChanged}
                          descriptor={descriptor}
                      />
                      </Grid.Column>
                      <Grid.Column width={3}>
                      <Button loading={isLoading} onClick={() => applyGiveXCode()}>
                          APPLY
                      </Button>
                      </Grid.Column>
                      <h4>{totalBalance && unit ? `Available Balance ${unit}${totalBalance}` : ''}</h4>
                  </Grid.Row>
                  </Grid> : 
                  <Button onClick={toggleShowInput} className="addPromoCode">
                  + Use Loaded Value
                  </Button>
                  }
              </AddPromoCodeContainer> :
              <BalanceContainer showInput={showInput}>
                    <h5 className='title'>You have ${totalBalance} to apply to your order</h5>
                    <Button loading={isLoading} onClick={applyToCart} className="addPromoCode">
                        Apply Now
                    </Button>
              </BalanceContainer> }


              {<AddPromoCodeContainer showInput={showScan}>
                  { showScan ? 
                  <Grid className="scan-qr-input">
                  <Grid.Row>
                      <Grid.Column floated="right" onClick={toggleShowScan} width={4} textAlign="right">
                        <Image src={CloseSearchBtn} style={{ cursor: 'pointer',display: 'inline-block' }} />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                { startScan  && ( <QrReader
                  onResult={(result:any, error) => {
                    if (!!result) {
                      applyGiveXCode(result?.text)
                      setShowScan(false);
                    }
                    if (!!error) {
                      console.info(error);
                    }
                  }}
                  containerStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  alignItems: 'center',
                }}

                  videoContainerStyle={{
                  width:'50%',
                  paddingTop :'unset',
                  height: '200px',
                }}

                  videoStyle={{
                    width: '400px',
                    height: 'unset',
                  }}
                  scanDelay={500}
                  constraints={ {facingMode: 'environment'} }
                  />)}
                  </Grid.Row>

                  </Grid> : 
                  <Button onClick={toggleShowScan} className="scanQrHere">
                  + Scan QR Code
                  </Button>
                  }
              </AddPromoCodeContainer> 
              }
              </Card.Header>
            </Card>
          </CardContainer>
        </StyledContainer>
      }
    </>
  );
};

export default GiveX;