import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useAppContext } from '../containers/App/AppContext';
import { useProductCategoryTabContext } from '../containers/ProductListing/product-context';
import { VenueService } from '../services';
import { ProductService } from '../services/ProductService';
import { IEtas, StoreService } from '../services/StoreService';
import { CategoryType, ICategory } from '../shared/models/Category';
import IProduct from '../shared/models/Product';
import Store from '../shared/models/Store';

interface StoreContext {
  categories: ICategory[] | undefined;
  isLoading: boolean;
  products: IProduct[];
  setStore: Dispatch<SetStateAction<Store | undefined>>;
  store: Store | undefined;
  storeEtas: IEtas | undefined;
}

interface StoreContextParams {
  fetchCategories?: boolean;
  fetchEtas?: boolean;
  fetchLocations?: boolean;
  fetchProducts?: boolean;
  storeId?: string;
}

export const useStoreContext = ({
  storeId,
  fetchCategories,
  fetchProducts,
  fetchEtas,
  fetchLocations,
}: StoreContextParams = {}): StoreContext => {
  const { store, venue, orderType, setShowLoader, setStore, setVenueLocations } = useAppContext();
  const { activeCategory, setActiveCategory } = useProductCategoryTabContext();
  const [categories, setCategories] = useState<ICategory[] | undefined>(undefined);
  const [storeEtas, setStoreEtas] = useState<IEtas | undefined>(undefined);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!store || (storeId && store.id !== storeId)) {
      getStore();
    }
  }, [storeId, store, venue]);

  useEffect(() => {
    if (!storeEtas && store && fetchEtas) {
      getStoreEtas();
    }
  }, [storeId, store, storeEtas, venue, fetchEtas]);

  useEffect(() => {
    if (store && !categories && fetchCategories) {
      getStoreCategories();
    }
  }, [store, setCategories, setActiveCategory, setShowLoader]);

  useEffect(() => {
    if (store && fetchProducts && categories && activeCategory) {
      getProductsByCategory();
    }
  }, [orderType, activeCategory, categories, store, storeId]);

  useEffect(() => {
    if (venue && store && fetchLocations) {
      getVenueLocations();
    }
  }, [venue, store, fetchLocations]);

  const getStoreEtas = async (): Promise<void> => {
    if (!store || !storeId) return;

    try {
      const { data } = await StoreService.GetStoreEtas(store.id);
      setStoreEtas(data);
    } catch {}
  };

  const getStore = async (): Promise<void> => {
    if (!storeId || !venue) {
      return;
    }

    if (store && store.id === storeId) {
      return;
    }
    try {
      setShowLoader(true);
      const { data: currentStore } = await StoreService.GetOneStore(storeId);
      const active = !!(
        venue.active &&
        currentStore.active &&
        ((venue.pickupAvailable && currentStore.pickupAvailable) ||
          (venue.deliveryAvailable && currentStore.deliveryAvailable)) &&
        ((venue.orderNowAvailable && currentStore.orderNowAvailable) ||
          (venue.orderAheadAvailable && currentStore.orderAheadAvailable))
      );

      setStore({
        ...currentStore,
        active,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      history.push('/404');
    }
  };

  const getStoreCategories = async () => {
    if (!store || !storeId) {
      return;
    }

    if (!store) {
      return;
    }
    setShowLoader(true);
    try {
      const {
        data: { items: newCategories },
      } = await ProductService.GetAllCategories({
        active: true,
        limit: 50,
        storeId,
        type: [CategoryType.MERCHANDISE, CategoryType.STANDALONE, CategoryType.COMBO].join(','),
      });

      setCategories(newCategories);
      setActiveCategory((activeCategory: ICategory | undefined) => {
        return (
          newCategories.find((category: ICategory) => category.id === activeCategory?.id) ||
          newCategories[0]
        );
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      history.push('/404');
    }
  };

  const getProductsByCategory = async () => {
    if (!store || !activeCategory || !storeId) {
      return;
    }

    setLoading(true);
    try {
      const {
        data: { items: newProducts },
      } = await ProductService.GetAllProducts({
        active: true,
        storeId,
        categoryId: activeCategory.id,
        limit: 100,
        ...(store.active && { availabilityType: orderType }),
      });
      setProducts(newProducts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast(error);
    }
  };

  const getVenueLocations = async () => {
    if (!venue || !store) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await VenueService.getVenueLocations(venue.id, store.id);
      setVenueLocations(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return {
    store,
    categories,
    products,
    isLoading,
    storeEtas,
    setStore,
  };
};
