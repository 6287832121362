import React from 'react';

import { BasePath } from 'shared/constants';
import { colors, fonts } from '../../../shared/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Card = styled.div`
  min-height: 276px;
  width: 340px;
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  background-color: #ffffff;
`;
const CardTitleContainer = styled.div`
  max-width: 250px;
`;

const CardTitle = styled.div`
  margin-top: 31px;
  font-family: ${fonts.BOLD};
  font-size: 18px;
  color: ${({ theme }) => theme.primaryColor};
  text-align: center;
  text-transform: uppercase;
`;

const DescriptionContainer = styled.div`
  max-width: 284px;
`;

const Description = styled.div`
  margin-top: 20px;
  font-family: ${fonts.LIGHT};
  color: ${colors.charcoalGrey};
  font-size: 18px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  text-decoration: none;
`;

const DescriptionAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

type TextCard = {
  cardTitle: string;
  description: string;
  button?: any;
};

const TextCard = ({ cardTitle, description, button }: TextCard) => {
  return (
    <Card>
      <DescriptionAndButtonContainer>
        <CardTitleContainer>
          <CardTitle>{cardTitle}</CardTitle>
        </CardTitleContainer>
        <DescriptionContainer>
          <Description>{description}</Description>
        </DescriptionContainer>
      </DescriptionAndButtonContainer>
      <ButtonContainer>
        {button && <Link to={`/${BasePath.BUILDING_EATS}/appointments`}>{button}</Link>}
      </ButtonContainer>
    </Card>
  );
};

export default TextCard;
