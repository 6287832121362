import React from 'react';
import styled from 'styled-components';
import AnimatedModal from 'components/Molecules/AnimatedModal/AnimatedModal';

const OrderChangeButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
`;

interface ChangeOrderLocationModalProps {
    newLocation: string;
    onAccept: () => void;
    onCloseModal: () => void;
    showModal: boolean;
}

const ChangeOrderLocationModal = (props: ChangeOrderLocationModalProps) => {
    const { newLocation, onAccept, onCloseModal, showModal } = props;

    return (
        <AnimatedModal {...({ onCloseModal, showModal })}>
            <AnimatedModal.Title copy='START A NEW ORDER?' />
            <AnimatedModal.Content>
                {
                    `Currently, you may only order from one location at a time.
                    If you begin an order with ${(newLocation || '').trim()}, your bag will be emptied.`
                }
            </AnimatedModal.Content>
            <OrderChangeButtonWrapper>
                <AnimatedModal.Button
                    copy='START NEW ORDER'
                    outline={true}
                    onClick={onAccept}
                />
            </OrderChangeButtonWrapper>
        </AnimatedModal>
    );
}

export default ChangeOrderLocationModal;
