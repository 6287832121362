import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../shared/theme';
import { BasePath } from 'shared/constants';
import { Link } from 'react-router-dom';

const Card = styled.div<{ background: string }>`
  height: 187px;
  width: 252px;
  background-image: url(${({ background }) => background});
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid black;
  border-radius: 10px;
  position: relative;
  text-align: center;
  cursor: pointer;
`;

const NameContainer = styled.div`
  height: 46px;
  width: 100%;
  background: rgba(71, 67, 80, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 9px 9px;
`;

const Name = styled.div`
  font-family: ${fonts.MEDIUM};
  color: ${colors.white};
  font-size: 18px;
`;

const CardWrapper = styled.div`
  margin: 30px;
`;
interface BuildingCardProps {
  id: string;
  currentVenue: any;
  buildingName: string;
  imageUrl: string;
}

const BuildingCardProps = (props: BuildingCardProps) => {
  const { id, buildingName, imageUrl, currentVenue } = props;

  return (
    <CardWrapper>
      <Link
        to={{
          pathname: `/${BasePath.BUILDING_EATS}/${id}`,
          state: {
            image: imageUrl,
            currentVenue,
            buildingName,
          },
        }}
      >
        <Card background={imageUrl}>
          <NameContainer>
            <Name>{buildingName}</Name>
          </NameContainer>
        </Card>
      </Link>
    </CardWrapper>
  );
};

export default BuildingCardProps;
