import CircleLocation from 'assets/images/circleLocation.svg';
import React from 'react';
import { Card, Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import useWindowWidth from '../../../hooks/UseWindowWidth';
import Header from '../../Atoms/Header';
import NoPaddingRow from '../../Atoms/NoPaddingRow';
import Spacer, { SpacerSizes } from '../../Atoms/Spacer';

const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.primaryColor};
`;

const EmptyBag = () => {
  const width = useWindowWidth();

  return (
    <>
      <Header as="h2" font="BOLD">
        My Bag
      </Header>
      <Card fluid>
        <Card.Content>
          <Spacer size={SpacerSizes.SMALL} />
          <Grid padded>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Image src={CircleLocation} style={{ width: width < 500 ? 90 : 120 }} />
              </Grid.Column>
            </Grid.Row>
            <NoPaddingRow>
              <Grid.Column textAlign="center">
                <StyledHeader as="h2" font="BOLD" textAlign="center">
                  Your Bag is Empty
                </StyledHeader>
              </Grid.Column>
            </NoPaddingRow>
            <NoPaddingRow>
              <Grid.Column textAlign="center">
                <Header as="h2" textAlign="center" size={16}>
                  Start by selecting a Food or Drink Package!
                </Header>
              </Grid.Column>
            </NoPaddingRow>
          </Grid>
          <Spacer size={SpacerSizes.SMALL} />
          <Spacer size={width < 400 ? SpacerSizes.MEDIUM : SpacerSizes.BIG} />
        </Card.Content>
      </Card>
      <Spacer size={SpacerSizes.MEDIUM} />
    </>
  );
};

export default EmptyBag;
