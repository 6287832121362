import SubmitButton from 'components/Atoms/Button/Submit';
import { EventWeekCard, TimeRangeCard } from 'components/Atoms/DateTimeCard';
import AnimatedModal from 'components/Molecules/AnimatedModal/AnimatedModal';
import { useEventContext } from 'containers/App/AppContext';
import { format, isEqual, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { Event, EventsService, ITimeSlot } from 'services/EventsService';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';
import { useVenueContext } from '../../../../hooks/useVenueContext';

const ScrollXContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 25px;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalTitle = styled.h2`
  color: ${colors.charcoalGrey};
  font-family: ${fonts.MEDIUM};
  font-size: 16px;
  margin-bottom: 5px;
  letter-spacing: 0.33pt;
  text-align: left;

  &:first-child {
    margin-top: -25px;
  }
`;

const ModalSubTitle = styled.h3`
  font-size: 18px;
  color: #474350;
  font-family: ${fonts.REGULAR};
  text-align: center;
  margin: 0;
  font-weight: normal;
`;

const TimeTitle = styled.h4`
  font-family: ${fonts.MEDIUM};
  font-size: 16px;
  color: ${colors.charcoalGrey};
  text-align: left;
`;

interface ModalProps {
  hideCloseX?: boolean;
  showModal: boolean;
  handleSubmit: (eventData?: Event | undefined) => Promise<void>;
  onCloseModal: () => void;
  events?: Event[] | undefined;
  orderAheadAvailable: boolean;
  orderNowAvailable: boolean;
}

interface TEvent {
  id: string;
  name: string;
}

export interface EventTimeSlot {
  startTime: string;
  endTime: string;
  value: string;
}

interface SelectedEventState {
  id: string;
  name: string;
  venueId: string;
}

const OrderAheadModal = ({
  handleSubmit,
  showModal,
  onCloseModal,
  events,
  orderAheadAvailable,
  orderNowAvailable,
}: ModalProps) => {
  const { venue } = useVenueContext();
  const { event, setEvent } = useEventContext();
  const [eventsList, setEventsList] = useState<TEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<SelectedEventState | undefined>();
  const [selectedTime, setSelectedTime] = useState<number>(-1);
  const [eventTimeSlots, setEventTimeSlots] = useState<EventTimeSlot[]>([]);
  const [loading, setLoading] = useState(false);

  const calculateTimeSlots = async (eventId: string) => {
    setLoading(true);
    try {
      const { data } = await EventsService.getEventTimeSlots(eventId);
      const timeSlots: EventTimeSlot[] = data.slots.map((slot: ITimeSlot) => ({
        startTime: format(utcToZonedTime(parseISO(slot.start), venue?.timezone || ''), 'h:mm'),
        endTime: format(utcToZonedTime(parseISO(slot.end), venue?.timezone || ''), 'h:mm a'),
        value: slot.start,
      }));

      setEventTimeSlots(timeSlots);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      toast('Sorry, something went wrong while trying to fetch available Time Slots');
    }
    setLoading(false);
  };

  const buildEventData = () => {
    if (!events) {
      return;
    }

    const eventsArray = events.map(({ id, name }) => ({ id, name }));

    setEventsList(eventsArray);

    if (event) {
      calculateTimeSlots(event.id);
    } else {
      if (eventsArray.length > 0) {
        calculateTimeSlots(eventsArray[0].id);
      }
    }
  };

  useEffect(() => {
    if (orderAheadAvailable && events && events.length > 0) {
      buildEventData();
      return;
    }
    if (orderNowAvailable) {
      setSelectedEvent(undefined);
      setSelectedTime(-1);
    }
  }, [events, orderAheadAvailable, orderNowAvailable]);

  useEffect(() => {
    if (!venue) return;

    if (event) {
      setSelectedEvent({ id: event.id, name: event.name, venueId: venue.id });
      return;
    }

    if (orderNowAvailable) {
      setSelectedEvent(undefined);
      setSelectedTime(-1);
      return;
    }

    if (orderAheadAvailable) {
      setSelectedEvent({
        ...eventsList[0],
        venueId: venue.id,
      });
      setSelectedTime(0);
    }
  }, [eventsList]);

  useEffect(() => {
    if (event) {
      const eventInterval = eventTimeSlots.findIndex((timeSlot: EventTimeSlot) =>
        isEqual(parseISO(timeSlot.value), parseISO(event.scheduledTime ?? '')),
      );
      setSelectedTime(eventInterval !== -1 ? eventInterval : 0);
    }
  }, [eventTimeSlots]);

  const handleDateSelect = (id: string) => {
    if (!venue) {
      return;
    }
    const event = eventsList.find((event: TEvent) => event.id === id);
    if (event && event.id !== selectedEvent?.id) {
      setSelectedTime(0);
      setSelectedEvent({
        ...event,
        venueId: venue.id,
      });
      calculateTimeSlots(event.id);
    }
  };

  const handleSubmitClick = async () => {
    if (!venue || !selectedEvent || selectedTime < 0) {
      setEvent(undefined);
      await handleSubmit();
      return;
    }

    const { startTime, endTime, value } = eventTimeSlots[selectedTime];

    const eventData: Event = {
      id: selectedEvent.id,
      scheduledTime: value,
      venueId: venue.id,
      startDate: startTime,
      endDate: endTime,
      advanceOrderWindow: 0,
      name: selectedEvent.name,
    };
    setEvent(eventData);
    await handleSubmit(eventData);
  };

  return (
    <AnimatedModal showModal={showModal} size="mini" onCloseModal={onCloseModal}>
      <ModalTitle>Select Date & Time</ModalTitle>
      <TimeTitle>Date</TimeTitle>
      <ScrollXContainer>
        {orderNowAvailable && (
          <>
            <EventWeekCard
              selected={!selectedEvent}
              index={0}
              arrayLength={1}
              value={''}
              handleOnClick={() => {
                setSelectedEvent(undefined);
                setEventTimeSlots([]);
              }}
              eventDate={new Date().toDateString()}
              name={'Today'}
            />
          </>
        )}
        {orderAheadAvailable &&
          eventsList.map(({ id }: TEvent, index: number) => {
            const { startDate, name } = events?.find(event => event.id === id) || {
              startDate: '',
              name: '',
            };
            return (
              <EventWeekCard
                selected={selectedEvent && selectedEvent.id === id}
                key={id}
                index={index + 1}
                arrayLength={eventsList.length + 1}
                value={id}
                handleOnClick={() => handleDateSelect(id)}
                eventDate={startDate}
                name={name}
              />
            );
          })}
      </ScrollXContainer>

      <TimeTitle>Time</TimeTitle>
      <ScrollXContainer>
        <Loader size="mini" active={loading} />
        {orderNowAvailable && !selectedEvent && (
          <>
            <TimeRangeCard
              selected={selectedTime === -1}
              key={0}
              index={0}
              arrayLength={1}
              start={`ASAP`}
              end={''}
              value={0}
              handleOnClick={() => setSelectedTime(-1)}
            />
          </>
        )}
        <React.Fragment>
          {orderAheadAvailable &&
            selectedEvent &&
            eventTimeSlots.map(({ startTime, endTime }, index: number) => {
              return (
                <TimeRangeCard
                  selected={selectedTime === index}
                  key={index + 1}
                  index={index + 1}
                  arrayLength={eventTimeSlots.length + 1}
                  start={`${startTime}`}
                  end={endTime}
                  value={index + 1}
                  handleOnClick={() => setSelectedTime(index)}
                />
              );
            })}
        </React.Fragment>
      </ScrollXContainer>

      <SubmitButton onClick={handleSubmitClick}>SAVE</SubmitButton>
    </AnimatedModal>
  );
};

export default OrderAheadModal;
