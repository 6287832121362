import styled, { css } from 'styled-components';
import { colors, fonts } from '../../../shared/theme';

export const OrderTypeContainer = styled.div<{ spaced?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ spaced }) => (spaced ? '10px' : 'auto')};
  margin-bottom: 5px;

  div + div {
    margin-left: 10px;
  }

  div:first-child {
    margin-left: 10px;
  }
  div:last-child {
    margin-right: 10px;
  }
`;

export const OrderSelection = styled.div<{ align?: string }>`
  display: inline-flex;
  flex: auto;
  justify-content: space-around;
  align-self: ${({ align }) => `${align || 'flex-start'}`};
  text-align: center;
  background: #fff;
  padding: 4px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  height: 35px;
  width: auto;
`;

export const OrderTypeLabel = styled.label<{
  checked: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  height: 100%;
  appearance: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  border-radius: 7px;
  padding: 5px 0px;
  background: #fff;
  color: ${colors.charcoalGrey};
  font-size: 1.1em;
  font-family: ${fonts.REGULAR};
  transition: all 100ms linear;

  ${({ checked, disabled, theme }) =>
    disabled
      ? OrderDisabled
      : checked
      ? OrderChecked
      : `background-color: #fff;
    color: ${colors.charcoalGrey};`}
`;

const OrderDisabled = css`
  background-color: ${colors.paleGrey};
  pointer-events: none;
  font-family: ${fonts.MEDIUM};
`;

const OrderChecked = css`
  background-color: ${({ theme }) => theme.primaryColor};
  color: #fff;
  font-weight: bold;
  font-family: ${fonts.MEDIUM};
  box-shadow: 0 1px 1px #0000002e;
`;

export const OrderChangeButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 35px;
`;
