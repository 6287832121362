import { AvailabilityType } from './Product';

export default interface ICart {
  convenienceFee: number;
  serviceFee: number;
  currency: string;
  deliveryLocationId?: string;
  discountAmt: number;
  eventId: string;
  id: string;
  instructions: string;
  lineItems: ILineItem[];
  location?: IDeliveryLocation[];
  orderType: OrderType;
  promotionId: string;
  scheduledTime?: string;
  storeId: string;
  subTotal: number;
  suite: string;
  tax: number;
  total: number;
  userId: string;
  venueId: string;
}

export interface IDeliveryLocation {
  id?: string;
  name: string;
  value: string;
}

export interface ILineItem {
  alcohol: boolean;
  availabilityType: AvailabilityType;
  description: string;
  id: string;
  instructions?: string;
  name: string;
  productId: string;
  productPrice: number;
  quantity: number;
  quantityRefunded: number;
  subSelections?: ISubCategorySelection[];
  total: number;
}
export interface ISubCategorySelection {
  id: string;
  name: string;
  subSelections: ISubProductSelection[];
  type: SubSelectionType;
}
export interface ISubProductSelection {
  id: string;
  name: string;
  price: number;
  quantity: number;
  type: SubSelectionType;
}

export enum OrderType {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}
export interface PromoType {
  code: string;
  storeId: string;
  venueId: string;
}
export enum SubSelectionType {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
}
export enum VenueIds {
  HIDE_USERDETAILS = '4c56a6fd-5542-472a-b0b1-35055b002a09',
}
