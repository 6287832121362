import { isEmpty } from 'lodash';
import { IVenueLocation } from './models/Venue';

export const findDeliveryLocations = (
  deliveryLocationId: string,
  venueLocations: IVenueLocation[],
): IVenueLocation[] => {
  if (!deliveryLocationId || isEmpty(venueLocations)) {
    return [];
  }

  for (const location of venueLocations) {
    if (location.id === deliveryLocationId) {
      return [location];
    }

    if (!isEmpty(location.locations)) {
      const foundLocations = findDeliveryLocations(deliveryLocationId, location.locations);
      if (!isEmpty(foundLocations)) {
        return [location, ...foundLocations];
      }
    }
  }

  return [];
};
