import styled from 'styled-components';
import { colors } from '../../shared/theme';
const SPAN_HEIGHT = 150;

export const ChatContainer = styled.div<{ clientHeight: number }>`
  .title {
    text-align: center;
  }
  main {
    .ui.segment {
      border-radius: 10px !important;
      height: ${props => `calc(${props.clientHeight}px - ${SPAN_HEIGHT}px)`};
      min-height: ${props => `calc(${props.clientHeight}px - ${SPAN_HEIGHT}px)`};
      max-height: ${props => `calc(${props.clientHeight}px - ${SPAN_HEIGHT}px)`};
      display: block !important;
      box-shadow: 0 0 8px 0 rgba(17, 17, 17, 0.1), 0 0 8px 0 rgba(17, 17, 17, 0.1) !important;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin: 20px 1rem 30px !important;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .input-container {
    background-color: rgb(241, 242, 242);
    padding: 10px !important;
    padding-left: 0 !important;
    height: 50px;
    display: flex;
    align-items: center !important;
    justify-content: center;
    color: ${colors.inputTextGrey};
    font-weight: bold;
  }
  .chat-icon {
    width: 20px;
    height: 18px;
    cursor: pointer;
  }
  .invisible-input {
    flex-basis: 95%;
    input {
      border: none !important;
      font-weight: bolder !important;
      color: ${colors.inputTextGrey} !important;
    }
  }
  .absolute-icon {
    position: absolute !important;
    height: 12px;
    top: calc(50% - 6px);
    margin-left: 10px;
  }
  input::placeholder {
    color: ${colors.inputPlaceholderGrey} !important;
    opacity: 1 !important;
  }
`;
