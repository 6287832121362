import styled from 'styled-components';
import { colors, fonts } from '../../../shared/theme';

export const HelpContainer = styled.div`
  .title {
    text-align: center;
  }
  .help-container {
    border-radius: 10px !important;
    margin: 20px 1rem 30px !important;
    border: none !important;
    box-shadow: none !important;
  }
`;

export const UnauthenticatedView = styled.div`
  margin-top: 30px;
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h5,
  h4 {
    margin-bottom: 15px !important;
    margin-top: 0px;
    text-align: center;
  }
  h5 {
    font-size: 15px;
    font-family: ${fonts.REGULAR};
    color: ${colors.charcoalGrey};
    max-width: 75%;
  }
  h4 {
    line-height: 26px;
    font-size: 22px;
    font-family: ${fonts.REGULAR};
    color: ${colors.charcoalGrey};
  }
  img {
    width: 48px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  a {
    color: ${colors.niceBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const AuthenticatedView = styled.div`
  margin-top: 30px;
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h5,
  h4,
  h3 {
    margin-bottom: 15px !important;
    margin-top: 0px;
    text-align: center;
    letter-spacing: -0.5px;
  }
  h5 {
    font-size: 15px;
    font-family: ${fonts.REGULAR};
    color: ${colors.charcoalGrey};
    max-width: 90%;
    letter-spacing: -0.5px;
  }
  h4 {
    line-height: 26px;
    font-size: 22px;
    font-family: ${fonts.REGULAR};
    color: ${colors.charcoalGrey};
  }
  h3 {
    line-height: 26px;
    font-size: 16px;
    font-family: ${fonts.MEDIUM};
    color: ${colors.charcoalGrey};
    text-transform: uppercase;
  }
  img {
    width: 48px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  a {
    color: ${colors.niceBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
