import React, { useState } from 'react';
import { ICategory } from 'shared/models/Category';

interface ProductCategoryState {
  activeCategory: ICategory | undefined;
  setActiveCategory: React.Dispatch<React.SetStateAction<ICategory | undefined>>;
}
interface ProductContextProps {
  categoryState: ProductCategoryState;
}

const ProductContext = React.createContext<ProductContextProps | undefined>(undefined);

const ProductContextProvider = (props: any) => {
  const [activeCategory, setActiveCategory] = useState<ICategory | undefined>(undefined);

  const { Provider } = ProductContext;

  const contextState: ProductContextProps = {
    categoryState: {
      activeCategory,
      setActiveCategory,
    },
  };

  return <Provider value={{ ...contextState }}>{props.children}</Provider>;
};

const useProductCategoryTabContext = () => {
  const categoryContextState = React.useContext(ProductContext);

  if (!categoryContextState) {
    return {
      activeCategory: undefined,
      setActiveCategory: () => undefined,
    };
  }

  const {
    categoryState: { activeCategory, setActiveCategory },
  } = categoryContextState;

  return { activeCategory, setActiveCategory };
};

export { ProductContextProvider, useProductCategoryTabContext };
