import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';

export const BackgroundImageGrid = styled(Grid)<{ background: string }>`
  position: absolute;
  width: 100%;
  top: 0px;
  background: ${({ background }) => (background ? `url(${background})` : '')};
  background-size: cover;
  background-position: center;
  height: 17em;
  padding-left: 1em !important;
  padding-right: 1em !important;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9) 48%,
    rgba(0, 0, 0, 0.5) 80%,
    rgba(0, 0, 0, 0) 100%
  );

  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const OrderPlacedBackground = ({ background }: { background: string }) => {
  return <BackgroundImageGrid padded background={background} />;
};

export default OrderPlacedBackground;
