import { Image } from 'semantic-ui-react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

export const StandCardContainer = styled.div.attrs(({ className }) => ({
  className,
}))`
  .ui.card {
    margin: 10px auto;
    text-align: left;
    border-radius: 10px;
    width: auto;
    max-width: 99%;
    .image {
      border-top-left-radius: inherit !important;
      border-top-right-radius: inherit !important;
    }

    &:last-child {
      margin-bottom: 15px;
    }
    .content.no_stores {
      color: ${colors.charcoalGrey};
      margin-top: 5px;
      font-size: 1.1em;
      font-family: ${fonts.MEDIUM};
    }
    .extra {
      border-top: 0 !important;
      margin-top: -10px;

      &.content .ui.divided.grid {
        padding: 0px 15px 15px 15px;

        .column {
          padding: 0px 15px;

          &:first-child {
            padding-left: 0px;
          }

          &:last-child {
            padding-right: 0px;
          }
        }
      }
      p {
        font-family: ${fonts.REGULAR};
        margin: 0px;
        color: ${colors.charcoalGrey};
      }
      span {
        font-family: ${fonts.REGULAR};
        color: #007eb2;
      }
    }
    .store_description {
      border-top: 0 !important;
      padding-top: 18px;
      padding-bottom: 5px;
      position: relative;
      .header {
        font-family: ${fonts.MEDIUM};
        font-weight: lighter !important;
        font-size: 18px !important;
        color: ${colors.charcoalGrey};
      }
      .meta {
        font-family: ${fonts.REGULAR};
        font-weight: lighter !important;
        font-size: 13px;
        color: ${colors.charcoalGrey};
      }
    }
  }
`;

export const MinimumTag = styled.div<{ image?: string }>`
  position: absolute;
  top: 0px;
  right: 0px;
  color: ${colors.charcoalGrey};
  background: ${colors.paleGrey};
  border-radius: 0px ${props => (props.image ? '0px' : '10px')} 0px 10px;
  font-family: ${fonts.REGULAR};
  font-weight: lighter;
  font-size: 12px;
  padding: 2px 5px;
`;

export const ClosedSignWrapper = styled.div`
  position: absolute;
  right: 20px;
`;

export const Sign = styled.div`
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 5px;
  position: relative;
  bottom: 2px;
`;

export const SignText = styled.div`
  color: white;
  padding: 5px 8px;
  font-family: ${fonts.BOLD};
  letter-spacing: 0.36px;
`;

export const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledSignCords = styled(Image)`
  height: 21px;
  width: 43px;
`;
