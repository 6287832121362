import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';
const StyledContainer = styled.div`
  * {
    color: ${colors.charcoalGrey};
  }
  .title-section {
    font-family: ${fonts.SUPER};
    display: block;
    padding-top: 10px;
    font-size: 16px;
  }
  .subtitle-section {
    font-family: ${fonts.SUPER};
    display: block;
    padding-top: 10px;
  }
  .subtitle-section-inline {
    font-family: ${fonts.SUPER};
    padding-top: 10px;
  }
  .d-block {
    display: block;
  }
  p {
    font-family: ${fonts.REGULAR};
    a {
      color: ${colors.navigationBlue};
    }
  }
`;
function AccessibilityView() {
  return (
    <StyledContainer>
      <div>
        <span className='title-section'> Accessibility Statement </span>

        <p>
          FanFood is committed to ensuring digital accessibility for people withdisabilities. We are
          continually improving the user experience for everyone,and applying the relevant
          accessibility standards. FanFood hasimplemented, and continues to update its
          implementation, of the relevantportions of the World Wide Web Consortium’s Web Content
          AccessibilityGuidelines 2.0 Level AA (WCAG 2.0 AA) as its Web accessibility standardor
          other standards to the extent required by the Americans with DisabilitiesAct (“ADA”).
        </p>
        <span className='title-section'> Contact FanFood with Your Accessibility Questions </span>
        <p>
          Please be aware that our efforts are ongoing. If, at any time, you havespecific questions
          or concerns about the accessibility of our mobile app orany page on our Web site, please
          contact our assigned Web siteAccessibility Officer at{' '}
          <a href='mailto:ada@fanfoodapp.com'>ada@fanfoodapp.com</a>
        </p>
        <p>
          If you do encounter an accessibility issue, please be sure to specify themobile app screen
          or Web page (URL), and we will make all reasonableefforts to make the page accessible for
          you.
        </p>
      </div>
    </StyledContainer>
  );
}

export default AccessibilityView;
