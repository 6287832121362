import FanfoodLove from 'containers/Profile/FanfoodLove';
import Policies from 'containers/Profile/Policies';
import Steps from 'containers/Suite/Steps';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import Toast from '../../components/Toast';
import { BasePath } from '../../shared/constants';
import Page404 from '../404';
import { About, Appointments, BEHome, Buildings } from '../BuildingEats';
import Cart from '../Cart';
import Chat from '../Chat';
import OrderPlaced from '../OrderPlaced';
import PartnerInvite from '../Partners/PartnerInvite';
import PaymentDelivery from '../PaymentDelivery';
import ProductDetails from '../ProductDetails';
import ProductListing from '../ProductListing';
import Profile from '../Profile';
import Account from '../Profile/Account';
import Help from '../Profile/Help';
import OrderHistory from '../Profile/OrderHistory';
import Payment from '../Profile/Payment';
import StoreListing from '../StoreListing';
import Auth from '../Suite/Auth';
import SuitesHome from '../Suite/Home';
import SuiteOrderPlaced from '../Suite/OrderPlaced';
import UserAuth from '../UserAuth';
import { VenueInvite } from '../VenueInvite';
import { useAppContext } from './AppContext';
import AppTransition from './AppTransition';
import Home from './Home';

const PrivateRoute = ({ component, ...options }: any) => {
  const { user } = useAppContext();

  if (user && user.authToken) {
    return <Route {...options} component={component} />;
  }

  // TODO: Combine these two components into the UserAuth component and remove the Auth component
  const finalComponent = /\/suite|\/invites/.test(options.path) ? Auth : UserAuth;
  return <Route {...options} component={finalComponent} />;
};

const GuestRoute = ({ component, ...options }: any) => {
  const { user } = useAppContext();
  const finalComponent = user && user.authToken ? Profile : component;

  return <Route {...options} component={finalComponent} />;
};

const AppRouter = () => {
  const { whiteLabelContext } = useAppContext();
  const venueValidation = whiteLabelContext?.isWhiteLabeled ? `(${whiteLabelContext.venueId})` : ``;

  return (
    <Router>
      <AppTransition>
        <GuestRoute path={`/${BasePath.CONSUMER}/auth`} exact component={UserAuth} />
        <Route
          path="/"
          exact
          render={() => {
            if (whiteLabelContext?.isWhiteLabeled) {
              // lock root to whitelabeled venue
              return <Redirect to={`/${BasePath.CONSUMER}/stores/${whiteLabelContext.venueId}`} />;
            } else {
              return <Home />;
            }
          }}
        />

        <PrivateRoute path={`/${BasePath.INVITES}/:inviteId`} exact component={VenueInvite} />
        <Route
          path={`/${BasePath.PARTNERS}/:partner/accept-invite/:orderId`}
          exact
          component={PartnerInvite}
        />
        <Route
          path={`/${BasePath.CONSUMER}/stores/:venueId${venueValidation}`}
          exact
          component={StoreListing}
        />
        <Route
          path={`/${BasePath.CONSUMER}/stores/:venueId${venueValidation}/products/:storeId`}
          exact
          component={ProductListing}
        />
        <Route
          path={`/${BasePath.CONSUMER}/stores/:venueId${venueValidation}/products/:storeId/product/:productId`}
          exact
          component={ProductDetails}
        />
        <Route path={`/${BasePath.CONSUMER}/cart/:cartId`} exact component={Cart} />
        <Route path={`/${BasePath.CONSUMER}/profile`} exact component={Profile} />
        <Route path={`/${BasePath.CONSUMER}/share`} exact component={FanfoodLove} />
        <Route path={`/${BasePath.CONSUMER}/policies`} exact component={Policies} />
        <Route path={`/${BasePath.CONSUMER}/help`} exact component={Help} />
        <PrivateRoute
          path={`/${BasePath.CONSUMER}/payment/:cartId`}
          exact
          component={PaymentDelivery}
        />
        <PrivateRoute
          path={[
            `/${BasePath.CONSUMER}/order-placed/:orderId/:orderType`,
            `/${BasePath.CONSUMER}/order-placed/:orderId`,
          ]}
          exact
          component={OrderPlaced}
        />
        <PrivateRoute path={`/${BasePath.CONSUMER}/order-history`} exact component={OrderHistory} />
        <PrivateRoute path={`/${BasePath.CONSUMER}/account-details`} exact component={Account} />
        <PrivateRoute path={`/${BasePath.CONSUMER}/payment-details`} exact component={Payment} />
        <PrivateRoute
          path={`/${BasePath.CONSUMER}/chat/:orderId/:venueId${venueValidation}/:storeId`}
          exact
          component={Chat}
        />

        {!whiteLabelContext?.isWhiteLabeled &&
          [
            <Route key="be-1" path={`/${BasePath.BUILDING_EATS}`} exact component={BEHome} />,
            <Route key="be-2" path={`/${BasePath.BUILDING_EATS}/about`} exact component={About} />,
            <Route
              key="be-3"
              path={`/${BasePath.BUILDING_EATS}/appointments`}
              exact
              component={Appointments}
            />,
            <Route
              key="be-4"
              path={`/${BasePath.BUILDING_EATS}/:buildingId`}
              exact
              component={Buildings}
            />,
            <PrivateRoute
              key="suites-1"
              path={`/${BasePath.SUITE}`}
              exact
              component={SuitesHome}
            />,
            <PrivateRoute
              key="suites-2"
              path={`/${BasePath.SUITE}/order-placed/:orderId`}
              exact
              component={SuiteOrderPlaced}
            />,
            <PrivateRoute
              key="suites-3"
              path={`/${BasePath.SUITE}/:suiteId`}
              exact
              component={Steps}
            />,
          ].map((routeComponent: JSX.Element) => routeComponent)}

        <Route
          render={({ location }) => {
            return location.pathname.indexOf(`/${BasePath.SUITE}`) === -1 &&
              location.pathname.indexOf(`/${BasePath.CONSUMER}`) === -1 &&
              location.pathname.indexOf(`/404`) === -1 ? (
              <Redirect to={`/${BasePath.CONSUMER}/stores${location.pathname}`} />
            ) : (
              <Page404 />
            );
          }}
        />
      </AppTransition>
      <Toast />
    </Router>
  );
};

export default AppRouter;
