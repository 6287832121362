import cardLock from 'assets/icons/cardLock.svg';
import React from 'react';
import { Image } from 'semantic-ui-react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';
import Store from '../../../../shared/models/Store';

const SecureCheckoutHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;

  &::after {
    flex: 1;
  }
`;

const HeaderTitle = styled.h1`
  font-family: ${fonts.BOLD};
  color: ${colors.charcoalGrey};
  font-size: 18px;
  letter-spacing: 0;
  margin: 0;
  padding-right: 5px;
`;

const LockIcon = styled(Image)`
  width: 11px;
  height: 15px;
  align-self: center;
  margin-bottom: 2px;
`;

const HeaderLockWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const CheckoutHeader = ({ store }: { store?: Store }) => {
  return (
    <SecureCheckoutHeader>
      <HeaderLockWrapper>
        <HeaderTitle data-testid="store-name">{store?.name}</HeaderTitle>
        <LockIcon src={cardLock} alt="Lock Icon" />
      </HeaderLockWrapper>
    </SecureCheckoutHeader>
  );
};

export default CheckoutHeader;
