import { SelectedComboItem } from 'containers/ProductConfigurator';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import IProduct, { ISubProduct } from '../../shared/models/Product';
import ComboCategory from '../ComboCategory';
import { ComboProductContainer } from './styled';

interface ComboProductProps {
  currencyPrefix: string;
  product: IProduct;
  setSelectedComboItems: React.Dispatch<React.SetStateAction<Array<SelectedComboItem>>>;
  selectedComboItems: Array<SelectedComboItem>;
}

export interface CheckedItem {
  id: string;
  quantity: number;
}

export interface ICheckedCategory {
  categoryId: string;
  limit: number;
  items: Array<CheckedItem>;
  categorySelectionQty: number;
}

export const ComboProduct = ({
  currencyPrefix,
  product,
  selectedComboItems,
  setSelectedComboItems,
}: ComboProductProps) => {
  const [activeIndex, setActiveIndex] = useState([product.subCategories[0].id]);
  const [checkedCategories, setCheckedCategories] = useState<ICheckedCategory[]>([]);

  const editItemCart = async (quantityChange: number, data: ISubProduct) => {
    const selectedItem = selectedComboItems.find(item => item.id === data.id);
    const newQuantity = Math.max((selectedItem?.quantity || 0) + quantityChange, 0);

    const updateSelectedComboItem = selectedComboItems.filter(
      (comboItem: SelectedComboItem) => comboItem.id !== data.id,
    );

    if (newQuantity > 0) {
      updateSelectedComboItem.push({
        id: data.id,
        parentId: data.parentId,
        productId: data.productId,
        name: data.name,
        price: data.price,
        type: 'PRODUCT',
        quantity: newQuantity,
      });
    }

    setSelectedComboItems(updateSelectedComboItem);
  };

  // Update checked items
  useEffect(() => {
    let newCheckedCategories: ICheckedCategory[] = [];
    product.subCategories.forEach(subCategory => {
      const checkedItems = subCategory.subProducts
        .filter(subProduct => selectedComboItems.some(item => item.id === subProduct.id))
        .map(subProduct => {
          const selectedComboItem = selectedComboItems.find(item => item.id === subProduct.id);
          const quantity = selectedComboItem ? selectedComboItem.quantity || 1 : 0;
          return { id: subProduct.id, quantity };
        });

      const categorySelectionQty = checkedItems
        .map((s: CheckedItem) => s.quantity || 1)
        .reduce((prev: number, curr: number) => prev + curr, 0);

      if (checkedItems.length > 0) {
        newCheckedCategories.push({
          categoryId: subCategory.categoryId,
          limit: subCategory.selectionLimit,
          items: checkedItems,
          categorySelectionQty,
        });
      }
    });

    setCheckedCategories(newCheckedCategories);
  }, [product, selectedComboItems]);

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    if (activeIndex.includes(index)) {
      setActiveIndex([...activeIndex.filter(item => item !== index)]);
    } else {
      setActiveIndex([...activeIndex, index]);
    }
  };

  useEffect(() => {
    if (product.subCategories && product.subCategories.length > 0) {
      setActiveIndex(product.subCategories.map((item: any) => item.id));
    } else {
      setActiveIndex([]);
    }
  }, [product]);

  return (
    // product.subCategories.some((category: any) => {
    //   return category.subProductproduct.some((subProduct: any) => subProduct.active)
    // })  && (
    <ComboProductContainer>
      <Accordion styled fluid exclusive={false}>
        {product.subCategories.map((category: any, index: number) => {
          if (category.subProducts.some((subProduct: any) => subProduct.active)) {
            return (
              <ComboCategory
                currencyPrefix={currencyPrefix}
                category={category}
                first={index === 0}
                key={category.id}
                onClick={handleClick}
                selectedComboItems={selectedComboItems}
                setSelectedComboItems={setSelectedComboItems}
                activeIndex={activeIndex}
                checkedCategories={checkedCategories}
                updateQuantity={editItemCart}
              />
            );
          }
        })}
      </Accordion>
    </ComboProductContainer>
  );
  // );
};

export default ComboProduct;
