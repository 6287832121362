import SearchBar from 'components/Organisms/SearchBar';
import { useAppContext } from 'containers/App/AppContext';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BasePath, VenueType } from 'shared/constants';
import FFlogo from '../../../assets/images/fanFoodLogo.png';
import { colors } from '../../../shared/theme';
import BELogo from '../assets/BE_logo.png';
import BuildingCard from '../BuildingCard';
import Footer from '../Footer';
import Restaurants from '../Restaurants';
import StyledButton from '../StyledButton';
import './index.scss';
import {
  AboutBoldSubTitle,
  AboutLeftRightBody,
  AboutLeftText,
  AboutLeftTextBody,
  AboutRightText,
  AboutSectionContainer,
  AboutSubTitle,
  AboutTextWrapper,
  AboutTitle,
  BuildingCards,
  BuildingCardTitle,
  BuildingCardWrapper,
  ButtonContainer,
  ContactContainer,
  ContactSubtitle,
  ContactTitle,
  HomeBackground,
  HomeHeader,
  HomeHeaderContainer,
  HomeHeaderContent,
  HomePageContainer,
  Logo,
  LogoContainer,
  NavContainer,
  NavLink,
  RestaurantTitle,
  ShowMore,
} from './styled';

interface Building {
  id: string;
  name: string;
  imageUrl: string;
  type: string;
  restaurants?: any[];
}

const restaurants = [
  "Corey's NYC Bagel Deli",
  'Chicago Taco Authority',
  'Coastline Catering',
  'Doctor Dogs',
  'Flash Taco',
  "Manny's Deli",
  'Sausage Fest Food Truck',
  'Zero Degrees Truck',
  'Piko Street Food Truck',
  'Black Dog Gelato',
  'Chicago Lunchbox',
  'BBQ Baron',
];

const Home = (): JSX.Element => {
  const AboutSection = React.createRef<HTMLDivElement>();
  const ContactSection = React.createRef<HTMLDivElement>();

  const { theme } = useAppContext();
  const [buildings, setBuildings] = useState<Building[]>([]);
  const numIncrease = window.innerWidth < 380 ? 10 : 20;
  const [numBuildingsRendered, setNumBuildingsRendered] = useState(numIncrease);

  useEffect(() => {
    setNumBuildingsRendered(window.innerWidth < 380 ? 10 : 20);
  }, [buildings]);

  const scrollToRef = (target: any) => {
    const divTarget = target.current;
    if (divTarget) {
      divTarget.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log('Error, no ref');
    }
  };

  const onShowMoreClick = () => {
    setNumBuildingsRendered(numBuildingsRendered + numIncrease);
  };

  return (
    <HomePageContainer>
      <header>
        <HomeBackground>
          <LogoContainer>
            <Logo src={BELogo} alt="buildingeats logo" />
          </LogoContainer>
          <HomeHeaderContainer>
            <HomeHeader>
              <HomeHeaderContent>
                We provide you with the convenience of cashless, contactless orders from the city's
                top restaurants and food trucks.
              </HomeHeaderContent>
              <NavContainer>
                <NavLink onClick={() => scrollToRef(AboutSection)}>ABOUT US</NavLink>
                <NavLink onClick={() => scrollToRef(ContactSection)}>CONTACT US</NavLink>
              </NavContainer>
            </HomeHeader>
          </HomeHeaderContainer>
        </HomeBackground>
      </header>
      <main className="building-eats">
        <BuildingCardTitle>SELECT YOUR BUILDING</BuildingCardTitle>
        <SearchBar
          onResults={setBuildings}
          venueType={VenueType.TENANT_BUILDING}
          includeInactive={true}
          showAutoComplete={false}
          noResultsMsg={'No Results Found. Please try again or explore our other partners!'}
          noResultsColor={theme.primaryColor}
          searchOnFirstLoad={true}
          limit={10000}
        />
        <BuildingCardWrapper>
          <BuildingCards>
            {buildings.slice(0, numBuildingsRendered).map((building: Building, index) => (
              <BuildingCard
                key={index}
                id={building.id}
                imageUrl={building.imageUrl || FFlogo}
                buildingName={building.name}
                currentVenue={building}
              />
            ))}
          </BuildingCards>
        </BuildingCardWrapper>
        {buildings.length > numBuildingsRendered && (
          <ShowMore onClick={onShowMoreClick}>See More Buildings</ShowMore>
        )}
        <RestaurantTitle>RESTAURANT &amp; FOOD TRUCK PARTNERS</RestaurantTitle>
        <Restaurants restaurants={restaurants} />

        <AboutSectionContainer>
          <AboutTitle ref={AboutSection as any}>ABOUT US</AboutTitle>
          <AboutTextWrapper>
            <AboutLeftText>
              <AboutSubTitle>Connecting tenants to local food experiences</AboutSubTitle>

              <AboutLeftTextBody>
                From connecting sports fans to their favorite concession food, to now connecting
                tenants to local food experiences, BuildingEats is all about cultivating communities
                through frictionless and personalized ordering experiences. BuildingEats is a new
                initiative that brings restaurants and food trucks directly to building tenants
                through bulk ordering, thus eliminating the time, labor, and hefty delivery fees as
                seen on traditional third-party apps.
              </AboutLeftTextBody>
              <AboutSubTitle style={{ marginTop: '20px' }}>
                Supporting the local community
              </AboutSubTitle>
              <AboutLeftTextBody>
                BuildingEats, just like FanFood, is entirely commission-free for restaurants to use
                with no upfront cost. For every order placed on the platform, FanFood donates a meal
                to a local food bank as an official partner of Feeding America.
              </AboutLeftTextBody>
            </AboutLeftText>

            <AboutRightText>
              <AboutBoldSubTitle>BUILDING TENANTS</AboutBoldSubTitle>
              <AboutLeftRightBody>
                Pre-order from a selection of local favorites from the comfort of your home or
                office. Can't decide what to eat? There are new choices each week, delivered right
                to your doorstep.
              </AboutLeftRightBody>
              <AboutBoldSubTitle>RESTAURANTS AND BUSINESSES</AboutBoldSubTitle>
              <AboutLeftRightBody>
                Serve a "full house" every evening by fulfilling orders from an entire building.
                These tenants will soon become your most loyal customers.
              </AboutLeftRightBody>
            </AboutRightText>
          </AboutTextWrapper>
        </AboutSectionContainer>

        <ContactContainer>
          <ContactTitle ref={ContactSection as any}>CONTACT US</ContactTitle>
          <ContactSubtitle>
            Don’t see your building listed? Want to add your restaurant to our list?
          </ContactSubtitle>
          <ButtonContainer>
            <Link to={`/${BasePath.BUILDING_EATS}/appointments`}>
              <StyledButton color={colors.charcoalGrey} text="SCHEDULE A MEETING" />
            </Link>
          </ButtonContainer>
        </ContactContainer>
      </main>
      <Footer transparent={false} />
    </HomePageContainer>
  );
};

export default Home;
