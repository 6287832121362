import CalendarIcon from 'assets/icons/calendar.svg';
import UserIcon from 'assets/icons/userIcon.svg';
import SuiteHeaderImg from 'assets/images/suiteHeader.png';
import { useAppContext } from 'containers/App/AppContext';
import { useSuiteContext } from 'containers/Suite/Steps/SuiteContext';
import useWindowWidth from 'hooks/UseWindowWidth';
import * as QueryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Grid, Image } from 'semantic-ui-react';
import { Event, EventsService } from 'services/EventsService';
import { IVenueLocation } from 'shared/models/Venue';
import { formatDateTime } from 'shared/utils';
import { useStoreContext } from '../../../hooks/useStoreContext';
import { useVenueContext } from '../../../hooks/useVenueContext';
import Header from '../../Atoms/Header';
import EventsModal from '../EventsModal';
import {
  Calendar,
  CenteredContent,
  GreyRow,
  ProfileButtonContainer,
  SelectDate,
  StyledGrid,
  UserIconStyles,
} from './styled';

interface SuiteHeaderProps {
  handleSetVisibleMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  suite?: IVenueLocation;
}

const SuiteHeader = ({ handleSetVisibleMenu, suite }: SuiteHeaderProps) => {
  const [openEventsModal, setEventsModal] = useState(false);
  const [events, setEvents] = useState<Event[] | undefined>(undefined);
  const { venue } = useVenueContext();
  const { store } = useStoreContext();
  const location = useLocation();
  const width = useWindowWidth();
  const history = useHistory();
  const { handleSelectedEvent, selectedEvent, setSelectedEvent } = useSuiteContext();
  const { user } = useAppContext();

  const isMobile = width < 800;

  useEffect(() => {
    if (!selectedEvent && venue && suite) {
      setEventsModal(true);
    }
  }, [venue, suite]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!suite) return;

      const queryString = QueryString.parse(location.search);
      if (selectedEvent && selectedEvent.venueId !== suite.venueId) {
        setSelectedEvent(undefined);
      }

      try {
        const { data: events } = await EventsService.getSuiteEvents(suite.id);
        setEvents(events);
        if (!queryString.eventId) {
          return;
        }
        const selectedEvent = events.find(event => event.id === queryString.eventId);

        if (selectedEvent) {
          handleEventSelect(selectedEvent);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEvents();
  }, [suite]);

  const handleShowEvents = () => {
    setEventsModal(true);
  };

  const handleEventSelect = (event: Event) => {
    if (!suite) {
      return;
    }

    setEventsModal(false);

    handleSelectedEvent(event);
  };

  const navigateToHome = () => {
    history.replace('/');
  };

  return (
    <>
      <StyledGrid backgroundimage={venue?.imageUrl ?? SuiteHeaderImg}>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Grid.Column floated="right" computer={3} tablet={4} mobile={4}>
            <CenteredContent style={{ marginRight: 10 }}>
              {handleSetVisibleMenu && (
                <ProfileButtonContainer onClick={() => handleSetVisibleMenu(true)}>
                  <Image src={UserIcon} style={UserIconStyles} alt="Access your profile" />
                  {isMobile ? '' : `Hi ${user?.firstName || 'FanFood User!'}`}
                </ProfileButtonContainer>
              )}
            </CenteredContent>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0, marginTop: -10 }} centered stretched>
          <Header
            color={'white'}
            font="SUPER"
            upperCase={true}
            size={isMobile ? 32 : 45}
            textAlign="center"
          >
            {venue ? (
              <>
                {venue.name} {suite && <>({suite.name})</>}
              </>
            ) : (
              <>Suites</>
            )}
          </Header>
        </Grid.Row>
      </StyledGrid>
      <Grid centered>
        {venue && suite && store && (
          <GreyRow centered>
            <SelectDate onClick={handleShowEvents}>
              {selectedEvent && `${selectedEvent.name} | `}
              <Calendar src={CalendarIcon} />

              {venue && selectedEvent && selectedEvent.id
                ? formatDateTime({
                    date: selectedEvent.startDate || '',
                    selectedEventDate: true,
                    timezone: venue.timezone,
                  })
                : 'Select Event Date'}
            </SelectDate>
          </GreyRow>
        )}
      </Grid>

      <EventsModal
        {...{
          events,
          handleEventSelect,
          onClose: () => setEventsModal(false),
          selectedEvent,
          venue,
          openEventsModal,
        }}
      />
    </>
  );
};

export default SuiteHeader;
