import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';
import { Select } from 'semantic-ui-react';

const StyledSelect = styled(Select)`
  border: unset !important;
  border-radius: unset !important;
  border-bottom: 1px solid ${colors.borderInput} !important;
  font-family: ${fonts.LIGHT};
  font-size: 16px;
  .dropdown.icon {
    position: relative !important;
    height: 10px !important;
    width: 10px !important;
    padding: 0px !important;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: ${colors.grey};
    transform: rotate(45deg);
    float: right;
    &::before {
      content: '' !important;
    }
  }
`;

type OptionType = {
  key: string;
  value: string;
  text: string;
};

type FloatingLabelInputType = {
  id: string;
  isRequired: boolean;
  autoComplete?: string;
  errorMsg?: string;
  name: string;
  value?: string;
  descriptor?: string;
  placeholder: any;
  pattern?: any;
  type?: string;
  isDisabled: boolean;
  noMargin?: boolean;
  showError?: boolean;
  onChange: (e: any, value?: any) => void;
  onFocus?: (e: any, value?: any) => void;
  onBlur?: (e: any, value?: any) => void;
  options?: OptionType[];
  width?: string;
  hideLabel?: boolean;
  maxLength?: number;
  children?: React.ReactNode;
};

type FloatingLabelInputContainerProps = {
  hasValue: boolean;
  hasErrorMsg: boolean;
  hasOptions?: boolean;
  isDisabled: boolean;
  noMargin: boolean;
  width?: string;
  providedDetails?: boolean;
};

const FloatingLabelInputContainer = styled.div<FloatingLabelInputContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${props => (props.noMargin ? '0' : '30px 0')};
  width: ${props => (props.width ? props.width : '100%')};

  ${StyledSelect} {
    display: flex;
    line-height: 20px;
    min-height: unset !important;
    width: 100%;
    flex: 1 1 auto;
    order: 2;
    padding: 8px 0px !important;
  }

  input {
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid ${props => (props.hasErrorMsg && !props.isDisabled ? props.theme.primaryColor : colors.borderInput)};
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    order: 2;
    font-family: ${fonts.LIGHT};
    font-size: 16px;
    color: ${colors.inputFontColor};
    background: transparent;
    padding: 6px 0 -1px 0;
    &:focus {
      outline: none;
    }
    &:disabled {
      background: inherit;
      color: ${colors.inputFontColor};
    }
  }
  label,
  span,
  p {
    font-family: ${fonts.REGULAR};
  }

  label {
    font-size: 16px;
    color: ${colors.inputBorderBottom};
    order: 1;
    padding-left: 2px;
    pointer-events: none;
    transform-origin: left top;
    transition: 200ms ease all;
    ${props =>
    props.hasOptions
      ? props.hasValue
        ? 'transform: scale(0.8) translate3d(0, 5px, 0);'
        : 'transform: scale(0) translate3d(0, 22px, 0);'
      : props.hasValue
        ? 'transform: scale(0.8) translate3d(0, 5px, 0);'
        : 'transform: scale(1) translate3d(0, 22px, 0);'}

    span {
      color: ${({ theme }) => theme.primaryColor};
      padding-right: 5px;
    }
  }
  p {
    color: ${colors.inputBorderBottom};
    display: flex;
    order: 4;
    font-style: italic;
    font-size: 12px;
    margin: 5px 0 !important;
  }
  .error-msg {
    color: ${({ theme }) => theme.primaryColor};
    padding-top: 5px;
    font-size: 13px;
    display: flex;
    order: 3;
  }

  span,
  p,
  span.error-msg {
    ${props => props.isDisabled && 'display: none;'}
  }
`;

const FloatingLabelInput = ({
  id,
  autoComplete = 'false',
  errorMsg,
  isDisabled,
  name,
  pattern,
  isRequired,
  placeholder,
  type,
  onChange,
  onBlur,
  onFocus,
  value,
  descriptor,
  maxLength,
  noMargin = false,
  showError,
  options = [],
  hideLabel = false,
  children,
  providedDetails,
}: any) => {
  const [hasValue, setHasValue] = useState(Boolean(value));
  const [hasError, setHasError] = useState(false);
  const hasOptions = options && options.length > 0;

  const handleOnFocus = (e: any) => {
    setHasValue(true);
    if (onFocus) onFocus(e);
  };

  const onInputBlur = (event: any, val?: any) => {
    if (hasOptions) {
      return;
    }
    setHasValue(Boolean(event.currentTarget.value));
    if (pattern) {
      const mhasError = !pattern.test(event.currentTarget.value);
      setHasError(mhasError);
      if (val && onBlur) {
        onBlur({
          name,
          value: val.value,
          hasError: mhasError,
        });
      } else {
        onBlur &&
          onBlur({
            name: event.currentTarget.name,
            value: event.currentTarget.value,
            hasError: mhasError,
          });
      }
    } else {
      if (val && onBlur) {
        onBlur(event, {
          name,
          value: val.value,
        });
      } else {
        onBlur && onBlur(event);
      }
    }
  };

  const onInputChange = (event: any, val?: any) => {
    if (pattern) {
      const mhasError = !pattern.test(event.currentTarget.value);
      setHasError(mhasError);
      if (val) {
        onChange({
          name,
          value: val.value,
          hasError: mhasError,
        });
      } else {
        onChange({
          name: event.currentTarget.name,
          value: event.currentTarget.value,
          hasError: mhasError,
        });
      }
    } else {
      if (val) {
        onChange(event, {
          name,
          value: val.value,
        });
      } else {
        onChange(event.currentTarget);
      }
    }
  };

  const showMessageError = showError ? showError : errorMsg && hasError;

  return (
    <FloatingLabelInputContainer
      noMargin={noMargin}
      hasErrorMsg={Boolean(showMessageError)}
      isDisabled={isDisabled}
      hasValue={hasValue}
      hasOptions={hasOptions}
      providedDetails={providedDetails}
    >
      {!hideLabel && (
        <label htmlFor={id}>
          {isRequired && <span>*</span>}
          {placeholder}
        </label>
      )}
      {hasOptions ? (
        <StyledSelect
          fluid
          required={isRequired}
          disabled={isDisabled}
          placeholder={placeholder}
          size='mini'
          onFocus={(e: any) => handleOnFocus(e)}
          onBlur={onBlur}
          onChange={onInputChange}
          options={options}
          defaultValue={value}
        />
      ) : (
        <>
          <input
            name={name}
            value={value}
            autoComplete={autoComplete}
            disabled={isDisabled}
            id={id}
            onFocus={(e: any) => handleOnFocus(e)}
            onBlur={onInputBlur}
            onChange={onInputChange}
            type={type}
            maxLength={maxLength}
          />
          {children}
        </>
      )}
      {showMessageError && <span className='error-msg'>{errorMsg}</span>}
      {descriptor && <p>{descriptor}</p>}
    </FloatingLabelInputContainer>
  );
};

export default FloatingLabelInput;
