import { useEffect, useState } from 'react';
import { useStoreContext } from './useStoreContext';
import { useVenueContext } from './useVenueContext';

interface IOrderTypeHook {
  pickup: boolean;
  delivery: boolean;
  orderAhead: boolean;
  orderNow: boolean;
}

export enum OrderTypeServices {
  DELIVERY = 'deliveryAvailable',
  PICKUP = 'pickupAvailable',
  ORDER_AHEAD = 'orderAheadAvailable',
  ORDER_NOW = 'orderNowAvailable',
}

interface IOrderTypeHookParams {
  isStore: boolean;
}

export const useOrderTypeHook = ({ isStore }: IOrderTypeHookParams): IOrderTypeHook => {
  const { venue } = useVenueContext({});
  const { store } = useStoreContext({});
  const [pickup, setPickUp] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [orderAhead, setOrderAhead] = useState(false);
  const [orderNow, setOrderNow] = useState(false);

  useEffect(() => {
    if (!venue) {
      return;
    }
    checkOrderAvailability();
  }, [venue, store]);

  const storeServiceAvailable = (service: OrderTypeServices): boolean => {
    return (venue && store && store.active && !!venue[service] && !!store[service]) || false;
  };
  const venueServiceAvailable = (service: OrderTypeServices): boolean => {
    return (venue && venue.active && !!venue[service]) || false;
  };

  const checkOrderAvailability = () => {
    if (!venue) {
      return;
    }

    if (isStore) {
      checkStoreOrderAvailability();
    } else {
      checkVenueOrderAvailability();
    }
  };

  const checkStoreOrderAvailability = () => {
    const pickupAvailable = storeServiceAvailable(OrderTypeServices.PICKUP);
    const deliveryAvailable = storeServiceAvailable(OrderTypeServices.DELIVERY);
    const orderAheadAvailable = storeServiceAvailable(OrderTypeServices.ORDER_AHEAD);
    const orderNowAvailable = storeServiceAvailable(OrderTypeServices.ORDER_NOW);

    setPickUp(pickupAvailable);
    setDelivery(deliveryAvailable);
    setOrderAhead((pickupAvailable || deliveryAvailable) && orderAheadAvailable);
    setOrderNow((pickupAvailable || deliveryAvailable) && orderNowAvailable);
  };

  const checkVenueOrderAvailability = () => {
    const pickupAvailable = venueServiceAvailable(OrderTypeServices.PICKUP);
    const deliveryAvailable = venueServiceAvailable(OrderTypeServices.DELIVERY);
    const orderAheadAvailable = venueServiceAvailable(OrderTypeServices.ORDER_AHEAD);
    const orderNowAvailable = venueServiceAvailable(OrderTypeServices.ORDER_NOW);

    setPickUp(pickupAvailable);
    setDelivery(deliveryAvailable);
    setOrderAhead((pickupAvailable || deliveryAvailable) && orderAheadAvailable);
    setOrderNow((pickupAvailable || deliveryAvailable) && orderNowAvailable);
  };

  return {
    pickup,
    delivery,
    orderAhead,
    orderNow,
  };
};
