import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Event, EventsService, ITimeSlot } from 'services/EventsService';
import { BasePath } from 'shared/constants';
import styled from 'styled-components';
import { EventTimeSlot } from '../../../components/Organisms/Modals/OrderAhead';
import { colors, fonts } from '../../../shared/theme';
import { useBuildingEatsContext, useEventContext } from '../../App/AppContext';
import RestaurantAvailability from './RestaurantAvailability';

interface BuildingPageRestaurantCardProps {
  image: string;
  eventName: string;
  description: string;
  availability?: any;
  startDate: string;
  endDate: string;
  restaurantOptions?: Array<any>;
  venueId: string;
  eventId: string;
  buildingId: string;
}

const RestaurantCardContainer = styled.div`
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 47px;
  padding-bottom: 47px;
  border-bottom: 1px solid ${colors.lightGrey};
  grid-gap: 15px;

  @media (min-width: 768px) {
    grid-template-columns: auto 1fr;
  }
`;

const RestaurantPhoto = styled.img`
  border-radius: 12px;
  align-self: flex-start;

  @media (max-width: 425px) {
    width: 100%;
  }

  width: min(max(350px, calc(21.875rem + ((1vw - 3.75px) * -4.6948))), 300px);
  min-width: 0vw;

  height: min(max(200px, calc(12.5rem + ((1vw - 3.75px) * 2.3474))), 225px);
  min-height: 0vw;
`;

const RestaurantDetailsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, min-content);
`;

const RestaurantTextContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-gap: 10px;
  margin: 0 10px 5px 10px;

  @media (min-width: 768px) {
    margin: 0 0 5px 0;
  }
`;

const RestaurantName = styled.div`
  font-family: ${fonts.BOLD};
  font-size: 18px;

  @media (min-width: 768px) {
    grid-column: 1;
  }
`;

const RestaurantDescription = styled.div`
  font-family: ${fonts.LIGHT};
  font-size: 14px;

  @media (min-width: 900px) {
    width: 80%;
  }
`;

const RestaurantDatesContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 10px;
  align-self: end;
`;

const RestaurantOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  @media (min-width: 768px) {
    grid-template-rows: 1fr 1fr;
    grid-gap: 5px;
  }

  img {
    @media (min-width: 768px) {
      grid-row: 1;
    }
  }
`;

const AvailabilityHeader = styled.div`
  font-family: ${fonts.BOLD};
  font-size: 16px;
  color: ${colors.charcoalGrey};
  margin-left: 10px;

  @media (min-width: 600px) and (max-width: 899px) {
    margin: 0;
  }

  @media (min-width: 900px) {
    margin: 0;
  }
`;

const TimeSlotsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ShowMore = styled.div`
  color: #147c9e;
  font-family: ${fonts.REGULAR};
  font-size: 15px;
  text-align: center;
  margin: 15px 0 0 0;
  cursor: pointer;
`;

const Months: string[] = [
  'JAN.',
  'FEB.',
  'MAR.',
  'APR.',
  'MAY',
  'JUNE',
  'JULY',
  'AUG.',
  'SEP.',
  'OCT.',
  'NOV.',
  'DEC.',
];

const Weekdays: string[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

const BuildingPageRestaurantCard = ({
  image,
  eventName,
  description,
  startDate,
  venueId,
  eventId,
  buildingId,
}: BuildingPageRestaurantCardProps) => {
  const [date, setDate] = React.useState<string>('');
  const [eventTimeSlots, setEventTimeSlots] = useState<EventTimeSlot[]>([]);
  const [numAvailabilitiesRendered, setNumAvailabilitiesRendered] = useState(
    window.innerWidth < 380 ? 4 : 9,
  );
  const { setEvent } = useEventContext();
  const { setBuildingEatsVenueId } = useBuildingEatsContext();

  const history = useHistory();

  const convertDateToWeekday = (startDate: string) => {
    const day = new Date(startDate);
    const weekday = day.getDay();
    const weekdate = day.getDate();
    const month = day.getMonth();

    setDate(`${Weekdays[weekday]}, ${Months[month]} ${weekdate}`);
  };

  const calculateTimeSlots = async (eventId: string) => {
    try {
      const { data } = await EventsService.getEventTimeSlots(eventId);
      const timeSlots: EventTimeSlot[] = data.slots.map((slot: ITimeSlot) => ({
        startTime: format(parseISO(slot.start), 'h:mm a'),
        endTime: format(parseISO(slot.end), 'h:mm a'),
        value: slot.start,
      }));
      setEventTimeSlots(timeSlots);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error.response);
      toast('Sorry, something went wrong while trying to fetch available Time Slots');
    }
  };

  const handleSubmitClick = (event: EventTimeSlot) => {
    if (!event) {
      toast('Invalid event or timeslot.');
      return;
    }

    const eventData: Event = {
      id: eventId,
      name: eventName,
      scheduledTime: event.value,
      venueId,
      startDate: event.startTime,
      endDate: event.endTime,
      advanceOrderWindow: 0,
    };

    setEvent(eventData);
    setBuildingEatsVenueId(buildingId);
    history.push(`/${BasePath.CONSUMER}/stores/${venueId}`);
  };

  useEffect(() => {
    if (startDate) {
      convertDateToWeekday(startDate);
    }

    if (eventId) {
      calculateTimeSlots(eventId);
    }
  }, [startDate, eventId]);

  const onShowMoreClick = () => setNumAvailabilitiesRendered(numAvailabilitiesRendered + 5);

  return (
    <RestaurantCardContainer>
      <RestaurantPhoto src={image} />
      <RestaurantDetailsContainer>
        <RestaurantTextContainer>
          <RestaurantOptions>
            <RestaurantName>{eventName}</RestaurantName>
          </RestaurantOptions>

          <RestaurantDescription>{description}</RestaurantDescription>
        </RestaurantTextContainer>

        <RestaurantDatesContainer>
          {date.length > 0 && <AvailabilityHeader>{date}</AvailabilityHeader>}
          <TimeSlotsContainer>
            {eventTimeSlots ? (
              eventTimeSlots.slice(0, numAvailabilitiesRendered).map((timeslot, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      handleSubmitClick(timeslot);
                    }}
                  >
                    <RestaurantAvailability
                      startTime={timeslot.startTime}
                      endTime={timeslot.endTime}
                      value={timeslot.value}
                    />
                  </div>
                );
              })
            ) : (
              <div>LOADING...</div>
            )}
          </TimeSlotsContainer>
          {eventTimeSlots.length > 6 && eventTimeSlots.length - 2 >= numAvailabilitiesRendered && (
            <ShowMore onClick={() => onShowMoreClick()}>See More Times</ShowMore>
          )}
        </RestaurantDatesContainer>
      </RestaurantDetailsContainer>
    </RestaurantCardContainer>
  );
};

export default BuildingPageRestaurantCard;
