import styled from 'styled-components';
import { colors, fonts } from '../../../shared/theme';

export const StyledContainer = styled.div`
  .ui.basic.clearing.segment {
    padding-right: 0px;
    padding-left: 0px;
  }
  .ui.segment {
    padding: 20px;
    margin: 5px 10px;
    border-radius: 10px;
  }
  h1 {
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 200;
  }
  h2 {
    color: ${colors.charcoalGrey};
    font-family: ${fonts.LIGHT};
    font-size: 20px;
    font-weight: 300;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
  }
  button.ui.circular.button.add-cc-btn {
    background: white;
    padding: 25px;
    border-radius: 50% !important;
    width: 93px;
    height: 93px;
    box-shadow: 0px 0px 6px 2px #c2c2c2;
  }
`;

export const SavedPaymentsContainer = styled.div`
  .ui.list {
    margin-top: 25px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 20px;
    position: relative;
    overflow: hidden;
    .item {
      padding: 15px !important;
      margin-bottom: 5px;
      font-family: ${fonts.LIGHT};
      font-size: 16px;
      color: ${colors.charcoalGrey};
      position: relative;
    }
  }
`;

export const CreditCardContainer = styled.div<{ deployed: boolean }>`
  .ui.grid {
    position: relative;
    left: ${props => (props.deployed ? '35px' : '0px')};
    transition: ease-out 0.2s;
    .column:last-child {
      padding-right: 0px;
    }
  }
  .number {
    padding-left: 0px;
    text-align: left;
    span {
      letter-spacing: 3px;
    }
  }
  .expiracy {
    padding-right: 0px;
    text-align: right;
    font-size: 14px;
  }
  .ui.image {
    display: inline-block;
    margin-right: 10px;
    bottom: 3px;
  }
  .selected-card {
    text-align: right;
    font-size: 12px;
    line-height: 12px;
    font-family: ${fonts.REGULAR};
    font-weight: lighter;
    padding: 0px !important;
    min-height: 12px;
  }
`;

export const SlideOptions = styled.div<{ deployed: boolean }>`
  height: 40px;
  position: absolute;
  width: 55px;
  background: ${colors.lightGrey};
  left: ${props => (props.deployed ? '-20px' : '-55px')};
  top: 6px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  align-content: center;
  transition: ease-out 0.2s;
  .ui.image.caret {
    height: 12px;
    width: auto;
    align-self: center;
    right: 0px;
    top: 13px;
    position: absolute;
    margin-right: 8px;
    cursor: pointer;
  }
  .actions {
    align-self: center;
    top: 2px;
    left: 10px;
    position: relative;
    .pencil {
      height: 21px;
      cursor: pointer;
    }
    .trash {
      height: 22px;
      cursor: pointer;
    }
  }
`;
