import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

type StripeFormProps = {
  onSave: (token: string | undefined) => Promise<void>;
  setOpenCC: Dispatch<SetStateAction<boolean>>;
};

interface CCForm {
  [elementType: string]: boolean;
}

const StripeFormContainer = styled.div`
  .StripeElement {
    width: 300px;
    margin: 0 auto;
    border-bottom: 1px solid #6e6e6e;
  }
  .ui.fluid.button {
    background: ${({ theme }) => theme.primaryColor};
    text-transform: uppercase;
    color: white;
    font-family: ${fonts.MEDIUM};
    font-size: 18px;
    font-weight: lighter;
  }
  .fluid.input.transparent.ui {
    border-bottom: 1px solid #6e6e6e;
  }
  .ui.segment {
    border: none;
    box-shadow: none;
  }
  .title {
    color: ${colors.charcoalGrey};
    font-family: ${fonts.LIGHT};
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 10px;
  }
`;

const StripeForm = ({ onSave, setOpenCC }: StripeFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [ccFormState, setCCFormState] = useState<CCForm>({});

  const handleSaveCC = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    setSaveDisabled(true);
    const card = elements.getElement(CardElement);

    if (!card) {
      return;
    }

    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
      });
      setOpenCC(false);
      await onSave(paymentMethod?.id);
    } catch (error) {
      card.clear();
    } finally {
      setLoading(false);
      setSaveDisabled(false);
    }
  };

  const onCardFormChange = (changeEvent: StripeCardElementChangeEvent) => {
    setCCFormState(prev => ({
      ...prev,
      [changeEvent.elementType]: changeEvent.complete,
    }));
  };

  const isCCFormInvalid =
    Object.values(ccFormState).some(value => !value) || Object.values(ccFormState).length <= 0;
  return (
    <StripeFormContainer>
      <Segment loading={loading}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="title">Card Information</div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid.Row>
                <CardElement onChange={onCardFormChange} onReady={() => setLoading(false)} />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Button
                onClick={async e => await handleSaveCC(e)}
                disabled={saveDisabled || isCCFormInvalid}
                fluid
                content="Save"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </StripeFormContainer>
  );
};

export default StripeForm;
