import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const ChatBubbleContainer = styled.div`
  .title {
    margin-left: 0 !important;
    text-align: center;
  }
  .bubble-container {
    padding: 0;
  }
  .bubble {
    color: ${colors.secondary};
    font-family: ${fonts.REGULAR};
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    font-size: 15px;
    line-height: 19px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border: 13px solid transparent;
      border-bottom: 0;
      margin-left: -6.5px;
      margin-bottom: -13px;
    }
  }

  .default-color {
    background-color: ${({ theme }) => theme.primaryColor};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 50%;
    padding-left: 21px;
    &:after {
      border-top-color: ${({ theme }) => theme.primaryColor};
      border-left: 0;
      left: 10%;
    }
  }
  .alternative-color {
    background-color: ${colors.bubbleBlue};
    color: ${colors.grape};
    text-align: right;
    padding-right: 21px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: 50%;
    &:after {
      border-top-color: ${colors.bubbleBlue};
      border-right: 0;
      right: 10%;
    }
  }
`;
