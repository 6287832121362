import { Square } from '../types';

declare global {
  interface Window {
    Square: Square;
  }
}

const getScript = (src: string): HTMLScriptElement | null =>
  document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);

const injectScript = (src: string): HTMLScriptElement => {
  const scriptLocation = document.head || document.body;

  if (!scriptLocation) {
    throw new Error('Square needs a body or head element');
  }

  const script = document.createElement('script');
  script.src = src;
  script.async = true;

  scriptLocation.appendChild(script);

  return script;
};

export async function loadSquare(src: string): Promise<Square | null> {
  let square: Promise<Square | null> | null = null;

  square = new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      resolve(null);
      return;
    }

    if (window.Square) {
      resolve(window.Square);
      return;
    }

    try {
      let script = getScript(src);

      if (!script) {
        script = injectScript(src);
      }

      script.addEventListener('load', () => {
        if (window.Square) {
          resolve(window.Square);
        } else {
          reject(new Error('Square.js failed to load properly.'));
        }
      });

      script.addEventListener('error', () => {
        reject(new Error('Error occurred while loading Square.js'));
      });
    } catch (err) {
      reject(err);
    }
  });

  return square;
}
