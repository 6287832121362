import styled from 'styled-components';
import { fonts, colors } from '../../shared/theme';

export const LocationContainer = styled.div`
  margin: 50px 60px 30px;
  > .ui.image {
    height: 66px;
    width: auto;
    margin: auto;
  }
  .title {
    margin-top: 23px;
    text-align: center;
    font-family: ${fonts.SUPER};
    font-weight: lighter;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    text-transform: uppercase;
  }
  .location {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-family: ${fonts.REGULAR};
    font-weight: lighter;
    font-size: 18px;
    color: ${colors.charcoalGrey};
    span {
      display: block;
    }
  }
`;

export const LocationActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .ui.button {
    padding: 0px;
    background: none;
    margin: 5px auto;
    width: max-content;
    color: ${colors.niceBlue};
    box-shadow: none;
    text-transform: uppercase;
    font-size: 12px;
    font-family: ${fonts.REGULAR};
    font-weight: lighter;
    &:active,
    &:focus {
      background: none;
    }
    .ui.image {
      height: 23px;
      width: auto;
    }
    &:last-child {
      margin-top: 10px;
    }
  }
`;

export const SummaryContainer = styled.div`
  padding-top: 20px;
  font-size: 14px;
  .title {
    font-family: ${fonts.SUPER};
    font-size: 18px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: lighter;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 15px;
  }
  .ui.list {
    .header .ui.grid .row {
      padding: 0px;

      .sub-item {
        margin-left: 10px;
      }
    }
  }
  .ui.card {
    padding: 0px 25px;
    .content {
      margin-right: -15px;
      margin-left: -15px;
      .ui.list {
        padding-bottom: 10px;
      }
    }
    .header {
      margin-bottom: 10px;
      .ui.list {
        margin-top: 0px;
        > .item .header {
          font-family: ${fonts.REGULAR};
          font-weight: lighter;
        }
      }
      .ui.grid > .row {
        padding: 15px 0px;
        &.no-padding-bottom {
          padding-bottom: 0;
        }
        &.no-padding-top {
          padding-top: 0;
        }
      }
    }
  }
  .ui.grid.grid-totals {
    padding: 10px;
    .row {
      padding: 4px 5px;
      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        padding-bottom: 0px;
      }
      .column {
        padding: 0px;
      }
    }
    .total {
      font-family: ${fonts.MEDIUM};
      font-weight: lighter;
    }
  }
`;

export const OrderDetailsActionsContainer = styled.div`
  margin-top: 30px;
  &&& {
    .row {
      padding: 0.3em 0px;
    }
    .button {
      color: ${colors.niceBlue} !important;
      box-shadow: 0 0 0 1px ${colors.niceBlue} inset;
      &:hover,
      &:active,
      &:focus {
        color: ${colors.niceBlue} !important;
        box-shadow: 0 0 0 1px ${colors.niceBlue} inset;
      }
    }
    .cancel-button {
      color: ${colors.niceBlue} !important;
      font-family: ${fonts.REGULAR};
      font-size: 12px;
      font-weight: lighter;
      text-transform: uppercase;
      box-shadow: none;
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        color: ${colors.niceBlue} !important;
      }
    }
  }
`;
