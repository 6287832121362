import { useMachine } from '@xstate/react/lib';
import closeSearchBtn from 'assets/icons/closeGray.png';
import EditWhite from 'assets/icons/EditWhite.png';
import fanFoodLogo from 'assets/images/fanFoodLogo.png';
import { Button } from 'components/Atoms/Button';
import Header, { Size } from 'components/Atoms/Header';
import Spacer, { SpacerSizes } from 'components/Atoms/Spacer';
import Footer from 'components/Organisms/Footer';
import { closeStyle } from 'components/Organisms/SuiteHeader/styled';
import { useAppContext } from 'containers/App/AppContext';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import {
  Grid,
  Image,
  Input,
  InputOnChangeData,
  Loader,
  Modal,
  TransitionablePortal,
} from 'semantic-ui-react';
import { UserService } from 'services/UserService';
import { saveSessionValues } from 'shared/SessionAuthPersist';
import { colors } from 'shared/theme';
import IUser from '../../../shared/models/User';
import authMachine from './machine';
import closeMenu from 'assets/icons/closeSearchBtn.png';
import {
  GridContainer,
  HomeContainer,
  PhoneInputContainer,
  ResendContainer,
  Spacer as StyleSpacer,
} from './styled';
declare const ENVARS: any;

enum CaptchaKey {
  clientKey = '6LdJ3JMiAAAAAHHle6rGZnuBWt0ACZ876PFzW9aT',
}

const Auth = () => {
  const [current, send] = useMachine(authMachine);
  const { setUser } = useAppContext();
  const history = useHistory();
  const [captcha, setCaptcha] = React.useState<boolean>(false);

  useEffect(() => {
    if (current.matches('failure') || current.matches('rejectedError')) {
      toast(current.context.errorMessage);
    }
  }, [current]);

  const handleRequestPin = async () => {
    send('SEND_REQUEST');
    const { nationalNumber, countryCallingCode } = parsePhoneNumber(current.context.phoneNumber);
    const result = await UserService.SendOtp({
      phoneNumber: nationalNumber.toString(),
      countryCode: `+${countryCallingCode.toString()}`,
    })
      .then(data => data.data.data.success)
      .catch((error: any) => {
        send({ type: 'REJECT', message: error.response.data.message });
        return undefined;
      });
    if (result) {
      send('SUCCESS');
    }
  };

  const handleEditPhoneNumber = () => {
    send('EDIT_NUMBER');
  };

  const handleCodeInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ) => {
    send({ type: 'TYPING', number: data.value });
    if (data.value.length >= 6) {
      send('VALIDATE');
      const { nationalNumber, countryCallingCode } = parsePhoneNumber(current.context.phoneNumber);
      const result = await UserService.VerifyOtp({
        phoneNumber: nationalNumber.toString(),
        countryCode: `+${countryCallingCode.toString()}`,
        otpCode: data.value,
        createUser: true,
      })
        .then(returned => returned.data.data)
        .catch((error: any) => {
          send({ type: 'ERROR', message: error.response.data.message });
          return undefined;
        });
      if (result) {
        const { id, authToken, firstName, phoneNumber } = result;
        saveSessionValues({ id, authToken, firstName, phoneNumber });
        setUser(result as IUser);
        send('AUTHENTICATED');
      }
    }
  };

  const handleInputChange = (phoneNumber: string) => {
    send({ type: 'TYPING', number: phoneNumber });
  };

  const showLoader = (): boolean => {
    return current.matches('sendingOTP') || current.matches('validatingCode');
  };

  const handleCloseButtonClick = () => {
    history.replace('/');
  };

  const onChangeCaptcha = (token: string | null) => {
    if (token) {
      setCaptcha(false)
      handleRequestPin()
    } else {
      setCaptcha(false)
      toast('Captcha validation failed, Please try again.');
    } 
  }

  const handleCaptcha = () => {
    setCaptcha(true)
  }

  return (
    <HomeContainer>
      <GridContainer centered columns={1}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <Image src={fanFoodLogo} className="logo" centered alt="FanFood logo" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={6}>
            <Header
              size={current.context.step === 1 ? Size.SMALL : Size.MINI}
              color={colors.secondary}
              font="SUPER"
              upperCase
              textAlign="center"
              as="h1"
            >
              {current.context.step === 1 ? 'Enter Your Phone Number' : 'Enter Your 6-digit pin'}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {current.context.step === 1 && (
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
              <Header
                size={Size.TINY}
                color={colors.secondary}
                font="LIGHT"
                textAlign="center"
                letterSpacing="-1px"
                as="h1"
              >
                We&#39;ll send you a 6-digit PIN to sign up / in
              </Header>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column
            textAlign="center"
            mobile={16}
            tablet={8}
            computer={8}
            largeScreen={6}
            widescreen={4}
          >
            {current.context.step === 1 ? (
              <PhoneInputContainer>
                <PhoneInput
                  defaultCountry="US"
                  country="US"
                  displayInitialValueAsLocalNumber
                  showCountrySelect={false}
                  placeholder="(312) 924-2857"
                  value={current.context.phoneNumber}
                  limitMaxLength
                  onChange={handleInputChange}
                  disabled={current.matches('sendingOTP')}
                />
              </PhoneInputContainer>
            ) : (
              <Input
                type="tel"
                onChange={handleCodeInputChange}
                placeholder="112233"
                maxLength={6}
                value={current.context.code}
                disabled={showLoader()}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal
            centered={true}
            open={captcha}
            onClose={() => setCaptcha(false)}
            closeIcon={<Image style={closeStyle} alt="Close Button" src={closeMenu} />}
            style={{ borderRadius: '10px' }}
            size="mini"
          >
            <Modal.Content style={{ borderRadius: '10px' }}>
              <ReCAPTCHA
                style={{marginTop:'20px'}}
                sitekey={CaptchaKey.clientKey}
                onChange={onChangeCaptcha}
              />,
            </Modal.Content>
          </Modal>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            {current.context.step === 1 ? (
              <Button
                type="solidRed"
                onClick={handleCaptcha}
                loading={current.matches('sendingOTP')}
              >
                REQUEST PIN
              </Button>
            ) : (
              <ResendContainer>
                <Header
                  size={Size.TINY}
                  color={colors.secondary}
                  font="LIGHT"
                  textAlign="center"
                  letterSpacing="-1.5px"
                  as="h1"
                >
                  <span onClick={handleRequestPin}>Resend PIN </span>
                  to {formatPhoneNumber(current.context.phoneNumber)}
                </Header>
                <Image src={EditWhite} onClick={handleEditPhoneNumber} alt="Edit phone number" />
              </ResendContainer>
            )}
          </Grid.Column>
        </Grid.Row>
        {current.context.step === 2 && (
          <Grid.Row>
            <Loader active={showLoader()} size="large" inverted />
          </Grid.Row>
        )}
      </GridContainer>
      <StyleSpacer />
      <Footer />
      <TransitionablePortal
        open={current.matches('invalid')}
        transition={{ animation: 'fade up', duration: 500 }}
      >
        <Modal
          centered={true}
          open={true}
          onClose={handleCloseButtonClick}
          closeIcon={<Image style={closeStyle} alt="Close Button" src={closeSearchBtn} />}
          style={{ borderRadius: '10px' }}
          size="mini"
        >
          <Modal.Content style={{ borderRadius: '10px' }}>
            <Modal.Description>
              <Spacer size={SpacerSizes.TINY} />
              <Header size={22} font="MEDIUM" textAlign="center" letterSpacing="-1.2px" as="h1">
                We’re Sorry!
              </Header>
              <Spacer size={SpacerSizes.TINY} />
              <Header size={16} font="LIGHT" textAlign="center" letterSpacing="-0.5px" as="h4">
                It doesn’t look like your account has been created yet.
              </Header>
              <Spacer size={SpacerSizes.TINY} />
              <Header size={16} font="LIGHT" textAlign="center" letterSpacing="-0.5px" as="h4">
                Please contact the venue with whom you reserved your suite to confirm they have the
                correct phone number.
              </Header>
              <Spacer size={SpacerSizes.TINY} />
              <Button type="solidRed" onClick={handleCloseButtonClick}>
                Close
              </Button>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    </HomeContainer>
  );
};

export default Auth;
