import { useAppContext } from '../../containers/App/AppContext';
import { usePaymentContext } from '../../containers/PaymentDelivery/context';

export const useStripeError = () => {
  const { stripeError, isAlertOpen, setIsAlertOpen } = usePaymentContext();

  return {
    stripeError,
    isAlertOpen,
    setIsAlertOpen,
  };
};

export const useTipHook = () => {
  const { tip, setTip, handleChangeTip, IsTipEnabled } = usePaymentContext();

  return {
    tip,
    setTip,
    handleChangeTip,
    IsTipEnabled,
  };
};

export const usePaymentMethod = () => {
  const { setShowLoader, venue, store } = useAppContext();
  const {
    isOrderFree,
    orderGroups,
    paymentType,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    isSuite,
    stripe,
    currentEventId,
  } = usePaymentContext();

  const showOpenTabOption = (venue?.allowOpenTabs &&
    store?.acceptsOpenTabOrders &&
    currentEventId &&
    isSuite) as boolean;

  return {
    setIsLoading: setShowLoader,
    isOrderFree,
    orderGroups,
    paymentType,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    isSuite,
    stripe,
    showOpenTabOption,
    currency: venue?.currency || '',
    currentEventId,
  };
};

export const usePaymentHandlers = () => {
  const {
    placeOrder,
    handlePlaceOrder,
    isSubmitting,
    setIsSubmitting,
    payButtonDisabled,
  } = usePaymentContext();
  return {
    placeOrder,
    handlePlaceOrder,
    isSubmitting,
    payButtonDisabled,
    setIsSubmitting,
  };
};

export const useOrderRecipientHook = () => {
  const { saveUserData, userEditValues, setUserEditValues } = usePaymentContext();
  return {
    saveUserData,
    userEditValues,
    setUserEditValues,
  };
};
