import React, { useEffect, useState } from 'react';
import { Card, Divider, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppContext } from '../../containers/App/AppContext';
import { useOrderRecipientHook } from '../../hooks/Payment/payment.hooks';
import { UserService } from '../../services/UserService';
import { CardContainer } from '../../shared/global-styled';
import { saveSessionValues } from '../../shared/SessionAuthPersist';
import { colors, fonts } from '../../shared/theme';
import SubmitButton from '../Atoms/Button/Submit';
import FloatingLabelInput from '../FloatingLabelInput';
import AnimatedModal from '../Molecules/AnimatedModal/AnimatedModal';

const StyledContainer = styled.div`
  .ui.top.right.attached.label {
    background: transparent;
    color: ${colors.niceBlue};
    padding: 22px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    cursor: pointer;
    font-family: ${fonts.REGULAR};
  }

  .title {
    margin-bottom: 10px;
    p {
      color: ${({ theme }) => theme.primaryColor};
      font-family: ${fonts.REGULAR};
      font-size: 12px;
    }
  }

  .ui.basic.button {
    margin-top: -4px;
    margin-left: -30px;
    position: relative;
    box-shadow: none;
    font-family: ${fonts.REGULAR};
    font-size: 0.9em;
    font-weight: lighter;
    color: ${colors.niceBlue}!important;
    text-transform: uppercase;
    padding-right: 10px;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      color: ${colors.niceBlue}!important;
    }
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  color: ${colors.charcoalGrey};
  font-family: ${fonts.LIGHT};
  font-weight: lighter;
  font-size: 16px;
  margin-top: 16px;
  line-height: 26px;
`;

const UserName = styled.div`
  align-self: flex-start;
`;

const UserEmail = styled.div`
  align-self: flex-end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OrderRecipient = () => {
  const { user } = useAppContext();
  const { userEditValues, setUserEditValues, saveUserData } = useOrderRecipientHook();
  const [showUserInfoForm, setUserInfoForm] = useState(false);
  const { firstName, lastName, email } = userEditValues;
  const saveDisabled = !firstName.value || !lastName.value || !email.value || email.hasError;

  useEffect(() => {
    const init = async () => {
      const getUserInfo = async () => {
        const { data: currentUser } = await UserService.GetCurrentUser();
        const { firstName, lastName, email } = currentUser;
        saveSessionValues({ ...user, ...currentUser });
        setUserEditValues({
          firstName: { value: firstName, hasError: !firstName },
          lastName: { value: lastName, hasError: !lastName },
          email: { value: email, hasError: !email },
        });
        if (!firstName || !lastName || !email) {
          setUserInfoForm(true);
        }
      };
      await getUserInfo();
    };

    init();
  }, [user]);

  const handleChange = ({ name, value, hasError }: any) => {
    setUserEditValues({
      ...userEditValues,
      [name]: { value, hasError },
    });
  };

  const handleCloseModal = async () => {
    await saveUserData();
    setUserInfoForm(false);
  };

  return (
    <>
      <StyledContainer>
        <CardContainer>
          <Card>
            <Card.Header>
              <div className="title">Order Recipient</div>
              <Label onClick={() => setUserInfoForm(true)} attached="top right">
                EDIT
              </Label>

              <Divider />
              <UserInfoContainer>
                <UserName>{`${firstName.value} ${lastName.value}`}</UserName>
                <UserEmail>{email.value}</UserEmail>
              </UserInfoContainer>
            </Card.Header>
          </Card>
        </CardContainer>
      </StyledContainer>
      <AnimatedModal
        size="small"
        hideCloseX={false}
        showModal={showUserInfoForm}
        onCloseModal={() => setUserInfoForm(false)}
      >
        <AnimatedModal.Title copy="Order Recipient" />
        <CardContainer>
          <Card>
            <FloatingLabelInput
              providedDetails={true}
              noMargin
              isRequired={true}
              id="firstName"
              placeholder="First Name"
              name="firstName"
              value={firstName.value}
              onChange={handleChange}
              type="text"
              showError={!firstName.value?.trim()}
              errorMsg="Please provide a name"
              isDisabled={false}
            />
            <FloatingLabelInput
              providedDetails={true}
              noMargin
              isRequired={true}
              id="lastName"
              placeholder="Last Name"
              name="lastName"
              value={lastName.value}
              onChange={handleChange}
              type="text"
              showError={!lastName.value?.trim()}
              errorMsg="Please provide a last name"
              isDisabled={false}
            />
            <FloatingLabelInput
              providedDetails={true}
              noMargin
              isRequired={true}
              id="email"
              placeholder="Email"
              name="email"
              value={email.value}
              onChange={handleChange}
              descriptor="This is where your receipt will be sent."
              type="email"
              errorMsg="Please provide a valid email"
              pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}
              isDisabled={false}
            />
          </Card>
          <SubmitButton disabled={saveDisabled} onClick={handleCloseModal}>
            SAVE
          </SubmitButton>
        </CardContainer>
      </AnimatedModal>
    </>
  );
};

export default OrderRecipient;
