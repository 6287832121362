import closeMenu from 'assets/icons/closeSearchBtn.png';
import userIcon from 'assets/icons/userIcon.svg';
import fanFoodLogo from 'assets/images/fanFoodLogo.png';
import { Size } from 'components/Atoms/Header';
import Footer from 'components/Organisms/Footer';
import SearchBar from 'components/Organisms/SearchBar';
import SingleTile from 'components/Organisms/SingleTile';
import SuiteSideMenu from 'components/Organisms/SuiteSideMenu';
import Venue from 'components/Venue';
import useWindowWidth from 'hooks/UseWindowWidth';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Image, Segment, Sidebar } from 'semantic-ui-react';
import { BasePath } from 'shared/constants';
import { colors } from 'shared/theme';
import { useAppContext } from '../AppContext';
import {
  CloseButton,
  CloseButtonIcon,
  FooterContainer,
  GridContainer,
  HomeContainer,
  NearByText,
  ProfileContainer,
  StyledHeader,
  StyledLogo,
  VenuesContainer,
} from './styled';

const Home = () => {
  const history = useHistory();
  const width = useWindowWidth();
  const [visible, setVisible] = useState(false);
  const [venues, setVenues] = useState([]);
  const { setDeliveryLocation, setDeliveryLocationId, setVenue } = useAppContext();

  useEffect(() => {
    setVenue(undefined);
    setDeliveryLocation(undefined);
    setDeliveryLocationId(undefined);
  }, []);

  const headerSize = () => {
    if (width >= 1200) {
      return Size.ENORMOUS;
    } else if (width >= 800) {
      return Size.HUGE;
    } else {
      return 60;
    }
  };

  const onVenueSelect = (venueId: string) => {
    history.push(`/${BasePath.CONSUMER}/stores/${venueId}`, {});
  };

  return (
    <Sidebar.Pushable as={HomeContainer}>
      <Sidebar
        as={Segment}
        animation="overlay"
        vertical
        visible={visible}
        aria-expanded={visible}
        width="wide"
        direction="right"
        style={{ padding: 0, backgroundColor: 'white' }}
      >
        <CloseButton
          aria-label="close menu"
          aria-pressed={visible}
          onClick={() => setVisible(false)}
        >
          <CloseButtonIcon src={closeMenu} />
        </CloseButton>
        <SuiteSideMenu setVisible={setVisible} />
      </Sidebar>
      <Sidebar.Pusher>
        <HomeContainer>
          <header>
            <ProfileContainer>
              <Button
                onClick={() => setVisible(!visible)}
                aria-label="Open user menu"
                aria-pressed={!visible}
              >
                <Image src={userIcon} alt="user icon" />
              </Button>
            </ProfileContainer>
            <StyledLogo src={fanFoodLogo} centered alt="FanFood logo" />
          </header>
          <GridContainer centered columns={1} width={width}>
            <main>
              <Grid.Row className="first-row">
                <StyledHeader
                  size={headerSize()}
                  color={colors.secondary}
                  font="SUPER"
                  upperCase
                  textAlign="center"
                  as="h1"
                >
                  ENTER YOUR LOCATION
                </StyledHeader>
                <div className="subtitle-search">To see food options near you</div>
                <SearchBar onResults={setVenues} />
              </Grid.Row>
              {venues.length > 0 && <NearByText>NEARBY FOOD OPTIONS</NearByText>}
              <VenuesContainer>
                {venues.map((venue: any) => {
                  return <Venue key={venue.id} onVenueSelect={onVenueSelect} venue={venue} />;
                })}
              </VenuesContainer>
              <Grid.Row className="row-bottom">
                <SingleTile />
              </Grid.Row>
            </main>
            <Grid.Row style={{ alignItems: 'flex-end' }}>
              <FooterContainer>
                <Footer />
              </FooterContainer>
            </Grid.Row>
          </GridContainer>
        </HomeContainer>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default Home;
