import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

export const SuggestionContainer = styled.div`
  padding: 0px 10px;
  background-color: white;
  position: relative;
  top: -1px;
  width: 24em;

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 480px) {
    width: 18em;
  }

  .suggestion-item {
    border-top: 1px solid #cfcfcf;
    padding: 7px;
    font-family: ${fonts.LIGHT};
    font-size: 16px;
    text-align: left;
  }

  &:first-child {
    .suggestion-item {
      border-top: unset;
    }
  }
`;

export const NearByText = styled.h3<{ color?: string }>`
  color: ${props => props.color || colors.white};
  font-size: 20px;
  font-family: ${fonts.SUPER};
  text-align: center;
  letter-spacing: 1px;
  margin: 20px 30px 0 30px;

  @media (max-width: 375px) {
    margin: 20px 10px 0 10px;
  }
`;

export const PoweredByGoogleImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px;

  img {
    width: 8em;
  }
`;

export const GridContainer = styled.div`
  .custom-container-classname {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    z-index: 1;
    cursor: pointer;
  }
  .custom-suggestion-classname {
    background-color: ${colors.lightGrey};
  }
  .google-suggestion {
    background-color: #fff;
  }
  .custom-suggestions-classname--active {
    background-color: ${colors.paleGrey};
  }
  .location-search-input {
    position: relative;
  }
  .location-search-input .ui.input > input {
    font-family: AkzidenzGroteskBELight;
    font-size: 22px;
  }

  @media (max-width: 480px) {
    margin-top: -30px !important;
  }
`;
