import React, { useState } from 'react';
import styled from 'styled-components';
import { List, Modal, Segment, Grid, Image, TransitionablePortal } from 'semantic-ui-react';
import { colors, fonts } from '../../../shared/theme';
import caretRightBlack from 'assets/icons/caretRightBlack.png';
import closeSearchBtn from 'assets/icons/closeSearchBtn.png';
import AccessibilityView from '../../../components/AccessibilityView';
import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';
import { PolicyUrls } from '../../../shared/constants';

const StyledContainer = styled.div`
  .ui.basic.clearing.segment {
    padding-right: 0px;
    padding-left: 0px;
  }
  .ui.segment {
    padding: 10px 25px;
    border-radius: 10px;
    margin: 20px 10px 5px;
  }
  .shadowed {
    box-shadow: 0 0 4px 0 rgba(17, 17, 17, 0.1), 0 0 4px 0 rgba(17, 17, 17, 0.1) !important;
  }
  .item {
    cursor: pointer;
  }
  .row {
    padding-bottom: 0 !important;
    padding-top: 1.2em !important;
  }
  .icon-right {
    width: 8px;
    height: 13px;
    margin-top: 1em !important;
  }
  h2 {
    color: ${colors.charcoalGrey};
    font-family: ${fonts.LIGHT};
    font-size: 17px;
    line-height: 45px;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
  }
  .row:last-child > .column > h2 {
    border-bottom: none;
  }
  .title {
    text-align: center;
    padding-top: 4px;
  }
`;

const StyledModal = styled.div`
  .title {
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    line-height: 26px;
    font-family: ${fonts.SUPER};
    text-align: center;
    margin-top: 15px;
    margin-bottom: -20px;
  }
  .subtitle {
    color: ${colors.charcoalGrey};
    font-size: 18px;
    line-height: 28px;
    font-family: ${fonts.LIGHT};
    text-align: center;
  }
`;

function Policies() {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('accessibility');

  const modalTypes: any = {
    accessibility: {
      title: 'ACCESSIBILITY',
      subtitle: 'FanFood cares about your accessibility',
    },
  };

  const show = (message: string) => {
    setModalType(message);
    setOpenModal(true);
  };
  const onClose = () => {
    setOpenModal(false);
  };

  const closeStyle = {
    width: '12px',
    height: '12px',
    float: 'right',
    marginTop: '15px',
    marginRight: '15px',
    cursor: 'pointer',
  };

  return (
    <StyledContainer>
      <ProfileHeader title='Policies' />
      <Segment className='shadowed'>
        <List divided verticalAlign='middle'>
          <List.Item onClick={() => {
            window.location.href = PolicyUrls.PRIVACY_POLICY_URL;
            return null;
          }}>
            <Image className='icon-right' src={caretRightBlack} floated={'right'} />
            <List.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <h2>Privacy</h2>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Content>
          </List.Item>
          <List.Item onClick={() => show('accessibility')}>
            <Image className='icon-right' src={caretRightBlack} floated={'right'} />
            <List.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <h2>Accessibility</h2>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Content>
          </List.Item>
          <List.Item onClick={() => {
            window.location.href = PolicyUrls.TERMS_OF_USE_URL;
            return null;
          }}>
            <Image className='icon-right' src={caretRightBlack} floated={'right'} />
            <List.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <h2>Terms of Use</h2>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Content>
          </List.Item>
        </List>
        <TransitionablePortal open={openModal} transition={{ animation: 'fade up', duration: 500 }}>
          <Modal
            centered={false}
            open={true}
            onClose={onClose}
            closeIcon={<Image style={closeStyle} src={closeSearchBtn} />}
            style={{ borderRadius: '10px' }}
            size='tiny'
          >
            <Modal.Content style={{ borderRadius: '10px' }}>
              <Modal.Description>
                <StyledModal>
                  <h2 className='title'>{modalTypes[modalType].title}</h2>
                  <h4 className='subtitle'>{modalTypes[modalType].subtitle}</h4>
                  {modalType === 'accessibility' && <AccessibilityView />}
                </StyledModal>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </TransitionablePortal>
      </Segment>
    </StyledContainer>
  );
}

export default Policies;
