import styled from 'styled-components';
import FansClap from 'assets/images/footballFansClap.jpg';
import { colors, fonts } from 'shared/theme';

export const FanfoodLoveContainer = styled.div`
  .ui.basic.clearing.segment {
    .ui.icon.menu {
      > div {
        color: ${colors.white};
      }
    }
  }
`;

export const BackgroundImage = styled.div`
  height: 60vh;
  background: url(${FansClap}) top center;
  background-size: cover;
  position: absolute;
  top: -20px;
  right: 0px;
  left: 0px;
  z-index: -1;
`;

export const BackgroundGradient = styled.div`
  height: 55vh;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: -1;
`;

export const CaptionContainer = styled.div`
  position: relative;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  font-family: ${fonts.REGULAR};
  font-weight: lighter;
  max-width: 250px;
  margin: 50px auto;
  div {
    font-size: 22px;
  }
  .ui.image {
    height: 64px;
    width: 64px;
    margin: 25px auto;
    display: block;

    img {
      height: 64px;
      width: auto;
    }
  }
  span {
    font-size: 16px;
  }
`;

export const ShareCard = styled.div`
  padding: 0px 15px;

  .ui.card {
    padding-bottom: 10px;

    .content {
      padding: 0px;

      .ui.divided.list {
        .item {
          text-transform: uppercase;
          font-family: ${fonts.REGULAR};
          font-size: 12px;
          font-weight: bold;
          color: ${colors.niceBlue};
          padding: 10px 0px;
          cursor: pointer;
        }
        .ui.image {
          height: 35px;
          width: auto;
          margin-right: 5px;
        }
      }
    }

    .header {
      font-family: ${fonts.LIGHT};
      font-size: 20px;
      color: ${colors.charcoalGrey};
      padding-bottom: 15px;
    }
  }
`;
