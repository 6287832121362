import { useAppContext } from 'containers/App/AppContext';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Checkbox, Divider, Form, Input } from 'semantic-ui-react';
import { OpenTabService } from 'services/OpenTabService';
import { default as OpenTabData, default as OpenTabModel } from 'shared/models/OpenTab';
import IUser from 'shared/models/User';
import styled from 'styled-components';
import { CardContainer } from '../../shared/global-styled';
import { colors, fonts } from '../../shared/theme';
import SubmitButton from '../Atoms/Button/Submit';
import AnimatedModal from '../Molecules/AnimatedModal/AnimatedModal';

const StyledContainer = styled.div`
  .ui.top.right.attached.label {
    background: transparent;
    color: ${colors.niceBlue};
    padding: 22px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    cursor: pointer;
    font-family: ${fonts.REGULAR};
  }

  .title {
    margin-bottom: 10px;
    p {
      color: ${({ theme }) => theme.primaryColor};
      font-family: ${fonts.REGULAR};
      font-size: 12px;
    }
  }
`;

const TabInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  font-family: ${fonts.LIGHT};
  font-weight: lighter;
  margin-top: 16px;
  line-height: 26px;
  .add-balance-main {
    display: flex;
    margin-right: 20px;
  }
  .balance-value {
    margin-top: 15px;
    margin-left: 20px;
  }
  .add-balance-input {
    font-size: 18px;
    font-weight: 600;
  }
  .add-balance {
    margin-top: 15px;
    margin-right: 15px;
    color: blue;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
  }
  .ui.input > input {
    font-weight: 600;
    text-align: end;
  }
  @media only screen and (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    form .field {
      margin-bottom: 20px;
    }
    .ui.input > input {
      width: 100%;
    }
  }
`;

const CheckBoxUi = styled.div`
form .field {
  color: ${colors.charcoalGrey};
  font-family: ${fonts.REGULAR};
  margin-top:10px;
  margin-right:60px;
  > div {
    display: flex;
    align-items: center;
    font-size: 15px;

    .ui.toggle.checkbox {
      margin: 0px 5px;
      width: 38px;

      label::after {
        // background: ${colors.white};
        box-shadow: none;
        width: 42px;
        height: 42px;
        top: -0.2em;
      }

      label::before {
        height: 35px;
        width: 85px;
      }

      input ~ label {
        ::after {
          left: 0.2em;
        }
        ::before {
          background: ${colors.grey};
        }
      }

      input:checked ~ label {
        ::after {
          left: 4em;
        }
        ::before {
          background: #008000 !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  form .field {
    margin-bottom:20px;
  }
  .ui.input>input {
    width:100%;
  }
}
`;

const TabText = styled.div`
  align-self: flex-start;
  color: #000;
`;

const AmountLimitText = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  color: #000;
`;
const BalanceModalContainer = styled.div`
  font-family: ${fonts.REGULAR};
  .balance-modal-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .alcohol-main {
    display: flex;
    margin-bottom: 20px;
  }
  .add-balance-input {
    font-size: 22px;
    font-weight: 600;
  }
  .ui.input > input {
    font-weight: 600;
  }
`;
const AlcoholText = styled.div`
  font-family: ${fonts.BOLD};
  font-weight: bold;
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
`;
export interface OpenTabProps {
  venueDetails?: {};
  openTabValues?: OpenTabData;
  user?: IUser;
  suiteId?: string;
  eventId?: string;
}

const OpenTab = ({ venueDetails, openTabValues, user, suiteId, eventId }: OpenTabProps) => {
  const [showBalanceModal, setShowBalanceModal] = useState<boolean>(false);
  const [finalBalance, setFinalBalance] = useState<number>(0);
  const [openTabState, setOpenTabState] = useState<OpenTabModel>();
  const [openTabsEnabled, setOpenTabsEnabled] = useState<boolean>(false);
  const [alcoholAllowed, setAlcoholAllowed] = useState<boolean>(false);
  const { openTab, setOpenTab } = useAppContext();

  useEffect(() => {
    if (openTabValues) {
      setOpenTabState(openTabValues);
      setOpenTabsEnabled(openTabValues.isActive);
      setAlcoholAllowed(openTabValues.alcoholAllowed);
      setFinalBalance(openTabValues.tabBalance);
    }
  }, [openTabValues]);

  const handleCloseModal = async () => {
    const openTabId = openTabState?.id;
    const OpenTabPayload = {
      tabBalance: finalBalance,
      userId: user?.id,
      suiteId: suiteId,
      eventId: eventId,
      alcoholAllowed: alcoholAllowed,
      isActive: openTabsEnabled,
    };
    if (openTabId) {
      const { data } = await OpenTabService.UpdateTab(OpenTabPayload, openTabId);
      if (data) {
        toast('OpenTab Updated successfully');
        setOpenTabState(data);
        setOpenTab(data);
      } else {
        toast('Something went wrong!');
      }
    }
    setShowBalanceModal(false);
  };

  const openTabModal = () => {
    if (!openTabsEnabled) {
      toast('Please Activate the tab first');
    } else setShowBalanceModal(true);
  };

  const SaveOpenTab = async () => {
    const OpenTabPayload = {
      name: `${user?.firstName} ${user?.lastName}`,
      tabBalance: 0,
      userId: user?.id,
      alcoholAllowed: false,
      suiteId: suiteId,
      eventId: eventId,
      isActive: true,
    };
    const { data } = await OpenTabService.SaveTab(OpenTabPayload);
    if (data) {
      setOpenTabState(data);
      setOpenTab(data);
    }
  };

  const OpenTabToggleFunction = () => {
    if (!openTabValues && !openTabState) {
      SaveOpenTab();
    }
    setOpenTabsEnabled(!openTabsEnabled);
    let openTabData = openTabState;
    if (openTabData) {
      openTabData.isActive = !openTabsEnabled;
      setOpenTab(openTabData);
    }
  };

  return (
    <>
      <StyledContainer>
        <CardContainer>
          <Card>
            <Card.Header>
              <div className="title">Open a Tab</div>
              <Divider />
              <TabInfoContainer>
                <CheckBoxUi>
                  <form>
                    <Form.Field>
                      <div>
                        <Checkbox
                          onChange={OpenTabToggleFunction}
                          checked={openTabsEnabled}
                          toggle
                        />
                      </div>
                    </Form.Field>
                  </form>
                </CheckBoxUi>
                <>
                  <div className="add-balance-main">
                    <div onClick={openTabModal} className="add-balance">
                      + ADD A BALANCE
                    </div>
                    <div className="add-balance-input">
                      <div className="balance-value">
                        Balance: {`${openTabState?.tabBalance || '0'}$`}
                      </div>
                    </div>
                  </div>
                  <AmountLimitText>
                    Once this amount is reached guests may still order but will be responsible for
                    their purchases
                  </AmountLimitText>
                </>
              </TabInfoContainer>
            </Card.Header>
          </Card>
        </CardContainer>
      </StyledContainer>
      <AnimatedModal
        size="small"
        hideCloseX={false}
        showModal={showBalanceModal}
        onCloseModal={() => setShowBalanceModal(false)}
      >
        <AnimatedModal.Title copy="" />
        <CardContainer>
          <BalanceModalContainer>
            <div className="balance-modal-title">Open a tab for your guests</div>
            <div className="alcohol-main">
              <AlcoholText>Allow tab purchases toward alcohol</AlcoholText>
              <CheckBoxUi>
                <form>
                  <Form.Field>
                    <div>
                      <Checkbox
                        onChange={() => setAlcoholAllowed(!alcoholAllowed)}
                        checked={alcoholAllowed}
                        toggle
                      />
                    </div>
                  </Form.Field>
                </form>
              </CheckBoxUi>
            </div>
            <AlcoholText>Add a maximum limit to your tab</AlcoholText>
            <div className="add-balance-input">
              <Input
                type="number"
                onChange={e => setFinalBalance(parseInt(e.target.value))}
                placeholder="Add balance"
                value={finalBalance}
              ></Input>
            </div>
            <AmountLimitText>
              Once this amount is reached guests may still order but will be responsible for their
              purchases
            </AmountLimitText>
          </BalanceModalContainer>
          <SubmitButton onClick={handleCloseModal}>SAVE</SubmitButton>
        </CardContainer>
      </AnimatedModal>
    </>
  );
};

export default OpenTab;
