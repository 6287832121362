import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const CheckoutSectionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 16px 0 30px 0;
`;

export const CheckoutSection = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentContainer = styled.div<{ clientHeight: number }>`
  img.ui.image.title-image {
    display: inline;
    max-width: 11px;
    margin-left: 6px;
  }
  .ui.fluid.button {
    max-width: 450px;
    background: ${({ theme }) => theme.primaryColor};
    text-transform: uppercase;
    color: white;
    margin-bottom: 40px;
    font-family: ${fonts.MEDIUM};
    font-size: 18px;
    font-weight: lighter;
  }

  .my-bag,
  .my-location {
    padding: 10px 5px 5px !important;
  }

  .ui.container {
    .ui.grid .button.compact {
      text-transform: uppercase;
      background: none;
      float: right;
      color: ${colors.niceBlue};
      &:hover {
        color: ${colors.seaFoam};
      }
    }

    .title {
      font-size: 18px;
      font-family: ${fonts.REGULAR};
      color: ${colors.charcoalGrey};
    }

    .tab-container {
      text-align: center;
      margin: 16px;
    }

    .img-tab {
      margin-right: 5px;
    }

    .span-tab {
      font-size: 16px;
      font-family: ${fonts.REGULAR};
    }

    .subtitle {
      font-family: ${fonts.LIGHT};
      color: ${colors.charcoalGrey};
      margin-top: 3px;
      margin-bottom: 9px;
    }

    .testing {
      width: max-content;
    }
  }
  .ui.card {
    max-width: 99%;
    width: 99%;
    margin: 0 auto;
  }

  .ui.card.summary {
    line-height: 26px;
    margin: 1em auto;
    border-radius: 10px;
    width: 100%;
    padding: 10px 10px 5px;
    .ui.grid {
      .row {
        padding: 0px;
        font-family: ${fonts.REGULAR};
        font-weight: lighter;
        font-size: 14px;
        color: ${colors.charcoalGrey};
        .right {
          text-align: right;
        }

        &.total {
          font-family: ${fonts.MEDIUM};
        }
      }
    }
    .content {
      padding: 10px;

      .ui.list {
        margin: 10px auto;
        .content {
          padding: 0px;
          > .ui.grid {
            padding: 0px;
            background: transparent;
            .row {
              font-size: 14px;
              font-family: ${fonts.REGULAR};
              font-weight: lighter;
              line-height: 18px;

              .sub-item {
                margin-left: 10px;
              }
            }
          }
        }
      }

      > .ui.grid {
        margin: -1px -20px;

        padding-bottom: 10px;
        padding-top: 10px;
        > .row {
          padding: 0px 7px;
          line-height: 20px;
        }
      }

      .header {
        font-family: ${fonts.LIGHT};
        font-weight: lighter;

        > .ui.container {
          margin: 0px !important;
          justify-content: space-between;
          .title {
            padding: 0px;
            font-size: 18px;
            color: ${colors.charcoalGrey};
            font-family: ${fonts.LIGHT};
            .ui.image {
              height: 7px;
              margin-right: 10px;
            }
          }
          .total {
            padding: 0px;
            font-size: 16px;
            color: ${colors.charcoalGrey};
            font-family: ${fonts.MEDIUM};
          }
        }
      }

      .promo-code {
        margin: 1px 1px;

        > .promo-code-input {
          margin-bottom: -16px;
        }
      }
    }
  }

  .ui.card.location {
    line-height: 26px;
    margin: 1em auto;
    border-radius: 10px;
    padding: 0px;

    .content {
      padding: 1em 20px;

      .venue-title {
        font-family: ${fonts.REGULAR};
        font-size: 12px;
        color: ${colors.brownishGrey};
      }

      .venue-name {
        top: -7px;
        font-family: ${fonts.LIGHT};
        font-size: 16px;
        color: ${colors.charcoalGrey};
      }

      .header {
        > .ui.grid.container {
          margin: 0px !important;
          justify-content: space-between;
          align-items: center;
          .title {
            padding: 0px;
            font-size: 18px;
            font-family: ${fonts.LIGHT};
            color: ${colors.charcoalGrey};
            font-weight: lighter;
          }
          .ui.basic.button {
            padding: 13px 0px;
            left: 3px;
            position: relative;
            box-shadow: none;
            font-family: ${fonts.REGULAR};
            font-size: 12px;
            font-weight: lighter;
            color: ${colors.niceBlue} !important;
            text-transform: uppercase;
            align-self: end;
            margin-bottom: 2px;

            &:focus,
            &:active,
            &:hover {
              box-shadow: none;
              color: ${colors.niceBlue} !important;
            }
          }
        }
      }
      > .divider {
        margin-top: 1px;
      }
      .ui.container.location-table {
        margin: 0px !important;
        .row {
          padding: 0;
          .column {
            padding-left: 0px;
          }

          .location-section {
            margin-bottom: 1rem;
            padding-left: 0;
            .row-header {
              padding: 0px;
              line-height: 12px;
              font-size: 12px;
              font-family: ${fonts.REGULAR};
              font-weight: lighter;
              color: ${colors.description};
              .column {
                &:first-child {
                  padding-left: 0px;
                }
                &:last-child {
                  padding-right: 0px;
                }
              }
            }
            .row-content {
              padding: 0px;
              font-size: 16px;
              font-family: ${fonts.LIGHT};
              font-weight: lighter;
              color: ${colors.charcoalGrey};
              .column {
                &:first-child {
                  padding-left: 0px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                &:last-child {
                  padding-right: 0px;
                }
              }
            }
          }
        }
      }
    }
  }

  display: flex;
  flex-direction: column;
  max-height: ${props => `${props.clientHeight}px`};

  main {
    height: ${props => `calc(${props.clientHeight}px - 70px)`};
    overflow-y: auto;
  }
`;

export const StyledFooter = styled.footer<{ bg?: string }>`
  padding: 10px 15px 10px 15px;
  button.ui.fluid.button {
    background: ${({ theme, bg }) => (bg ? `url(${bg})` : theme.primaryColor)};
    color: ${colors.secondary};
    text-transform: uppercase;
    height: 46px;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 10px;
    font-size: 18px;

    ${({ bg }) =>
      bg &&
      `
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;

    `}
  }
`;

export const ModalTitle = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${fonts.BOLD};
  font-size: 18px;
  letter-spacing: -0.2px;
  text-align: center;
  margin-bottom: 15px;
`;

export const ModalContent = styled.p`
  font-family: ${fonts.REGULAR};
  color: ${colors.black};
  font-size: 18px;
  letter-spacing: -0.2px;
  text-align: center;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 13px;
`;

export const ModalButton = styled.div`
  color: white;
  font-family: ${fonts.REGULAR};
  background-color: ${({ theme }) => theme.primaryColor};
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  padding: 7px 13px;
  width: max-content;
  margin: 0 auto;
  cursor: pointer;
`;
