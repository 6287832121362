import { toast } from 'react-toastify';
import useCartContext from '../../containers/Cart/context';
import { CartService, UpdateCartDto } from '../../services/CartService';
import ICart from '../../shared/models/Cart';
import { ActionResponse } from './lineItem';

export interface ICartActionHandler {
  getCart: (cartId: string) => () => Promise<ActionResponse<ICart>>;
  updateCart: (UpdateCartDto: UpdateCartDto) => () => Promise<ActionResponse<ICart>>;
  removeCart: () => () => Promise<ActionResponse<ICart>>;
  addPromoToCart: (promoCode: string) => () => Promise<ActionResponse<ICart>>;
}

export const useCartHandler = (): ICartActionHandler => {
  const { cart, setCart } = useCartContext();

  const getCart = (cartId: string) => async (): Promise<ActionResponse<ICart>> => {
    const apiAction: ActionResponse<ICart> = { success: false, error: false };
    try {
      const { data } = await CartService.GetCart(cartId);
      setCart(data);
      return {
        ...apiAction,
        success: data,
      };
    } catch (error) {
      toast(error.response.data.message);
      return {
        ...apiAction,
        error,
      };
    }
  };

  const updateCart = (payload: UpdateCartDto) => async (): Promise<ActionResponse<ICart>> => {
    const apiAction: ActionResponse<ICart> = { success: false, error: false };
    if (!cart) {
      toast("Cart doesn't exist");
      return {
        ...apiAction,
        error: {
          response: {
            data: {
              message: "Cart doesn't exist",
            },
          },
        },
      };
    }

    try {
      const { data } = await CartService.UpdateCart(cart.id, payload);
      setCart(data);
      return {
        ...apiAction,
        success: data,
      };
    } catch (error) {
      toast(error.response.data.message);
      return {
        ...apiAction,
        error,
      };
    }
  };

  const addPromoToCart = (promoCode: string) => async (): Promise<ActionResponse<ICart>> => {
    const apiAction: ActionResponse<ICart> = { success: false, error: false };
    if (!cart) {
      toast("Cart doesn't exist");
      return {
        ...apiAction,
        error: {
          response: {
            data: {
              message: "Cart doesn't exist",
            },
          },
        },
      };
    }

    try {
      const { data } = await CartService.ApplyPromo(cart.id, {
        code: promoCode,
        storeId: cart.storeId,
        venueId: cart.venueId,
      });
      setCart(data);
      return {
        ...apiAction,
        success: data,
      };
    } catch (error) {
      toast(error.response.data.message);
      return {
        ...apiAction,
        error,
      };
    }
  };

  const removeCart = () => async (): Promise<ActionResponse<ICart>> => {
    const apiAction: ActionResponse<ICart> = { success: false, error: false };
    if (!cart) {
      toast("Cart doesn't exist");
      return {
        ...apiAction,
        error: {
          response: {
            data: {
              message: "Cart doesn't exist",
            },
          },
        },
      };
    }
    try {
      const { data } = await CartService.DeleteCart(cart.id);
      setCart(data);
      return {
        ...apiAction,
        success: undefined,
      };
    } catch (error) {
      toast(error.response.data.message);
      return {
        ...apiAction,
        error,
      };
    }
  };

  return {
    getCart,
    updateCart,
    removeCart,
    addPromoToCart,
  };
};
