import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Image } from 'semantic-ui-react';

export default styled(ToastContainer)`
  &.Toastify__toast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 83px;
  }
  .Toastify__toast {
    font-family: ${fonts.REGULAR};
    font-size: 16px;
    color: ${colors.white};
    background-color: ${colors.charcoalGrey};
    border-radius: 5px;
    max-width: 350px;
    width: 100%;
  }
`;

export const CloseButtonContainer = styled(Image)`
  position: absolute !important;
  height: 12px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;
