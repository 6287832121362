import { Grid, Image } from 'semantic-ui-react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

const StyledRow = styled(Grid.Row)`
  justify-content: space-around !important;
`;

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 8px solid;
  width: 220px;
  color: ${({ selected, theme }) => (selected ? `${theme.primaryColor}` : `${colors.black}`)};

  &:hover {
    cursor: pointer;
  }
`;

const StyledColumn = styled(Grid.Column)`
  display: flex !important;
  justify-content: center;
`;

const Step = styled.div<{ selected: boolean }>`
  color: ${({ selected, theme }) => (selected ? `${theme.primaryColor}` : `${colors.black}`)};
  font-size: 15px;
  font-family: ${({ selected }) => (selected ? `${fonts.BOLD}` : `${fonts.LIGHT}`)};
  margin-bottom: 5px;
`;

const StepItem = styled.p<{ selected: boolean }>`
  color: ${({ selected, theme }) => (selected ? `${theme.primaryColor}` : `${colors.black}`)};
  font-family: ${({ selected }) => (selected ? `${fonts.BOLD}` : `${fonts.LIGHT}`)};
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CheckCircle = styled(Image)`
  height: 22px;
  width: 22px;
  margin-bottom: 8px;
`;

const Circle = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #f0f0f0;
  margin-bottom: 8px;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;

  .ui.button {
    background: ${({ theme }) => theme.primaryColor};
    align-self: flex-end;
    height: 50px;
    width: 100px;
  }
`;

const ButtonText = styled.div`
  font-size: 18px;
  letter-spacing: -0.2px;
  font-family: ${fonts.REGULAR};
  color: #fff;
`;

const MobileWrapper = styled.div`
  margin: 15px 0;
`;

export {
  MobileWrapper,
  StyledButton,
  Circle,
  CheckCircle,
  StepItem,
  Step,
  StyledColumn,
  Container,
  StyledRow,
  ButtonWrapper,
  ButtonText,
};
