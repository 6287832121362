import React from 'react'; // we need this to make JSX compile
import { Card, Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import useWindowWidth from '../../hooks/UseWindowWidth';
import { colors, fonts } from '../../shared/theme';
import { QuantityControl } from '../QuantityControl';

type ProductCardProps = {
  currencyPrefix: string;
  description: string;
  hasSubProducts: boolean;
  imageurl?: string;
  isFree: boolean;
  onCardClick: (event: React.MouseEvent<any>) => void;
  price: string;
  quantity: number;
  storeIsActive: boolean;
  title: string;
  updateQuantity: any;
  showProductImages: boolean;
  maxDescription?: number;
  CustomQuantityControl?: JSX.Element;
};

const ProductCardContainer = styled.div.attrs(({ className }) => ({
  className,
}))`
  .ui.card {
    margin: 10px auto;
    text-align: left;
    border-radius: 10px;
    &:last-child {
      margin-bottom: 15px;
    }
    .column {
      span {
        color: ${colors.seaFoam};
        font-weight: bold;
        font-weight: 600;
      }
      .meta {
        margin-bottom: 10px;
      }
    }
    .column + .column {
      padding: 0 !important;
    }
    .image {
      align-items: center;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      display: flex;
      height: 100%;
      justify-content: center;

      img {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .header-container {
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;

      .header-text {
        .header {
          font-size: 17px;
          font-family: ${fonts.MEDIUM};
          font-weight: lighter;
          color: ${colors.charcoalGrey};
          line-height: 1.2em;
          max-height: 2.4em;
          overflow: hidden;
          position: relative;
          text-overflow: ellipsis;
          margin: 0 1em 0 0;
          padding: 0 1em 0 0;
        }
        .meta {
          font-size: 13px;
          font-family: ${fonts.LIGHT};
          color: ${colors.pureGrey};
          margin: 3px 0 0 0;
          line-height: 1.1em;
          overflow: hidden;
          position: relative;
          margin: 0 -1em 0 0;
          padding: 0 1em 0 0;
        }
      }
      .price {
        font-size: 17px;
        font-family: ${fonts.REGULAR};
        color: ${colors.charcoalGrey};
        margin-left: 15px;
      }
      .quantity-container {
        display: flex;
        justify-content: right;
        margin: 0 auto;
      }
    }
  }
`;

const PriceQuantityContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const ProductCard = ({
  currencyPrefix,
  description,
  hasSubProducts,
  imageurl,
  isFree,
  onCardClick,
  price,
  quantity,
  storeIsActive,
  title,
  updateQuantity,
  showProductImages,
  maxDescription,
  CustomQuantityControl,
}: ProductCardProps) => {
  const width = useWindowWidth();
  let trimmedDescription = description;
  let maxCharacters = maxDescription ?? 75;

  let trimmedTitle = title;
  let maxTitleChar = 50;

  if (width < 330) {
    maxCharacters = 40;
    maxTitleChar = 27;
  } else if (width < 380) {
    maxCharacters = 60;
    maxTitleChar = 36;
  }

  if (description && description.length > maxCharacters) {
    trimmedDescription = description.substring(0, maxCharacters).concat('...');
  }

  if (title && title.length > maxTitleChar) {
    trimmedTitle = title.substring(0, maxTitleChar).concat('...');
  }

  const showImage = showProductImages && !!imageurl;

  return (
    <ProductCardContainer className="productCard" onClick={onCardClick}>
      <Card fluid>
        <Card.Content>
          <Grid className="product-details" columns={showImage ? 2 : 1}>
            <Grid.Column width={showImage ? 9 : 16} className="header-container">
              <div className="header-text">
                <Card.Header>{showImage ? trimmedTitle : title}</Card.Header>
                <Card.Meta>{trimmedDescription}</Card.Meta>
              </div>
              {CustomQuantityControl ? (
                CustomQuantityControl
              ) : (
                <PriceQuantityContainer>
                  {storeIsActive && (
                    <QuantityControl
                      canDecrementQuantity={!hasSubProducts}
                      changeQuantity={(value: number) => updateQuantity(value)}
                      quantity={quantity}
                    />
                  )}
                  <div className="price">{!isFree && `${currencyPrefix}${price}`}</div>
                </PriceQuantityContainer>
              )}
            </Grid.Column>
            {showImage && (
              <Grid.Column width={7} className="productImg">
                {imageurl && <Image src={imageurl} wrapped ui={false} />}
              </Grid.Column>
            )}
          </Grid>
        </Card.Content>
      </Card>
    </ProductCardContainer>
  );
};
