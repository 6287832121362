import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';
import React, { useState } from 'react';
import { Button, Image, List, Modal, Segment, TransitionablePortal } from 'semantic-ui-react';
import styled from 'styled-components';
import CaretRightBlack from '../../assets/icons/caretRightBlack.png';
import closeSearchBtn from '../../assets/icons/closeSearchBtn.png';
import Spacer, { SpacerSizes } from '../../components/Atoms/Spacer';
import useCartContext from '../../containers/Cart/context';
import { BasePath } from '../../shared/constants';
import { clearSession } from '../../shared/SessionAuthPersist';
import { colors, fonts } from '../../shared/theme';
import { useAppContext } from '../App/AppContext';

declare const ENVARS: any;

const StyledContainer = styled.div`
  height: 100%;
  background: ${colors.lightGrey};
  .ui.basic.clearing.segment {
    padding-right: 5px;
    padding-left: 5px;
  }
  section {
    padding: 0 15px;
    .ui.button {
      font-family: ${fonts.MEDIUM};
      font-size: 18px;
      letter-spacing: 1.08px;
      font-weight: lighter;
      text-transform: uppercase;
    }
  }
  .ui.divided.list {
    margin: 15px -15px 0px;
    border-top: 1px solid ${colors.white};
    border-bottom: 1px solid ${colors.white};
    .item {
      padding: 15px;
      border-color: ${colors.white};
      font-family: ${fonts.REGULAR};
      font-weight: lighter;
      font-size: 18px;
      .image {
        height: 13px;
        width: auto;
        margin: 5px 0px;
      }
    }
  }
  h1 {
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 200;
  }
  .content {
    padding-top: 4px;
  }
  .ui.fluid.button {
    background: ${({ theme }) => theme.primaryColor};
    color: ${colors.secondary};
    text-transform: uppercase;
    height: 46px;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 10px;
  }
`;

const StyledFooter = styled.div`
  .ui.segment {
    background: ${colors.footerBackground};
    padding: 8px;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 0px;
    border: none;
    h4 {
      margin: 0;
      color: ${colors.footerText};
      text-align: center;
    }
  }
`;

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    line-height: 26px;
    font-family: ${fonts.SUPER};
    text-align: center;
    margin-top: 15px;
    margin-bottom: -20px;
    text-transform: uppercase;
  }
  .subtitle {
    color: ${colors.charcoalGrey};
    font-size: 18px;
    line-height: 28px;
    font-family: ${fonts.LIGHT};
    text-align: center;
    max-width: 75%;
    letter-spacing: -1px;
  }
  .ui.teal.basic.button {
    padding-top: 15px;
    padding-bottom: 15px;
    border-color: ${colors.niceBlue} !important;
    color: ${colors.niceBlue} !important;
    box-shadow: 0 0 0 1px ${colors.niceBlue} inset !important;
    text-transform: uppercase;
    font-size: 12px;
    font-family: ${fonts.REGULAR};
    &.details {
      box-shadow: none !important;
    }
    margin-bottom: 20px;
  }
`;

function Profile(props: any) {
  const { user, setUser } = useAppContext();
  const { clearCart } = useCartContext();
  const [openModal, setOpenModal] = useState(false);
  const hasValidSession = user?.authToken;

  const onClose = () => setOpenModal(false);

  const navigateTo = (path: string, validateSession: boolean) => {
    if (validateSession) {
      if (hasValidSession) {
        props.history.push(path, { fromProfile: true });
      } else {
        setOpenModal(true);
      }
    } else {
      props.history.push(path, { fromProfile: true });
    }
  };

  const navigateToLogin = () => {
    window.location.href = `/${BasePath.CONSUMER}/auth`;
  };

  const logout = async () => {
    clearSession();
    setUser(undefined);
    await clearCart();
    props.history.replace('/', {});
  };

  const closeStyle = {
    width: '12px',
    height: '12px',
    float: 'right',
    marginTop: '15px',
    marginRight: '15px',
    cursor: 'pointer',
  };

  return (
    <StyledContainer>
      {props.isSuite ? <Spacer size={SpacerSizes.MEDIUM} /> : <ProfileHeader />}
      <section>
        {!hasValidSession && (
          <Button fluid onClick={navigateToLogin}>
            SIGN IN / SIGN UP
          </Button>
        )}
        {hasValidSession && (
          <Button fluid onClick={logout}>
            LOGOUT
          </Button>
        )}
        <List divided verticalAlign="middle">
          <List.Item onClick={() => navigateTo(`/${BasePath.CONSUMER}/account-details`, true)}>
            <List.Content floated="right">
              <Image src={CaretRightBlack} />
            </List.Content>
            <List.Content>Account</List.Content>
          </List.Item>
          <List.Item onClick={() => navigateTo(`/${BasePath.CONSUMER}/order-history`, true)}>
            <List.Content floated="right">
              <Image src={CaretRightBlack} />
            </List.Content>
            <List.Content>Orders</List.Content>
          </List.Item>
          <List.Item onClick={() => navigateTo(`/${BasePath.CONSUMER}/payment-details`, true)}>
            <List.Content floated="right">
              <Image src={CaretRightBlack} />
            </List.Content>
            <List.Content>Payment</List.Content>
          </List.Item>
          <List.Item onClick={() => navigateTo(`/${BasePath.CONSUMER}/help`, false)}>
            <List.Content floated="right">
              <Image src={CaretRightBlack} />
            </List.Content>
            <List.Content>Help</List.Content>
          </List.Item>
          <List.Item onClick={() => navigateTo(`/${BasePath.CONSUMER}/share`, false)}>
            <List.Content floated="right">
              <Image src={CaretRightBlack} />
            </List.Content>
            <List.Content>FanFood Love</List.Content>
          </List.Item>
          <List.Item onClick={() => navigateTo(`/${BasePath.CONSUMER}/policies`, false)}>
            <List.Content floated="right">
              <Image src={CaretRightBlack} />
            </List.Content>
            <List.Content>Policies</List.Content>
          </List.Item>
        </List>
      </section>
      <StyledFooter>
        <Segment>
          <h4>FanFood, Inc.</h4>
        </Segment>
      </StyledFooter>
      <TransitionablePortal open={openModal} transition={{ animation: 'fade up', duration: 500 }}>
        <Modal
          centered={false}
          open={true}
          onClose={onClose}
          closeIcon={<Image style={closeStyle} src={closeSearchBtn} />}
          style={{ bottom: '20px', borderRadius: '10px', maxWidth: '420px' }}
          size="tiny"
        >
          <Modal.Content style={{ borderRadius: '10px' }}>
            <Modal.Description>
              <StyledModal>
                <h2 className="title">Sign Up Now!</h2>
                <h4 className="subtitle">
                  Looks like you're not signed in or don't have an account yet.
                </h4>
                <Button basic color="teal" onClick={navigateToLogin}>
                  Sign In / Sign Up
                </Button>
              </StyledModal>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    </StyledContainer>
  );
}

export default Profile;
