import ApplePayIcon from 'assets/images/applePayBlack.png';
import GooglePayIcon from 'assets/images/googlePayBlack.png';
import GiveX from 'components/GiveX';
import CartHeader from 'components/Organisms/PWA/Headers/CartHeader/CartHeader';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button, Container, Image, Modal } from 'semantic-ui-react';
import { OpenTabService } from 'services/OpenTabService';
import OpenTabModel from 'shared/models/OpenTab';
import styled from 'styled-components';
import closeSearchBtn from '../../assets/icons/closeSearchBtn.png';
import CustomAlert from '../../components/CustomAlert';
import { CartSummary } from '../../components/ItemsSummary/CartSummary';
import OrderRecipient from '../../components/OrderRecipient';
import { PaymentCard, PaymentMethodType } from '../../components/PaymentMethods';
import { TipCard } from '../../components/TipCard';
import { useLineItemHandler } from '../../hooks/handlers/lineItem';
import {
  usePaymentHandlers,
  usePaymentMethod,
  useStripeError,
  useTipHook,
} from '../../hooks/Payment/payment.hooks';
import { useStoreContext } from '../../hooks/useStoreContext';
import { useVenueContext } from '../../hooks/useVenueContext';
import useWindowHeight from '../../hooks/UseWindowHeight';
import { BasePath, PlatformPaymentType } from '../../shared/constants';
import { clearSession } from '../../shared/SessionAuthPersist';
import { colors, fonts } from '../../shared/theme';
import { useAppContext } from '../App/AppContext';
import useCartContext from '../Cart/context';
import { PaymentProvider, usePaymentContext } from './context';
import { ModalButton, ModalContent, ModalTitle, PaymentContainer, StyledFooter } from './styled';
import { VenueIds } from '../../shared/models/Cart';
interface PaymentRouteProps {
  cartId: string;
  storeId: string;
}

enum VenueToHide {
  RiverfrontStadium = '7f6efc25-fcd5-4147-8d3e-0b2ebe42fa55',
}

const PaymentCardTab = styled.div`
  background: transparent;
  color: ${colors.niceBlue};
  padding: 22px;
  padding-left: 0;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  cursor: pointer;
  font-family: ${fonts.REGULAR};
`;

const closeStyle = {
  width: '12px',
  height: '12px',
  float: 'right',
  marginRight: '15px',
  cursor: 'pointer',
};

const PaymentDeliveryFooter = () => {
  const { paymentType, selectedPaymentMethod, isOrderFree } = usePaymentMethod();
  const { handlePlaceOrder, isSubmitting, payButtonDisabled } = usePaymentHandlers();
  const { useNewPayment } = usePaymentContext();
  const { openTab } = useAppContext();

  const nativePay =
    paymentType && selectedPaymentMethod.paymentMethodType === PaymentMethodType.MobilePay;

  const nativePayIcon =
    !openTab?.isActive && !useNewPayment
      ? nativePay
        ? paymentType === PlatformPaymentType.apple
          ? ApplePayIcon
          : GooglePayIcon
        : ''
      : '';

  return (
    <StyledFooter bg={!isOrderFree ? nativePayIcon : ''}>
      <Button
        disabled={isSubmitting || payButtonDisabled}
        fluid
        onClick={handlePlaceOrder}
        className={'placeOrder'}
      >
        {(!nativePayIcon || isOrderFree) && 'Place Order'}
      </Button>
    </StyledFooter>
  );
};

const PaymentDelivery = () => {
  const history = useHistory();
  const { cartId } = useParams<PaymentRouteProps>();
  const [openTabValues, setOpenTabValues] = React.useState<OpenTabModel>();
  const {
    useNewPayment,
    setUseNewPayment,
    useDeferPayment,
    setUseDeferPayment,
  } = usePaymentContext();
  const { gameDayOrder, setGameDayOrder } = usePaymentContext();
  const { openTab, setOpenTab } = useAppContext();
  const { removeFromCart } = useLineItemHandler();
  const [alcoholExist, setAlcoholExist] = React.useState(false);
  const [paymentOption, setPaymentOption] = React.useState(true);
  const { selectedPaymentMethod } = usePaymentMethod();
  const { order, setUser } = useAppContext();
  const { cart } = useCartContext({
    cartId,
  });

  const { venue, venueEvents } = useVenueContext({
    venueId: cart?.venueId,
    fetchEvents: true,
    gameDayEvent: true,
  });

  useEffect(() => {
    (async () => {
      if (cart && cart.deliveryLocationId && venueEvents && venueEvents?.length > 0) {
        const { data } = await OpenTabService.GetTab(cart.deliveryLocationId, venueEvents[0].id);
        if (data?.id) {
          setOpenTabValues(data);
          setOpenTab(data);
        } else {
          setOpenTab(undefined);
        }
      } else {
        setOpenTab(undefined);
      }
    })();
    setGameDayOrder(true);
  }, [cart, venueEvents]);

  useEffect(() => {
    if (cart && openTabValues?.alcoholAllowed == false) {
      const items = cart.lineItems;
      for (const item of items) {
        if (item.alcohol === true) {
          setAlcoholExist(true);
        }
      }
    }
  }, [openTabValues]);

  const handleCartItems = async () => {
    if (cart) {
      const items = cart.lineItems;
      for (const item of items) {
        if (item?.alcohol === true) {
          await removeFromCart({ quantity: 0 }, item)();
        }
      }
      setAlcoholExist(!alcoholExist);
    }
  };

  const handlePayment = () => {
    setUseNewPayment(!useNewPayment);
    setAlcoholExist(!alcoholExist);
    setPaymentOption(!paymentOption);
  };

  const { store } = useStoreContext({
    storeId: cart?.storeId,
    fetchLocations: true,
  });

  const { isAlertOpen, stripeError, setIsAlertOpen } = useStripeError();
  const { IsTipEnabled, handleChangeTip, tip } = useTipHook();
  const paymentMethodProps = usePaymentMethod();
  const [isCardLength, setIsCardLength] = React.useState<boolean>(false);

  const clientHeight = useWindowHeight();

  const handleOnBackClick = () => {
    if (!cart) {
      history.replace(`/${BasePath.CONSUMER}/stores/${venue?.id}/products/${store?.id}`, {
        return: true,
      });
      return;
    }

    history.replace(`/${BasePath.CONSUMER}/cart/${cart?.id}`, {
      return: true,
    });
  };

  const creditCardsCheckFunction = (value: []) => {
    if (value.length > 0) {
      setIsCardLength(true);
    } else {
      setIsCardLength(false);
    }
  };

  const showNewPaymentCard = () => {
    setUseNewPayment(!useNewPayment);
  };

  const handleDeferPayment = () => {
    setUseDeferPayment(!useDeferPayment);
    setPaymentOption(false);
  };

  const handleStripeErrorModal = async () => {
    setIsAlertOpen(false);
    clearSession();
    setUser(undefined);
  };

  return (
    <>
      <Modal
        centered={true}
        open={alcoholExist}
        onClose={() => history.goBack()}
        closeIcon={<Image style={closeStyle} alt="Close Button" src={closeSearchBtn} />}
        style={{ borderRadius: '10px', padding: '10px', width: 'max-content' }}
      >
        <Modal.Content style={{ alignItems: 'center' }}>
          <Modal.Description>
            <ModalTitle>HELLO</ModalTitle>
            <ModalContent>Alcohol is not allowed in open tab!</ModalContent>
            <ModalContent>Place order without alcohol.</ModalContent>
            <ModalButton onClick={handleCartItems}>Without Alcohol</ModalButton>
            <ModalContent>Place order using your own credit card.</ModalContent>
            <ModalButton onClick={handlePayment}>Continue</ModalButton>
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <PaymentContainer clientHeight={clientHeight}>
        <CartHeader onClick={handleOnBackClick} store={store} />
        <main>
          <Container>
            {venue && venue.id !== VenueIds.HIDE_USERDETAILS && <OrderRecipient />}
            {/*  */}
            <TipCard showTip={IsTipEnabled()} onChange={handleChangeTip} tip={tip} />

            {openTabValues &&
              openTabValues.isActive &&
              paymentOption &&
              !venue?.deferPaymentCapture &&
              selectedPaymentMethod.paymentMethodType === PaymentMethodType.CreditCard && (
                <PaymentCardTab onClick={showNewPaymentCard}>
                  {useNewPayment ? 'Use Tab' : 'Use your payment card'}
                </PaymentCardTab>
              )}
            {openTabValues &&
              openTabValues.isActive &&
              venue?.allowOpenTabs &&
              venue.deferPaymentCapture && (
                <PaymentCardTab onClick={handleDeferPayment}>
                  {useDeferPayment ? 'Use Tab' : 'Use Defer Payment'}
                </PaymentCardTab>
              )}
            <PaymentCard
              {...paymentMethodProps}
              creditCardsCheck={creditCardsCheckFunction}
              openTabValues={openTabValues}
              useNewPayment={useNewPayment}
              gameDayOrder={true}
              useDeferPayment={useDeferPayment}
            />
            {venue?.id === VenueToHide.RiverfrontStadium && <GiveX tip={tip} />}
            <CartSummary showTip={IsTipEnabled()} tip={tip} showSummary />
          </Container>
        </main>
        <PaymentDeliveryFooter />
        <CustomAlert
          isOpen={isAlertOpen}
          onCloseModal={handleStripeErrorModal}
          title="PAYMENT ISSUE"
          message={stripeError}
          footerMessage="You may see a pending statement charge, but it will be removed after 1-3 days. You must log out and try again"
        />
      </PaymentContainer>
    </>
  );
};

const PaymentDeliveryWrapper = () => {
  return (
    <PaymentProvider>
      <PaymentDelivery />
    </PaymentProvider>
  );
};
export default PaymentDeliveryWrapper;
