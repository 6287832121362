import React from 'react';
import Container, { CloseButtonContainer } from './styled';
import closeWhite from '../../assets/icons/closeWhite.png';

const Toast = () => {
  return (
    <Container
      position='bottom-center'
      autoClose={3000}
      hideProgressBar
      closeOnClick
      pauseOnHover={false}
      closeButton={<CloseButtonContainer src={closeWhite} />}
    />
  );
};

export default Toast;
