import { toast } from 'react-toastify';
import { useAppContext } from '../../containers/App/AppContext';
import useCartContext from '../../containers/Cart/context';
import {
  CartService,
  CreateCartDto,
  LineItemDto,
  UpdateLineItemDto,
} from '../../services/CartService';
import ICart, { ILineItem } from '../../shared/models/Cart';
import { useCartValidation } from '../validators/cart_validators';

export interface ActionResponse<T> {
  success: T | boolean | undefined;
  error: any;
}

export interface ILineItemActionHandler {
  createCart: (createCartDto: CreateCartDto) => () => Promise<ActionResponse<ICart>>;
  addItemToCart: (
    lineItemDto: LineItemDto,
    isAlcohol?: boolean,
  ) => () => Promise<ActionResponse<ICart>>;
  removeFromCart: (
    lineItemDto: UpdateLineItemDto,
    lineItem: ILineItem,
  ) => () => Promise<ActionResponse<ICart>>;
}

export const useLineItemHandler = (): ILineItemActionHandler => {
  const { venue } = useAppContext();
  const { cart, setCart } = useCartContext();
  const { checkAlcoholOverLimit } = useCartValidation();

  const addItemToCart = (payload: LineItemDto, isAlcohol = false) => async (): Promise<
    ActionResponse<ICart>
  > => {
    const apiAction: ActionResponse<ICart> = { success: false, error: false };
    if (!cart) {
      toast(`Cart does not exist`);
      return {
        ...apiAction,
        error: {
          response: {
            data: {
              message: `Cart does not exist`,
            },
          },
        },
      };
    }

    if (payload && isAlcohol && checkAlcoholOverLimit(payload)) {
      toast(`Limit of ${venue?.alcoholLimit} alcoholic drinks per order`);
      return {
        ...apiAction,
        error: {
          response: {
            data: {
              message: `Limit of ${venue?.alcoholLimit} alcoholic drinks per order`,
            },
          },
        },
      };
    }
    try {
      const { data } = await CartService.AddLineItem(cart.id, payload);
      setCart(data);
      return {
        ...apiAction,
        success: data,
      };
    } catch (error) {
      toast(error?.response.data.message);
      return {
        ...apiAction,
        error,
      };
    }
  };

  const createCart = (payload: CreateCartDto) => async (): Promise<ActionResponse<ICart>> => {
    const apiAction: ActionResponse<ICart> = { success: false, error: false };
    try {
      const { data } = await CartService.CreateCart(payload);
      setCart(data);
      return {
        ...apiAction,
        success: data,
      };
    } catch (error) {
      toast(error?.response.data.message);
      return {
        ...apiAction,
        error,
      };
    }
  };

  const removeFromCart = (payload: UpdateLineItemDto, lineItem: ILineItem) => async (): Promise<
    ActionResponse<ICart>
  > => {
    const apiAction: ActionResponse<ICart> = { success: false, error: false };
    if (!cart) {
      toast(`Cart does not exist`);
      return {
        ...apiAction,
        error: {
          response: {
            data: {
              message: "Cart doesn't exist",
            },
          },
        },
      };
    }
    try {
      const { data } = await CartService.UpdateLineItem(cart?.id, lineItem.id, payload);
      setCart(data);
      return {
        ...apiAction,
        success: data,
      };
    } catch (error) {
      toast(error?.response.data.message);
      return {
        ...apiAction,
        error,
      };
    }
  };

  return {
    addItemToCart,
    removeFromCart,
    createCart,
  };
};
