import React from 'react';
import { Message, Footer } from './styled';
import AnimatedModal from 'components/Molecules/AnimatedModal/AnimatedModal';

type ModalLocationProps = {
  isOpen: boolean;
  onCloseModal: () => void;
  title: string;
  message: string;
  footerMessage: string;
};

const Alert = ({ isOpen, onCloseModal, title, message, footerMessage }: ModalLocationProps) => {
  return (
    <AnimatedModal showModal={isOpen} onCloseModal={onCloseModal}>
      <AnimatedModal.Title copy={title} />
      <AnimatedModal.Content>
        <Message>{message}</Message>
        <Footer>{footerMessage}</Footer>
      </AnimatedModal.Content>
    </AnimatedModal>
  );
};

export default Alert;
