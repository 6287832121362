import { get, isEmpty, isEqual, last } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../containers/App/AppContext';
import useCartContext from '../../containers/Cart/context';
import { IDeliveryLocation, OrderType } from '../../shared/models/Cart';
import { IVenueLocation } from '../../shared/models/Venue';
import { findDeliveryLocations } from '../../shared/venue.util';
import { useCartHandler } from '../handlers/cart';

interface DeliveryLocationContext {
  deliveryLocation: IDeliveryLocation[] | undefined;
  deliveryLocationId: string | undefined;
  deliveryLocationInput: string | undefined;
  selectedDeliveryLocations: IVenueLocation[];
  setDeliveryLocation: Dispatch<SetStateAction<IDeliveryLocation[] | undefined>>;
  validDeliveryLocations: any;
  updateDeliveryLocation: (location: IDeliveryLocation[] | undefined) => Promise<void>;
  updateDeliveryLocationId: (locations: IVenueLocation[], customInput?: string) => Promise<void>;
}

export const useDeliveryLocation = (): DeliveryLocationContext => {
  const {
    deliveryLocation,
    deliveryLocationId,
    deliveryLocationInput,
    setDeliveryLocation,
    setDeliveryLocationId,
    setDeliveryLocationInput,
    setOrderType,
    store,
    venue,
    venueLocations,
  } = useAppContext();
  const { cart } = useCartContext();
  const { updateCart } = useCartHandler();
  const [validDeliveryLocations, setValidDeliveryLocations] = useState<any>({});

  const venueDeliveryLocations = get(venue, ['validLocationTypes'], []);
  const storeDeliveryLocations = get(store, ['validLocationTypes'], []);

  useEffect(() => {
    if (venueDeliveryLocations.length > 0) {
      setValidDeliveryLocations(parseStoreLocations());
    }
  }, [store, venue]);

  const selectedDeliveryLocations: IVenueLocation[] = useMemo(() => {
    if (!deliveryLocationId || !venueLocations || isEmpty(venueLocations)) {
      return [];
    }
    return findDeliveryLocations(deliveryLocationId, venueLocations);
  }, [venueLocations, deliveryLocationId]);

  // Update the Delivery Location if the cart doesn't exist and the venue uses New Locations
  useEffect(() => {
    if (cart || !venue?.useNewLocations) {
      return;
    }

    const newDeliveryLocation = selectedDeliveryLocations
      .map(({ name }) => ({ name: '', value: name }))
      .concat(deliveryLocationInput ? [{ name: '', value: deliveryLocationInput }] : []);
    setDeliveryLocation(newDeliveryLocation);
  }, [selectedDeliveryLocations, deliveryLocationInput]);

  const parseStoreLocations = () => {
    const locationObj: any = {};
    if (storeDeliveryLocations.length > 0) {
      storeDeliveryLocations.forEach(storeDeliveryLocation => {
        const { locationTypeId, validValues } = storeDeliveryLocation;

        const venueLevelLocation = venueDeliveryLocations.find(
          venueLocation => venueLocation.id === locationTypeId,
        );

        if (!venueLevelLocation || (venueLevelLocation.validValues.length && !validValues.length))
          return;

        const storeLevelLocations = venueLevelLocation;
        storeLevelLocations.validValues = validValues;
        const location = extractLocations(storeLevelLocations);

        locationObj[storeLevelLocations.name] = location;
      });
    } else {
      venueDeliveryLocations.forEach(venueDeliveryLocation => {
        const locations = extractLocations(venueDeliveryLocation);
        locationObj[venueDeliveryLocation.name] = locations;
      });
    }

    return locationObj;
  };

  const extractLocations = (validLocationTypes: any) => {
    const location = [];

    let types = validLocationTypes;
    while (types) {
      location.push({
        name: types.name,
        value: types.validValues,
      });
      const { validLocationTypes: locationTypes } = types;
      const [firstLocationType] = locationTypes;
      types = firstLocationType;
    }
    return location;
  };

  const updateDeliveryLocation = async (location: IDeliveryLocation[] | undefined) => {
    setOrderType(OrderType.DELIVERY);
    setDeliveryLocation(location);
    if (location && cart) {
      if (!isEqual(cart.location, location)) {
        await updateCart({
          ...(cart.orderType !== OrderType.DELIVERY && { orderType: OrderType.DELIVERY }),
          location,
        })();
      }
    }
  };

  const updateDeliveryLocationId = async (locations: IVenueLocation[], customInput?: string) => {
    const deliveryLocation = last(locations);
    if (!deliveryLocation) {
      return;
    }
    const newDeliveryLocationId = deliveryLocation.isDeliveryLocation
      ? deliveryLocation.id
      : undefined;

    if (!newDeliveryLocationId) {
      return;
    }

    setOrderType(OrderType.DELIVERY);
    setDeliveryLocationId(newDeliveryLocationId);
    setDeliveryLocationInput(customInput);

    if (cart) {
      await updateCart({
        orderType: OrderType.DELIVERY,
        deliveryLocationId: newDeliveryLocationId,
        ...(deliveryLocation.isCustomInput && { deliveryLocationInput: customInput }),
      })();
    }
  };

  return {
    deliveryLocation,
    deliveryLocationId,
    deliveryLocationInput,
    selectedDeliveryLocations,
    setDeliveryLocation,
    validDeliveryLocations,
    updateDeliveryLocation,
    updateDeliveryLocationId,
  };
};
