import { useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, Stripe } from '@stripe/stripe-js';
import { find, get } from 'lodash';
import React, { Dispatch, ReactChild, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { GiveXService } from 'services/GiveXService';
import { OpenTabService } from 'services/OpenTabService';
import { PaymentMethod, PaymentMethodType } from '../../components/PaymentMethods';
import { useSquareContext } from '../../components/Square/context';
import createContextWrapper from '../../hooks/CreateContext';
import { useDeliveryLocation } from '../../hooks/Location';
import { CheckoutDto, OrderService } from '../../services/OrderService';
import { UserService, UserType } from '../../services/UserService';
import { BasePath, PlatformPaymentType } from '../../shared/constants';
import { OrderType } from '../../shared/models/Cart';
import OrderGroup from '../../shared/models/OrderGroup';
import { PaymentGateway } from '../../shared/models/Venue';
import { saveSessionValues } from '../../shared/SessionAuthPersist';
import { getCurrencyPrefix } from '../../shared/utils';
import { useAppContext, useEventContext } from '../App/AppContext';
import useCartContext from '../Cart/context';
import { VenueIds } from '../../shared/models/Cart';
interface IRecipientState {
  value: string;
  hasError: boolean;
}

interface IOrderRecipient {
  firstName: IRecipientState;
  lastName: IRecipientState;
  email: IRecipientState;
}

export interface PaymentContext {
  currentEventId: string | undefined;
  IsTipEnabled: () => boolean;
  finalAmount: string | number;
  handleChangeTip: (amount: number) => void;
  handlePlaceOrder: () => Promise<void>;
  isAlertOpen: boolean;
  isOrderFree: boolean;
  isSubmitting: boolean;
  isSuite: boolean;
  orderGroups: OrderGroup[];
  payButtonDisabled: boolean;
  paymentRequest: PaymentRequest | undefined;
  paymentType: string;
  useNewPayment: boolean;
  gameDayOrder: boolean;
  useDeferPayment: boolean;
  placeOrder: (paymentMethodId?: string) => Promise<void>;
  saveUserData: () => Promise<void>;
  selectedPaymentMethod: PaymentMethod;
  setIsAlertOpen: Dispatch<SetStateAction<boolean>>;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  setOrderGroups: Dispatch<SetStateAction<OrderGroup[]>>;
  setPayButtonDisabled: Dispatch<SetStateAction<boolean>>;
  setPaymentRequest: Dispatch<SetStateAction<PaymentRequest | undefined>>;
  setPaymentType: Dispatch<SetStateAction<string>>;
  setSelectedPaymentMethod: Dispatch<SetStateAction<PaymentMethod>>;
  setTip: Dispatch<SetStateAction<number>>;
  setUserEditValues: Dispatch<SetStateAction<IOrderRecipient>>;
  setUseNewPayment: Dispatch<SetStateAction<boolean>>;
  setUseDeferPayment: Dispatch<SetStateAction<boolean>>;
  setGameDayOrder: Dispatch<SetStateAction<boolean>>;
  stripe: Stripe | null;
  stripeAmount: number;
  stripeError: string;
  tip: number;
  userEditValues: IOrderRecipient;
}

interface IPaymentProviderProps {
  isSuite?: boolean;
  children: ReactChild;
}

enum OpenTabOrderType {
  MAIN_ORDER = 'MAIN_ORDER',
  SUB_ORDER = 'SUB_ORDER',
}

const [usePaymentContext, PaymentContextProvider] = createContextWrapper<PaymentContext>();

const PaymentProvider = ({ isSuite = false, children }: IPaymentProviderProps) => {
  const {
    user,
    setUser,
    venue,
    setShowLoader,
    store,
    setOrder,
    order,
    setDeliveryLocationId,
    openTab,
    givexData,
    setGivexData,
  } = useAppContext();
  const stripe = useStripe();
  const { squarePayments, initNativePay, tokenize } = useSquareContext();
  const [tip, setTip] = useState(0);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [paymentType, setPaymentType] = useState('');
  const [payButtonDisabled, setPayButtonDisabled] = useState(false);
  const [orderGroups, setOrderGroups] = useState<OrderGroup[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>({
    orderGroupSpendingLimit: '0',
    paymentMethodType: venue?.deferPaymentCapture
      ? PaymentMethodType.Defer
      : PaymentMethodType.CreditCard,
  });
  const [stripeError, setStripeError] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { deliveryLocation, setDeliveryLocation } = useDeliveryLocation();
  const { setEvent } = useEventContext();
  const { cart, setCart } = useCartContext();
  const history = useHistory();
  const [currentEventId, setCurrentEventId] = useState<string | undefined>(undefined);
  const [useNewPayment, setUseNewPayment] = useState<boolean>(false);
  const [useDeferPayment, setUseDeferPayment] = useState<boolean>(false);
  const [gameDayOrder, setGameDayOrder] = useState<boolean>(false);

  const [userEditValues, setUserEditValues] = useState<IOrderRecipient>({
    firstName: { value: user?.firstName || '', hasError: false },
    lastName: { value: user?.lastName || '', hasError: false },
    email: { value: user?.email || '', hasError: false },
  });

  const finalAmount = givexData?.balance
    ? (Number(cart?.total) - Number(givexData.balance) + Number(tip)).toFixed(2)
    : (Number(cart?.total) + Number(tip)).toFixed(2) || 0;

  const stripeAmount = Number((Number(finalAmount) * 100).toFixed(2));

  const currencyPrefix = getCurrencyPrefix(cart?.currency);

  const isOrderFree = (cart?.total ?? 0) + tip <= 0;

  const applyRedemption = async () => {
    const payload = {
      jsonrpc: '2.0',
      method: 'dc_901',
      id: '60134',
      params: ['en', '1162789409', '37633', '8KJqGFAOdiUxVXev', '', '', ''],
    };
    if (givexData?.balance) {
      payload.params[4] = givexData.cardNumber;
      payload.params[5] = givexData.balance.toString();
      setGivexData(undefined);
      const data = await GiveXService.applyRedemption(payload);
      console.log(data, 'apply redemptionnn');
    }
  };

  useEffect(() => {
    return () => {
      if (order) {
        setCart(undefined);
        setEvent(undefined);
        setDeliveryLocation(undefined);
        setDeliveryLocationId(undefined);
        if (givexData?.balance) {
          applyRedemption();
        }
      }
    };
  }, [order]);

  useEffect(() => {
    if (selectedPaymentMethod.paymentMethodType === PaymentMethodType.Defer) {
      return setPayButtonDisabled(false);
    }

    if (isOrderFree) {
      return setPayButtonDisabled(false);
    }

    // Prevents hitting Stripe with transactions less than one unit of currency
    //  when a free order has an associated tip.
    if ((cart?.total ?? 0) <= 0 && tip < 1) {
      if (!toast.isActive('tipping-minimum')) {
        toast('Provide a value greater than 1 when tipping', { toastId: 'tipping-minimum' });
      }
      return setPayButtonDisabled(true);
    }

    const orderTotal = (cart?.total ?? 0) + tip;
    switch (selectedPaymentMethod.paymentMethodType) {
      case PaymentMethodType.CreditCard:
        return setPayButtonDisabled(!selectedPaymentMethod.selectedCard);
      case PaymentMethodType.MobilePay: {
        switch (venue?.paymentGateway) {
          case PaymentGateway.SQUARE: {
            return setPayButtonDisabled(!paymentType);
          }
          case PaymentGateway.STRIPE:
          default:
            return setPayButtonDisabled(!paymentRequest);
        }
      }
      case PaymentMethodType.Tab: {
        // must have selected card
        if (!selectedPaymentMethod.selectedCard) return setPayButtonDisabled(true);

        // if tab is selected but no orderGroupId is selected, this is a new tab
        if (!selectedPaymentMethod.orderGroupId) {
          // check for a sufficient spending limit
          const limit = Number(selectedPaymentMethod.orderGroupSpendingLimit);
          if (limit > 0 && orderTotal > limit) return setPayButtonDisabled(true);

          return setPayButtonDisabled(false);
        }

        // this is an existing tab. confirm that the order total does not exceed the balance
        const selectedOrderGroup = find(
          orderGroups,
          (orderGroup: OrderGroup) => orderGroup.id === selectedPaymentMethod.orderGroupId,
        );
        if (!selectedOrderGroup) return setPayButtonDisabled(true);
        return setPayButtonDisabled(
          selectedOrderGroup.spendingLimit > 0 &&
            orderTotal > selectedOrderGroup.spendingLimitBalance,
        );
      }
    }
  }, [selectedPaymentMethod, userEditValues, paymentRequest, tip, paymentType, venue]);

  useEffect(() => {
    const init = async () => {
      if (!stripe || !cart || !store || paymentRequest || isSuite || !venue) {
        return;
      }
      try {
        const PR = stripe.paymentRequest({
          country: 'US',
          currency: cart?.currency.toLowerCase() || 'usd',
          total: {
            label: 'FanFood Inc.',
            amount: stripeAmount,
          },
        });

        const paymentAvailability = await PR.canMakePayment();

        if (venue.paymentGateway === PaymentGateway.STRIPE) {
          PR.on('paymentmethod', async ({ complete, paymentMethod, ...data }: any) => {
            if (paymentMethod.id) {
              toast('your card is authorized successfully');
              const placeOrderData: any = await placeOrder(paymentMethod.id);
              if (placeOrderData) {
                toast('your order is placed successfully');
                complete('success');
              } else {
                toast('could not place order');
                complete('fail');
              }
            } else {
              toast('card authorization failed, please try again');
              complete('fail');
            }
          });
          setPaymentRequest(PR);
        }

        if (paymentAvailability && venue.paymentGateway === PaymentGateway.STRIPE) {
          if (paymentAvailability.applePay) {
            setPaymentType(PlatformPaymentType.apple);
          } else {
            setPaymentType(PlatformPaymentType.android);
          }
          setSelectedPaymentMethod({
            ...selectedPaymentMethod,
            paymentMethodType: PaymentMethodType.MobilePay,
            selectedCard: undefined,
          });
        }
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error);
      }
    };
    init();
  }, [stripe, setPaymentRequest, cart, store, venue]);

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [setIsSubmitting]);

  const IsTipEnabled = (): boolean => {
    if (!store || !venue) {
      return false;
    }
    switch (cart?.orderType) {
      case OrderType.DELIVERY:
        return store.enableDeliveryTips ?? venue.enableDeliveryTips;
      case OrderType.PICKUP:
        return store.enablePickupTips ?? venue.enablePickupTips;
      default:
        return false;
    }
  };

  const showMinimumOrderToast = () => {
    if (!cart || !store) {
      return;
    }
    const { subTotal } = cart;
    const { orderMinimum, name } = store;

    if (!toast.isActive('minimum-toast')) {
      toast(
        `${name} has a ${currencyPrefix}${orderMinimum} order minimum. Please add $${Number(
          orderMinimum - subTotal,
        ).toFixed(2)} more to checkout.`,
        {
          autoClose: false,
          className: 'minimum-toast',
          toastId: 'minimum-toast',
        },
      );
    }
  };

  const handleChangeTip = (amount: number) => {
    const tipAmount = Number(amount).toFixed(2);
    setTip(Number(tipAmount));
  };

  const showStripeErrorMessage = (message: string) => {
    setStripeError(message || 'Something went wrong. Please try again');
    setIsAlertOpen(true);
  };

  const cartHasPaidItems = cart?.lineItems.some(item => item.total > 0);

  const placeOrder = async (paymentMethodId?: string) => {
    if (!cart || !store) {
      return;
    }
    const { firstName, lastName, email } = userEditValues;
    if (venue && venue.id !== VenueIds.HIDE_USERDETAILS) {
      if (
        firstName?.value.length <= 0 ||
        lastName?.value.length <= 0 ||
        email?.value.length <= 0 ||
        email.hasError
      ) {
        toast('Please enter required fields');
        return;
      }
    }

    if (cartHasPaidItems && (cart?.subTotal ?? 0) < store.orderMinimum) {
      showMinimumOrderToast();
      return;
    }

    if (
      selectedPaymentMethod.paymentMethodType !== PaymentMethodType.Defer &&
      !isOrderFree &&
      !selectedPaymentMethod.selectedCard &&
      !paymentMethodId &&
      !selectedPaymentMethod.orderGroupId
    ) {
      toast('Please select a payment method');
      return;
    }

    if (!isSuite && cart?.orderType === 'DELIVERY' && !deliveryLocation) {
      toast('Please select a delivery location');
      return;
    }

    setIsSubmitting(true);
    const checkoutPayload: CheckoutDto = {
      cartId: cart?.id ?? '',
      finalPrice: +finalAmount,
      tipAmt: tip,
      tabId: openTab?.isActive && !useDeferPayment ? openTab?.id : null,
      tabOrderType:
        openTab?.isActive && !useDeferPayment
          ? gameDayOrder
            ? OpenTabOrderType.SUB_ORDER
            : OpenTabOrderType.MAIN_ORDER
          : null,
      tabBalance: openTab?.isActive && !useDeferPayment ? openTab.tabBalance : null,
      givexData: givexData && givexData,
      ...(selectedPaymentMethod.paymentMethodType === PaymentMethodType.Tab && {
        addToOrderGroup: true,
      }),
      ...(selectedPaymentMethod.paymentMethodType === PaymentMethodType.Defer && {
        deferred: true,
      }),
      ...(selectedPaymentMethod.orderGroupId && {
        orderGroupId: selectedPaymentMethod.orderGroupId,
      }),
      ...(!isOrderFree &&
        (selectedPaymentMethod.selectedCard || paymentMethodId) && {
          paymentMethodId: selectedPaymentMethod.selectedCard?.id ?? paymentMethodId,
        }),
      ...(selectedPaymentMethod.orderGroupSpendingLimit && {
        orderGroupSpendingLimit: +selectedPaymentMethod.orderGroupSpendingLimit,
      }),
    };

    try {
      const { data: order } = await OrderService.CheckOut(checkoutPayload);
      setOrder(order);
      setIsSubmitting(false);
      if (openTab && order && !useNewPayment && gameDayOrder) {
        const OpenTabPayload = {
          tabBalance: openTab.tabBalance - Number(finalAmount),
        };
        await OpenTabService.UpdateTab(OpenTabPayload, openTab.id);
      }
      if (isSuite) {
        return history.replace(`/${BasePath.SUITE}/order-placed/${order.id}`);
      }
      history.replace(`/${BasePath.CONSUMER}/order-placed/${order.id}`);
    } catch (error) {
      setIsSubmitting(false);
      const message = get(error, ['response', 'data', 'message'], null);
      showStripeErrorMessage(message);
      toast(message || 'Something went wrong. Please try again!!');
    }
  };

  const saveUserData = async () => {
    if (!user) {
      return;
    }
    const { firstName, lastName, email } = userEditValues;

    const needUpdate =
      firstName.value !== user.firstName ||
      lastName.value !== user.lastName ||
      email.value !== user.email;
    if (needUpdate) {
      setIsSubmitting(true);
      const payload: UserType = {
        firstName: firstName.value?.trim(),
        lastName: lastName.value?.trim(),
        email: email.value?.trim(),
      };
      const updateResponse = await UserService.UpdateUser(user.id, payload);
      const { status, data } = updateResponse;
      if (status === 200) {
        const toSaveData = {
          ...user,
          ...data,
        };
        setUser(toSaveData);
        saveSessionValues(toSaveData);
      }
      setIsSubmitting(false);
    }
  };

  const handlePlaceOrder = async () => {
    if (isOrderFree) {
      await placeOrder();
      return;
    }

    if (openTab?.isActive && !useNewPayment && gameDayOrder) {
      await placeOrder();
      return;
    }

    switch (selectedPaymentMethod.paymentMethodType) {
      case PaymentMethodType.CreditCard:
      case PaymentMethodType.Defer:
      case PaymentMethodType.Tab:
        await placeOrder();
        break;
      case PaymentMethodType.MobilePay:
        await handleNativePay();
        break;
    }
  };

  const handleNativePay = async () => {
    switch (venue?.paymentGateway) {
      case PaymentGateway.STRIPE: {
        if (paymentType && paymentRequest) {
          paymentRequest.update({
            currency: cart?.currency.toLowerCase() || 'usd',
            total: {
              label: 'FanFood Inc.',
              amount: stripeAmount,
            },
          });
          paymentRequest.show();
        }
        break;
      }
      case PaymentGateway.SQUARE: {
        if (!paymentType || !squarePayments || !finalAmount) {
          return;
        }

        const paymentRequest = squarePayments.paymentRequest({
          countryCode: 'US',
          currencyCode: cart?.currency?.toUpperCase() || 'USD',
          total: {
            label: 'FanFood Inc.',
            amount: finalAmount,
          },
        });

        const nativePay = await initNativePay(paymentType, paymentRequest);

        if (nativePay) {
          const tokenResult = await tokenize(nativePay);

          await placeOrder(tokenResult?.token);
        }
      }
    }
  };

  return (
    <PaymentContextProvider
      value={{
        IsTipEnabled,
        currentEventId,
        finalAmount,
        handleChangeTip,
        handlePlaceOrder,
        isAlertOpen,
        isOrderFree,
        isSubmitting,
        isSuite,
        orderGroups,
        payButtonDisabled,
        paymentRequest,
        paymentType,
        placeOrder,
        saveUserData,
        selectedPaymentMethod,
        useNewPayment,
        setUseNewPayment,
        useDeferPayment,
        setUseDeferPayment,
        gameDayOrder,
        setGameDayOrder,
        setIsAlertOpen,
        setIsSubmitting,
        setOrderGroups,
        setPayButtonDisabled,
        setPaymentRequest,
        setPaymentType,
        setSelectedPaymentMethod,
        setTip,
        setUserEditValues,
        stripe,
        stripeAmount,
        stripeError,
        tip,
        userEditValues,
      }}
    >
      {children}
    </PaymentContextProvider>
  );
};

export { usePaymentContext, PaymentProvider };
