import styled from 'styled-components';
import { Input, Form, Select, Image } from 'semantic-ui-react';

import { fonts } from 'shared/theme';

export const StyledSelect = styled(Select)`
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #6e6e6e !important;
  padding: 0 !important;

  .text {
    font-size: 16px;
    font-family: ${fonts.REGULAR};
    width: 100%;
  }
`;

export const StyledSelectWithoutPlaceholder = styled(Select)`
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #6e6e6e !important;

  display: flex !important;
  align-items: center !important;
  padding: 0 !important;

  .text {
    font-size: 16px;
    font-family: ${fonts.REGULAR};
    width: 100%;
  }
`;

export const PlaceHolder = styled.div`
  color: #474350;
  padding: 1em 2.1em 4px 0em;
  font-size: 16px;
`;

export const ModalSubTitle = styled.div`
  font-family: ${fonts.REGULAR};
  font-size: 18px;
  text-align: center;
  color: #474350;
  padding: 0 15%;
`;

export const StyledInput = styled(Input)`
  input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    padding: 0.67857143em 0em !important;
    border-bottom: 1px solid #6e6e6e !important;
  }
`;

export const StyledDownChevron = styled(Image)`
  width: 13px;
  height: 7px;
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledField = styled(Form.Field)`
  flex: 1 !important;
  flex-basis: 33.3333% !important;
  padding-top: 22px;
`;

export const FieldLabel = styled.label`
  text-align: left;
  font-family: ${fonts.REGULAR};
  color: #6e6e6e !important;
  font-size: 12px !important;
  font-weight: normal !important;
  margin: 0 !important;
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 30px;
`;
