import styled, { css } from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const Wrapper = styled.div`
  margin: auto -21px;
  .ui.horizontal.list {
    overflow-x: auto;
    white-space: nowrap;
    padding: 0px;
    width: 100%;
    height: 100%;
    font-size: 1.3em;

    &:not(.celled) > .item:first-child {
      margin-left: 21px !important;
    }
    .item:last-child {
      margin-right: 21px;
    }
  }
`;

export const TipSelector = styled.div`
  display: flex;
  height: 36px;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-top: 10px;
  flex-grow: auto;
`;

export const TipOption = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin-left: -1px;
  background-clip: border-box;
  transform: scale3d(1, 1, 1);
  background-color: #fff;
  border: 1px solid;
  color: #b7b7b7;
  transition: 0.15s ease-in-out background-color;

  ${({ selected }) => selected && TipSelectedCSS}

  &:hover {
    color: ${colors.charcoalGrey};
    background: rgba(91, 150, 213, 0.2), 0.15;
  }

  &:first-child {
    margin-left: 0;
    border-radius: 3px 0 0 3px;
  }
`;

const TipSelectedCSS = css`
  background-color: ${colors.charcoalGrey}!important;
  color: #ffffff !important;
  z-index: 2 !important;
  font-family: ${fonts.MEDIUM};
  cursor: text;
`;

export const TipCustomInput = styled.input<{ selected?: boolean }>`
  font-size: 1em;
  color: #b7b7b7;
  outline: none;
  height: 100%;
  width: 100%;
  border: 1px solid #bbb;
  position: relative;
  align-self: center;
  text-align: center;
  padding-bottom: 2px;
  flex-grow: 2;
  border-radius: 0 3px 3px 0;
  border-left: 0px;

  ${({ selected }) => selected && TipSelectedCSS}

  &:hover {
    color: ${colors.charcoalGrey};
    background: rgba(91, 150, 213, 0.2), 0.15;
  }

  &:focus {
    border-color: ${colors.charcoalGrey};
  }

  &::placeholder {
    color: #b7b7b7;
  }
`;

export const TipCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    align-self: flex-start;
    padding: 0px;
    font-size: 18px;
    color: ${colors.charcoalGrey};
    font-family: ${fonts.REGULAR}!important;
  }
  .tip_amount {
    align-self: flex-end;
    padding: 0px;
    font-size: 16px;
    color: ${colors.charcoalGrey};
    font-family: ${fonts.MEDIUM};
  }
`;
