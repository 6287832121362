import React from 'react';
import { ChatBubbleContainer } from './styled';
import { Grid } from 'semantic-ui-react';

export default function ChatBubble({ message, isUser }: any) {
  return (
    <ChatBubbleContainer>
      <Grid columns={1} className='bubble-container'>
        <Grid.Column className={`bubble ${isUser ? 'alternative-color' : 'default-color'}`}>
          {message}
        </Grid.Column>
      </Grid>
    </ChatBubbleContainer>
  );
}
