import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppContext } from '../containers/App/AppContext';
import { VenueService } from '../services';
import { Event, EventsService } from '../services/EventsService';
import { StoreService, StoreTypes } from '../services/StoreService';
import { Sponsor } from '../services/VenueService';
import Store from '../shared/models/Store';
import Venue, { IVenueLocation } from '../shared/models/Venue';
import { isValidUUIDv4 } from '../shared/utils';

interface IVenueContext {
  venue: Venue | undefined;
  stores: Store[] | undefined;
  venueEvents: Event[] | undefined;
  venueLocations: IVenueLocation[] | undefined;
  setVenue: Dispatch<SetStateAction<Venue | undefined>>;
  setVenueEvents: Dispatch<SetStateAction<Event[] | undefined>>;
  setStores: Dispatch<SetStateAction<Store[] | undefined>>;
  venueSponsor: VenueSponsor | undefined;
  dataLoading: boolean;
}

export interface VenueSponsor {
  title: string;
  sponsors: Sponsor[];
}

interface VenueContextParams {
  venueId?: string;
  fetchStores?: boolean;
  fetchEvents?: boolean;
  fetchSponsors?: boolean;
  fetchLocations?: boolean;
  gameDayEvent?: boolean;
}

export const useVenueContext = ({
  venueId,
  fetchStores,
  fetchEvents,
  fetchSponsors,
  fetchLocations,
  gameDayEvent,
}: VenueContextParams = {}): IVenueContext => {
  const history = useHistory();
  const {
    venue,
    setVenue,
    setShowLoader,
    deliveryLocation,
    deliveryLocationId,
    orderType,
    event,
    venueLocations,
    setVenueLocations,
  } = useAppContext();
  const [stores, setStores] = useState<Store[] | undefined>(undefined);
  const [venueSponsor, setVenueSponsor] = useState<VenueSponsor | undefined>(undefined);
  const [venueEvents, setVenueEvents] = useState<Event[] | undefined>(undefined);
  const [dataLoading, setDataLoader] = useState<boolean>(true);

  useEffect(() => {
    if (
      !venue ||
      (venueId && isValidUUIDv4(venueId) && venue.id !== venueId) ||
      (venueId && !isValidUUIDv4(venueId) && venue.vanityUrl && venue.vanityUrl !== venueId)
    ) {
      getVenue();
    }
  }, [history, setVenue, venueId, venue]);

  useEffect(() => {
    if (venue && fetchSponsors) {
      getActiveSponsorModule();
    }
  }, [venue, fetchSponsors]);

  useEffect(() => {
    if (venue && fetchStores) {
      getVenueStores();
    }
  }, [venue, deliveryLocation, deliveryLocationId, orderType, fetchStores, event]);

  useEffect(() => {
    if (venue && fetchEvents) {
      getVenueEvents();
    }
  }, [venue, fetchEvents]);

  useEffect(() => {
    if (venue && fetchLocations) {
      getVenueLocations();
    }
  }, [venue, fetchLocations]);

  const getVenue = async (): Promise<void> => {
    if (!venueId) {
      return;
    }
    setShowLoader(true);
    try {
      const venueData = isValidUUIDv4(venueId)
        ? await VenueService.GetOneVenue(venueId)
        : await VenueService.GetOneVenueBySlug(venueId);

      setVenue(venueData.data);
    } catch (error) {
      if (error.response.data.message === 'Venue not found.') {
        history.push('/404');
      } else {
        toast(error.response.data.message);
      }
    }
    setShowLoader(false);
  };

  const getActiveSponsorModule = async (): Promise<void> => {
    if (!venueId || !venue) {
      return;
    }
    try {
      const {
        data: { title, sponsors },
      } = await VenueService.getActiveSponsorModule(venue.id);
      setVenueSponsor({
        title,
        sponsors,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error.response);
    }
  };

  const getVenueStores = async (): Promise<void> => {
    if (!venueId || !venue) {
      return;
    }

    const deliveryLocationParams = !deliveryLocationId && deliveryLocation && deliveryLocation[0];

    const storeDataRequest = {
      venueId: venue.id,
      type: StoreTypes.REGULAR,
      ...(venue.active && {
        orderType,
        ...(event && venue.orderAheadAvailable && { orderAheadAvailable: true }),
        ...(!event && venue.orderNowAvailable && { orderNowAvailable: true }),
        ...(deliveryLocationParams &&
          venue.deliveryAvailable && {
            deliveryLocationName: deliveryLocationParams.name,
            deliveryLocationValue: deliveryLocationParams.value,
          }),
        ...(deliveryLocationId && { deliveryLocationId }),
      }),
    };

    setDataLoader(true);
    try {
      const {
        data: { items },
      } = await StoreService.GetAllStores({ ...storeDataRequest, limit: 50 });

      const venueIsOrderNowAvailable = venue.orderNowAvailable;
      const venueIsOrderAheadAvailable = venue.orderAheadAvailable;

      setStores(
        items.map((store: Store) => {
          return {
            ...store,
            active:
              venue &&
              venue.active &&
              (venueIsOrderNowAvailable || venueIsOrderAheadAvailable) &&
              store &&
              store.active &&
              (store.orderNowAvailable || store.orderAheadAvailable),
          };
        }),
      );
    } catch (error) {
      if (error.response.data.message === 'Venue not found.') {
        history.push('/404');
      } else {
        toast(error.response.data.message);
      }
    } finally {
      // confirms fetch to retrieve stores, even if none are available
      // independent of App context's loading spinner
      setDataLoader(false);
    }
  };

  const getVenueEvents = async () => {
    if (!venue) {
      return;
    }
    setDataLoader(true);
    try {
      const {
        data: { items },
      } = await EventsService.getAllEvents({
        venueId: venue.id,
        future: true,
        limit: 100,
        gameDayEvent: gameDayEvent
      });
      setVenueEvents(items);
    } catch (error) {
      console.log(error);
    }
    setDataLoader(false);
  };

  const getVenueLocations = async () => {
    if (!venue) {
      return;
    }
    setDataLoader(true);
    try {
      const { data } = await VenueService.getVenueLocations(venue.id);
      setVenueLocations(data);
    } catch (error) {
      console.log(error);
    }
    setDataLoader(false);
  };

  return {
    dataLoading,
    setStores,
    setVenueEvents,
    stores,
    venue,
    venueEvents,
    venueLocations,
    venueSponsor,
    setVenue,
  };
};
