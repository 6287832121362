import PackageCard from 'components/Molecules/PackageCard/PackageCard';
import { useSuiteContext } from 'containers/Suite/Steps/SuiteContext';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Divider, Grid, Menu, Segment, Sticky } from 'semantic-ui-react';
import { useProductCategoryTabContext } from '../../../containers/ProductListing/product-context';
import { ICategoryWithProducts } from '../../../containers/Suite/Steps';
import { useVenueContext } from '../../../hooks/useVenueContext';
import { getCurrencyPrefix } from '../../../shared/utils';
import { PackageDetail } from '../../Molecules/PackageDetail';
import { CategoryMenu, PackageListContainer, PackageName } from './styled';

interface PackagesProps {
  categories: ICategoryWithProducts[];
}

const Packages = ({ categories }: PackagesProps) => {
  const { activeCategory, setActiveCategory } = useProductCategoryTabContext();
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>();
  const { selectedIndex } = useSuiteContext();
  const [autoScroll, setAutoScroll] = useState(true);
  const categoryRef = useRef<HTMLElement | null>();
  const categoryMenuRef = useRef(null);
  const { venue } = useVenueContext();
  const currencyPrefix = getCurrencyPrefix(venue?.currency);

  useEffect(() => {
    const resetState = () => {
      if (categories.length > 0) {
        setActiveCategory(categories[0]);
      }
    };
    resetState();
  }, [selectedIndex]);

  useEffect(() => {
    const handleActiveCategory = () => {
      if (!activeCategory || !autoScroll) {
        return;
      }

      const categoryComponent = document.getElementById(activeCategory.id);

      categoryRef.current = categoryComponent;

      categoryRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    };

    handleActiveCategory();
  }, [activeCategory]);

  const isProductDetail = (category: ICategoryWithProducts) => {
    if (!selectedProductId) {
      return false;
    }

    return category.products.some(product => product.id === selectedProductId);
  };

  return (
    <PackageListContainer ref={categoryMenuRef}>
      <Sticky>
        <Segment className="categoryMenu">
          <Grid padded stackable>
            <Grid.Row>
              <CategoryMenu
                stackable
                fluid
                widths={categories.length.toString()}
                secondary
                pointing
              >
                {categories.map((category: ICategoryWithProducts, index: number) => (
                  <Menu.Item
                    as="button"
                    key={category.id}
                    name={category.name}
                    active={activeCategory?.id === category.id}
                    onClick={() => {
                      setAutoScroll(true);
                      setActiveCategory(category);
                    }}
                  >
                    {category.name}
                  </Menu.Item>
                ))}
              </CategoryMenu>
            </Grid.Row>
          </Grid>
        </Segment>
      </Sticky>
      <Segment basic>
        <Grid className="products-container" stackable>
          {categories.map((category: ICategoryWithProducts, index: number) => {
            return (
              <Fragment key={index}>
                {index !== 0 && (
                  <Divider horizontal>
                    <PackageName>{category.name}</PackageName>
                  </Divider>
                )}
                <Grid.Row
                  stretched
                  onMouseEnter={() => {
                    setAutoScroll(false);
                    setActiveCategory(category);
                  }}
                  onTouchStart={() => {
                    setAutoScroll(false);
                    setActiveCategory(category);
                  }}
                  id={category.id}
                  columns={2}
                >
                  {isProductDetail(category) ? (
                    <PackageDetail
                      setSelectedProductId={setSelectedProductId}
                      selectedProductId={selectedProductId}
                      closeDetails={() => {
                        setSelectedProductId(undefined);
                      }}
                    />
                  ) : (
                    <PackageCard
                      selectedProductId={selectedProductId}
                      setSelectedProductId={setSelectedProductId}
                      category={category}
                      currencyPrefix={currencyPrefix}
                    />
                  )}
                </Grid.Row>
              </Fragment>
            );
          })}
        </Grid>
      </Segment>
    </PackageListContainer>
  );
};

export default Packages;
