import CloseIcon from 'assets/icons/closeSearchBtn.png';
import { chain, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Divider, Grid, List, Loader, Modal } from 'semantic-ui-react';
import useCartContext from '../../../../containers/Cart/context';
import { useLineItemHandler } from '../../../../hooks/handlers/lineItem';
import { LineItemDto } from '../../../../services/CartService';
import { BasePath } from '../../../../shared/constants';
import { ILineItem, ISubProductSelection } from '../../../../shared/models/Cart';
import { getCurrencyPrefix } from '../../../../shared/utils';
import { QuantityControl } from '../../../QuantityControl';
import { ChangeComboContent, CloseButton, ItemNotes, LineItem, TitleContainer } from '../../styled';

interface LineItemDetailProps {
  lineItem: ILineItem;
  index: number;
  currency: string | undefined;
  showDescription?: boolean;
  CustomQuantitySelector?: JSX.Element;
}

interface SubSelectionProps {
  lineItem: ILineItem;
  currencyPrefix: string;
}

export const SubSelection = ({ lineItem, currencyPrefix }: SubSelectionProps) => {
  const subProducts = chain(lineItem?.subSelections || [])
    .map(subSelection => subSelection.subSelections)
    .reduce((result: ISubProductSelection[], subProduct) => {
      return result.concat(subProduct);
    }, [])
    .value();

  return (
    <>
      {subProducts.map((subProduct, index: number) => {
        return (
          <Grid>
            <Grid.Row
              className={`no-padding-top ${index < subProducts.length - 1 && 'no-padding-bottom'}`}
              key={subProduct.id}
            >
              <Grid.Column className="line-item-subproduct" floated="left" width={10}>
                {`${subProduct.name}${subProduct.quantity > 1 ? ` (x${subProduct.quantity})` : ''}`}
              </Grid.Column>
              <Grid.Column className="line-item-price" floated="right" width={5}>
                {subProduct.price > 0 &&
                  `${currencyPrefix}${Number(subProduct.price * (subProduct.quantity || 1)).toFixed(
                    2,
                  )}`}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      })}
    </>
  );
};

export const LineItemComponent = ({
  lineItem,
  index,
  currency,
  CustomQuantitySelector,
  showDescription = true,
}: LineItemDetailProps) => {
  const { cart } = useCartContext();
  const { addItemToCart, removeFromCart } = useLineItemHandler();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [changeComboModal, setChangeComboModal] = useState(false);
  const [comboItemUpdate, setComboItemUpdate] = useState<ILineItem | undefined>(undefined);
  const currencyPrefix = getCurrencyPrefix(currency);

  const changeQuantity = (lineItem: ILineItem) => async (value: number) => {
    if (!isEmpty(lineItem.subSelections) && value === 1) {
      setChangeComboModal(true);
      setComboItemUpdate(lineItem);
    } else if (value > 0) {
      setLoading(true);
      const { productId, alcohol, subSelections, instructions } = lineItem;

      const payload: LineItemDto = {
        productId,
        quantity: value,
        ...(!isEmpty(subSelections) && { subSelections }),
        ...(instructions && { instructions }),
      };

      await addItemToCart(payload, alcohol)();
    } else {
      setLoading(true);
      await removeFromCart(
        {
          quantity: lineItem.quantity + value > 0 ? lineItem.quantity + value : 0,
        },
        lineItem,
      )();
    }
    setLoading(false);
  };

  const updateComboProduct = (different: boolean) => async () => {
    if (!comboItemUpdate || !cart) return;

    const { productId, alcohol, subSelections, instructions } = comboItemUpdate;

    if (different) {
      history.replace(
        `/${BasePath.CONSUMER}/stores/${cart.venueId}/products/${cart.storeId}/product/${productId}`,
      );
      return;
    }

    const payload: LineItemDto = {
      productId,
      quantity: 1,
      ...(!isEmpty(subSelections) && { subSelections }),
      ...(instructions && { instructions }),
    };

    setLoading(true);

    await addItemToCart(payload, alcohol)();

    setChangeComboModal(false);

    setLoading(false);
  };
  return (
    <>
      <LineItem key={lineItem.id}>
        {index > 0 && <Divider />}
        <List.Item>
          <List.Header>
            <Grid>
              <Grid.Row>
                <Grid.Column className="line-item-title" floated="left" width={10}>
                  {lineItem.name}
                </Grid.Column>
                <Grid.Column className="line-item-title line-item-price" floated="right" width={6}>
                  {lineItem.productPrice > 0 &&
                    `${currencyPrefix}${(lineItem.productPrice * lineItem.quantity).toFixed(2)}`}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </List.Header>
          <SubSelection lineItem={lineItem} currencyPrefix={currencyPrefix} />
          {showDescription && (
            <List.Description className="line-item-description">
              {lineItem.description}
            </List.Description>
          )}
          {isLoading ? (
            <Loader active size="tiny" inline />
          ) : CustomQuantitySelector ? (
            CustomQuantitySelector
          ) : (
            <>
              <QuantityControl
                canDecrementQuantity={true}
                changeQuantity={changeQuantity(lineItem)}
                quantity={lineItem.quantity}
              />
            </>
          )}
          <ItemNotes>{lineItem.instructions}</ItemNotes>
        </List.Item>
      </LineItem>
      <Modal
        basic
        style={{
          bottom: '40px',
        }}
        open={changeComboModal}
        closeIcon={<CloseButton src={CloseIcon} />}
        onClose={() => setChangeComboModal(false)}
      >
        <ChangeComboContent>
          <Card fluid>
            <Card.Header>
              <TitleContainer>
                Same or Change?
                <span>Would you like the same items or need to change something?</span>
              </TitleContainer>
            </Card.Header>
            <Card.Content>
              <Button basic onClick={updateComboProduct(false)}>
                Keep the same items
              </Button>
              <Button basic onClick={updateComboProduct(true)}>
                I want to make changes
              </Button>
            </Card.Content>
          </Card>
        </ChangeComboContent>
      </Modal>
    </>
  );
};
