import styled from 'styled-components';
import { fonts, colors } from '../../shared/theme';
import { ReactNode } from 'react';

type StepperContainerProps = {
  direction: string | undefined;
  itemCount: number;
  children: ReactNode;
};

type StepContainerProps = {
  direction: string | undefined;
  isActive: boolean | undefined;
  isDone: boolean | undefined;
};

export const StepperContainer = styled.div<StepperContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  ${({ direction }) => (direction === 'vertical' ? `width: max-content; margin: auto` : ``)};
  justify-content: space-between;
  font-family: ${fonts.REGULAR};
  font-size: 14px;
  position: relative;
  ${({ direction, itemCount }) =>
    direction === 'vertical'
      ? `&::before {
            content: '';
            position: absolute;
            left: 12px;
            top: 10px;
            bottom: 35px;
            width: 1px;
            border: 1px solid ${colors.grey};
            z-index: 0;
        }`
      : `&::before {
            content: '';
            position: absolute;
            left: ${itemCount <= 3 ? '15.5%' : '12%'};
            right: ${itemCount <= 3 ? '15.5%' : '12%'};
            top: 6px;
            border: 2px solid ${colors.white};
            z-index: 0;
        }`};
`;

export const StepContainer = styled.div<StepContainerProps>`
  max-width: 500px;
  display: flex;
  ${({ direction }) =>
    direction === 'vertical'
      ? `
    font-family: ${fonts.MEDIUM};
    font-weight: lighter;
    font-size: 18px;
    `
      : ''};
  color: ${({ isActive }) => (isActive ? colors.white : colors.grey)};
  ${({ direction }) =>
    direction === 'vertical'
      ? `flex-direction: row; margin-bottom: 25px`
      : `flex-direction: column`};
  position: relative;
  ${({ direction }) => direction !== 'vertical' && 'width: 33.33%'}
  ${({ direction, isActive, isDone }) =>
    direction === 'vertical'
      ? `&::before {
            content: '';
            position: absolute;
            left: 12px;
            top: -10px;
            bottom: -10px;
            width: 1px;
            border: 1px solid ${colors.seaFoam};
            z-index: 0;
            display: ${isActive || isDone ? 'block' : 'none'};
        }
        &:first-child {
            ::before {
                top: 5px;
            }
        }
        &:last-child {
            ::before {
                bottom: 25px;
            }
        }
        `
      : `&::before {
            content: '';
            position: absolute;
            left: 0px;
            right: 0px;
            top: 6px;
            border: 2px solid ${colors.seaFoam};
            z-index: 0;
            display: ${isActive || isDone ? 'block' : 'none'};
        };
        &:first-child {
            ::before {
                left: 50%;
            }
        }
        &:last-child {
            ::before {
                right: 50%;
            }
        }
        `};
`;

export const StepDot = styled.div<StepContainerProps>`
  width: ${({ direction, isActive }) => (direction === 'vertical' && isActive ? '25px' : '15px')};
  height: ${({ direction, isActive }) => (direction === 'vertical' && isActive ? '25px' : '15px')};
  border-radius: 50%;
  background-color: ${({ isActive, isDone }) =>
    isActive || isDone ? colors.seaFoam : colors.white};
  align-self: center;
  ${({ direction, isActive }) =>
    direction === 'vertical'
      ? `
        margin-right: ${isActive ? '7px' : '12px'};
        margin-left: ${isActive ? '0.5px' : '5.5px'};
        `
      : `
        margin-right: 0px;
        margin-bottom: 5px;
        `};
  content: '';
  z-index: 2;
  .image {
    height: 9px;
    width: 12px;
    left: 6.5px;
    bottom: 2px;
  }
`;
