import { CheckedItem, ICheckedCategory } from 'components/ComboProduct';
import { SelectedComboItem } from 'containers/ProductConfigurator';
import { sumBy } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { Accordion, Checkbox, Divider, Form, Image } from 'semantic-ui-react';
import caretDown from '../../assets/icons/caretDown.png';
import caretUp from '../../assets/icons/caretUp.png';
import { ISubCategory, ISubProduct } from '../../shared/models/Product';
import { QuantityControl } from '../QuantityControl';
import {
  CaretContainer,
  CheckboxContainer,
  ComboCategoryContainer,
  QuantityControlContainer,
  RequiredContainer,
  SelectionLimit,
  SelectionTitle,
  SelectionTitleContainer,
  SelectionWrapper,
  SubproductPrice,
} from './styled';

interface ComboCategoryProps {
  currencyPrefix: string;
  category: ISubCategory;
  first: boolean;
  onClick: (e: any, titleProps: any) => void;
  activeIndex: string[];
  selectedComboItems: Array<SelectedComboItem>;
  setSelectedComboItems: React.Dispatch<React.SetStateAction<Array<SelectedComboItem>>>;
  checkedCategories: ICheckedCategory[];
  updateQuantity: (quantityChange: number, data: ISubProduct) => void;
}

export const ComboCategory = ({
  currencyPrefix,
  category,
  onClick,
  activeIndex,
  first,
  selectedComboItems,
  setSelectedComboItems,
  checkedCategories,
  updateQuantity,
}: ComboCategoryProps) => {
  const onChangeCheckbox = (
    e: React.FormEvent<HTMLInputElement>,
    checked: boolean,
    selectedItem: any,
  ) => {
    e.preventDefault();

    const newSelectedComboItems = selectedComboItems.filter(
      (comboItem: SelectedComboItem) => comboItem.id !== selectedItem.id,
    );

    if (checked) {
      newSelectedComboItems.push({
        id: selectedItem.id,
        parentId: selectedItem.parentId,
        productId: selectedItem.productId,
        name: selectedItem.name,
        price: selectedItem.price,
        required: selectedItem.required,
        type: 'PRODUCT',
        quantity: 1,
      });
    }

    setSelectedComboItems(newSelectedComboItems);
  };

  const isComboItemChecked = (itemId: string) => {
    const found = selectedComboItems.find(comboItem => comboItem.id === itemId);
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  const isCheckboxDisabled = (categoryId: string, id: string) => {
    const checkedCategory = checkedCategories.find(
      (checkedItem: ICheckedCategory) => checkedItem.categoryId === categoryId,
    );
    if (
      checkedCategory &&
      checkedCategory.limit > 0 &&
      checkedCategory.categorySelectionQty >= checkedCategory.limit
    ) {
      // Disable products not in the category checkedItem list.
      if (!checkedCategory.items.some((item: CheckedItem) => item.id === id)) {
        return true;
      }
    }

    return false;
  };

  const renderPrice = (price: number): JSX.Element => (
    <SubproductPrice>{price > 0 && `(+${currencyPrefix}${price})`}</SubproductPrice>
  );

  const showAddButton = (subProduct: any) => {
    const totalCategoryQuantity = sumBy(
      selectedComboItems.filter(item => item.parentId === category.id),
      'quantity',
    );

    if (category.selectionLimit > 0 && totalCategoryQuantity >= category.selectionLimit) {
      return false;
    }

    const selectedItem = selectedComboItems.find(item => item.id === subProduct.id);
    if (subProduct.quantityLimit > 0 && (selectedItem?.quantity || 0) >= subProduct.quantityLimit) {
      return false;
    }

    return true;
  };

  // allows add/remove of non-multiselect options
  const onClickRadio = (checked: boolean, data: ISubProduct, categoryProductIds: string[]) => {
    const otherCategoryItems = selectedComboItems.filter(
      (comboItem: SelectedComboItem) =>
        !categoryProductIds.some((id: string) => comboItem.id === id),
    );

    if (checked) {
      otherCategoryItems.push({
        id: data.id,
        parentId: data.parentId,
        productId: data.productId,
        name: data.name,
        price: data.price,
        type: 'PRODUCT',
        quantity: 1,
      });
    }

    setSelectedComboItems(otherCategoryItems);
  };

  const showQuantityControl = (subProductLimit: number): boolean => {
    const getLimit = (): number => {
      if (category.multiselect && category.selectionLimit <= 0) {
        return subProductLimit;
      } else if (category.multiselect && subProductLimit <= 0) {
        return category.selectionLimit;
      } else if (category.multiselect) {
        return Math.min(subProductLimit, category.selectionLimit);
      } else {
        return subProductLimit;
      }
    };

    const limit = getLimit();
    return limit > 1 || limit <= 0;
  };

  const renderSelections = () =>
    category.multiselect ? (
      <CheckboxContainer className="checkbox-container">
        {category.subProducts.map((subProduct: ISubProduct) => {
          const { categoryId, id, name, price, active } = subProduct;
          const selectedItem = selectedComboItems.find(item => item.id === id);
          if (active) {
            return (
              <SelectionWrapper key={id}>
                <Checkbox
                  aria-label={name}
                  checked={isComboItemChecked(id)}
                  disabled={isCheckboxDisabled(categoryId, id)}
                  label={name}
                  onChange={(e, data) => {
                    onChangeCheckbox(e, data.checked as boolean, subProduct);
                  }}
                />
                {renderPrice(price)}
                {selectedItem && showQuantityControl(subProduct.quantityLimit) && (
                  <QuantityControlContainer>
                    <QuantityControl
                      canDecrementQuantity={true}
                      changeQuantity={(value: number) => {
                        updateQuantity(value, subProduct);
                      }}
                      quantity={selectedItem?.quantity || 1}
                      showAddButton={showAddButton(subProduct)}
                    />
                  </QuantityControlContainer>
                )}
              </SelectionWrapper>
            );
          }
        })}
      </CheckboxContainer>
    ) : (
      category.subProducts.map((subProduct: ISubProduct, ii: number, spArr: ISubProduct[]) => {
        const { id, name, price, active } = subProduct;
        const categoryProductIds = spArr.map((sp: any) => sp.id);
        const selectedItem = selectedComboItems.find(item => item.id === id);
        if (active) {
          return (
            <SelectionWrapper key={ii}>
              <Form.Radio
                aria-label={name}
                checked={isComboItemChecked(id)}
                label={name}
                onClick={(e, data) =>
                  onClickRadio(data.checked as boolean, subProduct, categoryProductIds)
                }
                value={id}
              />
              {renderPrice(price)}
              {selectedItem && showQuantityControl(subProduct.quantityLimit) && (
                <QuantityControlContainer>
                  <QuantityControl
                    canDecrementQuantity={true}
                    changeQuantity={(value: number) => {
                      updateQuantity(value, subProduct);
                    }}
                    quantity={selectedItem?.quantity || 1}
                    showAddButton={showAddButton(subProduct)}
                  />
                </QuantityControlContainer>
              )}
            </SelectionWrapper>
          );
        }
      })
    );

  return (
    <ComboCategoryContainer first={first}>
      <Accordion.Title
        active={activeIndex.includes(category.id)}
        index={category.id}
        onClick={onClick}
      >
        {!first && <Divider />}
        {category.name}
        <CaretContainer>
          <Image
            src={!activeIndex.includes(category.id) ? caretDown : caretUp}
            alt={
              !activeIndex.includes(category.id) ? 'chevron pointing up' : 'chevron pointing down'
            }
          />
        </CaretContainer>
      </Accordion.Title>
      <Accordion.Content active={activeIndex.includes(category.id)} className="negative-margin-top">
        <SelectionTitleContainer>
          <SelectionTitle>
            {`SELECT YOUR ${
              category.multiselect
                ? category.name.toUpperCase()
                : pluralize.singular(category.name).toUpperCase()
            }`}
          </SelectionTitle>
          <SelectionLimit>
            {category.selectionLimit > 0 && ` - Up to ${category.selectionLimit}`}
          </SelectionLimit>
        </SelectionTitleContainer>
        <RequiredContainer>{category.required && <span>Required</span>}</RequiredContainer>
        {renderSelections()}
      </Accordion.Content>
    </ComboCategoryContainer>
  );
};

export default ComboCategory;
