import { OrderType } from '../shared/models/Cart';
import { ICategory } from '../shared/models/Category';
import IProduct from '../shared/models/Product';
import { ApiResponse, APIService, IPaginationRequest, IPaginationResponse } from './APIService';

export interface IGetProductsQuery extends IPaginationRequest {
  active?: boolean;
  availabilityType?: OrderType;
  categoryId?: string;
  storeId: string;
}

export interface IGetCategoriesQuery extends IPaginationRequest {
  active?: boolean;
  storeId: string;
  type?: string; // note this is a string array of CategoryTypes
}

export class ProductService {
  static async GetAllProducts(
    query: IGetProductsQuery,
  ): Promise<ApiResponse<IPaginationResponse<IProduct>>> {
    return await APIService.getData('/products', query);
  }

  static async GetOneProduct(id: string): Promise<ApiResponse<IProduct>> {
    return await APIService.getData(`/products/${id}`);
  }

  static async GetAllCategories(
    query: IGetCategoriesQuery,
  ): Promise<ApiResponse<IPaginationResponse<ICategory>>> {
    return await APIService.getData('/categories', query);
  }
}
