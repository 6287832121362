import axios from 'axios';
import { getLocalValue, SESSION_KEY } from '../shared/SessionAuthPersist';

declare const ENVARS: any;

export interface IPaginationRequest {
  limit?: number;
  page?: number;
}

export interface ApiResponse<TData> {
  data: TData;
}

export interface IPaginationResponse<E> {
  items: E[];
  itemCount: number;
  pageCount: number;
  totalCount: number;
}

export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
}

const apiV1 = axios.create({
  baseURL: `${ENVARS.API.BASE_URL}/api/v1/`,
  headers: {
    'Cache-Control': 'no-cache',
    'X-Platform': 'WEB',
  },
});

const apiV2 = axios.create({
  baseURL: `${ENVARS.API.BASE_URL}/api/v2/`,
  headers: {
    'Cache-Control': 'no-cache',
    'X-Platform': 'WEB',
  },
});

const beforeRequest = (request: any) => {
  const session = JSON.parse(getLocalValue(SESSION_KEY));
  if (session && session.authToken) {
    request.headers.Authorization = `Bearer ${session.authToken}`;
  }
  return request;
};

const onRequestSuccess = (response: any) => {
  return response;
};
const onRequestFailure = (err: any) => {
  const { response } = err;
  if (response.status === 401) {
    console.log('invalid token');
    // Clean session
    window.localStorage.removeItem(SESSION_KEY);
    // Force refresh
    window.location.replace('/');
  }
  throw err;
};

apiV1.interceptors.request.use(beforeRequest);
apiV1.interceptors.response.use(onRequestSuccess, onRequestFailure);

apiV2.interceptors.request.use(beforeRequest);
apiV2.interceptors.response.use(onRequestSuccess, onRequestFailure);

const apiInstances = {
  [ApiVersion.V1]: apiV1,
  [ApiVersion.V2]: apiV2,
};

export class APIService {
  static async getData<T>(
    url: string,
    params?: object,
    version: ApiVersion = ApiVersion.V1,
  ): Promise<ApiResponse<T>> {
    const response = await apiInstances[version].get(url, { params });
    return response;
  }

  static async postData<T>(
    url: string,
    params?: object,
    version: ApiVersion = ApiVersion.V1,
  ): Promise<ApiResponse<T>> {
    const response = await apiInstances[version].post(url, { ...params });
    return response;
  }

  static async deleteData<T>(
    url: string,
    version: ApiVersion = ApiVersion.V1,
  ): Promise<ApiResponse<T>> {
    const response = await apiInstances[version].delete(url);
    return response;
  }

  static async updateData<T>(
    url: string,
    params?: object,
    version: ApiVersion = ApiVersion.V1,
  ): Promise<ApiResponse<T>> {
    const response = await apiInstances[version].put(url, { ...params });
    return response;
  }
}
