import React from 'react';
import { MemoryRouter, Route, Switch } from 'react-router';
import Policies from 'containers/Profile/Policies';
import { BasePath } from 'shared/constants';
import Help from 'containers/Profile/Help';
import FanfoodLove from 'containers/Profile/FanfoodLove';
import OrderHistory from 'containers/Profile/OrderHistory';
import MenuTransition from './MenuTransition';
import Account from 'containers/Profile/Account';
import Payment from 'containers/Profile/Payment';
import Chat from 'containers/Chat';
import Profile from 'containers/Profile';

const SuiteSideMenu = ({
  setVisible,
}: {
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <MemoryRouter>
      <MenuTransition>
        <Switch>
          <Route exact path='/' render={props => <Profile {...props} isSuite={true} />} />
          <Route
            path={`/${BasePath.CONSUMER}/order-history`}
            exact
            render={props => <OrderHistory setVisible={setVisible} {...props} />}
          />
          <Route path={`/${BasePath.CONSUMER}/account-details`} exact component={Account} />
          <Route path={`/${BasePath.CONSUMER}/payment-details`} exact component={Payment} />
          <Route path={`/${BasePath.CONSUMER}/share`} exact component={FanfoodLove} />
          <Route
            path={`/${BasePath.CONSUMER}/chat/:orderId/:venueId/:storeId`}
            exact
            component={Chat}
          />
          <Route exact path={`/${BasePath.CONSUMER}/policies`} component={Policies} />
          <Route path={`/${BasePath.CONSUMER}/help`} exact component={Help} />
        </Switch>
      </MenuTransition>
    </MemoryRouter>
  );
};
export default SuiteSideMenu;
