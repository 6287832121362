import ProfileHeader from 'components/Organisms/PWA/Headers/ProfileHeader';
import { useAppContext } from 'containers/App/AppContext';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { OpenOrderStatus, PastOrderStatus } from '../../../components/OrderStatus';
import { OrderService } from '../../../services/OrderService';
import { BasePath } from '../../../shared/constants';
import { Order, OrderStatus } from '../../../shared/models/Order';
import { colors, fonts } from '../../../shared/theme';

const OrderHistoryContainer = styled(Segment)`
  .ui.grid > .row {
    display: unset;
  }
  .ui.container.grid > .column {
    padding-right: 0;
    padding-left: 0;

    &.section {
      padding-bottom: 0;
      font-size: 20px;
      font-family: ${fonts.LIGHT};
    }
  }
`;

const NoOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`;

const NoOrdersHeader = styled.h2`
  font-family: ${fonts.REGULAR};
  color: ${colors.charcoalGrey};
  font-size: 22px;
`;

const ChangeIt = styled.h3`
  font-family: ${fonts.REGULAR};
  color: ${colors.charcoalGrey};
  font-size: 15px;
  margin: 0 0 30px 0;
`;

const StartOrderButton = styled.button`
  width: 165px;
  height: 34px;
  border-radius: 10px;
  border: 1px solid #147c9e;
  background: transparent;
  color: #147c9e;
  font-family: ${fonts.REGULAR};

  &:hover {
    cursor: pointer;
  }
`;

const StartOrderButtonText = styled.span`
  position: relative;
  top: 2px;
`;

const OrderStatusTitle = styled.h2`
  font-family: ${fonts.LIGHT};
  font-size: 20px;
  color: ${colors.charcoalGrey};
  letter-spacing: 0;
  font-weight: 300;
  margin-top: 25px;
`;

interface Orders {
  preOrders: Order[];
  newOrders: Order[];
  pastOrders: Order[];
}

enum NewOrderStatuses {
  FULFILLED = 'FULFILLED',
  IN_PROCESS = 'IN_PROCESS',
  NEW = 'NEW',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  READY_FOR_RUNNER = 'READY_FOR_RUNNER',
}

enum FutureOrderStatuses {
  PRE_ORDER = 'PRE_ORDER',
}
enum PastOrderStatuses {
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
}

export default function OrderHistory({ setVisible }: any) {
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<Orders>({
    newOrders: [],
    pastOrders: [],
    preOrders: [],
  });
  const history = useHistory();

  useEffect(() => {
    const getorders = async () => {
      if (!user) {
        return;
      }
      setIsLoading(true);
      try {
        const {
          data: { items },
        } = await OrderService.GetUserOrders({ userId: user.id });

        setOrders({
          preOrders: items.filter(({ status }: Order) => status in FutureOrderStatuses),
          pastOrders: items.filter(
            ({ status, refundStatus }: Order) =>
              status in PastOrderStatuses || refundStatus === OrderStatus.FULFILLED,
          ),
          newOrders: items.filter(
            ({ status, refundStatus }: Order) =>
              status in NewOrderStatuses && refundStatus !== OrderStatus.FULFILLED,
          ),
        });
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    getorders();
  }, [user]);

  const navigateToChatScreen = (venueId: string, storeId: string, orderId: string) => {
    history.push(`/${BasePath.CONSUMER}/chat/${orderId}/${venueId}/${storeId}`);
  };

  return (
    <OrderHistoryContainer loading={isLoading} basic>
      <ProfileHeader title="Orders" />
      {orders.newOrders.length > 0 && !isLoading && (
        <>
          <OrderStatusTitle style={{ marginBottom: 15 }}>Open Orders</OrderStatusTitle>
          <Grid padded="horizontally" columns={1} centered>
            {orders.newOrders.map(newOrder => (
              <Grid.Row key={newOrder.id}>
                <OpenOrderStatus
                  scheduledTime={newOrder.scheduledTime}
                  order={newOrder}
                  onChatIconClick={() =>
                    navigateToChatScreen(
                      newOrder.venueId,
                      newOrder.storeId,
                      newOrder.orderNo.toString(),
                    )
                  }
                />
              </Grid.Row>
            ))}
          </Grid>
        </>
      )}
      {orders.preOrders.length > 0 && (
        <>
          <OrderStatusTitle style={{ marginTop: 15 }}>Future Orders</OrderStatusTitle>
          <Grid padded columns={1} centered>
            {orders.preOrders.map((preOrder: Order) => (
              <Grid.Row key={preOrder.id}>
                <OpenOrderStatus
                  scheduledTime={preOrder.scheduledTime}
                  order={preOrder}
                  onChatIconClick={() =>
                    navigateToChatScreen(
                      preOrder.venueId,
                      preOrder.storeId,
                      preOrder.orderNo.toString(),
                    )
                  }
                />
              </Grid.Row>
            ))}
          </Grid>
        </>
      )}
      {orders.pastOrders.length > 0 && (
        <>
          <OrderStatusTitle>Past Orders</OrderStatusTitle>
          <Grid padded columns={1} centered>
            {orders.pastOrders.map((pastOrder: Order) => (
              <Grid.Row key={pastOrder.id}>
                <PastOrderStatus
                  order={pastOrder}
                  onChatIconClick={() =>
                    navigateToChatScreen(
                      pastOrder.venueId,
                      pastOrder.storeId,
                      pastOrder.orderNo.toString(),
                    )
                  }
                />
              </Grid.Row>
            ))}
          </Grid>
        </>
      )}

      {orders.preOrders.length === 0 &&
        orders.newOrders.length === 0 &&
        orders.pastOrders.length === 0 && (
          <NoOrdersContainer>
            <NoOrdersHeader>No open or past orders</NoOrdersHeader>
            <ChangeIt>Let’s change that!</ChangeIt>
            <StartOrderButton onClick={() => setVisible(false)}>
              <StartOrderButtonText>START YOUR ORDER</StartOrderButtonText>
            </StartOrderButton>
          </NoOrdersContainer>
        )}
    </OrderHistoryContainer>
  );
}
