import useWindowWidth from 'hooks/UseWindowWidth';
import React, { Dispatch, ReactChild, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CartService, CreateCartDto, LineItemDto } from 'services/CartService';
import { Event } from 'services/EventsService';
import createContextWrapper from '../../../hooks/CreateContext';
import ICart, { ILineItem, OrderType } from '../../../shared/models/Cart';
import { IVenueLocation } from '../../../shared/models/Venue';
import { useEventContext } from '../../App/AppContext';
import useCartContext from '../../Cart/context';

interface LineItemCount {
  id: string;
  quantity: number;
}

interface CartPackage {
  eventId: string;
  productId: string;
  quantityIncrement: 1 | -1;
  storeId: string;
  userId: string;
}

type State = {
  addItemToCart: (payload: CreateCartDto) => Promise<string | undefined>;
  addLineItem: (payload: LineItemDto) => Promise<void>;
  buttonLoadingId: string | undefined;
  cart: ICart | undefined;
  clearCart: () => Promise<void>;
  confirmEventChange: () => void;
  displayChangeWarningModal: boolean;
  handlePackageSelection: ({
    eventId,
    productId,
    quantityIncrement,
    storeId,
    userId,
  }: CartPackage) => Promise<void>;
  handleSelectedEvent: (event: Event | undefined) => void;
  isLoading: boolean;
  isMobile: boolean;
  packageQuantity: LineItemCount[] | undefined;
  selectedEvent: Event | undefined;
  selectedIndex: number;
  setButtonLoadingId: Dispatch<SetStateAction<string | undefined>>;
  setCart: (cart: ICart | undefined) => void;
  setDisplayEventChangeWarningModal: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedEvent: (event: Event | undefined) => void;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  setSuite: (suite: IVenueLocation | undefined) => void;
  suite: IVenueLocation | undefined;
  totalQuantity: number;
  updateLineItem: (itemId: string, newQuantity: number) => Promise<void>;
};

interface SuiteContextProviderProps {
  children: ReactChild;
}

const [useSuiteContext, SuiteCtxProvider] = createContextWrapper<State>();

const SuiteContextProvider = ({ children }: SuiteContextProviderProps) => {
  const {
    setCart,
    cart,
    addItemToCart,
    updateLineItem,
    clearCart,
    numberOfCartItems: totalQuantity,
  } = useCartContext();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoadingId, setButtonLoadingId] = useState<string | undefined>();
  const [isMobile, setIsMobile] = useState(false);
  const [displayChangeWarningModal, setDisplayEventChangeWarningModal] = useState<boolean>(false);
  const [packageQuantity, setPackageQuantity] = useState<LineItemCount[] | undefined>(undefined);
  const [suite, setSuite] = useState<IVenueLocation | undefined>(undefined);
  const { event: selectedEvent, setEvent: setSelectedEvent } = useEventContext();
  const [tempEvent, setTempEvent] = useState<Event | undefined>(undefined);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (!cart || !selectedEvent) {
      setSelectedIndex(0);
    }
  }, [cart]);

  useEffect(() => {
    const cartItemCounts = cart?.lineItems.map((lineItem: ILineItem) => ({
      id: lineItem.productId,
      quantity: lineItem.quantity,
    }));
    setPackageQuantity(cartItemCounts);
  }, [cart?.lineItems]);

  useEffect(() => {
    const getMobileState = () => {
      windowWidth <= 960 ? setIsMobile(true) : setIsMobile(false);
    };

    getMobileState();
  }, [windowWidth]);

  const handleSelectedEvent = (eventData: Event | undefined) => {
    if (cart && cart.eventId !== eventData?.id) {
      setDisplayEventChangeWarningModal(true);
      setTempEvent(eventData);
      return;
    }

    setSelectedEvent(eventData);
  };

  const confirmEventChange = () => {
    if (tempEvent) {
      setSelectedEvent(tempEvent);
      setTempEvent(undefined);
    }
    clearCart();
    setDisplayEventChangeWarningModal(false);
  };

  const addLineItem = async (payload: LineItemDto): Promise<void> => {
    if (!cart) {
      return;
    }
    setButtonLoadingId(payload.productId);
    try {
      const { data } = await CartService.AddLineItem(cart.id, payload);
      setCart(data);
    } catch (error) {
      toast(error.response.data.message);
    }
    setButtonLoadingId(undefined);
  };

  const handlePackageSelection = async ({
    userId,
    storeId,
    productId,
    quantityIncrement,
    eventId,
  }: CartPackage) => {
    setButtonLoadingId(productId);

    const payload: CreateCartDto = {
      userId,
      storeId,
      orderType: OrderType.DELIVERY,
      lineItem: {
        productId,
        quantity: 1,
      },
      eventId,
      ...(suite && { deliveryLocationId: suite.id }),
      ...(selectedEvent && {
        scheduledTime: selectedEvent.scheduledTime || selectedEvent.startDate,
      }),
    };

    const cartItem = cart?.lineItems.find(item => productId === item.productId);

    if (quantityIncrement < 0 && cartItem && cartItem.id) {
      const updatedQuantity = (cartItem.quantity += quantityIncrement);
      await updateLineItem(cartItem.id, updatedQuantity);
    } else {
      await addItemToCart(payload);
    }
    setButtonLoadingId(undefined);
  };

  return (
    <SuiteCtxProvider
      value={{
        totalQuantity,
        updateLineItem,
        addLineItem,
        addItemToCart,
        clearCart,
        setCart,
        cart,
        selectedIndex,
        setSelectedIndex,
        isLoading,
        setIsLoading,
        isMobile,
        packageQuantity,
        handleSelectedEvent,
        selectedEvent,
        setDisplayEventChangeWarningModal,
        displayChangeWarningModal,
        setSelectedEvent,
        confirmEventChange,
        handlePackageSelection,
        buttonLoadingId,
        suite,
        setSuite,
        setButtonLoadingId,
      }}
    >
      {children}
    </SuiteCtxProvider>
  );
};

export { SuiteContextProvider, useSuiteContext };
