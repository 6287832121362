import { QuantityControl } from 'components/QuantityControl';
import { useSuiteContext } from 'containers/Suite/Steps/SuiteContext';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Divider, Grid, List } from 'semantic-ui-react';
import styled from 'styled-components';
import { LineItemDto } from '../../../services/CartService';
import { CardContainer } from '../../../shared/global-styled';
import { ILineItem } from '../../../shared/models/Cart';
import { Button } from '../../Atoms/Button';
import Header from '../../Atoms/Header';
import { CartSummary } from '../../ItemsSummary/CartSummary';
import { LineItemComponent } from '../../ItemsSummary/Item/LineItem/LineItem';
import { MyBagTitle } from '../../ItemsSummary/styled';
import EmptyBag from '../EmptyBag';

const SummaryContainer = styled.div`
  padding: 15px 0;

  .ui.fluid.card {
    border-radius: 10px;
  }

  .no-margin {
    margin: 0px 1em !important;
  }
  .ui.grid > .row {
    &.no-padding-bottom {
      padding-bottom: 0;
    }
    &.no-padding-top {
      padding-top: 0;
    }
  }
`;
export interface OrderSummaryProps {
  showCheckoutButton?: boolean;
  stepsLength?: number;
  tip?: number;
  type: 'BAG' | 'CHECKOUT';
}

const OrderSummary = ({
  type,
  tip,
  showCheckoutButton = true,
  stepsLength = 0,
}: OrderSummaryProps) => {
  const history = useHistory();
  const { cart, updateLineItem, addLineItem, suite, setSelectedIndex } = useSuiteContext();

  const goToSecureCheckout = () => {
    if (cart?.venueId && cart?.suite === suite?.name) {
      setSelectedIndex(stepsLength);
    }
  };

  const editLineItemQuantity = async (value: number, lineItem: ILineItem) => {
    //here you will acces the context update cart function
    if (!lineItem) return;

    const { id, productId, quantity, subSelections, instructions } = lineItem;

    const payload: LineItemDto = {
      productId,
      quantity: 1,
      subSelections,
      instructions,
    };

    if (value > 0) {
      await addLineItem(payload);
    } else {
      await updateLineItem(id, quantity + value);
    }
  };

  return (
    <SummaryContainer>
      {cart && (
        <>
          {type === 'BAG' && (
            <Header as="h2" font="BOLD">
              My Bag
            </Header>
          )}

          <CardContainer>
            <Card fluid>
              <Card.Header>
                <Grid columns={1} padded>
                  <Grid.Row>
                    <MyBagTitle floated="left" width={6}>
                      My Bag
                    </MyBagTitle>
                  </Grid.Row>
                </Grid>
              </Card.Header>
              <Card.Content>
                <List verticalAlign="middle">
                  {cart.lineItems.map((lineItem: ILineItem, index: number) => {
                    return (
                      <LineItemComponent
                        lineItem={lineItem}
                        showDescription={false}
                        index={0}
                        currency={cart.currency}
                        CustomQuantitySelector={
                          <Grid.Row className="no-padding-top">
                            <Grid.Column>
                              <QuantityControl
                                canDecrementQuantity={true}
                                changeQuantity={quantity => {
                                  editLineItemQuantity(quantity, lineItem);
                                }}
                                quantity={lineItem.quantity}
                                showAddButton={true}
                              />
                            </Grid.Column>
                            {index < cart.lineItems.length - 1 && <Divider />}
                          </Grid.Row>
                        }
                      />
                    );
                  })}
                </List>
              </Card.Content>
            </Card>
          </CardContainer>
          <CartSummary tip={tip ?? 0} showSummary showTip={type === 'CHECKOUT'} />
          {type === 'BAG' && showCheckoutButton && (
            <Grid padded centered>
              <Grid.Row>
                <Grid.Column>
                  <Button type="solidRed" onClick={goToSecureCheckout}>
                    Secure Checkout
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </>
      )}
      {(!cart || cart.lineItems.length === 0) && <EmptyBag />}
    </SummaryContainer>
  );
};

export default OrderSummary;
