import styled from 'styled-components';
import { fonts } from '../../shared/theme';

type StoreContainerProps = {
  readonly bg: string;
};

export const AngleContainer = styled.div`
  z-index: 1;
  margin-bottom: 30px;
  background: #ffffff;
  position: relative;
  box-shadow: 0 0 30px 46px white;
  text-align: center;
  width: 100%;
  height: 20px;
  cursor: pointer;
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 30px;
`;

export const ScrollContainer = styled.div`
  background: white;
  padding-bottom: 10px;
`;

export const ConsessionStandTitle = styled.div`
  font-family: ${fonts.SUPER};
  color: ${({ theme }) => theme.primaryColor};
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 1rem 15px 1rem;
  letter-spacing: 1px;
  line-height: 14px;
`;

export const SkeletonListingsContainer = styled.div`
  top: 150px;
  width: 100%;
`;

export const FoodOptions = styled.div`
  margin: 50px 0 20px 15px;
`;

export const LoadingHeaderContainer = styled.div`
  margin-top: -3px;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 170px;
`;

export const SlantedButtonSkeleton = styled.div`
  align-self: center;
  position: absolute;
  top: 120px;
  width: calc(100% - 2.25rem);
  height: 50px;
  border-radius: 10px;
`;

export const SkeletonLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
