import React from 'react';

import { BasePath } from 'shared/constants';
import { colors, fonts } from '../../../shared/theme';
import { Link } from 'react-router-dom';
import BELogo from '../assets/BE_logo.png';
import chicagoRiverBlur from '../assets/chicagoRiverBlur.jpg';
import Footer from '../Footer';
import PagePositionTop from '../PagePositionTop';
import styled from 'styled-components';
import StyledButton from '../StyledButton';
import TextCard from '../TextCard';

const AboutBackground = styled.div`
  background: url(${chicagoRiverBlur}) no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;

  @media (min-height: 1000px) {
    height: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 50vw;
`;

const AboutHeader = styled.div`
  color: ${colors.white};
  font-family: ${fonts.LIGHT};
  font-size: 22px;
  text-align: center;
  margin-top: 3px;
  margin-left: 13px;
  margin-right: 13px;
  line-height: 1.2;
`;
const AboutTagLine = styled.div`
  color: ${colors.white};
  font-family: ${fonts.LIGHT};
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
`;

const AboutTextContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
`;

const AboutText = styled.div`
  color: ${colors.charcoalGrey};
  font-family: ${fonts.LIGHT};
  font-size: 22px;
  text-align: center;
  margin-top: 3px;
  line-height: 1.2;
`;

const TextCardContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f2f2;
`;

const TextCardMobileWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f1f2f2;
  padding-bottom: 50px;
`;

const TextCardRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, max-content));
  grid-gap: 21px;
  justify-content: center;
  padding: initial;
  width: 1130px;
`;

const AboutFooter = styled.div`
  position: relative;
`;

const About = () => {
  return (
    <>
      <PagePositionTop />
      <AboutBackground>
        <div>
          <Link to={`/${BasePath.BUILDING_EATS}`}>
            <LogoContainer>
              <Logo src={BELogo} alt="buildingeats logo" />
            </LogoContainer>
          </Link>
          <AboutTagLine>A division of FanFood</AboutTagLine>
          <AboutHeader>
            "It's the only app that allows residents to order as a group and have <br />
            restaurants drop off their orders. It really brings the community together." <br />-
            Kinzie Park, Chicago customer
          </AboutHeader>
          <TextCardContainer>
            <AboutTextContainer>
              <AboutText>
                Connecting tenants to local food experiences, BuildingEats is all about cultivating
                communities <br />
                through frictionless and personalized ordering experiences. A new initiative that
                brings restaurants <br />
                and food trucks directly to building tenants through bulk ordering, thus eliminating
                the time, labor, <br />
                and hefty delivery fees as seen on traditional third-party apps.
              </AboutText>
            </AboutTextContainer>
          </TextCardContainer>
          <TextCardMobileWrapper>
            <TextCardRow>
              <TextCard
                cardTitle="FOR BUILDING TENANTS"
                description={`
                  Pre-order from a selection of local favorites from the comfort
                  of your home or office. Can't decide what to eat? There are new
                  choices each week, delivered right to your doorstep.
                `}
                button={<StyledButton text="SIGN UP MY BUILDING!" />}
              />
              <TextCard
                cardTitle="For restaurants and businesses"
                description={`
                  Serve a "full house" every evening by fulfilling orders from an
                  entire building. These tenants will soon become your most loyal
                  customers.
                `}
                button={<StyledButton text="SIGN UP MY RESTAURANT!" />}
              />
              <TextCard
                cardTitle="Supporting the local community"
                description={`
                  BuildingEats, just like FanFood, is entirely commission-free for
                  restaurants to use with no upfront cost. For every order placed on
                  the platform, FanFood donates a meal to a local food bank as an
                  official partner of Feeding America.
                `}
              />
            </TextCardRow>
          </TextCardMobileWrapper>
        </div>
        <AboutFooter>
          <Footer transparent={false} />
        </AboutFooter>
      </AboutBackground>
    </>
  );
};

export default About;
