import { Partners } from '../shared/constants';
import IUser from '../shared/models/User';
import { ApiResponse, APIService } from './APIService';
import { IVenueInvite } from './VenueService';

const basePath = '/partners';

interface IAcceptInviteBody {
  orderId: string;
}

export interface IPartnerInvite {
  invite: IVenueInvite;
  user: IUser;
}

export class PartnerService {
  static async acceptInvite(
    partner: Partners,
    body: IAcceptInviteBody,
  ): Promise<ApiResponse<IPartnerInvite>> {
    return await APIService.postData(`${basePath}/${partner}/accept-invite`, body);
  }
}
