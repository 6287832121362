import styled from 'styled-components';
import { colors, fonts } from '../../../shared/theme';

export const PackageDetailContainer = styled.div`
  .ui.product.grid {
    width: 100%;

    .ui.image.close-icon {
      cursor: pointer;
      position: absolute;
      height: auto;
      width: auto;
      right: 13px;
      top: 5px;
    }
    .header {
      font-size: 2rem;
      font-family: ${fonts.MEDIUM};
      font-weight: lighter;
      color: ${colors.charcoalGrey};
      line-height: 1.2em;
      max-height: 2.4em;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      margin: 0 -1em 0 0;
      padding: 0 1em 0 0;
    }
    .meta {
      font-size: 1.5rem;
      font-family: ${fonts.LIGHT};
      color: ${colors.pureGrey};
      margin: 3px 0 0 0;
      line-height: 1.1em;
      overflow: hidden;
      position: relative;
      margin: 0 -1em 0 0;
      padding: 0 1em 0 0;
    }

    .ui.button.addtoBag {
      align-content: flex-end;
      border-radius: 10px;
      font-family: ${fonts.REGULAR};
      font-size: 17px;
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      width: 100%;
      align-self: center;
      background-color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export const PackageImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ui.image {
    height: 75%;
    width: 100%;
    margin-bottom: 5px;
    align-self: flex-start;
  }
`;
