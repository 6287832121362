import LocationType, { LocationTypeType } from './LocationType';
import Theme from './Theme';
export interface ISuiteVenue {
  id: string;
  image: string;
  name: string;
  suites: IVenueLocation[];
}

export interface IVenueLocation {
  categoryName: string;
  id: string;
  isCustomInput: boolean;
  isDeliveryLocation: boolean;
  locations: IVenueLocation[];
  name: string;
  parentId: string | null;
  type: LocationTypeType;
  venueId: string;
}

export enum PaymentGateway {
  SQUARE = 'SQUARE',
  STRIPE = 'STRIPE',
}

export default interface Venue {
  active: boolean;
  alcoholAgeRestriction: number;
  alcoholLimit: number;
  alcoholMessage: string;
  allowOpenTabs: boolean;
  calculateTaxes: boolean;
  city: string;
  currency: string;
  deferPaymentCapture: boolean;
  deliveryAvailable: boolean;
  enableDeliveryTips: boolean;
  enablePickupTips: boolean;
  id: string;
  imageUrl: string;
  name: string;
  orderAheadAvailable: boolean;
  orderNowAvailable: boolean;
  paymentGateway: PaymentGateway;
  pickupAvailable: boolean;
  theme?: Theme;
  timezone: string;
  useNewLocations: boolean;
  validLocationTypes?: LocationType[];
  vanityUrl: string;
}
