import { format } from 'date-fns';
import React from 'react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

type StyledCardProps = {
  index: number;
  arrayLength: number;
  selected: boolean | undefined;
};

export const EventDateCard = styled.button<StyledCardProps>`
  border: 1px solid;
  border-radius: 10px;
  transition: 0.3s;
  margin-right: ${({ index, arrayLength }) => (index === arrayLength ? '0' : '10px')};
  background-color: ${({ selected }) => (selected ? `${colors.charcoalGrey}` : 'white')};
  color: ${({ selected }) => (selected ? 'white' : `${colors.grey}`)};
  height: 67px;
  min-width: 95px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  white-space: normal;
  cursor: pointer;

  &:focus {
    outline: 1px dotted;
  }

  &:active {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
  }
`;

export const StyledDateTimeCard = styled.button<StyledCardProps>`
  border: 1px solid;
  border-radius: 10px;
  transition: 0.3s;
  margin-right: ${({ index, arrayLength }) => (index === arrayLength ? '0' : '10px')};
  background-color: ${({ selected }) => (selected ? `${colors.charcoalGrey}` : 'white')};
  color: ${({ selected }) => (selected ? 'white' : `${colors.grey}`)};
  height: 55px;
  min-width: 89px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span:first-of-type {
    padding-bottom: 3px;
  }

  &:focus {
    outline: 1px dotted;
  }

  &:active {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
  }
`;

const DateTime = styled.span`
  font-family: ${fonts.MEDIUM};
  font-size: 12px;
  letter-spacing: 0.6px;
`;

const Name = styled.div`
  font-family: ${fonts.LIGHT};
  font-size: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

type DateWeekCardProps = {
  value: string;
  handleOnClick: (value: string) => void;
  arrayLength: number;
  index: number;
  selected: boolean | undefined;
  eventDate: string | Date;
  name: string;
  timeFormat?: string;
};

export const EventWeekCard = ({
  value,
  handleOnClick,
  index,
  arrayLength,
  selected,
  eventDate,
  name,
  timeFormat,
}: DateWeekCardProps) => {
  const doOnClick = () => {
    handleOnClick(value);
  };

  const renderDate = () => {
    const date = new Date(eventDate);
    try {
      return format(date, timeFormat ?? 'LLL d').toUpperCase();
    } catch (e) {
      return format(new Date(), timeFormat ?? 'LLL d').toUpperCase();
    }
  };

  return (
    <EventDateCard
      selected={selected}
      aria-pressed={selected}
      onClick={doOnClick}
      index={index}
      arrayLength={arrayLength}
      aria-label={`${name} event on ${renderDate()}`}
    >
      <Name>{name}</Name>
      <DateTime>{renderDate()}</DateTime>
    </EventDateCard>
  );
};

type TimeRangeCardProps = {
  start: string;
  end: string;
  value: number;
  handleOnClick: (value: number) => void;
  index: number;
  arrayLength: number;
  selected: boolean | undefined;
};

export const TimeRangeCard = ({
  start,
  end,
  value,
  handleOnClick,
  index,
  arrayLength,
  selected,
}: TimeRangeCardProps) => {
  const doOnClick = () => {
    handleOnClick(value);
  };

  const DateTimeComponent = () => {
    return (
      <>
        {start && end ? (
          <>
            <DateTime>{`${start} -`}</DateTime>
            <DateTime>{`${end}`}</DateTime>
          </>
        ) : (
          <DateTime>ASAP</DateTime>
        )}
      </>
    );
  };

  return (
    <StyledDateTimeCard
      selected={selected}
      onClick={doOnClick}
      aria-pressed={selected}
      index={index}
      arrayLength={arrayLength}
      aria-label={`From ${start} to ${end}`}
    >
      <DateTimeComponent />
    </StyledDateTimeCard>
  );
};
