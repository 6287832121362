import { chain, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { CartService, CreateCartDto, LineItemDto, UpdateCartDto } from '../../services/CartService';
import ICart, { ILineItem, OrderType } from '../../shared/models/Cart';
import { useAppContext } from '../App/AppContext';
interface ICartContext {
  numberOfCartItems: number;
  alcoholProductCount: number;
  updateLineItem: (itemId: string, newQuantity: any) => Promise<void>;
  setCart: (cart: ICart | undefined) => void;
  addItemToCart: (payload: CreateCartDto) => Promise<string | undefined>;
  updateCartInfo: (payload: UpdateCartDto) => Promise<string | undefined>;
  addPromoToCart: (code: string) => Promise<void>;
  addLineItem: (
    payload: LineItemDto,
    existingQuantity: number,
    itemId: string,
    isAlcohol?: boolean,
  ) => Promise<void>;
  clearCart: () => Promise<void>;
  cart: ICart | undefined;
}

const useCartContext = ({ cartId }: { cartId?: string } = {}): ICartContext => {
  const {
    cart,
    setCart,
    venue,
    setDeliveryLocation,
    setDeliveryLocationId,
    store,
    setShowLoader,
  } = useAppContext();

  const numberOfCartItems =
    cart?.lineItems.reduce((a: number, b: ILineItem) => a + b.quantity, 0) || 0;

  const alcoholProductCount = chain(cart?.lineItems || [])
    .filter((lineItem: ILineItem) => lineItem.alcohol)
    .sumBy(lineItem => lineItem.quantity)
    .value();

  useEffect(() => {
    if (!cart && cartId) {
      getCart();
    }
  }, [cartId, cart]);

  useEffect(() => {
    if (cart && venue && store && (cart.venueId !== venue.id || cart.storeId !== store.id)) {
      setCart(undefined);
      setDeliveryLocation(undefined);
      setDeliveryLocationId(undefined);
    }
  }, [venue, store]);

  useEffect(() => {
    if (!cart) {
      return;
    }
    if (cart.orderType === OrderType.DELIVERY) {
      setDeliveryLocation(cart.location);
      setDeliveryLocationId(cart.deliveryLocationId);
    }
  }, [cart]);

  const clearCart = async () => {
    if (cart) {
      await CartService.DeleteCart(cart.id);
      setCart(undefined);
    }
  };

  const addItemToCart = async (payload: CreateCartDto) => {
    try {
      if (!cart) {
        const { data } = await CartService.CreateCart(payload);
        setCart(data);
        return data.id;
      } else if (payload.lineItem) {
        const { data } = await CartService.AddLineItem(cart.id, payload.lineItem);
        setCart(data);
        return data.id;
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  const overAlcoholLimitWarning = () =>
    toast(`Limit of ${venue?.alcoholLimit} alcoholic drinks per order`);

  const checkAlcoholOverLimit = (quantity: number, itemId: string) => {
    if (!cart || !venue) {
      return false;
    }
    const { lineItems } = cart;
    const { alcoholLimit } = venue;
    const currentItem = lineItems.find(item => item.id === itemId);
    const alcoholicQuantity = lineItems
      .filter(item => item.alcohol)
      .reduce((total: number, item) => total + item.quantity, 0);
    return currentItem && currentItem.quantity < quantity && alcoholicQuantity >= alcoholLimit;
  };

  const addLineItem = async (
    payload: LineItemDto,
    existingQuantity: number,
    itemId: string,
    isAlcohol: boolean = false,
  ) => {
    if (!cart) {
      return;
    }
    if (isAlcohol) {
      if (checkAlcoholOverLimit(existingQuantity + payload.quantity, itemId)) {
        overAlcoholLimitWarning();
        return;
      }
    }
    try {
      const { data } = await CartService.AddLineItem(cart.id, payload);
      setCart(data);
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  const updateLineItem = async (itemId: string, newQuantity: any) => {
    if (!cart) {
      return;
    }
    try {
      const { data } = await CartService.UpdateLineItem(cart.id, itemId, {
        quantity: newQuantity < 0 ? 0 : newQuantity,
      });
      if (isEmpty(data.lineItems)) {
        await clearCart();
      } else {
        setCart(data);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  const updateCartInfo = async (payload: UpdateCartDto) => {
    if (!cart) {
      return;
    }

    try {
      const { data } = await CartService.UpdateCart(cart.id, payload);
      setCart(data);
      return data.id;
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  const addPromoToCart = async (code: string) => {
    if (!cart) {
      return;
    }

    try {
      const { data } = await CartService.ApplyPromo(cart.id, {
        code,
        venueId: cart.venueId,
        storeId: cart.storeId,
      });
      setCart(data);
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  const getCart = async () => {
    if (!cartId) {
      return;
    }
    try {
      setShowLoader(true);

      const { data: currentCart } = await CartService.GetCart(cartId);

      setCart(currentCart);

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
    }
  };

  return {
    alcoholProductCount,
    addPromoToCart,
    addItemToCart,
    addLineItem,
    cart,
    clearCart,
    numberOfCartItems,
    setCart,
    updateCartInfo,
    updateLineItem,
  };
};

export default useCartContext;
