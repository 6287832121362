import React, { ReactNode } from 'react';
import { Button as FFButton, ButtonProps } from 'semantic-ui-react';
import { colors, fonts } from 'shared/theme';
import styled, { css } from 'styled-components';
import { switchProp, ifProp } from 'styled-tools';

interface Props extends ButtonProps {
  type?: 'redOutline' | 'whiteOutline' | 'solidRed';
  children?: ReactNode;
  onClick?: () => void;
  inverse?: string;
  loading?: boolean;
  disabled?: boolean;
}

const Button = styled(({ ...props }: Props) => <FFButton {...props} />)`
  &&& {
    border: solid 2px;

    ${switchProp('type', {
  redOutline: css`
        border-color: ${({ theme }) => theme.primaryColor};
        background-color: transparent;
        color: ${({ theme }) => ifProp({ inverse: 'text' }, theme.primaryColor, colors.secondary)};

        transition: background-color 0.1s ease;
        padding: 15px 32px;
        height: 50px;

        &:hover {
          background-color: ${({ theme }) => theme.primaryColor};
          color: ${({ theme }) => ifProp({ inverse: 'text' }, colors.secondary, theme.primaryColor)};
        }

        &:active {
          opacity: 0.9;
        }
      `,
  whiteOutline: css`
        border-color: ${colors.secondary};
        color: ${colors.secondary};
        background: transparent;
        transition: background-color 0.1s ease;
        padding: 15px 32px;
        height: 50px;
        line-height: 0;

        &:hover {
          background-color: ${colors.secondary};
          color: ${colors.black};
        }

        &:active {
          opacity: 0.9;
        }
      `,
  solidRed: css`
                border-color: ${({ theme }) => theme.primaryColor};
                color: ${colors.secondary}
                background: ${({ theme }) => theme.primaryColor};
                transition: background-color 0.1s ease;
                height: 50px;
                line-height: 0;
                padding: 15px 32px;


                &:hover, &:active, &:focus {
                    color: ${({ theme }) => theme.primaryColor}
                    background: ${colors.white};
                }

                &:active {
                    opacity: 0.9;
                }
            `,
})};
    border-radius: 4px;
    font-size: 1.75em;
    font-weight: 300;
    font-family: ${fonts.REGULAR};
    width: 100%;
    margin: 0;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
`;

type ModalButtonProps = {
  onClick?: () => void;
  outline?: boolean;
};

const ModalButton = styled.button<ModalButtonProps>`
  border-radius: 6px;
  border: ${({ outline }) => (outline ? 'solid 1px #147c9e' : 'none')};
  font-family: ${fonts.REGULAR};
  font-size: 15px;
  letter-spacing: -0.17px;
  color: #147c9e;
  padding: 10px 20px;
  background: none;

  &:focus {
    outline: thin dotted ${({ theme }) => theme.primaryColor};
  }

  &:hover {
    cursor: pointer;
  }
`;

export { Button, ModalButton };
