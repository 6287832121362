import FullWhiteBag from 'assets/icons/shoppingBag.png';
import CartHeader from 'components/Organisms/PWA/Headers/CartHeader/CartHeader';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container, Divider } from 'semantic-ui-react';
import { MyBagCard } from '../../components/ItemsSummary/Bag';
import { CartSummary } from '../../components/ItemsSummary/CartSummary';
import { OrderSelection } from '../../components/OrderSelection';
import {
  ButtonWrapper,
  HeaderIcon,
} from '../../components/Organisms/PWA/Headers/ProductHeader/styled';
import { useStoreContext } from '../../hooks/useStoreContext';
import { useVenueContext } from '../../hooks/useVenueContext';
import useWindowHeight from '../../hooks/UseWindowHeight';
import { BasePath } from '../../shared/constants';
import useCartContext from './context';
import { BagItemCount, CartIcon, PaymentContainer } from './styled';
import { VenueIds } from '../../shared/models/Cart';

export const BagIcon = () => {
  const [animateBag, setAnimateBag] = useState(false);
  const { numberOfCartItems } = useCartContext();

  useEffect(() => {
    const animateBag = () => {
      setAnimateBag(true);
      setTimeout(() => {
        setAnimateBag(false);
      }, 1000);
    };
    if (numberOfCartItems > 0) {
      animateBag();
    }
  }, [numberOfCartItems]);

  return (
    <>
      <CartIcon expand={numberOfCartItems > 99}>
        <ButtonWrapper animateBag={animateBag} aria-label="Go to cart" className="bagIconBtn">
          <HeaderIcon src={FullWhiteBag} alt="Cart Icon" />

          <BagItemCount shaded={true} count={numberOfCartItems}>
            {numberOfCartItems > 0 && numberOfCartItems}
          </BagItemCount>
        </ButtonWrapper>
      </CartIcon>
    </>
  );
};

const Cart = () => {
  const { cartId } = useParams<{ cartId: string }>();
  const clientHeight = useWindowHeight();
  const history = useHistory();
  const { cart } = useCartContext({
    cartId,
  });
  const { venue, venueEvents } = useVenueContext({
    venueId: cart?.venueId,
  });
  const { store } = useStoreContext({
    storeId: cart?.storeId,
    fetchLocations: true,
  });

  const backToItems = () => {
    if (cart) {
      history.replace(`/${BasePath.CONSUMER}/stores/${cart?.venueId}/products/${cart?.storeId}`, {
        return: true,
      });
    } else {
      history.replace(`/${BasePath.CONSUMER}/stores/${venue?.id}/products/${store?.id}`, {
        return: true,
      });
    }
  };

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  const handleProceedToCheckout = () => {
    history.push(`/${BasePath.CONSUMER}/payment/${cart?.id}`);
  };

  return (
    <PaymentContainer clientHeight={clientHeight}>
      <CartHeader onClick={backToItems} store={store} />
      <OrderSelection spaced entity={store} />
      <Divider className="toggle-divider" />
      <main>
        <Container>
          <MyBagCard />
          <CartSummary />
        </Container>
      </main>
      <footer>
        <Button fluid onClick={handleProceedToCheckout} className="proceedToCheckout">
          {venue && venue.id !== VenueIds.HIDE_USERDETAILS ? `Proceed to Payment` : `Proceed to checkout`}
        </Button>
      </footer>
    </PaymentContainer>
  );
};

export default Cart;
