import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Checkbox, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import chatIcon from '../../assets/icons/chatIcon.png';
import circleExclaim from '../../assets/icons/circleExclaim.png';
import closeSearchBtn from '../../assets/icons/closeSearchBtn.png';
import { OrderService } from '../../services/OrderService';
import { colors, fonts } from '../../shared/theme';

export const CancelOrderContainer = styled.div`
  .ui.card {
    width: 300px;
    height: auto;
    border-radius: 10px;
    background-color: white;
    margin: 0 auto;
    top: 50px;
    padding: 20px;
    button.ui.icon.button {
      position: absolute;
      top: 10px;
      right: 5px;
      background: none;
    }
  }
  section {
    position: relative;
    text-align: left;
    top: 70px;
    max-width: 300px;
    margin: 0 auto;
    label {
      font-family: ${fonts.REGULAR};
      font-size: 16px;
      color: ${colors.charcoalGrey};
    }
    p {
      font-family: ${fonts.LIGHT};
      font-size: 14px;
      color: ${colors.tabGrey};
      margin-top: 5px;
      margin-left: 27px;
      max-width: 250px;
    }
  }
  .header {
    padding-top: 10px;
  }
  button.ui.fluid.button {
    position: absolute;
    bottom: 20px;
    max-width: 346px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .ui.button {
    background: none;
  }
  .ui.card > .content {
    border-top: none;
    text-align: center;
    h1 {
      color: ${({ theme }) => theme.primaryColor};
      font-family: ${fonts.SUPER};
      font-size: 18px;
      text-transform: uppercase;
    }
    p {
      color: ${colors.charcoalGrey};
      font-family: ${fonts.REGULAR};
      font-size: 18px;
    }
    .chat-icon {
      max-width: 24px;
    }

    .ui.fluid.button {
      background: ${({ theme }) => theme.primaryColor} !important;
      text-transform: uppercase;
      color: white;
      font-family: ${fonts.MEDIUM};
      font-size: 18px;
      font-weight: lighter;
    }
  }

  .ui.fluid.button {
    background: ${({ theme }) => theme.primaryColor} !important;
  }

  button.ui.fluid.button {
    font-size: 18px;
    background: ${({ theme }) => theme.primaryColor};
    color: ${colors.secondary};
    text-transform: uppercase;
    height: 46px;
    max-width: 450px;
    width: calc(100% - 30px);
    margin: 0 auto;
    border-radius: 10px;
  }
`;

const CancelOrder = ({ orderId, onClose, onCancelledOrder, onChatIconClick }: any) => {
  const [confirm, setConfirm] = useState(false);

  const onConfirmCancel = (e: any, { checked }: any) => {
    setConfirm(checked);
  };

  const doCancelOrder = async () => {
    try {
      await OrderService.CancelOrder(orderId);
      onCancelledOrder();
    } catch (error) {
      toast(error.message);
    }
  };

  return (
    <CancelOrderContainer>
      <Card>
        <Card.Content>
          <Button icon onClick={onClose}>
            <Image className="close-icon" src={closeSearchBtn} style={{ width: 18 }} />
          </Button>
          <Image centered src={circleExclaim} />
          <h1>Cancel Order</h1>
          <p>Oh no! Is there something we may help you with first?</p>
          <Button onClick={onChatIconClick}>
            <Image className="chat-icon" centered src={chatIcon} />
          </Button>
        </Card.Content>
      </Card>
      <section>
        <Checkbox
          checked={confirm}
          onChange={onConfirmCancel}
          label="Yes, I would like to cancel my order."
        />
        <p>
          Your order will be canceled upon submission. Please allow 3-5 days for your refund to
          post.
        </p>
      </section>
      <Button onClick={doCancelOrder} disabled={!confirm} fluid>
        Cancel Order
      </Button>
    </CancelOrderContainer>
  );
};

export default CancelOrder;
