import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useAppContext } from '../../../containers/App/AppContext';
import { useDeliveryLocation } from '../../../hooks/Location';
import { OrderType } from '../../../shared/models/Cart';
import { OrderSelection, OrderTypeLabel } from './styled';

interface IOrderTypeToggle {
  setPickUp: () => void;
  setDelivery: () => void;
  pickUpAvailable: boolean;
  deliveryAvailable: boolean;
  showLocationModal: () => void;
}

export const OrderTypeToggle = ({
  setPickUp,
  setDelivery,
  pickUpAvailable,
  deliveryAvailable,
  showLocationModal,
}: IOrderTypeToggle) => {
  const { orderType, setOrderType } = useAppContext();
  const { deliveryLocation, deliveryLocationId } = useDeliveryLocation();

  const pickup = !!orderType && orderType === OrderType.PICKUP;
  const delivery = !!orderType && orderType === OrderType.DELIVERY;

  useEffect(() => {
    if (pickUpAvailable && !deliveryAvailable) {
      setOrderType(OrderType.PICKUP);
    }
    if (deliveryAvailable && !pickUpAvailable) {
      setOrderType(OrderType.DELIVERY);
      if (isEmpty(deliveryLocation) && !deliveryLocationId) {
        showLocationModal();
      }
    }
  }, [pickUpAvailable, deliveryAvailable]);

  return (
    <OrderSelection className="order_selection">
      {!pickUpAvailable && !deliveryAvailable && (
        <>
          <OrderTypeLabel disabled={true} checked={false}>
            Unavailable
          </OrderTypeLabel>
        </>
      )}
      {pickUpAvailable && (
        <>
          <OrderTypeLabel
            disabled={!pickUpAvailable}
            checked={pickUpAvailable && pickup}
            onClick={e => setPickUp()}
          >
            Pickup
          </OrderTypeLabel>
        </>
      )}

      {deliveryAvailable && (
        <>
          <OrderTypeLabel
            disabled={!deliveryAvailable}
            checked={deliveryAvailable && delivery}
            onClick={e => setDelivery()}
          >
            Delivery
          </OrderTypeLabel>
        </>
      )}
    </OrderSelection>
  );
};
