import { chain, isEmpty } from 'lodash';
import * as QueryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../containers/App/AppContext';
import useCartContext from '../../containers/Cart/context';
import { useCartHandler } from '../../hooks/handlers/cart';
import { useDeliveryLocation } from '../../hooks/Location';
import { useOrderTypeHook } from '../../hooks/useOrderType';
import { useVenueContext } from '../../hooks/useVenueContext';
import { useCartValidation } from '../../hooks/validators/cart_validators';
import { Event } from '../../services/EventsService';
import { IDeliveryLocation, OrderType } from '../../shared/models/Cart';
import LocationType from '../../shared/models/LocationType';
import { AvailabilityType } from '../../shared/models/Product';
import Store from '../../shared/models/Store';
import Venue from '../../shared/models/Venue';
import { DeliveryLocation } from '../DeliveryLocation';
import AnimatedModal from '../Molecules/AnimatedModal/AnimatedModal';
import { DeliveryLocationModal } from '../Organisms/Modals/Location/DeliveryLocationModal';
import ModalLocation from '../Organisms/Modals/Location/ModalLocation';
import OrderAheadModal from '../Organisms/Modals/OrderAhead';
import { OrderTimeDropDown } from './EventSelect';
import { OrderTypeToggle } from './OrderTypeToggle';
import { OrderChangeButtonWrapper, OrderTypeContainer } from './OrderTypeToggle/styled';

interface IOrderSelection {
  entity?: Venue | Store;
  spaced?: boolean;
  deepLink?: boolean;
}

const isStore = (entity: Venue | Store | undefined): entity is Store => {
  return !!entity && (entity as Store).venueId !== undefined;
};

export const OrderSelection = ({ entity, spaced, deepLink }: IOrderSelection) => {
  const { pickup, delivery, orderAhead, orderNow } = useOrderTypeHook({
    isStore: isStore(entity),
  });
  const [showOrderAheadModal, setShowOrderAheadModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showChangeOrderTypeWarning, setShowChangeOrderTypeWarning] = useState(false);
  const { venueEvents, venueLocations, venue } = useVenueContext({
    fetchEvents: showOrderAheadModal && orderAhead,
  });
  const { cart } = useCartContext();
  const { checkCartOrderTypeItemsInvalid } = useCartValidation();
  const { updateCart } = useCartHandler();
  const { setOrderType, orderType, setDeliveryLocationId } = useAppContext();
  const { deliveryLocationId, setDeliveryLocation, updateDeliveryLocation } = useDeliveryLocation();
  const location = useLocation();

  useEffect(() => {
    if (!venue || !deepLink) return;

    const queryString = QueryString.parse(location.search);

    // New Delivery Location Deep Link
    if (queryString.dl && venueLocations && venue.useNewLocations && !deliveryLocationId) {
      setOrderType(OrderType.DELIVERY);
      setDeliveryLocationId(queryString.dl as string);
      setShowLocationModal(true);
      return;
    }

    if (!queryString.type || !Object.keys(OrderType).includes(queryString.type as string)) {
      return;
    }

    setOrderType(queryString.type as OrderType);

    if (queryString.type === OrderType.DELIVERY) {
      if (
        queryString.area &&
        queryString.location &&
        venue.validLocationTypes &&
        venue.validLocationTypes.length > 0
      ) {
        const isValidLocation = chain(venue.validLocationTypes)
          .filter((locationType: LocationType) => locationType.name === queryString.area)
          .find((locationType: LocationType) =>
            locationType.validValues.includes(queryString.location as string),
          )
          .value();

        if (isValidLocation) {
          setDeliveryLocation([
            { name: queryString.area as string, value: queryString.location as string },
          ]);
        }
        if (isEmpty(isValidLocation?.validLocationTypes)) {
          return;
        }
      }
      setShowLocationModal(true);
    }
  }, [venue, venueLocations]);

  const setPickUp = async (confirm?: boolean) => {
    if (
      !confirm &&
      cart &&
      cart.orderType !== OrderType.PICKUP &&
      checkCartOrderTypeItemsInvalid(AvailabilityType.PICKUP)
    ) {
      setShowChangeOrderTypeWarning(true);
      return;
    }
    setOrderType(OrderType.PICKUP);
    setDeliveryLocation(undefined);
    if (cart && cart.orderType !== OrderType.PICKUP) {
      await updateCart({
        orderType: OrderType.PICKUP,
      })();
    }
  };

  const setDelivery = (confirm?: boolean) => {
    if (
      !confirm &&
      cart &&
      cart.orderType !== OrderType.DELIVERY &&
      checkCartOrderTypeItemsInvalid(AvailabilityType.DELIVERY)
    ) {
      setShowChangeOrderTypeWarning(true);
      return;
    }
    setShowLocationModal(true);
  };

  const handleLocationSubmit = async (location: IDeliveryLocation[] | undefined) => {
    if (!location || location.length === 0) {
      if (pickup) {
        setPickUp(true);
      }
    }
    setShowLocationModal(false);
    await updateDeliveryLocation(location);
  };

  const handleEventUpdate = async (event?: Event | undefined) => {
    setShowOrderAheadModal(false);
    if (!cart) {
      return;
    }
    const { eventId, scheduledTime } = cart;

    if (event && (eventId !== event.id || scheduledTime !== event.scheduledTime)) {
      await updateCart({
        eventId: event.id,
        scheduledTime: event.scheduledTime,
        gameDayOrder: true,
      })();
      return;
    }

    if (!event && (eventId || scheduledTime)) {
      await updateCart({
        eventId: null,
      })();
    }
  };

  const handleConfirmOrderTypeChange = async () => {
    setShowChangeOrderTypeWarning(false);
    const isOrderPickUp = cart?.orderType === OrderType.PICKUP;
    if (isOrderPickUp) {
      setDelivery(true);
      return;
    } else {
      setPickUp(true);
    }
  };
  return (
    <>
      {(pickup || delivery) && (
        <OrderTypeContainer spaced={spaced}>
          <OrderTypeToggle
            setPickUp={setPickUp}
            setDelivery={setDelivery}
            pickUpAvailable={pickup}
            deliveryAvailable={delivery}
            showLocationModal={() => setShowLocationModal(true)}
          />
          {(orderNow || orderAhead) && (
            <OrderTimeDropDown
              orderAheadAvailable={orderAhead}
              orderNowAvailable={orderNow}
              openModal={() => setShowOrderAheadModal(true)}
            />
          )}
        </OrderTypeContainer>
      )}
      {delivery && orderType === OrderType.DELIVERY && (
        <DeliveryLocation showLocationModal={setShowLocationModal} />
      )}
      <OrderAheadModal
        showModal={showOrderAheadModal}
        onCloseModal={() => setShowOrderAheadModal(false)}
        events={venueEvents}
        handleSubmit={handleEventUpdate}
        orderAheadAvailable={orderAhead}
        orderNowAvailable={orderNow}
      />
      <AnimatedModal
        showModal={showChangeOrderTypeWarning}
        onCloseModal={() => setShowChangeOrderTypeWarning(false)}
      >
        <AnimatedModal.Title
          copy={`${cart?.orderType === OrderType.PICKUP ? 'PICKUP' : 'DELIVERY'}`}
        />
        <AnimatedModal.Content>
          {`Your order is currently set for ${
            cart?.orderType === OrderType.PICKUP ? 'Pickup' : 'Delivery'
          }.
            If you switch to ${cart?.orderType === OrderType.PICKUP ? 'Delivery' : 'Pickup'},
            some items will be removed from your bag.`}
        </AnimatedModal.Content>
        <OrderChangeButtonWrapper>
          <AnimatedModal.Button
            copy="CANCEL"
            outline={true}
            onClick={() => setShowChangeOrderTypeWarning(false)}
          />

          <AnimatedModal.Button copy="OKAY" outline={true} onClick={handleConfirmOrderTypeChange} />
        </OrderChangeButtonWrapper>
      </AnimatedModal>
      <DeliveryLocationModal
        showModal={!!(showLocationModal && venue?.useNewLocations)}
        handleOnCloseModal={() => setShowLocationModal(false)}
      />
      <ModalLocation
        showModal={showLocationModal && !venue?.useNewLocations}
        handleOnCloseModal={() => setShowLocationModal(false)}
        handleSubmitLocation={handleLocationSubmit}
      />
    </>
  );
};
