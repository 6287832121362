import { ReactNode } from 'react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

export enum Size {
  ENORMOUS = '8em',
  HUGE = '6em',
  LARGE = '45px',
  MEDIUM = '35px',
  SMALL = '32px',
  MINI = '1.8em',
  TINY = '1.5em',
  INFIMUM = '1.2em',
  MINUSCULE = '1em',
}

export interface HeaderProps {
  font?: 'SUPER' | 'MEDIUM' | 'REGULAR' | 'LIGHT' | 'BOLD';
  color?: string;
  letterSpacing?: string | number;
  size?: Size | number;
  upperCase?: boolean;
  children?: ReactNode;
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  truncated?: boolean;
}
const Header = styled.h2<HeaderProps>`
  &&& {
    font-family: ${props => (props.font ? fonts[props.font] : fonts.REGULAR)};
    color: ${props => (props.color ? props.color : colors.black)};
    font-size: ${props =>
      typeof props?.size === 'number' ? props.size + 'px' : props.size || Size.MINI};
    text-transform: ${props => (props.upperCase ? 'uppercase' : 'unset')};
    text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
    letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : '0.72px')};
    white-space: ${props => (props.truncated ? 'nowrap' : 'unset')};
    overflow: ${props => (props.truncated ? 'hidden' : 'unset')};
    text-overflow: ${props => (props.truncated ? 'ellipsis' : 'unset')};
    margin: 0;
  }
`;

export default Header;
