import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors, fonts } from '../../shared/theme';

export const ProductListingContainer = styled.div<{ cartCount: number; clientHeight: number }>`
  display: flex;
  flex-direction: column;
  max-height: ${props => `${props.clientHeight}px`};
  .ui.clearing.segment {
    box-shadow: none;
    border: 0;
    &.products {
      margin-top: 10px;
      padding-top: 10px;
    }
  }
  .ui.button {
    border-radius: 10px;
    font-size: 12px;
    font-family: ${fonts.MEDIUM};
  }
  .ui.fluid.button {
    background: ${({ theme }) => theme.primaryColor};
    color: ${colors.secondary};
    height: 46px;
    max-width: 450px;
    margin: 0 auto;

    span {
      margin-left: 0 auto;
    }
  }
  .ui.secondary.pointing.menu {
    margin-left: 15px;
    margin-bottom: 0px;
    overflow-y: hidden !important;
    overflow-x: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
      display: none;
    }
    .item {
      font-size: 16px;
      border-bottom-width: 4px;
      font-family: ${fonts.REGULAR};
      font-weight: lighter;
      color: ${colors.tabGrey};
      padding: 8px 11px;
    }
    .item.active {
      font-size: 16px;
      border-bottom: 4px solid;
      font-family: ${fonts.MEDIUM};
      font-weight: lighter;
      color: ${colors.secondary};
    }
  }
  main {
    height: ${props =>
      props.cartCount > 0
        ? `calc(${props.clientHeight}px - 270px)`
        : `calc(${props.clientHeight}px - 204px)`};
    overflow-y: auto;
  }

  footer {
    padding: 10px 15px 10px 15px;
    margin-top: auto;
    position: relative;
    button.ui.fluid.button {
      display: flex;
      justify-content: center;
      background: ${({ theme }) => theme.primaryColor};
      color: ${colors.secondary};
      text-transform: uppercase;
      border-radius: 10px;
      font-size: 18px;
      font-weight: lighter;

      span {
        margin-top: 3px;
      }
    }
  }
`;

export const MenuTitleContainer = styled.div`
  margin: -10px 10px auto;
`;

export const MenuHeader = styled.h2`
  font-size: 18px;
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${fonts.SUPER};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
`;

export const OrderMin = styled.div`
  font-family: ${fonts.REGULAR};
  font-size: 14px;
  letter-spacing: 0;
  color: ${colors.charcoalGrey};
`;

export const OrderChangeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`;

export const NoProductsAvailableText = styled.p`
  text-align: center;
  font-size: 14px;
  color: ${colors.charcoalGrey};
  font-family: ${fonts.LIGHT};
`;

export const StyledMenu = styled(Menu)`
  &.ui.secondary.pointing.menu {
    margin-left: 0;
    margin-right: 0;
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);
    margin-top: 0;
  }
  button.active.item {
    border-color: ${({ theme }) => theme.primaryColor}!important;
    color: ${({ theme }) => theme.primaryColor}!important;
  }
`;
