import styled from 'styled-components';
import { fonts } from 'shared/theme';
import { Grid, Image } from 'semantic-ui-react';

const StyledRow = styled(Grid.Row)`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 8px solid;
  width: 285px;
  color: ${({ theme }) => theme.primaryColor};
`;

const ContainerBorderBottom = styled.div`
  border-bottom: 1px solid #979797;
`;

const CheckCircle = styled(Image)`
  height: 16px;
  width: 16px;
`;

const StepItem = styled.p`
  color: ${({ theme }) => theme.primaryColor};
  font-family: ${fonts.BOLD};
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CirclesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const CircleWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child):after {
    content: '';
    position: relative;
    width: 94px;
    height: 1px;
    background: #979797;
    z-index: 1;
  }
`;

const SkipButton = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.primaryColor};
  font: 15px ${fonts.REGULAR};
  padding: 0;
  position: absolute;
  right: 5%;
  cursor: pointer;

  &:focus {
    outline: dotted thin ${({ theme }) => theme.primaryColor};
  }
`;

export {
  SkipButton,
  CircleWrapper,
  CirclesWrapper,
  StepItem,
  CheckCircle,
  ContainerBorderBottom,
  Container,
  StyledRow,
};
