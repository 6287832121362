import { ApiResponse, APIService, IPaginationResponse } from './APIService';
const basePath = '/events';

export interface Event {
  id: string;
  name: string;
  description?: string;
  active?: boolean;
  venueId: string;
  startDate: string;
  endDate: string;
  advanceOrderWindow: number;
  scheduledTime?: string;
}

export interface ITimeSlot {
  start: string;
  end: string;
  orders: number;
}

export interface TimeSlots {
  maxOrdersPerInterval: number;
  slots: ITimeSlot[];
}

interface FormData {
  future?: boolean;
  limit?: number;
  venueId: string;
  gameDayEvent?: boolean;
}

export class EventsService {
  static async getAllEvents(formData: FormData): Promise<ApiResponse<IPaginationResponse<Event>>> {
    return await APIService.getData(`${basePath}`, formData);
  }

  static async getEvent(eventId: string): Promise<ApiResponse<Event>> {
    return await APIService.getData(`${basePath}/${eventId}`);
  }

  static async getEventTimeSlots(eventId: string): Promise<ApiResponse<TimeSlots>> {
    return await APIService.getData(`${basePath}/${eventId}/slot-intervals`);
  }

  static async getSuiteEvents(suiteId: string): Promise<ApiResponse<Event[]>> {
    return await APIService.getData(`${basePath}/suites/${suiteId}`);
  }
}
