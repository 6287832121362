import React from 'react';

export enum SpacerSizes {
  BIG = '100px',
  MEDIUM = '50px',
  SMALL = '30px',
  TINY = '20px',
  MINI = '10px',
}

export interface SpacerProps {
  size: SpacerSizes;
}

const Spacer = (props: SpacerProps) => {
  return <div style={{ marginBottom: props.size }} />;
};

export default Spacer;
