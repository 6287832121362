import { useMachine } from '@xstate/react/lib';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { BasePath } from 'shared/constants';
import poweredByGoogle from '../../../assets/images/poweredByGoogle.png';
import { VenueService } from '../../../services';
import Venue, { PaymentGateway } from '../../../shared/models/Venue';
import SearchInput from '../../Atoms/SearchInput';
import searchBarMachine from './machine';
import {
  GridContainer,
  NearByText,
  PoweredByGoogleImageContainer,
  SuggestionContainer
} from './styled';

type SearchBarProps = {
  onResults: (venue: any) => void;
  venueType?: string;
  includeInactive?: boolean;
  showAutoComplete?: boolean;
  noResultsMsg?: string;
  searchOnFirstLoad?: boolean;
  noResultsColor?: string;
  limit?: number;
};

interface SuggestionParams {
  lat: number;
  lng: number;
}

const SearchBar = ({
  onResults,
  venueType,
  includeInactive,
  showAutoComplete = true,
  noResultsMsg = 'No Results Found',
  noResultsColor,
  searchOnFirstLoad = false,
  limit,
}: SearchBarProps) => {
  const inputSearch: any = document.getElementById('input-search');
  const history = useHistory();
  const [current, send] = useMachine(searchBarMachine);
  const [address, setAddress] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [selected, setSelected] = useState(false);

  const getSuggested = async (suggestionParams: SuggestionParams) => {
    try {
      setNoResults(false);
      const venuesResponse = await VenueService.GetAllSuggested({
        venueType,
        includeInactive,
        radius: 100,
        ...suggestionParams,
      });
      const venues = venuesResponse.data;
      if ((venues.data || []).length === 0) {
        setNoResults(true);
      }
      onResults(venues.data);
    } catch (error) {
      toast('Something went wrong!');
    }
  };

  const handleSelect = (_address: string, venueId: string) => {
    if (inputSearch) {
      inputSearch.value = _address;
    }
    if (venueId.length === 36) {
      history.push(`/${BasePath.CONSUMER}/stores/${venueId}`);
      return;
    }
    setAddress(_address);
    geocodeByAddress(_address)
      .then(results => getLatLng(results[0]))
      .then(latLng => getSuggested(latLng))
      .catch(error => console.error('Error', error));
    setSelected(true);
  };

  const handleSearchChangeVenue = async (value: string) => {
    if (value || searchOnFirstLoad) {
      send({ type: 'TYPING', query: value });
      try {
        const params = {
          search: value,
          type: venueType,
          includeInactive,
          limit,
          paymentGateways: [PaymentGateway.SQUARE, PaymentGateway.STRIPE].join(','),
        };
        const venueList = await VenueService.GetAllVenues(params);
        const formattedVenues = venueList.data.items.map((entry: Venue) => ({
          title: entry.name,
          description: entry.name,
          placeId: entry.id,
        }));

        // the intent here is to set results as input changes
        // PWA homepage sets these results after clicking on an auto-complete selection
        if (!showAutoComplete) {
          onResults(venueList.data.items);
          if ((venueList.data.items || []).length === 0) {
            setNoResults(true);
          }
        }
        send({ type: 'RESOLVE', results: formattedVenues });
      } catch (e) {
        send('REJECT');
      }
    } else {
      onResults([]);
      send('CLEAR');
    }
  };

  const handleChange = (_address: string) => {
    setAddress(_address);
    setSelected(false);
    setNoResults(false);
    handleSearchChangeVenue(_address);
  };

  const clearSearch = () => {
    setNoResults(false);
    setSelected(false);
    setAddress('');
    if (inputSearch) {
      inputSearch.value = '';
    }

    if (searchOnFirstLoad) {
      handleSearchChangeVenue('');
    } else {
      onResults([]);
    }
  };

  useEffect(() => {
    if (searchOnFirstLoad) {
      (async () => {
        await handleSearchChangeVenue('');
      })();
    }
  }, []);

  return (
    <GridContainer>
      <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => {
          suggestions = (current.context.results || []).concat(suggestions);
          const suggestionsLength = suggestions.length - 1;
          return (
            <div className="location-search-input">
              <SearchInput
                loading={loading}
                isTyping={address !== ''}
                clearSearch={clearSearch}
                {...getInputProps({})}
                onKeyDown={() => {
                  return true; // prevents throwing errors when users hit 'Enter'
                }}
              />
              {showAutoComplete && (
                <div className="custom-container-classname">
                  {address !== '' &&
                    !selected &&
                    suggestions.map((suggestion: any, index: number) => {
                      const className = suggestion.active
                        ? 'custom-suggestion-classname--active'
                        : suggestion.title
                        ? 'custom-suggestion-classname'
                        : 'google-suggestion';
                      return (
                        <SuggestionContainer key={suggestion.placeId} className={className}>
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className: 'suggestion-item',
                            })}
                          >
                            <span>{suggestion.description || suggestion.title}</span>
                          </div>
                          {suggestionsLength === index && !suggestion.title && (
                            <PoweredByGoogleImageContainer>
                              <img src={poweredByGoogle} />
                            </PoweredByGoogleImageContainer>
                          )}
                        </SuggestionContainer>
                      );
                    })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
      {noResults === true && <NearByText color={noResultsColor}>{noResultsMsg}</NearByText>}
    </GridContainer>
  );
};

export default SearchBar;
