import { Menu } from 'semantic-ui-react';
import { colors, fonts } from 'shared/theme';
import styled from 'styled-components';

export const PackageName = styled.h2`
  font-family: ${fonts.REGULAR};
  font-size: 25px;
  color: ${colors.charcoalGrey};
  text-transform: none;
`;

export const PackageListContainer = styled.div`
  .ui.segment.categoryMenu {
    border:none;
    box-shadow:none;
  }
  .productCard {
    .ui.fluid.card {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
      height: 235px
      max-height: 235px;
      .content,.product-details{
        height:235px;

        .header {
          font-size: 20px;
        }

        .meta {
          font-size: 15px;
        }

        .image {
          img {
          width: 100%;
          height: 235px
          min-height: 235px
          }
        }
      }
      &:hover {
        box-shadow: 0 0 20px rgba(33, 33, 33, 0.2);
      }
    }
  }
`;

export const CategoryMenu = styled(Menu)`
  &.ui.pointing.secondary.menu {
    width: 100%;
    margin-left: 15px;
    margin-bottom: 0px;
    overflow-y: hidden !important;
    overflow-x: scroll;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
      display: none;
    }
    .item {
      font-size: 20px;
      font-family: ${fonts.REGULAR};
      color: ${colors.charcoalGrey};
      padding: 8px 11px;
    }
    .item.active {
      font-size: 20px;
      border-bottom: 4px solid;
      font-family: ${fonts.MEDIUM};
      color: ${colors.secondary};
    }

    button.active.item {
      border-bottom: 10px solid ${({ theme }) => theme.primaryColor}!important;
      color: ${({ theme }) => theme.primaryColor}!important;
    }
  }
`;
