import React, { Dispatch, SetStateAction } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppContext } from '../../containers/App/AppContext';
import { useDeliveryLocation } from '../../hooks/Location';
import { OrderType } from '../../shared/models/Cart';
import { colors, fonts } from '../../shared/theme';

interface DeliveryLocationProps {
  showLocationModal: Dispatch<SetStateAction<boolean>>;
}

export const LocationCard = styled(Card)`
  &.ui.fluid.card.delivery-location-card {
    margin: 10px 10px 0px auto;
    max-width: 95%;
    width: 95%;
    padding: 5px !important;
    min-height: auto;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  }
`;

const DeliveryLocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const DeliveryLocationIconAndText = styled.div`
  display: flex;
  justify-content: stretch;
`;

const DeliveryLocationIcon = styled.div`
  padding: 5px;
  margin-top: -5px;
  & > i.icon {
    color: ${({ theme }) => theme.primaryColor};
    font-size: 1.3em;
    margin-top: 2px;
  }
`;

const LocationPinCircle = styled.div`
  background: transparent;
  width: 5px;
  height: 5px;
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  transform: rotateX(50deg);
  margin: -10px 5px 5px;
  padding: 5px;
`;

const DeliveryLocationText = styled.div`
  padding-top: 5px;
  span {
    font-family: ${fonts.LIGHT};
    font-size: 1em;
  }
`;

const DeliveryLocationEdit = styled.div`
  margin-right: -10px;
  .ui.basic.button {
    box-shadow: none;
    font-family: ${fonts.REGULAR};
    font-size: 0.9em;
    font-weight: lighter;
    color: ${colors.niceBlue}!important;
    text-transform: uppercase;
    padding-right: 10px;
    margin-top: -2px;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      color: ${colors.niceBlue}!important;
    }
  }
`;

export const DeliveryLocation = ({ showLocationModal }: DeliveryLocationProps) => {
  const { deliveryLocation } = useDeliveryLocation();
  const { orderType, venue } = useAppContext();

  if (!venue || orderType !== OrderType.DELIVERY) {
    return null;
  }

  const deliveryLocationName = (deliveryLocation ?? [])
    .map(({ name, value }) => `${name} ${value}`)
    .join(', ');

  return (
    <LocationCard className="delivery-location-card" fluid>
      <DeliveryLocationContainer>
        <DeliveryLocationIconAndText>
          <DeliveryLocationIcon>
            <Icon className="point"></Icon>
            <LocationPinCircle />
          </DeliveryLocationIcon>
          <DeliveryLocationText>
            <span>{deliveryLocationName}</span>
          </DeliveryLocationText>
        </DeliveryLocationIconAndText>

        <DeliveryLocationEdit>
          <Button basic onClick={() => showLocationModal(true)}>
            Edit
          </Button>
        </DeliveryLocationEdit>
      </DeliveryLocationContainer>
    </LocationCard>
  );
};
