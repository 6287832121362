import React, { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors, fonts } from '../../../shared/theme';
import { useSquareContext } from '../context';
import { Card } from '../types';

interface ISquareCard {
  handleCardSave: (token: string | undefined) => Promise<void>;
  setOpenCC: Dispatch<SetStateAction<boolean>>;
}

const SquareFormContainer = styled.div`
  #square-card {
    width: 100%;
    border-bottom: 1px solid #6e6e6e;
  }
  .ui.fluid.button {
    background: ${({ theme }) => theme.primaryColor};
    text-transform: uppercase;
    color: white;
    font-family: ${fonts.MEDIUM};
    font-size: 18px;
    font-weight: lighter;
  }
  .ui.segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    box-shadow: none;
  }
  .title {
    color: ${colors.charcoalGrey};
    font-family: ${fonts.LIGHT};
    font-size: 20px;
    font-weight: 300;
    padding-bottom: 10px;
  }
`;

export default function SquareCard({ handleCardSave, setOpenCC }: ISquareCard) {
  const [card, setCard] = useState<Card | null>(null);
  const [isError, setError] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const { squarePayments, initCard, tokenize } = useSquareContext();

  useLayoutEffect(() => {
    (async () => {
      if (squarePayments && !card && !saveDisabled) {
        setCard(await initCard('#square-card'));
      }
    })();
  }, [squarePayments, setCard, saveDisabled]);

  useEffect(() => {
    if (card) {
      card.addEventListener('errorClassAdded', e => {
        setError(true);
      });
      card.addEventListener('errorClassRemoved', e => {
        setError(false);
      });
    }

    return () => {
      if (card) {
        card.removeEventListener('errorClassAdded', () => {});
        card.removeEventListener('errorClassRemoved', () => {});
      }
    };
  }, [card]);

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!card || !squarePayments) {
      return;
    }
    setSaveDisabled(true);
    const tokenResult = await tokenize(card);

    if (!tokenResult) {
      setError(true);
    } else {
      setOpenCC(false);
      await handleCardSave(tokenResult.token);
    }
    setSaveDisabled(false);
  };

  return (
    <SquareFormContainer>
      <Segment loading={!card}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="title">Card Information</div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid.Row>
                <div id="square-card"></div>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Button
                onClick={async e => {
                  await handleSave(e);
                }}
                disabled={saveDisabled || isError}
                fluid
                content="Save"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </SquareFormContainer>
  );
}
