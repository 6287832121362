import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { fonts } from '../../../shared/theme';

export const OrderTimeDetails = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const OrderTimeSummary = styled.div<{ bold: boolean | undefined }>`
  align-self: flex-start;
  cursor: pointer;
  list-style: none;
  font-size: 1.1em;
  font-family: ${({ bold }) => (bold ? fonts.MEDIUM : fonts.LIGHT)};
  text-align: left;
  padding: 5px 0px;
  margin-left: 5px;
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px none;
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const DropDownArrow = styled(Icon)`
  &.icon.angle.down {
    align-self: flex-end;
    transform: translateY(-50%);
    transform-origin: center center;
    color: rgba(0, 0, 0, 0.25);
    width: 10px;
    height: 10px;
    margin: 10px 0px 0px 0px !important;
  }
`;
