import React from 'react';

import { BasePath } from 'shared/constants';
import { colors, fonts } from '../../../shared/theme';
import { Link } from 'react-router-dom';
import FFLogo from '../../../assets/images/fanFoodLogo.png';
import PagePositionTop from '../PagePositionTop';
import styled, { css } from 'styled-components';

const StyledFooter = styled.footer<{ transparent?: boolean }>`
  min-height: 110px;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : `${colors.black}`)};
  display: grid;
  justify-items: center;
  padding: 20px 0;
  grid-gap: 20px;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledLinkCss = css`
  color: ${colors.white};
  font-family: ${fonts.LIGHT};
  text-decoration: none;
  text-align: center;

  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const StyledInternalLink = styled(Link)`
  ${StyledLinkCss}
`;
const StyledExternalLink = styled.a`
  ${StyledLinkCss}
`;

const LogoText = styled.div`
  color: ${colors.white};
  font-size: 15px;
  font-family: ${fonts.REGULAR};
  margin-right: 5px;
  margin-bottom: 7px;
`;

const LinkContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  padding: 20px 0;
  place-items: center center;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(4, auto);
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    grid-row: 2;
  }
`;

const Logo = styled.img`
  width: 120px;
`;

const Footer = ({ transparent }: { transparent?: boolean }) => {
  return (
    <StyledFooter transparent={transparent}>
      <PagePositionTop />
      <LogoContainer>
        <LogoText>Powered By</LogoText>

        <Logo src={FFLogo} alt='Fan food logo' />
      </LogoContainer>
      <LinkContainer>
        <StyledInternalLink to={`/${BasePath.BUILDING_EATS}/about`}>
          About Building Eats
        </StyledInternalLink>
        <StyledExternalLink href='mailto:GetStarted@BuildingEats.com'>
          Get Started
        </StyledExternalLink>
        <StyledExternalLink href='mailto:Support@BuildingEats.com'>Support</StyledExternalLink>
      </LinkContainer>
    </StyledFooter>
  );
};

export default Footer;
