import SkeletonLoader from 'components/Molecules/SkeletonLoader';
import StoreListingHeader from 'components/Organisms/PWA/Headers/StoreListingHeader';
import ChangeOrderLocationModal from 'containers/ChangeOrderLocationModal';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { OrderSelection } from '../../components/OrderSelection';
import { NoStoresCard, StandCard } from '../../components/StandCard';
import useCartContext from '../../containers/Cart/context';
import { useVenueContext } from '../../hooks/useVenueContext';
import { BasePath } from '../../shared/constants';
import Store from '../../shared/models/Store';
import { useBuildingEatsContext, useEventContext } from '../App/AppContext';
import {
  ConsessionStandTitle,
  FoodOptions,
  LoadingHeaderContainer,
  ScrollContainer,
  SkeletonListingsContainer,
  SkeletonLoaderContainer,
  SlantedButtonSkeleton,
} from './styled';

const StoreListing = () => {
  const history = useHistory();
  const { venueId } = useParams<{ venueId: string }>();
  const { cart, clearCart } = useCartContext();
  const { event } = useEventContext();
  const { buildingEatsVenueId, setBuildingEatsVenueId } = useBuildingEatsContext();
  const { venue: currentVenue, dataLoading: isLoading, venueSponsor, stores } = useVenueContext({
    venueId,
    fetchLocations: true,
    fetchStores: true,
    fetchSponsors: true,
  });

  const [newStore, setNewStore] = useState<Store | undefined>(undefined);
  const [showChangeStoreWarning, setShowChangeStoreWarning] = useState(false);

  const goToStore = (selectedStoreId: string) => {
    history.push(`/${BasePath.CONSUMER}/stores/${currentVenue?.id}/products/${selectedStoreId}`, {
      currentVenue,
    });
  };

  const handleStoreSelect = (selectedStore: Store) => {
    if (!cart) {
      goToStore(selectedStore.id);
      return;
    }

    if (cart.storeId === selectedStore.id && selectedStore.active) {
      goToStore(selectedStore.id);
      return;
    }

    if (cart.storeId !== selectedStore.id && selectedStore.active) {
      setNewStore(selectedStore);
      setShowChangeStoreWarning(true);
      return;
    }

    if (cart.storeId !== selectedStore.id && !selectedStore.active) {
      goToStore(selectedStore.id);
      return;
    }
  };

  const handleNavigateBack = () => {
    if (buildingEatsVenueId) {
      setBuildingEatsVenueId(undefined);
      history.push(`/${BasePath.BUILDING_EATS}/${buildingEatsVenueId}`);
      return;
    }

    history.push('/');
  };

  const handleStartNewOrder = async (newStore: any) => {
    await clearCart();
    setShowChangeStoreWarning(false);
    goToStore(newStore.id);
  };

  return (
    <>
      {currentVenue && (
        <>
          <StoreListingHeader
            entity={currentVenue}
            handleBack={handleNavigateBack}
            venueSponsor={venueSponsor}
          >
            <OrderSelection deepLink={true} entity={currentVenue} />
          </StoreListingHeader>
          <ConsessionStandTitle>Locations</ConsessionStandTitle>
          {stores && !isLoading && (
            <ScrollContainer>
              <Container textAlign="center">
                {stores &&
                  stores
                    .filter((store: Store) => store.visible)
                    .map((store: Store) => (
                      <StandCard
                        key={store.id}
                        onClick={() => handleStoreSelect(store)}
                        store={store}
                        showLoadingState={false}
                        orderAheadSchedule={event?.scheduledTime}
                        venue={currentVenue}
                      />
                    ))}
                {stores && stores.length === 0 && <NoStoresCard />}
              </Container>
            </ScrollContainer>
          )}
        </>
      )}

      {!currentVenue && isLoading && (
        <>
          <SkeletonLoaderContainer>
            <LoadingHeaderContainer>
              <SkeletonLoader height={140} />
              <SlantedButtonSkeleton>
                <SkeletonLoader height={50} />
              </SlantedButtonSkeleton>
            </LoadingHeaderContainer>
            <FoodOptions>
              <SkeletonLoader height={20} width={150} />
            </FoodOptions>
          </SkeletonLoaderContainer>
        </>
      )}
      {(!stores || isLoading) && (
        <>
          <SkeletonLoaderContainer>
            <SkeletonListingsContainer>
              <Container textAlign="center">
                {Array.from({ length: 2 }, (_, k) => (
                  <StandCard key={k} onClick={() => {}} store={undefined} showLoadingState={true} />
                ))}
              </Container>
            </SkeletonListingsContainer>
          </SkeletonLoaderContainer>
        </>
      )}

      {newStore && (
        <ChangeOrderLocationModal
          newLocation={newStore.name}
          onAccept={() => handleStartNewOrder(newStore)}
          onCloseModal={() => setShowChangeStoreWarning(false)}
          showModal={showChangeStoreWarning}
        />
      )}
    </>
  );
};

export default StoreListing;
